import * as Yup from 'yup';
import { useCallback, useEffect, useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import Box from '@mui/material/Box';
import {
    RHFTextField,
    RHFSelect,
} from 'src/components/hook-form';
import { Accordion, AccordionDetails, AccordionSummary, Divider, Grid, MenuItem, Typography } from '@mui/material';
import { GLOBALCLASSINPUT } from 'src/utils/contants/config-common';
import { getLookUp } from 'src/redux/slices/lookup';
import { useLocales } from 'src/locales';
import { DatePicker } from '@mui/x-date-pickers';
import { IExchange } from 'src/types/common';
import { Controller, useForm } from 'react-hook-form';
import Iconify from 'src/components/iconify';

// ----------------------------------------------------------------------


export interface FormValuesProps extends IExchange {

}

type Props = {
    onChange: (address: IExchange) => void;
    gridTemplateColumns: any;
    isLoyality?:any;
};

export default function ExchangeForm({ onChange, gridTemplateColumns = 3,isLoyality=false }: Props) {
    const [varientLookUp, setVarientLookUp] = useState([] as any);
    const [yearLookUp, setYearLookUp] = useState([] as any);
    const [hypothecatedLookUp, setHypothecatedLookUp] = useState([] as any);
    const [relationShipLookUp, setRelationShipLookUp] = useState([] as any);
    const [controlled, setControlled] = useState<string | false>("VEHICLEDETAIL");
    const [monthsLookUp, setMonthsLookUp] = useState([{ value: 1, label: 'January' }, { value: 2, label: 'February' }, { value: 3, label: 'March' }, { value: 3, label: 'March' }, { value: 4, label: 'April' },
    { value: 5, label: 'May' }, { value: 6, label: 'June' }, { value: 7, label: 'July' }, { value: 8, label: 'August' },
    { value: 9, label: 'September' }, { value: 10, label: 'October' }, { value: 11, label: 'November' }, { value: 12, label: 'December' }] as any);

    const { t } = useLocales();
    const NewExchangeSchema = Yup.object().shape({
        exchangeRegistrationNumber: Yup.string().required('Insurance Company is required'),
        exchangeMake: Yup.string().required('Cover Note is required'),
        exchangeModel: Yup.string().required('Insurance Amount is required'),
        exchangeModelGroup: Yup.string().required('Cover Note Date is required'),
        exchangeVarientId: Yup.string().required('Registration Number is required'),
        exchangeKMS: Yup.string().required('Registration Number is required'),
        exchangeUsage: Yup.string().required('Registration Number is required'),
        exchangeMonthOfRegistration: Yup.string().required('Registration Number is required'),
        exchangeYearOfRegistration: Yup.string().required('Registration Number is required'),
        exchangeVinNumber: Yup.string().required('Registration Number is required'),
        exchangeHypothecatedId: Yup.string().required('Registration Number is required'),
    });

    const defaultValues = {
        exchangeRegistrationNumber: '',
        exchangeMake: '',
        exchangeModel: '',
        exchangeModelGroup: '',
        exchangeVarientId: '',
        exchangeKMS: '',
        exchangeUsage: '',
        exchangeMonthOfRegistration: '',
        exchangeYearOfRegistration: '',
        exchangeVinNumber: '',
        exchangeHypothecatedId: '',
    };

    useEffect(() => {
        getLookUp("VARIENT").then((x: any) => {
            if (x && x.data && x.data.length > 0) {
                console.log(x.data)
                setVarientLookUp(x.data);
            }
        });
        getLookUp("RELATIONSHIP").then((x: any) => {
            if (x && x.data && x.data.length > 0) {
                console.log(x.data)
                setRelationShipLookUp(x.data);
            }
        });
        getLookUp("HYPOTHECATED").then((x: any) => {
            if (x && x.data && x.data.length > 0) {
                console.log(x.data)
                setHypothecatedLookUp(x.data);
            }
        });
    }, [])

    const handleChangeControlled =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setControlled(isExpanded ? panel : false);
    };

    const methods = useForm<FormValuesProps>({
        resolver: yupResolver(NewExchangeSchema),
        defaultValues,
    });

    const {
        handleSubmit, control,
        // formState: { isSubmitting },
    } = methods;

    const onInputChange = useCallback(
        async (data: FormValuesProps) => {
            try {
                onChange({
                    exchangeRegistrationNumber: data.exchangeRegistrationNumber,
                    exchangeMake: data.exchangeMake,
                    exchangeModel: data.exchangeModel,
                    exchangeModelGroup: data.exchangeModelGroup,
                    exchangeVarientId: data.exchangeVarientId,
                    exchangeKMS: data.exchangeKMS,
                    exchangeUsage: data.exchangeUsage,
                    exchangeMonthOfRegistration: data.exchangeMonthOfRegistration,
                    exchangeYearOfRegistration: data.exchangeYearOfRegistration,
                    exchangeVinNumber: data.exchangeVinNumber,
                    exchangeHypothecatedId: data.exchangeHypothecatedId,
                });
            } catch (error) {
                console.error(error);
            }
        },
        [onChange]
    );

    return (
        <>
            <Accordion  key='VEHICLEDETAIL'
                expanded={controlled === 'VEHICLEDETAIL'}
                onChange={handleChangeControlled('VEHICLEDETAIL')}>
                <AccordionSummary expandIcon={<Iconify icon="eva:arrow-ios-downward-fill" />} > 
                    <Typography variant="subtitle1" style={{ width: "100%" }}>Vehicle Details  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Box rowGap={3} columnGap={2} display="grid" gridTemplateColumns={{ xs: 'repeat(1, 1fr)', sm: 'repeat(1, 1fr)' }}>
                        <RHFTextField size={GLOBALCLASSINPUT} fullWidth name="searchBar" label={t('ENQUIRYMANAGEMENT.FORM.SEARCHBAR') as any} />
                    </Box>
                    <br />
                    <Box rowGap={3} columnGap={2} display="grid" gridTemplateColumns={{ xs: 'repeat(1, 1fr)', sm: `repeat(${gridTemplateColumns}, 1fr)` }}>
                        <RHFTextField size={GLOBALCLASSINPUT} name="exchangeRegistrationNumber" label={t('ENQUIRYMANAGEMENT.FORM.EXCHANGEREGISTRATIONNUMBER') as any} />
                        <RHFTextField size={GLOBALCLASSINPUT} name="exchangeMake" label={t('ENQUIRYMANAGEMENT.FORM.EXCHANGEMAKE') as any} />
                        <RHFTextField size={GLOBALCLASSINPUT} name="exchangeModel" label={t('ENQUIRYMANAGEMENT.FORM.EXCHANGEMODEL') as any} />
                        <RHFTextField size={GLOBALCLASSINPUT} name="exchangeModelGroup" label={t('ENQUIRYMANAGEMENT.FORM.EXCHANGEMODELGROUP') as any} />
                        <RHFSelect size={GLOBALCLASSINPUT} name="exchangeVarientId" label={t('ENQUIRYMANAGEMENT.FORM.EXCHANGEVARIENT') as any} >
                        <MenuItem value="">None</MenuItem>
                <Divider sx={{ borderStyle: 'dashed' }} />
                            {varientLookUp && varientLookUp.map((item: any) => (
                                <MenuItem key={item.value + item.label} value={item.value}> {item.label} </MenuItem>
                            ))}
                        </RHFSelect>
                        <RHFTextField size={GLOBALCLASSINPUT} name="exchangeKMS" label={t('ENQUIRYMANAGEMENT.FORM.EXCHANGEKMS') as any} />
                        <RHFTextField size={GLOBALCLASSINPUT} name="exchangeUsage" label={t('ENQUIRYMANAGEMENT.FORM.EXCHANGEUSAGE') as any} />
                        <RHFSelect size={GLOBALCLASSINPUT} name="exchangeMonthOfRegistration" label={t('ENQUIRYMANAGEMENT.FORM.EXCHANGEMONTHOFREGISTRATION') as any} >
                        <MenuItem value="">None</MenuItem>
                <Divider sx={{ borderStyle: 'dashed' }} />
                            {monthsLookUp && monthsLookUp.map((item: any) => (
                                <MenuItem key={item.value + item.label} value={item.value}> {item.label} </MenuItem>
                            ))}
                        </RHFSelect>
                        <RHFSelect size={GLOBALCLASSINPUT} name="exchangeYearOfRegistration" label={t('ENQUIRYMANAGEMENT.FORM.EXCHANGEYEAROFREGISTRATION') as any} >
                        <MenuItem value="">None</MenuItem>
                <Divider sx={{ borderStyle: 'dashed' }} />
                            {yearLookUp && yearLookUp.map((item: any) => (
                                <MenuItem key={item.value + item.label} value={item.value}> {item.label} </MenuItem>
                            ))}
                        </RHFSelect>
                        <RHFTextField size={GLOBALCLASSINPUT} name="exchangeVinNumber" label={t('ENQUIRYMANAGEMENT.FORM.EXCHANGEVINNUMBER') as any} />
                        <RHFSelect size={GLOBALCLASSINPUT} name="exchangeHypothecatedId" label={t('ENQUIRYMANAGEMENT.FORM.EXCHANGEHYPOTHECATED') as any} >
                        <MenuItem value="">None</MenuItem>
                <Divider sx={{ borderStyle: 'dashed' }} />
                            {hypothecatedLookUp && hypothecatedLookUp.map((item: any) => (
                                <MenuItem key={item.value + item.label} value={item.value}> {item.label} </MenuItem>
                            ))}
                        </RHFSelect>
                    </Box>
                </AccordionDetails>
            </Accordion>
            <Accordion key='CUSTOMERDETAIL'
                expanded={controlled === 'CUSTOMERDETAIL'}
                onChange={handleChangeControlled('CUSTOMERDETAIL')}>
                <AccordionSummary expandIcon={<Iconify icon="eva:arrow-ios-downward-fill" />}>
                    <Typography variant="subtitle1" style={{ width: "100%" }}>Customer Details </Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Box rowGap={2} columnGap={2} display="grid" gridTemplateColumns={{ xs: 'repeat(1, 1fr)', sm: `repeat(${gridTemplateColumns}, 1fr)` }}>
                       <RHFTextField size={GLOBALCLASSINPUT} name="exchangeCustomerName" label={t('ENQUIRYMANAGEMENT.FORM.EXCHANGECUSTOMERNAME') as any} />
                        <RHFSelect size={GLOBALCLASSINPUT} name="exchangeRelationShipId" label={t('ENQUIRYMANAGEMENT.FORM.EXCHANGERELATIONSHIP') as any} >
                        <MenuItem value="">None</MenuItem>
                <Divider sx={{ borderStyle: 'dashed' }} />
                            {relationShipLookUp && relationShipLookUp.map((item: any) => (
                                <MenuItem key={item.value + item.label} value={item.value}> {item.label} </MenuItem>
                            ))}
                        </RHFSelect>
                        <RHFTextField size={GLOBALCLASSINPUT} name="exchangeSchemeName" label={t('ENQUIRYMANAGEMENT.FORM.EXCHANGESCHEMENAME') as any} />
                        <RHFTextField size={GLOBALCLASSINPUT} name="exchangeSchemeAmount" label={t('ENQUIRYMANAGEMENT.FORM.EXCHANGESCHEMEAMOUNT') as any} />
                        {isLoyality==='false' &&  <RHFTextField size={GLOBALCLASSINPUT} name="exchangeCustomerExpectedPrice" label={t('ENQUIRYMANAGEMENT.FORM.EXCHANGECUSTOMEREXPECTEDPRICE') as any} />}
                       {isLoyality==='false' &&  <RHFTextField size={GLOBALCLASSINPUT} name="exchangeProcurementPrice" label={t('ENQUIRYMANAGEMENT.FORM.EXCHANGEPROCUREMENTPRICE') as any} />}
                    </Box>
                </AccordionDetails>
            </Accordion>
        </>
    );
}
