import { createSlice, Dispatch } from '@reduxjs/toolkit';
import { ICityState } from 'src/types/city';
// utils
import axios, { API_ENDPOINTS } from 'src/utils/axios';


const initialState: ICityState = {
    cities:[],
    city:null,
    citiesStatus:{
        loading:false,
        empty:false,
        error:null
    },
    cityStatus:{
        loading:false,
        error:null
    },
    cityDelete: {
        loading:false,
        empty:false,
        error:null
    }
}

const slice = createSlice({
    name: 'city',
    initialState,
    reducers:{
        getCitiesStart(state) {
            state.citiesStatus.loading=true;
            state.citiesStatus.empty=false;
            state.citiesStatus.error = null;
        },
        getCitiesFailure(state, action) {
            state.citiesStatus.loading=true;
            state.citiesStatus.empty=false;
            state.citiesStatus.error = action.payload;
        },
        getCitiesSuccess(state, action) {
            const cities = action.payload;
            state.cities = cities;
            state.citiesStatus.loading = false;
            state.citiesStatus.empty = !cities.length;
            state.citiesStatus.error = null;
        }
        ,
        deleteCityStart(state) {
            state.cityDelete.loading=true;
            state.cityDelete.empty=false;
            state.cityDelete.error = null;
        },
        deleteCityFailure(state, action) {
            state.cityDelete.loading=true;
            state.cityDelete.empty=false;
            state.cityDelete.error = action.payload;
        },
        deleteCitySuccess(state, action) {
            const cityDelete = action.payload;
            state.cityDelete = cityDelete;
            state.cityDelete.loading = false;
            state.cityDelete.empty = action.payload;
            state.cityDelete.error = null;
        }
    }
});

export default slice.reducer;

// Actions
export const {
    getCitiesStart,
    getCitiesFailure,
    getCitiesSuccess,
    deleteCityStart,
    deleteCityFailure,
    deleteCitySuccess
} = slice.actions;

export function getCities(data:any) {
        return async (dispatch: Dispatch) => {
            dispatch(slice.actions.getCitiesStart());
            try{
               // const response = await axios.post(API_ENDPOINTS.VEHICLECLASS.SEARCH,data);
                dispatch(slice.actions.getCitiesSuccess([
                    {id:1,cityName:'Chanchalguda',cityCode:"CGL",state:"Telangana",countryName:"India",districtName:"Hyderabad - Central",status:true},
                ]
                    ));
            }catch(error){
                dispatch(slice.actions.getCitiesFailure(error));
            }
        }
}

export function deleteCity(id: any) {
    return async (dispatch: Dispatch) => {
        dispatch(slice.actions.deleteCityStart());
        try{
            const response = await axios.get(API_ENDPOINTS.BODYTYPE.DELETE+id);
            dispatch(slice.actions.deleteCitySuccess(response.data));
        } catch(error){
            dispatch(slice.actions.deleteCityFailure(error));
        }
    }
}
