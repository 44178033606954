 
import {  useSelector } from "src/redux/store";

export default function useVisit() {
    const { visit,visitStatus} = useSelector(
        (state) => state.visit
    );
 
    return {
        visit,visitStatus,
    }
}