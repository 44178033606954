import { createSlice, Dispatch } from '@reduxjs/toolkit';
import { IColorPalatteState } from 'src/types/color.palatte';
// utils
import axios, { API_ENDPOINTS } from 'src/utils/axios';


const initialState: IColorPalatteState = {
    colorPalattes:[],
    colorPalatte:null,
    colorPalattesStatus:{
        loading:false,
        empty:false,
        error:null
    },
    colorPalatteStatus:{
        loading:false,
        error:null
    },
    colorPalatteDelete: {
        loading:false,
        empty:false,
        error:null
    }
}

const slice = createSlice({
    name: 'colorPalatte',
    initialState,
    reducers:{
        getColorPalattesStart(state) {
            state.colorPalattesStatus.loading=true;
            state.colorPalattesStatus.empty=false;
            state.colorPalattesStatus.error = null;
        },
        getColorPalattesFailure(state, action) {
            state.colorPalattesStatus.loading=true;
            state.colorPalattesStatus.empty=false;
            state.colorPalattesStatus.error = action.payload;
        },
        getColorPalattesSuccess(state, action) {
            const colorPalattes = action.payload;
            state.colorPalattes = colorPalattes;
            state.colorPalattesStatus.loading = false;
            state.colorPalattesStatus.empty = !colorPalattes.length;
            state.colorPalattesStatus.error = null;
        }
        ,
        deleteColorPalatteStart(state) {
            state.colorPalatteDelete.loading=true;
            state.colorPalatteDelete.empty=false;
            state.colorPalatteDelete.error = null;
        },
        deleteColorPalatteFailure(state, action) {
            state.colorPalatteDelete.loading=true;
            state.colorPalatteDelete.empty=false;
            state.colorPalatteDelete.error = action.payload;
        },
        deleteColorPalatteSuccess(state, action) {
            const colorPalatteDelete = action.payload;
            state.colorPalatteDelete = colorPalatteDelete;
            state.colorPalatteDelete.loading = false;
            state.colorPalatteDelete.empty = action.payload;
            state.colorPalatteDelete.error = null;
        }
    }
});

export default slice.reducer;

// Actions
export const {
    getColorPalattesStart,
    getColorPalattesFailure,
    getColorPalattesSuccess,
    deleteColorPalatteStart,
    deleteColorPalatteFailure,
    deleteColorPalatteSuccess
} = slice.actions;
 
export function getColorPalattes(data:any) {
    return async (dispatch: Dispatch) => {
        dispatch(slice.actions.getColorPalattesStart());
        try {
            await axios.get(API_ENDPOINTS.PRODUCTSETTTINGS.COLORPALATTE.LIST).then((res:any)=> {
                if(res.data.statusCode === 200)
                dispatch(slice.actions.getColorPalattesSuccess(res.data.data));
            else 
                 dispatch(slice.actions.getColorPalattesFailure([]));
            });
        }catch(error){
            dispatch(slice.actions.getColorPalattesFailure(error));
        }
    }
}

export function deleteColorPalatte(id: any) {
return  axios.delete(`${API_ENDPOINTS.PRODUCTSETTTINGS.COLORPALATTE.DELETE}/${id}`);
}

export function addUpdateColorPalatte(data: any) {
return axios.post(API_ENDPOINTS.PRODUCTSETTTINGS.COLORPALATTE.ADDUPDATECOLORPALATTE,data);
}