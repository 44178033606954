import { useDispatch, useSelector } from "src/redux/store";

export default function useLeadSource() {
    const dispatch = useDispatch();
    const { sourceOfEnquiry,sourceOfEnquiryDelete,sourceOfEnquiryStatus} = useSelector(
        (state) => state.leadSource
    );


    return {
        sourceOfEnquiry,sourceOfEnquiryStatus,
    }
}