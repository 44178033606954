import { useCallback } from "react";
import { deleteState } from "src/redux/slices/geography-settings/state";
import { useDispatch, useSelector } from "src/redux/store";
import { useRouter } from "src/routes/hook";

export default function useStates() {
    const dispatch = useDispatch();
    const { state,stateDelete,statesStatus,states} = useSelector(
        (item) => item.states
    );

    const onDeleteState = useCallback(
        (id: string) => {
            dispatch(deleteState(id));
        },
        [dispatch]
    );

    return {
        state,stateDelete,statesStatus,states,
        onDeleteState
    }
}