import {useSelector } from "src/redux/store";

export default function useBodyType() {
    const { bodyTypesStatus,bodyTypes} = useSelector(
        (state) => state.bodyType
    );

    return {
       bodyTypesStatus,bodyTypes,
    }
}