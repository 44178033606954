import { IErrorType } from "../error";

export type IBuyerTypeTableFilters = {
    name: string;
    status: string[];
  };
  
export type IBuyerTypeTableFilterValue = string | string[];

export type IBuyerType = {
    id:number;
    name:string;
    description:string;
    displayOrder:number;
    status:boolean;
}
 
export type IBuyerTypeState = {
  buyerType: IBuyerType[];
  buyerTypeStatus: {
    loading: boolean;
    empty: boolean;
    error: IErrorType;
  };
  buyerTypeDelete: {
    loading:boolean;
    empty:any;
    error:IErrorType;
  }
};

export const defaultFilters = {
  name: '',
  status: [],
};