import { IErrorType } from "../error";

export type IVisitTableFilters = {
    name: string;
    status: string[];
  };
  
export type IVisitTableFilterValue = string | string[];

export type IVisit = {
    id:number;
    name:string;
    description:string;
    displayOrder:number;
    status:boolean;
}
 
export type IVisitState = {
  visit: IVisit[];
  visitStatus: {
    loading: boolean;
    empty: boolean;
    error: IErrorType;
  };
  visitDelete: {
    loading:boolean;
    empty:any;
    error:IErrorType;
  }
};

export const defaultFilters = {
  name: '',
  status: [],
};