import { createSlice, Dispatch } from '@reduxjs/toolkit';
import { IEnquiryStatusState } from 'src/types/lead-settings/lead.status.type';
// utils
import axios, { API_ENDPOINTS } from 'src/utils/axios';

const initialState: IEnquiryStatusState = {
    enquiryStatus:[],
    enquiryStatusStatus:{
        loading:false,
        empty:false,
        error:null
    },
    enquiryStatusDelete: {
        loading:false,
        empty:false,
        error:null
    }
}

const slice = createSlice({
    name: 'enquiryStatus',
    initialState,
    reducers:{
        getEnquiryStatusStart(state) {
            state.enquiryStatusStatus.loading=true;
            state.enquiryStatusStatus.empty=false;
            state.enquiryStatusStatus.error = null;
        },
        getEnquiryStatusFailure(state, action) {
            state.enquiryStatusStatus.loading=true;
            state.enquiryStatusStatus.empty=false;
            state.enquiryStatusStatus.error = action.payload;
        },
        getEnquiryStatusSuccess(state, action) {
            const enquiryStatus = action.payload;
            state.enquiryStatus = enquiryStatus;
            state.enquiryStatusStatus.loading = false;
            state.enquiryStatusStatus.empty = !enquiryStatus.length;
            state.enquiryStatusStatus.error = null;
        },
        deleteEnquiryStatusStart(state) {
            state.enquiryStatusDelete.loading=true;
            state.enquiryStatusDelete.empty=false;
            state.enquiryStatusDelete.error = null;
        },
        deleteEnquiryStatusFailure(state, action) {
            state.enquiryStatusDelete.loading=true;
            state.enquiryStatusDelete.empty=false;
            state.enquiryStatusDelete.error = action.payload;
        },
        deleteEnquiryStatusSuccess(state, action) {
            const enquiryStatusDelete = action.payload;
            state.enquiryStatusDelete = enquiryStatusDelete;
            state.enquiryStatusDelete.loading = false;
            state.enquiryStatusDelete.empty = action.payload;
            state.enquiryStatusDelete.error = null;
        }
    }
});

export default slice.reducer;

// Actions
export const {
    getEnquiryStatusStart,
    getEnquiryStatusFailure,
    getEnquiryStatusSuccess,
    deleteEnquiryStatusStart,
    deleteEnquiryStatusFailure,
    deleteEnquiryStatusSuccess
} = slice.actions;

export function getEnquiryStatus(data:any) {
    return async (dispatch: Dispatch) => {
        dispatch(slice.actions.getEnquiryStatusStart());
        try {
            await axios.get(API_ENDPOINTS.ENQUIRYSETTINGS.ENQUIRYSTATUS.LIST).then((res:any)=> {
                if(res.data.statusCode === 200) {
                    dispatch(slice.actions.getEnquiryStatusSuccess(res.data.data));
                }
            else 
                 dispatch(slice.actions.getEnquiryStatusSuccess([]));
            });
        }catch(error){
            dispatch(slice.actions.getEnquiryStatusFailure(error));
        }
    }
}

export function deleteEnquiryStatus(id: any) {
    return  axios.delete(`${API_ENDPOINTS.ENQUIRYSETTINGS.ENQUIRYSTATUS.DELETE}/${id}`);
}

export function addUpdateEnquiryStatus(data: any) {
    return axios.post(API_ENDPOINTS.ENQUIRYSETTINGS.ENQUIRYSTATUS.ADDUPDATEENQUIRYSTATUS,data);
}