export const MANDAL_COLUMNS = ["mandalCode","mandalName","cityName","districtName","state","countryName","status"];

export const TABLE_HEAD_MANDAL = [
    { id: 'cityCode', label: "MANDAL.VIEW.GRID.MANDALCODE" },
    { id: 'cityName', label: "MANDAL.VIEW.GRID.MANDALNAME" },
    { id: 'city', label: "MANDAL.VIEW.GRID.CITY" },
    { id: 'districtName', label: "MANDAL.VIEW.GRID.DISTRICT" },
    { id: 'state', label: "MANDAL.VIEW.GRID.STATE" },
    { id: 'countryName', label: "MANDAL.VIEW.GRID.COUNTRY" },
    { id: 'status', label: "MANDAL.VIEW.GRID.STATUS" },
    { id: 'id', width: 88 },
  ];

export const defaultFilters = {
    name: '',
    status: [],
  };
  