import { Helmet } from "react-helmet-async";
import { useLocales } from "src/locales";
import LeadStatusListView from "src/sections/masters/lead-settings/lead-status-list-view";

export default function LeadStatusListPage() {
  const {t}=useLocales();
    return (
      <>
        <Helmet>
          <title> { t("LEADSTATUS.PAGE.TITLE")}</title>
        </Helmet>
  
        <LeadStatusListView />
      </>
    );
  }