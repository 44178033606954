export const DISTRICTS_COLUMNS = ["districtCode","districtName","state","countryName","status"];

export const TABLE_HEAD_DISTRICTS = [
    { id: 'districtCode', label: "DISTRICTS.VIEW.GRID.DISTRICTCODE" },
    { id: 'districtName', label: "DISTRICTS.VIEW.GRID.DISTRICTNAME" },
    { id: 'state', label: "DISTRICTS.VIEW.GRID.STATE" },
    { id: 'countryName', label: "DISTRICTS.VIEW.GRID.COUNTRY" },
    { id: 'status', label: "DISTRICTS.VIEW.GRID.STATUS" },
    { id: 'id', width: 88 },
  ];

export const defaultFilters = {
    name: '',
    status: [],
  };
  