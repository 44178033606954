import { useCallback, useEffect, useState } from 'react';
import { useFormContext, useFieldArray } from 'react-hook-form';
// @mui
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
// components
import Iconify from 'src/components/iconify';
import { RHFSelect, RHFTextField } from 'src/components/hook-form';
import { useLocales } from 'src/locales';
import { GLOBALCLASSINPUT } from 'src/utils/contants/config-common';
import { getLookUp } from 'src/redux/slices/lookup';
import { ColorPicker } from 'src/components/color-utils';
import { Card, IconButton, Table, TableBody, TableCell, TableHead, TableRow, Tooltip } from '@mui/material';

// ----------------------------------------------------------------------

export default function NewVehicleDetails() {
  const { control, setValue, resetField } = useFormContext();
  const { t } = useLocales();
  const [varientTypeLookUp, setVarientTypeLookUp] = useState([] as any);
  const [transmissionLookUp, setTransmissionLookUp] = useState([] as any);
  const [fuelTypeLookUp, setFuelTypeLookUp] = useState([] as any);
  const [colorsLookUp, setColorsLookUp] = useState([] as any);
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'varientDetails',
  });

  useEffect(() => {
    getLookUp("VARIENTTYPE").then((x: any) => {
      if (x) {
        setVarientTypeLookUp([{ value: "1", label: "Automatic" }, { value: "2", label: "Manual" }]);
      }
    });
    getLookUp("COLOR").then((x: any) => {
      if (x && x.data && x.data.length > 0) {
          console.log(x.data)
          setColorsLookUp(x.data);
      }
  });
  getLookUp("TRANSMISSION").then((x: any) => {
    if (x && x.data && x.data.length > 0) {
        console.log(x.data)
        setTransmissionLookUp(x.data);
    }
});
  }, []);

  const handleAdd = () => {
    append({
      varientTypeId: '1',
      transmission: '',
      fuelType: '',
      colors: 1,
    });
  };

  const handleRemove = (index: number) => {
    remove(index);
  };

  const handleClearService = useCallback(
    (index: number) => {
      resetField(`varientDetails[${index}].varientTypeId`);
      resetField(`varientDetails[${index}].transmission`);
      resetField(`varientDetails[${index}].fuelType`);
      resetField(`varientDetails[${index}].colors`);
    },
    [resetField]
  );

  const handleSelectVarientType = useCallback(
    (index: number, option: string) => {
      setValue(
        `varientDetails[${index}].varientTypeId`,
        varientTypeLookUp.find((item: any) => item.value === option)?.value
      );
      console.log(option)
    },
    [setValue, varientTypeLookUp]
  );

  return (
    <>
      <Typography variant="subtitle1" sx={{ mb: 3 }}>
        {t('NEWVEHICLEMODEL.FORM.VARIENTDETAIL') as any} :
      </Typography>
      <Card sx={{ p: 3 }}>
      <Box rowGap={3} columnGap={2} display="grid" gridTemplateColumns={{ xs: 'repeat(1, 1fr)', sm: 'repeat(6, 1fr)' }}>
        <RHFSelect  size={GLOBALCLASSINPUT} name="varientTypeId" label={t('COMMON.FORM.VARIENTTYPE') as any} >
          <MenuItem value="">None</MenuItem>
          <Divider sx={{ borderStyle: 'dashed' }} />
          {varientTypeLookUp && varientTypeLookUp.map((item: any) => (
            <MenuItem key={item.value + item.label} value={item.value}> {item.label} </MenuItem>
          ))}
        </RHFSelect>
        <RHFSelect  size={GLOBALCLASSINPUT} name="transmission" label={t('COMMON.FORM.TRANSMISSION') as any} >
          <MenuItem value="">None</MenuItem>
          <Divider sx={{ borderStyle: 'dashed' }} />
          {transmissionLookUp && transmissionLookUp.map((item: any) => (
            <MenuItem key={item.value + item.label} value={item.value}> {item.label} </MenuItem>
          ))}
        </RHFSelect>

        <RHFSelect  size={GLOBALCLASSINPUT} name="fuelType" label={t('COMMON.FORM.FUELTYPE') as any} >
          <MenuItem value="">None</MenuItem>
          <Divider sx={{ borderStyle: 'dashed' }} />
          {fuelTypeLookUp && fuelTypeLookUp.map((item: any) => (
            <MenuItem key={item.value + item.label} value={item.value}> {item.label} </MenuItem>
          ))}
        </RHFSelect>
        <RHFTextField  
          size={GLOBALCLASSINPUT}
          name='colors'
          label={t('COMMON.FORM.SKU') as any}
        />
        <RHFSelect  size={GLOBALCLASSINPUT} name="color" label={t('COMMON.FORM.COLOR') as any} >
          <MenuItem value="">None</MenuItem>
          <Divider sx={{ borderStyle: 'dashed' }} />
          {colorsLookUp && colorsLookUp.map((item: any) => (
           <MenuItem key={item.value + item.label} value={item.value} > <span style={{ backgroundColor: item.data, padding: "3px 10px", borderRadius: "50%", marginRight: "5px", width: "20px", height: "20px" }}>&nbsp;</span> {item.label}    </MenuItem>
          ))}
        </RHFSelect>
        <Box rowGap={3} columnGap={2} display="grid" gridTemplateColumns={{ xs: 'repeat(1, 1fr)', sm: 'repeat(2, 1fr)' }}>
                    <Button variant="contained" >  Add </Button>
                    <Button >  Clear  </Button>
                </Box>
      </Box>
      <br/>
      <Table sx={{ minWidth: "100%" }}>
        <TableHead>
          <TableRow>
            {["COMMON.FORM.VARIENTTYPE", "COMMON.FORM.TRANSMISSION", "COMMON.FORM.FUELTYPE", "COMMON.FORM.COLOR", "COMMON.FORM.SKU"].map((item: any) => (
              <TableCell key={item} style={{ padding: "4px", fontSize: '10px', textAlign: 'center' }}>
                <Typography variant="caption" sx={{ mb: 0.5 }}>  {t(item)} </Typography>
              </TableCell>
            ))}
            <TableCell style={{ padding: "4px", fontSize: '10px', textAlign: 'center' }}>
              <Typography variant="caption" sx={{ mb: 0.5 }}>  &nbsp; </Typography>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell style={{ padding: "4px", fontSize: '10px',  textAlign: 'center' }}>
              <Typography variant="caption" sx={{ mb: 0.5 }}>  Service Name 1 </Typography>
            </TableCell>
            <TableCell style={{ padding: "4px", fontSize: '10px',  textAlign: 'center' }}>
              <Typography variant="caption" sx={{ mb: 0.5 }}>  2000 </Typography>
            </TableCell>
            <TableCell style={{ padding: "4px", fontSize: '10px',  textAlign: 'center' }}>
              <Typography variant="caption" sx={{ mb: 0.5 }}>  2000 </Typography>
            </TableCell>
            <TableCell style={{ padding: "4px", fontSize: '10px',  textAlign: 'center' }}>
              <Typography variant="caption" sx={{ mb: 0.5 }}>  2000 </Typography>
            </TableCell>
            <TableCell style={{ padding: "4px", fontSize: '10px',  textAlign: 'center' }}>
              <Typography variant="caption" sx={{ mb: 0.5 }}>  2000 </Typography>
            </TableCell>
            <TableCell style={{ padding: "4px", fontSize: '10px',  textAlign: 'center' }}>
              <Tooltip title="Quick Edit" placement="top" arrow>
                <IconButton onClick={() => {
                }}  >
                  <Iconify icon="solar:pen-bold" />
                </IconButton>
              </Tooltip>
              <Tooltip title="Quick Delete" sx={{ color: 'error.main' }} placement="top" arrow>
                <IconButton onClick={() => {
                }}>
                  <Iconify icon="solar:trash-bin-trash-bold" />
                </IconButton>
              </Tooltip>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
      </Card>
    </>
  );
}
