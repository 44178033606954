import { IErrorType } from "./error";

export type IVarientTypeTableFilters = {
    name: string;
    status: string[];
  };
  
export type IVarientTypeTableFilterValue = string | string[];

export type IVarientType = {
    id:number;
    varientType:string;
    transmission:any;
    fuelType:any;
    color:any;
    image:any;
    description:string;
    status:boolean;
}
  
export type IVarientTypeState = {
  varientTypes: IVarientType[];
  varientType: IVarientType | null;
  varientTypesStatus: {
    loading: boolean;
    empty: boolean;
    error: IErrorType;
  };
  varientTypeStatus: {
    loading: boolean;
    error: IErrorType;
  };
  varientTypeDelete: {
    loading:boolean;
    empty:any;
    error:IErrorType;
  }
};

export const defaultFilters = {
  name: '',
  status: [],
};