 
import {  useSelector } from "src/redux/store";

export default function useAllotment() {
    const { allotment,allotmentStatus} = useSelector(
        (state) => state.allotment
    );
 
    return {
        allotment,allotmentStatus,
    }
}