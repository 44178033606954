import * as Yup from 'yup';
import { useCallback, useEffect, useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import Box from '@mui/material/Box';
import {
    RHFTextField,
    RHFSelect,
} from 'src/components/hook-form';
import { Accordion, AccordionDetails, AccordionSummary, Button, Grid, IconButton, MenuItem, Table, TableBody, TableCell, TableHead, TableRow, Tooltip, Typography } from '@mui/material';
import { ACCESSORIES_GRID_COLUMNS, GLOBALCLASSINPUT } from 'src/utils/contants/config-common';
import { getLookUp } from 'src/redux/slices/lookup';
import { useLocales } from 'src/locales';
import { DatePicker, DateTimePicker } from '@mui/x-date-pickers';
import { IAddOn } from 'src/types/common';
import { Controller, useForm } from 'react-hook-form';
import { TABLE_HEAD_ENQUIRYTESTDRIVE } from 'src/utils/contants/enquiry-management/config-enquiry-type';
import Iconify from 'src/components/iconify';

// ----------------------------------------------------------------------


export interface FormValuesProps extends IAddOn {

}

type Props = {
    onChange: (address: IAddOn) => void;
    gridTemplateColumns: any;
};

export default function AddOnForm({ onChange, gridTemplateColumns = 3 }: Props) {
    const [tableTestDriveData, setTableTestDriveData] = useState<any[]>([]);
    const [vehicleModelLookUp, setVehicleModelLookUp] = useState([] as any);
    const [controlled, setControlled] = useState<string | false>("ACCESSORIES");
    const { t } = useLocales();
    const NewInsuranceDetailSchema = Yup.object().shape({
        accesseriesPartName: Yup.string(),
        accesseriesPartNumber: Yup.string(),
        accesseriesSellingPrice: Yup.string(),
        accesseriesDiscountPrice: Yup.string(),
        accesseriesQuantity: Yup.string(),
        warrentyName: Yup.string(),
        warrentyPrice: Yup.string(),
        warrentyKMS: Yup.string(),
        warrentyValidFrom: Yup.string(),
        warrentyValidTo: Yup.string()
    });

    const defaultValues = {
        accesseriesPartName: '',
        accesseriesPartNumber: '',
        accesseriesSellingPrice: '',
        accesseriesDiscountPrice: '',
        accesseriesQuantity: '',
        warrentyName: '',
        warrentyPrice: '',
        warrentyKMS: '',
        warrentyValidFrom: '',
        warrentyValidTo: ''
    };

    useEffect(() => {
        getLookUp("VEHICLEMODEL").then((x: any) => {
            if (x && x.data && x.data.length > 0) {
                console.log(x.data)
                setVehicleModelLookUp(x.data);
            }
        });
    }, [])

    const handleChangeControlled =
        (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
            setControlled(isExpanded ? panel : false);
        };


    const methods = useForm<FormValuesProps>({
        resolver: yupResolver(NewInsuranceDetailSchema),
        defaultValues,
    });

    const {
        handleSubmit, control,
        // formState: { isSubmitting },
    } = methods;

    const onInputChange = useCallback(
        async (data: FormValuesProps) => {
            try {
                onChange({
                    accesseriesPartName: data.accesseriesPartName,
                    accesseriesPartNumber: data.accesseriesPartNumber,
                    accesseriesSellingPrice: data.accesseriesSellingPrice,
                    accesseriesDiscountPrice: data.accesseriesDiscountPrice,
                    accesseriesQuantity: data.accesseriesQuantity,
                    warrentyName: data.warrentyName,
                    warrentyPrice: data.warrentyPrice,
                    warrentyKMS: data.warrentyKMS,
                    warrentyValidFrom: data.warrentyValidFrom,
                    warrentyValidTo: data.warrentyValidTo
                });
            } catch (error) {
                console.error(error);
            }
        },
        [onChange]
    );

    return (
        <>
            <Accordion key='ACCESSORIES'
                expanded={controlled === 'ACCESSORIES'}
                onChange={handleChangeControlled('ACCESSORIES')}>
                <AccordionSummary expandIcon={<Iconify icon="eva:arrow-ios-downward-fill" />} >
                    <Typography variant="subtitle1" style={{ width: "100%" }}>Accessories  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Box rowGap={3} columnGap={2} display="grid" gridTemplateColumns={{ xs: 'repeat(1, 1fr)', sm: 'repeat(1, 1fr)' }}>
                        <RHFTextField size={GLOBALCLASSINPUT} fullWidth name="searchBar" label={t('ENQUIRYMANAGEMENT.FORM.SEARCHBAR') as any} />
                    </Box>
                    <br />
                    <Box gap={3} display="grid" gridTemplateColumns={{ xs: `repeat(${gridTemplateColumns}, 1fr)`, }} >
                        <RHFTextField size={GLOBALCLASSINPUT} name="accesseriesPartName" label={t('COMMON.FORM.ACCESSERIESPARTNAME') as any} />
                        <RHFTextField size={GLOBALCLASSINPUT} name="accesseriesPartNumber" label={t('COMMON.FORM.ACCESSERIESPARTNUMBER') as any} />
                        <RHFTextField size={GLOBALCLASSINPUT} name="accesseriesSellingPrice" label={t('COMMON.FORM.ACCESSERIESSELLINGPRICE') as any} />
                        <RHFTextField size={GLOBALCLASSINPUT} name="accesseriesDiscountPrice" label={t('COMMON.FORM.ACCESSERIESDISCOUNTPRICE') as any} />
                        <RHFTextField size={GLOBALCLASSINPUT} name="accesseriesQuantity" label={t('COMMON.FORM.ACCESSERIESQUANTITY') as any} />
                        <Box rowGap={3} columnGap={2} display="grid" gridTemplateColumns={{ xs: 'repeat(1, 1fr)', sm: 'repeat(2, 1fr)' }}>
                        <Button variant="contained" >
                        Save
                        </Button>
                        <Button >
                            Clear
                        </Button>
                    </Box>
                    </Box>
                    <br />
                    <Table sx={{ minWidth: "100%" }}>
                    <TableHead>
                        <TableRow>
                            {ACCESSORIES_GRID_COLUMNS && ACCESSORIES_GRID_COLUMNS.map((item: any) => (
                                <TableCell key={item.label} style={{ padding: "4px", fontSize: '10px', textAlign: 'center' }}>
                                    <Typography variant="caption" sx={{ mb: 0.5 }}>  {t(item)} </Typography>
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow>
                            <TableCell style={{ padding: "4px", fontSize: '10px', width: "118px", textAlign: 'center' }}>
                                <Typography variant="caption" sx={{ mb: 0.5 }}>  Maruti </Typography>
                            </TableCell>
                            <TableCell style={{ padding: "4px", fontSize: '10px', width: "118px", textAlign: 'center' }}>
                                <Typography variant="caption" sx={{ mb: 0.5 }}>  Hyderabad </Typography>
                            </TableCell>
                           
                            <TableCell style={{ padding: "4px", width: "118px", textAlign: 'center' }}>
                                <Typography variant="caption" sx={{ mb: 0.5 }}>  12345 </Typography>
                            </TableCell>
                            <TableCell style={{ padding: "4px", width: "118px", textAlign: 'center' }}>
                                <Typography variant="caption" sx={{ mb: 0.5 }}>  12369 </Typography>
                            </TableCell>
                            <TableCell style={{ padding: "4px", width: "118px", textAlign: 'center' }}>
                                <Typography variant="caption" sx={{ mb: 0.5 }}>  326542 </Typography>
                            </TableCell>
                            <TableCell align="center" sx={{ px: 1, whiteSpace: 'nowrap' }}>
                            <Tooltip title="Quick Edit" placement="top" arrow>
                                <IconButton onClick={() => {
                                }}  >
                                    <Iconify icon="solar:pen-bold" />
                                </IconButton>
                            </Tooltip>
                            <Tooltip title="Quick Delete" sx={{ color: 'error.main' }} placement="top" arrow>
                                <IconButton onClick={() => {
                                }}>
                                    <Iconify icon="solar:trash-bin-trash-bold" />
                                </IconButton>
                            </Tooltip>
                        </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell style={{ padding: "4px", fontSize: '10px', width: "118px", textAlign: 'center' }}>
                                <Typography variant="caption" sx={{ mb: 0.5 }}>  Maruti </Typography>
                            </TableCell>
                            <TableCell style={{ padding: "4px", fontSize: '10px', width: "118px", textAlign: 'center' }}>
                                <Typography variant="caption" sx={{ mb: 0.5 }}>  Hyderabad </Typography>
                            </TableCell>
                            <TableCell style={{ padding: "4px", width: "118px", textAlign: 'center' }}>
                                <Typography variant="caption" sx={{ mb: 0.5 }}>  12345 </Typography>
                            </TableCell>
                            <TableCell style={{ padding: "4px", width: "118px", textAlign: 'center' }}>
                                <Typography variant="caption" sx={{ mb: 0.5 }}>  12369 </Typography>
                            </TableCell>
                            <TableCell style={{ padding: "4px", width: "118px", textAlign: 'center' }}>
                                <Typography variant="caption" sx={{ mb: 0.5 }}>  326542 </Typography>
                            </TableCell>
                            <TableCell align="center" sx={{ px: 1, whiteSpace: 'nowrap' }}>
                            <Tooltip title="Quick Edit" placement="top" arrow>
                                <IconButton onClick={() => {
                                }}  >
                                    <Iconify icon="solar:pen-bold" />
                                </IconButton>
                            </Tooltip>
                            <Tooltip title="Quick Delete" sx={{ color: 'error.main' }} placement="top" arrow>
                                <IconButton onClick={() => {
                                }}>
                                    <Iconify icon="solar:trash-bin-trash-bold" />
                                </IconButton>
                            </Tooltip>
                        </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
                </AccordionDetails>
            </Accordion>

            <Accordion key='WARRENTY'
                expanded={controlled === 'WARRENTY'}
                onChange={handleChangeControlled('WARRENTY')}>
                <AccordionSummary expandIcon={<Iconify icon="eva:arrow-ios-downward-fill" />} >
                    <Typography variant="subtitle1" style={{ width: "100%" }}>Warrenty  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Box rowGap={3} columnGap={2} display="grid" gridTemplateColumns={{ xs: 'repeat(1, 1fr)', sm: 'repeat(3, 1fr)' }}>
                        <RHFTextField size={GLOBALCLASSINPUT} name="warrentyName" label={t('COMMON.FORM.WARRENTYNAME') as any} />
                        <RHFTextField size={GLOBALCLASSINPUT} name="warrentyPrice" label={t('COMMON.FORM.WARRENTYPRICE') as any} />
                        <RHFTextField size={GLOBALCLASSINPUT} name="warrentyKMS" label={t('COMMON.FORM.WARRENTYKMS') as any} />
                        <Controller
                            name="warrentyValidFrom"
                            control={control}
                            render={({ field, fieldState: { error } }) => (
                                <DatePicker
                                    label={t('COMMON.FORM.WARRENTYVALIDFROM') as any}
                                    value={field.value}
                                    onChange={(newValue) => {
                                        field.onChange(newValue);
                                    }}
                                    slotProps={{
                                        textField: {
                                            size: 'small',
                                            error: !!error,
                                            helperText: error?.message,
                                        },
                                    }}
                                />
                            )}
                        />
                        <Controller
                            name="warrentyValidTo"
                            control={control}
                            render={({ field, fieldState: { error } }) => (
                                <DatePicker
                                    label={t('COMMON.FORM.WARRENTYVALIDTO') as any}
                                    value={field.value}
                                    onChange={(newValue) => {
                                        field.onChange(newValue);
                                    }}
                                    slotProps={{
                                        textField: {
                                            size: 'small',
                                            error: !!error,
                                            helperText: error?.message,
                                        },
                                    }}
                                />
                            )}
                        />
                    </Box>
                </AccordionDetails>
            </Accordion>
        </>
    );
}
