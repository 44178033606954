import {useSelector } from "src/redux/store";

export default function useFuelType() {
    const { fuelTypesStatus,fuelTypes} = useSelector(
        (state) => state.fuelType
    );

    return {
       fuelTypesStatus,fuelTypes,
    }
}