import { IErrorType } from "./error";

export type ICityTableFilters = {
    name: string;
    status: string[];
  };
  
export type ICityTableFilterValue = string | string[];

export type ICity = {
    cityId:number;
    cityCode:string;
    cityName:string;
    stateId:string;
    countryId:string;
    districtId:string;
    image:string;
    description:string;
    status:boolean;
}
  
export type ICityState = {
  cities: ICity[];
  city: ICity | null;
  citiesStatus: {
    loading: boolean;
    empty: boolean;
    error: IErrorType;
  };
  cityStatus: {
    loading: boolean;
    error: IErrorType;
  };
  cityDelete: {
    loading:boolean;
    empty:any;
    error:IErrorType;
  }
};

export const defaultFilters = {
  name: '',
  status: [],
};