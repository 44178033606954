


export const SEGMENTDETAIL_COLUMNS = ["vehicleClassName","engineSize","length","status"];

export const TABLE_HEAD_SEGMENT_DETAIL = [
    { id: 'vehicleClassName', label: "SEGMENTDETAIL.VIEW.GRID.VEHICLECLASSNAME" },
    { id: 'engineSize', label: "SEGMENTDETAIL.VIEW.GRID.ENGINESIZE" },
    { id: 'length', label: "SEGMENTDETAIL.VIEW.GRID.LENGTH" },
    { id: 'status', label: "SEGMENTDETAIL.VIEW.GRID.STATUS" },
    { id: 'id', width: 88 },
  ];

  
export const defaultFilters = {
    name: '',
    status: [],
  };
  