import { useSelector } from "src/redux/store";

export default function useColorPalatte() {
    const { colorPalatte,colorPalatteDelete,colorPalattesStatus,colorPalattes} = useSelector(
        (state) => state.colorPalatte
    );

    return {
        colorPalattesStatus,colorPalattes,
    }
}