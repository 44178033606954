import { Helmet } from "react-helmet-async";
import { useLocales } from "src/locales";
import OrganisationListView from "src/sections/organisation-settings/organisation/organisation-list-view";

export default function OrganisationPage() {
  const {t}=useLocales();
    return (
      <>
        <Helmet>
          <title> { t("ENQUIRYMANAGEMENT.PAGE.TITLE")}</title>
        </Helmet>
  
        <OrganisationListView />
      </>
    );
  }