import * as Yup from 'yup';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import Box from '@mui/material/Box';
import {
    RHFTextField,
    RHFSelect,
    RHFSwitch,
} from 'src/components/hook-form';
import { Divider, FormControlLabel, Grid, MenuItem, Switch } from '@mui/material';
import { GLOBALCLASSINPUT } from 'src/utils/contants/config-common';
import { getLookUp } from 'src/redux/slices/lookup';
import { useLocales } from 'src/locales';
import { DatePicker } from '@mui/x-date-pickers';
import { ICustomerDetail } from 'src/types/common';
import { Controller, useForm } from 'react-hook-form';

// ----------------------------------------------------------------------

export interface FormValuesProps extends ICustomerDetail {
}

type Props = {
    onChange: (address: ICustomerDetail) => void;
    customerDetails: ICustomerDetail
};


export default function CustomerDetailForm({ customerDetails, onChange }: Props) {
    const [salutationLookUp, setSalutationLookUp] = useState([] as any);
    const [genderLookUp, setGenderLookUp] = useState([] as any);
    const [buyerTypeLookUp, setBuyerTypeLookUp] = useState([] as any);
    const [enquiryOwnerLookUp, setEnquiryOwnerLookUp] = useState([] as any);
    const [sourceOfEnquiryLookUp, setSourceOfEnquiryLookUp] = useState([] as any);
    const [colorLookUp, setColorLookUp] = useState([] as any);
    const [vehicleModelLookUp, setVehicleModelLookUp] = useState([] as any);
    const [varientTypeLookUp, setVarientTypeLookUp] = useState([] as any);
    const { t } = useLocales();
    const NewCustomerDetailSchema = Yup.object().shape({
        salutationId: Yup.string(),
        fullName: Yup.string(),
        mobileNumber: Yup.string(),
        alternateNumber: Yup.string(),
        genderId: Yup.string(),
        panNumber: Yup.string(),
        gsinNumber: Yup.string(),
        birthDate: Yup.string(),
        emailAddress: Yup.string(),
        sourceOfEnquiryId: Yup.string(),
        buyerTypeId: Yup.string(),
        enquiryOwnerId: Yup.string(),
        vehicleRegistrationNumber: Yup.string(),
        vehicleModelId: Yup.string(),
        varientId: Yup.string(),
        color: Yup.string(),
        isReferral: Yup.bool(),
    });

    const defaultValues = useMemo(() => ({
        salutationId: customerDetails?.salutationId || "",
        fullName: customerDetails?.fullName || "",
        mobileNumber: customerDetails?.mobileNumber || "",
        alternateNumber: customerDetails?.alternateNumber || "",
        genderId: customerDetails?.genderId || "",
        panNumber: customerDetails?.panNumber || "",
        gsinNumber: customerDetails?.gsinNumber || "",
        birthDate: customerDetails?.birthDate || "",
        emailAddress: customerDetails?.emailAddress || "",
        sourceOfEnquiryId: customerDetails?.sourceOfEnquiryId || "",
        buyerTypeId: customerDetails?.buyerTypeId || "",
        enquiryOwnerId: customerDetails?.enquiryOwnerId || "",
        vehicleRegistrationNumber: customerDetails?.vehicleRegistrationNumber || "",
        vehicleModelId: customerDetails?.vehicleModelId || "",
        varientId: customerDetails?.varientId || "",
        color: customerDetails?.color || "",
        isReferral: customerDetails?.isReferral || undefined
    }), [customerDetails]);

    useEffect(() => {
        getLookUp("COLOR").then((x: any) => {
            if (x && x.data && x.data.length > 0) {
                console.log(x)
                setColorLookUp(x.data);
            }
        });
        getLookUp("VEHICLEMODEL").then((x: any) => {
            if (x && x.data && x.data.length > 0) {
                console.log(x)
                setVehicleModelLookUp(x.data);
            }
        });
        getLookUp("VARIENTTYPE").then((x: any) => {
            if (x && x.data && x.data.length > 0) {
                console.log(x)
                setVarientTypeLookUp(x.data);
            }
        });
        getLookUp("SALUTATION").then((x: any) => {
            if (x && x.data && x.data.length > 0) {
                console.log(x.data)
                setSalutationLookUp(x.data);
            }
        });
        getLookUp("GENDER").then((x: any) => {
            if (x && x.data && x.data.length > 0) {
                console.log(x.data)
                setGenderLookUp(x.data);
            }
        });
        getLookUp("ENQUIRYOWNER").then((x: any) => {
            if (x && x.data && x.data.length > 0) {
                console.log(x.data)
                setEnquiryOwnerLookUp(x.data);
            }
        });
        getLookUp("BUYERTYPE").then((x: any) => {
            if (x && x.data && x.data.length > 0) {
                console.log(x.data)
                setBuyerTypeLookUp(x.data);
            }
        });
        getLookUp("SOURCEOFENQUIRY").then((x: any) => {
            if (x && x.data && x.data.length > 0) {
                console.log(x)
                setSourceOfEnquiryLookUp(x.data);
            }
        });
    }, [])

    const methods = useForm<FormValuesProps>({
        resolver: yupResolver(NewCustomerDetailSchema),
        defaultValues,
    });

    const {
        handleSubmit, control, watch,setValue
        // formState: { isSubmitting },
    } = methods;

    const values = watch();

    const onReferralChange = (data:any) => {
        setValue("isReferral",data.target.checked);
        console.log(data);
        console.log(values);
    }

    const onInputChange = useCallback(
        async (data: FormValuesProps) => {
            try {
                onChange({
                    salutationId: data.salutationId,
                    fullName: data.fullName,
                    mobileNumber: data.mobileNumber,
                    alternateNumber: data.alternateNumber,
                    genderId: data.genderId,
                    panNumber: data.panNumber,
                    gsinNumber: data.gsinNumber,
                    birthDate: data.birthDate,
                    emailAddress: data.emailAddress,
                    sourceOfEnquiryId: data.sourceOfEnquiryId,
                    buyerTypeId: data.buyerTypeId,
                    enquiryOwnerId: data.enquiryOwnerId,
                    isSameAsBillingAddress: data.isSameAsBillingAddress,
                    vehicleRegistrationNumber: data.vehicleRegistrationNumber,
                    vehicleModelId: data.vehicleModelId,
                    varientId: data.varientId,
                    color: data.color,
                    isReferral: data.isReferral,
                });
            } catch (error) {
                console.error(error);
            }
        },
        [onChange]
    );

    return (
        <>
            <RHFSelect name="salutationId" size={GLOBALCLASSINPUT} label={t('COMMON.FORM.SALUTATION') as any}  onChange={()=>onInputChange}>
                <MenuItem value="">None</MenuItem>
                <Divider sx={{ borderStyle: 'dashed' }} />
                {salutationLookUp && salutationLookUp.map((item: any) => (
                    <MenuItem key={item.value + item.label} value={item.value}> {item.label} </MenuItem>
                ))}
            </RHFSelect>
            <RHFTextField size={GLOBALCLASSINPUT} name="fullName" label={t('COMMON.FORM.FULLNAME') as any} />
            <RHFTextField size={GLOBALCLASSINPUT} name="mobileNumber" label={t('COMMON.FORM.MOBILENUMBER') as any}  onChange={()=>onInputChange}/>
            <RHFTextField size={GLOBALCLASSINPUT} name="alternateNumber" label={t('COMMON.FORM.ALTERNATENUMBER') as any}  onChange={()=>onInputChange}/>
            <RHFSelect name="genderId" size={GLOBALCLASSINPUT} label={t('COMMON.FORM.GENDER') as any}  onChange={()=>onInputChange}>
                <MenuItem value="">None</MenuItem>
                <Divider sx={{ borderStyle: 'dashed' }} />
                {genderLookUp && genderLookUp.map((item: any) => (
                    <MenuItem key={item.value + item.label} value={item.value}> {item.label} </MenuItem>
                ))}
            </RHFSelect>
            <RHFTextField size={GLOBALCLASSINPUT} name="panNumber" label={t('COMMON.FORM.PANNUMBER') as any}  onChange={()=>onInputChange}/>
            <RHFTextField size={GLOBALCLASSINPUT} name="gsinNumber" label={t('COMMON.FORM.GSINNUMBER') as any}  onChange={()=>onInputChange}/>
            <Controller
                name="birthDate"
                control={control}
                render={({ field, fieldState: { error } }) => (
                    <DatePicker
                        label={t('COMMON.FORM.BIRTHDATE') as any}
                        value={field.value}
                        onChange={(newValue) => {
                            field.onChange(newValue);
                        }}
                        format="dd-MMM-yyyy"
                        slotProps={{
                            textField: {
                                size: 'small',
                                error: !!error,
                                helperText: error?.message,
                            },
                        }}
                    />
                )}
            />
            <RHFTextField size={GLOBALCLASSINPUT} name="emailAddress" label={t('COMMON.FORM.EMAILADDRESS') as any}  onChange={()=>onInputChange}/>
            <RHFSelect name="sourceOfEnquiryId" size={GLOBALCLASSINPUT} label={t('COMMON.FORM.SOURCEOFENQUIRY') as any}  onChange={()=>onInputChange}>
                <MenuItem value="">None</MenuItem>
                <Divider sx={{ borderStyle: 'dashed' }} />
                {sourceOfEnquiryLookUp && sourceOfEnquiryLookUp.map((item: any) => (
                    <MenuItem key={item.value + item.label} value={item.value}> {item.label} </MenuItem>
                ))}
            </RHFSelect>
            <RHFSelect name="buyerTypeId" size={GLOBALCLASSINPUT} label={t('COMMON.FORM.BUYERTYPE') as any}  onChange={()=>onInputChange}>
                <MenuItem value="">None</MenuItem>
                <Divider sx={{ borderStyle: 'dashed' }} />
                {buyerTypeLookUp && buyerTypeLookUp.map((item: any) => (
                    <MenuItem key={item.value + item.label} value={item.value}> {item.label} </MenuItem>
                ))}
            </RHFSelect>
            <RHFSelect name="enquiryOwnerId" size={GLOBALCLASSINPUT} label={t('COMMON.FORM.ENQUIRYOWNER') as any}  onChange={()=>onInputChange}>
                <MenuItem value="">None</MenuItem>
                <Divider sx={{ borderStyle: 'dashed' }} />
                {enquiryOwnerLookUp && enquiryOwnerLookUp.map((item: any) => (
                    <MenuItem key={item.value + item.label} value={item.value}> {item.label} </MenuItem>
                ))}
            </RHFSelect>
            <RHFTextField size={GLOBALCLASSINPUT} name="vehicleRegistrationNumber" label={t('COMMON.FORM.VEHICLEREGISTRATIONNUMBER') as any} />
            <RHFSelect name="vehicleModelId" size={GLOBALCLASSINPUT} label={t('COMMON.FORM.VEHICLEMODEL') as any}  onChange={()=>onInputChange}>
                <MenuItem value="">None</MenuItem>
                <Divider sx={{ borderStyle: 'dashed' }} />
                {vehicleModelLookUp && vehicleModelLookUp.map((item: any) => (
                    <MenuItem key={item.value + item.label} value={item.value}> {item.label} </MenuItem>
                ))}
            </RHFSelect>
            <RHFSelect name="varientId" size={GLOBALCLASSINPUT} label={t('COMMON.FORM.VARIENTTYPE') as any}  onChange={()=>onInputChange}>
                <MenuItem value="">None</MenuItem>
                <Divider sx={{ borderStyle: 'dashed' }} />
                {varientTypeLookUp && varientTypeLookUp.map((item: any) => (
                    <MenuItem key={item.value + item.label} value={item.value}> {item.label} </MenuItem>
                ))}
            </RHFSelect>
            <RHFSelect name="colorId" size={GLOBALCLASSINPUT} label={t('COMMON.FORM.COLOR') as any}  onChange={()=>onInputChange}>
                <MenuItem value="">None</MenuItem>
                <Divider sx={{ borderStyle: 'dashed' }} />
                {colorLookUp && colorLookUp.map((item: any) => (
                    <MenuItem key={item.value + item.label} value={item.value} > <span style={{ backgroundColor: item.data, padding: "3px 10px", borderRadius: "50%", marginRight: "5px", width: "20px", height: "20px" }}>&nbsp;</span> {item.label}    </MenuItem>
                ))}
            </RHFSelect>
            {/* <FormControlLabel 
            name="isReferral"
                control={
                    <Switch checked={values.isReferral||false} onChange={onReferralChange} name="isReferral" />
                }
                label={t("COMMON.FORM.REFERRAL")}
            /> */}
            <RHFSwitch checked={values.isReferral||false}   label={t("COMMON.FORM.REFERRAL")}  onChange={()=>onInputChange} name="isReferral" />
            <RHFSwitch checked={values.isLoyality||false}   label={t("COMMON.FORM.LOYALITY")}  onChange={()=>onInputChange} name="isReferral" />
        </>
    );
}
