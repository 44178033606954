import { useCallback } from "react";
import { deletePincode } from "src/redux/slices/geography-settings/pincode";
import { useDispatch, useSelector } from "src/redux/store";

export default function usePincode() {
    const dispatch = useDispatch();
    const { pincode,pincodeDelete,pincodesStatus,pincodes} = useSelector(
        (state) => state.pincode
    );

    const onDeletePincode = useCallback(
        (id: string) => {
            dispatch(deletePincode(id));
        },
        [dispatch]
    );

    return {
        pincode,pincodeDelete,pincodesStatus,pincodes,
        onDeletePincode
    }
}