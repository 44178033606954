
import { useSelector } from "src/redux/store";

export default function useVehicleStatus() {
    const { vehicleStatus,vehicleStatusDelete,vehicleStatusStatus} = useSelector(
        (state) => state.vehicleStatus
    );

    return {
        vehicleStatus,vehicleStatusStatus,
    }
}