import * as Yup from 'yup';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import Box from '@mui/material/Box';
// types
import { IAddressItem } from 'src/types/address';
// assets
import { countries } from 'src/assets/data';
// components
import Iconify from 'src/components/iconify';
import {
    RHFTextField, RHFSelect,
} from 'src/components/hook-form';
import { Divider, Grid, MenuItem } from '@mui/material';
import { GLOBALCLASSINPUT } from 'src/utils/contants/config-common';
import { useLocales } from 'src/locales';
import { IDeliveryNote } from 'src/types/sales-journey/delivery.note';
import { DeliveryNoteInvoice, IDeliveryNoteInvoice } from 'src/types/common';
import { getLookUp } from 'src/redux/slices/lookup';
import { DatePicker } from '@mui/x-date-pickers';

// ----------------------------------------------------------------------

interface FormValuesProps extends IDeliveryNoteInvoice {
}

type Props = {
    onChange: (address: IDeliveryNoteInvoice) => void;
    currentModel?: IDeliveryNoteInvoice;
    gridTemplateColumns?: any;
};

export default function DeliveryNoteInvoiceForm({ onChange, currentModel = { ...DeliveryNoteInvoice } as IDeliveryNoteInvoice, gridTemplateColumns }: Props) {
    const { t } = useLocales();
    const [salesExecutiveLookUp, setSalesExecutiveLookUp] = useState([] as any);
    const [teamLeaderLookUp, setTeamLeaderLookUp] = useState([] as any);
    const [salesManagerLookUp, setSalesManagerLookUp] = useState([] as any);

    const NewAddressSchema = Yup.object().shape({
        deliveryNoteForId: Yup.string(),
        invoiceNumber: Yup.string(),
        invoiceDate: Yup.string(),
        vinNumber: Yup.string(),
        engineNumber: Yup.string(),
        relationShip: Yup.string(),
        deliveryDate: Yup.string(),
        salesExecutiveId: Yup.string(),
        teamLeadId: Yup.string(),
        customerName: Yup.string(),
        fatherName: Yup.string(),
        customerId: Yup.string(),
    });

    const defaultValues = useMemo(() => ({
        deliveryNoteForId: currentModel?.deliveryNoteForId || "",
        invoiceNumber: currentModel?.invoiceNumber || "",
        invoiceDate: currentModel?.invoiceDate || "",
        vinNumber: currentModel?.vinNumber || "",
        engineNumber: currentModel?.engineNumber || "",
        relationShipId: currentModel?.relationShipId || "",
        deliveryDate: currentModel?.deliveryDate || "",
        salesExecutiveId: currentModel?.salesExecutiveId || "",
        teamLeadId: currentModel?.teamLeadId || "",
        customerName: currentModel?.customerName || "",
        fatherName: currentModel?.fatherName || "",
        customerId: currentModel?.customerId || "",
    }), [currentModel]);

    useEffect(() => {
        getLookUp("SALESEXECUTIVE").then((x: any) => {
            if (x && x.data && x.data.length > 0) {
                console.log(x)
                setSalesExecutiveLookUp(x.data);
            }
        });
        getLookUp("TEAMLEADER").then((x: any) => {
            if (x && x.data && x.data.length > 0) {
                console.log(x)
                setTeamLeaderLookUp(x.data);
            }
        });
        getLookUp("SALESMANAGER").then((x: any) => {
            if (x && x.data && x.data.length > 0) {
                console.log(x)
                setSalesManagerLookUp(x.data);
            }
        });
    }, [])

    const methods = useForm<FormValuesProps>({
        resolver: yupResolver(NewAddressSchema),
        defaultValues,
    });

    const {
        handleSubmit,control
        // formState: { isSubmitting },
    } = methods;

    const onInputChange = useCallback(
        async (data: FormValuesProps) => {
            try {
                onChange({
                    deliveryNoteForId: data?.deliveryNoteForId,
                    invoiceNumber: data?.invoiceNumber,
                    invoiceDate: data?.invoiceDate,
                    vinNumber: data?.vinNumber,
                    engineNumber: data?.engineNumber,
                    relationShipId: data?.relationShipId,
                    deliveryDate: data?.deliveryDate,
                    salesExecutiveId: data?.salesExecutiveId,
                    teamLeadId: data?.teamLeadId,
                    customerName: data?.customerName,
                    fatherName: data?.fatherName,
                    customerId: data?.customerId,
                });
            } catch (error) {
                console.error(error);
            }
        },
        [onChange]
    );

    return (
        <>
            <RHFTextField size={GLOBALCLASSINPUT} name="address" label={t("COMMON.FORM.DELIVERYNOTEFOR")} onChange={() => onInputChange} />
            <RHFTextField size={GLOBALCLASSINPUT} name="invoiceNumber" label={t("COMMON.FORM.INVOICENUMBER")} onChange={() => onInputChange} />
            <Controller
                name="invoiceDate"
                control={control}
                render={({ field, fieldState: { error } }) => (
                    <DatePicker
                        label={t('COMMON.FORM.INVOICEDATE') as any}
                        value={field.value}
                        onChange={(newValue) => {
                            field.onChange(newValue);
                        }}
                        format="dd-MMM-yyyy"
                        slotProps={{
                            textField: {
                                size: 'small',
                                error: !!error,
                                helperText: error?.message,
                            },
                        }}
                    />
                )}
            />
            <RHFTextField size={GLOBALCLASSINPUT} name="vinNumber" label={t("COMMON.FORM.VINNUMBER")} onChange={() => onInputChange} />
            <RHFTextField size={GLOBALCLASSINPUT} name="engineNumber" label={t("COMMON.FORM.ENGINENUMBER")} onChange={() => onInputChange} />
            <RHFTextField size={GLOBALCLASSINPUT} name="relationShipManager" label={t("COMMON.FORM.RELATIONSHIPMANAGER")} onChange={() => onInputChange} />
            <Controller
                name="deliveryDate"
                control={control}
                render={({ field, fieldState: { error } }) => (
                    <DatePicker
                        label={t('COMMON.FORM.DELIVERYDATE') as any}
                        value={field.value}
                        onChange={(newValue) => {
                            field.onChange(newValue);
                        }}
                        format="dd-MMM-yyyy"
                        slotProps={{
                            textField: {
                                size: 'small',
                                error: !!error,
                                helperText: error?.message,
                            },
                        }}
                    />
                )}
            />
            <RHFSelect name="salesExecutiveId" size={GLOBALCLASSINPUT} label={t('COMMON.FORM.SALEEXECUTIVE') as any} onChange={() => onInputChange}>
                <MenuItem value="">None</MenuItem>
                <Divider sx={{ borderStyle: 'dashed' }} />
                {salesExecutiveLookUp && salesExecutiveLookUp.map((item: any) => (
                    <MenuItem key={item.value + item.label} value={item.value}> {item.label} </MenuItem>
                ))}
            </RHFSelect>
            <RHFSelect name="teamLeadId" size={GLOBALCLASSINPUT} label={t('COMMON.FORM.TEAMLEAD') as any} onChange={() => onInputChange}>
                <MenuItem value="">None</MenuItem>
                <Divider sx={{ borderStyle: 'dashed' }} />
                {teamLeaderLookUp && teamLeaderLookUp.map((item: any) => (
                    <MenuItem key={item.value + item.label} value={item.value}> {item.label} </MenuItem>
                ))}
            </RHFSelect>
            <RHFTextField size={GLOBALCLASSINPUT} name="customerName" label={t("COMMON.FORM.CUSTOMERNAME")} onChange={() => onInputChange} />
            <RHFTextField size={GLOBALCLASSINPUT} name="fatherName" label={t("COMMON.FORM.FATHERNAME")} onChange={() => onInputChange} />
            <RHFTextField size={GLOBALCLASSINPUT} name="customerId" label={t("COMMON.FORM.CUSTOMERID")} onChange={() => onInputChange} />
        </>
    );
}