import { IBookingDetail, ICustomerBooking, IExchange, IFinanceDetail, IInsuranceDetail, IReferrals, ISchemesOffers, IVehicleDetail } from "../common";
import { IErrorType } from "../error";

export type IInvoiceTableFilters = {
  name: string;
  status: string[];
};

export type IInvoiceTableFilterValue = string | string[];

export type IInvoice = {
  id: number;
  search:string;
  taxCalculation:string;
  bookingDate:string;
  taxPayableOnReverseCharge:string;
  bookingDetail: IBookingDetail;
  bookingCustomer: ICustomerBooking;
  billingCustomer: ICustomerBooking;
  vehicleDetail:IVehicleDetail;
  schemeOffers:ISchemesOffers;
  insurance:IInsuranceDetail;
  finance:IFinanceDetail;
  exchange:IExchange;
  loyality:any;
  referral:IReferrals;
}

export type IInvoiceState = {
  invoice: IInvoice[];
  invoiceStatus: {
    loading: boolean;
    empty: boolean;
    error: IErrorType;
  };
  invoiceDelete: {
    loading: boolean;
    empty: any;
    error: IErrorType;
  }
};

export const defaultFilters = {
  name: '',
  status: [],
};