export const LEADCATEGORY_COLUMNS = ["name","displayOrder","status"];

export const TABLE_HEAD_LEAD_CATEGORY = [
    { id: 'name', label: "LEADCATEGORY.VIEW.GRID.LEADCATEGORYNAME" },
    { id: 'displayOrder', label: "LEADCATEGORY.VIEW.GRID.DISPLAYORDER" },
    { id: 'status', label: "LEADCATEGORY.VIEW.GRID.STATUS" },
    { id: 'id', width: 88 },
  ];

export const defaultFilters = {
    name: '',
    status: [],
  };
  