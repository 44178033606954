import { useSelector } from "src/redux/store";

export default function useVehicleClass() {
    const { vehicleClassesStatus,vehicleClasses} = useSelector(
        (state) => state.vehicleClass
    );

   
    return {
        vehicleClassesStatus,vehicleClasses,
    }
}