import {  useSelector } from "src/redux/store";

export default function useLeadStatus() {
    const { enquiryStatus,enquiryStatusDelete,enquiryStatusStatus} = useSelector(
        (state) => state.enquiryStatus
    );

 

    return {
        enquiryStatus,enquiryStatusDelete,enquiryStatusStatus 
    }
}