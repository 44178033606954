import { createSlice, Dispatch } from '@reduxjs/toolkit';
import { IVehicleModelState } from 'src/types/vehicle.model';
// utils
import axios, { API_ENDPOINTS } from 'src/utils/axios';


const initialState: IVehicleModelState = {
    vehicleModel:[],
    vehicleModelStatus:{
        loading:false,
        empty:false,
        error:null
    },
    vehicleModelDelete: {
        loading:false,
        empty:false,
        error:null
    }
}

const slice = createSlice({
    name: 'vehicleModel',
    initialState,
    reducers:{
        getVehicleModelsStart(state) {
            state.vehicleModelStatus.loading=true;
            state.vehicleModelStatus.empty=false;
            state.vehicleModelStatus.error = null;
        },
        getVehicleModelsFailure(state, action) {
            state.vehicleModelStatus.loading=true;
            state.vehicleModelStatus.empty=false;
            state.vehicleModelStatus.error = action.payload;
        },
        getVehicleModelsSuccess(state, action) {
            const vehicleModel = action.payload;
            state.vehicleModel = vehicleModel;
            state.vehicleModelStatus.loading = false;
            state.vehicleModelStatus.empty = !vehicleModel.length;
            state.vehicleModelStatus.error = null;
        }
        ,
        deleteVehicleModelStart(state) {
            state.vehicleModelDelete.loading=true;
            state.vehicleModelDelete.empty=false;
            state.vehicleModelDelete.error = null;
        },
        deleteVehicleModelFailure(state, action) {
            state.vehicleModelDelete.loading=true;
            state.vehicleModelDelete.empty=false;
            state.vehicleModelDelete.error = action.payload;
        },
        deleteVehicleModelSuccess(state, action) {
            const vehicleModelDelete = action.payload;
            state.vehicleModelDelete = vehicleModelDelete;
            state.vehicleModelDelete.loading = false;
            state.vehicleModelDelete.empty = action.payload;
            state.vehicleModelDelete.error = null;
        }
    }
});

export default slice.reducer;

// Actions
export const {
    getVehicleModelsStart,
    getVehicleModelsFailure,
    getVehicleModelsSuccess,
    deleteVehicleModelStart,
    deleteVehicleModelFailure,
    deleteVehicleModelSuccess
} = slice.actions;

export function getVehicleModels(data:any) {
    return async (dispatch: Dispatch) => {
        dispatch(slice.actions.getVehicleModelsStart());
        try {
            await axios.get(API_ENDPOINTS.VEHICLEMODEL.LIST).then((res:any)=> {
                if(res.data.statusCode === 200)
                dispatch(slice.actions.getVehicleModelsSuccess(res.data.data));
            else 
                 dispatch(slice.actions.getVehicleModelsFailure([]));
            });
        }catch(error){
            dispatch(slice.actions.getVehicleModelsFailure(error));
        }
    }
}

export function deleteVehicleModel(id: any) {
return  axios.delete(`${API_ENDPOINTS.VEHICLEMODEL.DELETE}/${id}`);
}

export function addUpdateVehicleModel(data: any) {
return axios.post(API_ENDPOINTS.VEHICLEMODEL.ADDUPDATEVEHICLEMODEL,data);
}