import { createSlice, Dispatch } from '@reduxjs/toolkit';
import { IBookingState } from 'src/types/booking/booking.type';
// utils
import axios, { API_ENDPOINTS } from 'src/utils/axios';

const initialState: IBookingState = {
    booking:[],
    bookingStatus:{
        loading:false,
        empty:false,
        error:null
    },
    bookingDelete: {
        loading:false,
        empty:false,
        error:null
    }
}

const slice = createSlice({
    name: 'booking',
    initialState,
    reducers:{
        getBookingStart(state) {
            state.bookingStatus.loading=true;
            state.bookingStatus.empty=false;
            state.bookingStatus.error = null;
        },
        getBookingFailure(state, action) {
            state.bookingStatus.loading=true;
            state.bookingStatus.empty=false;
            state.bookingStatus.error = action.payload;
        },
        getBookingSuccess(state, action) {
            const booking = action.payload;
            state.booking = booking;
            state.bookingStatus.loading = false;
            state.bookingStatus.empty = !booking.length;
            state.bookingStatus.error = null;
        },
        deleteBookingStart(state) {
            state.bookingDelete.loading=true;
            state.bookingDelete.empty=false;
            state.bookingDelete.error = null;
        },
        deleteBookingFailure(state, action) {
            state.bookingDelete.loading=true;
            state.bookingDelete.empty=false;
            state.bookingDelete.error = action.payload;
        },
        deleteBookingSuccess(state, action) {
            const bookingDelete = action.payload;
            state.bookingDelete = bookingDelete;
            state.bookingDelete.loading = false;
            state.bookingDelete.empty = action.payload;
            state.bookingDelete.error = null;
        }
    }
});

export default slice.reducer;

// Actions
export const {
    getBookingStart,
    getBookingFailure,
    getBookingSuccess,
    deleteBookingStart,
    deleteBookingFailure,
    deleteBookingSuccess
} = slice.actions;

export function getBooking(data:any) {
        return async (dispatch: Dispatch) => {
            dispatch(slice.actions.getBookingStart());
            try{
            //    const response = await axios.post(API_ENDPOINTS.VEHICLECLASS.SEARCH,data);
                dispatch(slice.actions.getBookingSuccess([
                    {id:1,bookingNumber:'671879821372',date:"23-12-2023",customerName:"Madhavan",contact:"987654321",model:"Breeza",status:true},
                    {id:2,bookingNumber:'671879821372',date:"20-12-2023",customerName:"Doni",contact:"987654321",model:"I10",status:false},
                ]
                    ));
            }catch(error){
                dispatch(slice.actions.getBookingFailure(error));
            }
        }
}

export function deleteBooking(id: any) {
    return async (dispatch: Dispatch) => {
        dispatch(slice.actions.deleteBookingStart());
        try{
            const response = await axios.get(API_ENDPOINTS.BODYTYPE.DELETE+id);
            dispatch(slice.actions.deleteBookingSuccess(response.data));
        } catch(error){
            dispatch(slice.actions.deleteBookingFailure(error));
        }
    }
}
