import {  useSelector } from "src/redux/store";

export default function useManufacturer() {
    const { manufacturersStatus,manufacturers} = useSelector(
        (state) => state.manufacturer
    );

    return {
       manufacturersStatus,manufacturers,
    }
}