
export const VEHICLECLASS_COLUMNS =["name","status"]

export const TABLE_HEAD_VEHICLE_CLASS = [
  { id: 'name', label: "VEHICLECLASS.VIEW.GRID.VEHICLECLASS" },
  { id: 'status', label: 'VEHICLECLASS.VIEW.GRID.STATUS' },
  { id: 'id', width: 88 },
];

export const STATUS_OPTIONS = [
  { value: 'active', label: 'Active' },
  { value: 'inactive', label: 'Inactive' },
];

export const defaultFilters = {
  name: '',
  status: [],
};
