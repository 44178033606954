import Button from '@mui/material/Button';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import IconButton from '@mui/material/IconButton';
// hooks
import { useBoolean } from 'src/hooks/use-boolean';
import Iconify from 'src/components/iconify';
import { ConfirmDialog } from 'src/components/custom-dialog';
import { usePopover } from 'src/components/custom-popover';
import { useLocales } from 'src/locales';
import Tooltip from '@mui/material/Tooltip';
import Label from 'src/components/label';
import Avatar from '@mui/material/Avatar';
import ListItemText from '@mui/material/ListItemText';
// ----------------------------------------------------------------------

type Props = {
  row: any;
  columnNames: string[];
  selected: boolean;
  onEditRow: VoidFunction;
  onViewRow: VoidFunction;
  onSelectRow: VoidFunction;
  onDeleteRow: VoidFunction;
};

export default function CommonTemplateTableRow({
  row,
  columnNames,
  selected,
  onSelectRow,
  onDeleteRow,
  onEditRow,
  onViewRow,
}: Props) {
  const { t } = useLocales();

  const confirm = useBoolean();

  const popover = usePopover();

  const renderRowElement = (key: any, index: any, rowData: any) => {
    switch (key) {
      case "leadName": return <TableCell key={`tableCell${index}`} sx={{ display: 'flex', alignItems: 'center' }}>
        <Avatar src="row[key]" sx={{ mr: 2 }} />
        <ListItemText
          primary={row[key] ? row[key].split(',')[0] : ""}
          secondary={row[key] ? row[key].split(',')[1] : ""}
          primaryTypographyProps={{ typography: 'body2', noWrap: true }}
          secondaryTypographyProps={{
            component: 'span',
            typography: 'caption',
          }}
        />
      </TableCell>
      case "customerName": return <TableCell key={`tableCell${index}`} sx={{ display: 'flex', alignItems: 'center' }}>
        <Avatar src="row[key]" sx={{ mr: 2 }} />
        <ListItemText
          primary={row[key] || ""}
          secondary={row?.contact  || ""}
          primaryTypographyProps={{ typography: 'body2', noWrap: true }}
          secondaryTypographyProps={{
            component: 'span',
            typography: 'caption',
          }}
        />
      </TableCell>
      case "contact": return <TableCell key={`tableCell${index}`} >
        <ListItemText
          primary={rowData[key]}
          secondary={rowData?.emailAddress}
          primaryTypographyProps={{ typography: 'body2', noWrap: true }}
          secondaryTypographyProps={{
            component: 'span',
            typography: 'caption',
          }}
        />
      </TableCell>
      case "status": return <TableCell key={`tableCell${index}`}>
        <Label variant="soft" color={
          (rowData[key] === true && 'success') ||
          (rowData[key] === false && 'warning') ||
          'default'
        }>
          {
            row[key] === true ? 'Active' : 'Inactive'
          }
        </Label>
      </TableCell>;
      case "img": return <TableCell key={`tableCell${index}`} style={{ width: "20px" }}>
        {rowData[key] ? <Avatar
          src={rowData[key]}
          variant="rounded"
          sx={{ width: 54, height: 54, mr: 2 }}
        /> : <Iconify icon="material-symbols:image" sx={{ width: 54, height: 54, mr: 2 }} />}
      </TableCell>;
      case "code": return <TableCell key={`tableCell${index}`} style={{ width: "50px" }}>
        <span style={{ backgroundColor: rowData[key], width: "100px", padding: "15px 25px 15px 25px", borderRadius: "30%" }}>  {"       "}</span>
      </TableCell>;
       case "color": return <TableCell key={`tableCell${index}`} style={{ width: "50px" }}>
       <span style={{ backgroundColor: rowData[key], width: "100px", padding: "15px 25px 15px 25px", borderRadius: "30%" }}>  {"       "}</span>
     </TableCell>;
      default: return <TableCell key={`tableCell${index}`}> {rowData[key]} </TableCell>;
    }
  }

  return (
    <>
      <TableRow hover selected={selected}>
        {
          columnNames
            .map((key, index) => (
              renderRowElement(key, index, row)
            ))
        }

        <TableCell align="right" sx={{ px: 1, whiteSpace: 'nowrap' }}>
          <Tooltip title="Quick Edit" placement="top" arrow>
            <IconButton onClick={() => {
              onEditRow();
              popover.onClose();
            }}  >
              <Iconify icon="solar:pen-bold" />
            </IconButton>
          </Tooltip>
          <Tooltip title="Quick Delete" sx={{ color: 'error.main' }} placement="top" arrow>
            <IconButton onClick={() => {
              confirm.onTrue();
              popover.onClose();
            }}>
              <Iconify icon="solar:trash-bin-trash-bold" />
            </IconButton>
          </Tooltip>
        </TableCell>
      </TableRow>
      <ConfirmDialog
        open={confirm.value}
        onClose={confirm.onFalse}
        title={t("CONFIRM_DIALOG_DELETE_TITLE")}
        content={t("CONFIRM_DELETE_MSG")}
        action={
          <Button variant="contained" color="error" onClick={onDeleteRow}>
            {t("CONFIRM_DIALOG_DELETE_BUTTON")}
          </Button>
        }
      />
    </>
  );
}
