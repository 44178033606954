import { createSlice, Dispatch } from '@reduxjs/toolkit';
import { IVisitState } from 'src/types/general-settings/visit';
// utils
import axios, { API_ENDPOINTS } from 'src/utils/axios';

const initialState: IVisitState = {
    visit:[],
    visitStatus:{
        loading:false,
        empty:false,
        error:null
    },
    visitDelete: {
        loading:false,
        empty:false,
        error:null
    }
}

const slice = createSlice({
    name: 'visit',
    initialState,
    reducers:{
        getVisitStart(state) {
            state.visitStatus.loading=true;
            state.visitStatus.empty=false;
            state.visitStatus.error = null;
        },
        getVisitFailure(state, action) {
            state.visitStatus.loading=true;
            state.visitStatus.empty=false;
            state.visitStatus.error = action.payload;
        },
        getVisitSuccess(state, action) {
            const visit = action.payload;
            state.visit = visit;
            state.visitStatus.loading = false;
            state.visitStatus.empty = !visit.length;
            state.visitStatus.error = null;
        },
        deleteVisitStart(state) {
            state.visitDelete.loading=true;
            state.visitDelete.empty=false;
            state.visitDelete.error = null;
        },
        deleteVisitFailure(state, action) {
            state.visitDelete.loading=true;
            state.visitDelete.empty=false;
            state.visitDelete.error = action.payload;
        },
        deleteVisitSuccess(state, action) {
            const visitDelete = action.payload;
            state.visitDelete = visitDelete;
            state.visitDelete.loading = false;
            state.visitDelete.empty = action.payload;
            state.visitDelete.error = null;
        }
    }
});

export default slice.reducer;

// Actions
export const {
    getVisitStart,
    getVisitFailure,
    getVisitSuccess,
    deleteVisitStart,
    deleteVisitFailure,
    deleteVisitSuccess
} = slice.actions;
 

export function getVisits(data:any) {
    return async (dispatch: Dispatch) => {
        dispatch(slice.actions.getVisitStart());
        try {
            await axios.get(API_ENDPOINTS.GENERALSETTINGS.VISIT.LIST).then((res:any)=> {
                if(res.data.statusCode === 200)
                dispatch(slice.actions.getVisitSuccess(res.data.data));
            else 
                 dispatch(slice.actions.getVisitFailure([]));
            });
        }catch(error){
            dispatch(slice.actions.getVisitFailure(error));
        }
    }
}

export function deleteVisit(id: any) {
return  axios.delete(`${API_ENDPOINTS.GENERALSETTINGS.VISIT.DELETE}/${id}`);
}

export function addUpdateVisit(data: any) {
return axios.post(API_ENDPOINTS.GENERALSETTINGS.VISIT.ADDUPDATEVISIT,data);
}