export const LEADSTATUS_COLUMNS = ["color","name","enquiryCategoryName","displayOrder","status"];

export const TABLE_HEAD_LEAD_STATUS = [
    { id: 'color', label: "LEADSTATUS.VIEW.GRID.COLORS" },
    { id: 'name', label: "LEADSTATUS.VIEW.GRID.LEADSTATUSNAME" },
    { id: 'enquiryCategoryName', label: "LEADSTATUS.VIEW.GRID.LEADCATEGRY" },
    { id: 'displayOrder', label: "LEADSTATUS.VIEW.GRID.DISPLAYORDER" },
    { id: 'status', label: "LEADSTATUS.VIEW.GRID.STATUS" },
    { id: 'id', width: 88 },
  ];

export const defaultFilters = {
    name: '',
    status: [],
  };
  