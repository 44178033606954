export const COLORPALATTE_COLUMNS = ["code","name","status"];

export const TABLE_HEAD_COLORPALATTE = [
    { id: 'code', label: "" },
    { id: 'name', label: "COLORPALATTE.VIEW.GRID.COLORCODE" },
    { id: 'status', label: "COLORPALATTE.VIEW.GRID.STATUS" },
    { id: 'id', width: 88 },
  ];

export const defaultFilters = {
    name: '',
    status: [],
  };
  