import { useCallback } from "react";
import { deleteCountry } from "src/redux/slices/geography-settings/country";
import { useDispatch, useSelector } from "src/redux/store";
import { useRouter } from "src/routes/hook";

export default function useCountry() {
    const dispatch = useDispatch();
    const { country,countryDelete,countriesStatus,countries} = useSelector(
        (state) => state.country
    );

    const onDeleteCountry = useCallback(
        (id: string) => {
            dispatch(deleteCountry(id));
        },
        [dispatch]
    );

    return {
        country,countryDelete,countriesStatus,countries,
        onDeleteCountry
    }
}