export const VEHICLEHISTORY_COLUMNS = ["name","displayOrder","status"];

export const TABLE_HEAD_VEHICLE_HISTORY = [
    { id: 'name', label: "VEHICLEHISTORY.VIEW.GRID.VEHICLEHISTORYNAME" },
    { id: 'order', label: "VEHICLEHISTORY.VIEW.GRID.DISPLAYORDER" },
    { id: 'status', label: "VEHICLEHISTORY.VIEW.GRID.STATUS" },
    { id: 'id', width: 88 },
  ];

export const defaultFilters = {
    name: '',
    status: [],
  };
  