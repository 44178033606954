import { useCallback } from "react";
import { deleteMandal } from "src/redux/slices/geography-settings/mandal";
import { useDispatch, useSelector } from "src/redux/store";

export default function useMandal() {
    const dispatch = useDispatch();
    const { mandal,mandalDelete,mandalsStatus,mandals} = useSelector(
        (state) => state.mandal
    );

    const onDeleteMandal = useCallback(
        (id: string) => {
            dispatch(deleteMandal(id));
        },
        [dispatch]
    );

    return {
        mandal,mandalDelete,mandalsStatus,mandals,
        onDeleteMandal
    }
}