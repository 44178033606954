export const TRANSMISSION_COLUMNS = ["name","status"];

export const TABLE_HEAD_TRANSMISSION = [
    { id: 'name', label: "TRANSMISSION.VIEW.GRID.TRANSMISSIONNAME" },
    { id: 'status', label: "TRANSMISSION.VIEW.GRID.STATUS" },
    { id: 'id', width: 88 },
  ];

export const defaultFilters = {
    name: '',
    status: [],
  };
  