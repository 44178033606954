 
import {  useSelector } from "src/redux/store";

export default function useOwner() {
    const { salutation,salutationStatus} = useSelector(
        (state) => state.salutation
    );
 
    return {
        salutation,salutationStatus,
    }
}