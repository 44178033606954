import * as Yup from 'yup';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import LoadingButton from '@mui/lab/LoadingButton';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Unstable_Grid2';
// routes
import { paths } from 'src/routes/paths';
import { useRouter } from 'src/routes/hook';
// components
import { CustomFile } from 'src/components/upload';
import { useSnackbar } from 'src/components/snackbar';
import { INewVehicleModel } from 'src/types/new.vehicle.model';
import FormProvider, {
  RHFSelect,
  RHFTextField,
  RHFUpload,
} from 'src/components/hook-form';
import { useSettingsContext } from 'src/components/settings';
import { useLocales } from 'src/locales';
import { GLOBALCLASSINPUT } from 'src/utils/contants/config-common';
import { Dialog, DialogTitle, DialogContent, Alert, DialogActions, MenuItem, Divider, Container } from '@mui/material';
import { getLookUp } from 'src/redux/slices/lookup';
import CustomBreadcrumbs from 'src/components/custom-breadcrumbs';
import { DatePicker } from '@mui/x-date-pickers';
import NewVehicleDetails from './new-vehicle-details';


// ----------------------------------------------------------------------

interface FormValuesProps extends Omit<INewVehicleModel, 'images'> {
  images: (CustomFile | string)[];
}

type Props = {
  title: string;
  open: boolean;
  onClose: VoidFunction;
  submitButtonText?: string;
  currentModel: INewVehicleModel;
};

// export default function NewVehicleModelForm({ currentModel, open, onClose, title, submitButtonText }: Props) {
  export default function NewVehicleModelForm() {
  const router = useRouter();
  const settings = useSettingsContext();
  const { t } = useLocales();
  const { enqueueSnackbar } = useSnackbar();
  const [vehicleClassLookUp, setVehicleClassLookUp] = useState([] as any);
  const [manufacturerLookUp, setManufacturerLookUp] = useState([] as any);
  const [currentModel, setCurrentModel] = useState({} as INewVehicleModel);
  const [selectedRow, setSelectedRow] = useState({} as INewVehicleModel);
  const NewUserSchema = Yup.object().shape({
    newVehicleModelName: Yup.string().required(t('NEWVEHICLEMODEL.FORM.VEHICLECLASSNAME_REQUIRED') as any),
    class: Yup.string().required(t('NEWVEHICLEMODEL.FORM.CLASS_REQUIRED') as any),
    year: Yup.string().required(t('NEWVEHICLEMODEL.FORM.YEAR_REQUIRED') as any),
    manufacturer: Yup.string().required(t('NEWVEHICLEMODEL.FORM.MANUFACTURER_REQUIRED') as any),
    image: Yup.string(),
    images: Yup.array().min(1, 'Images is required'),
    description: Yup.string(),
    varientDetails: Yup.array(),
    status: Yup.bool()
  });

  const defaultValues = useMemo(
    () => ({
      newVehicleModelName: currentModel?.newVehicleModelName || '',
      class: currentModel?.class || '',
      year: currentModel?.year || '',
      manufacturer: currentModel?.manufacturer || '',
      image: currentModel?.image || '',
      description: currentModel?.description || '',
      status: currentModel?.status || true,
      images: currentModel?.images || [],
      varientDetails: currentModel?.varientDetails || [
        { varientTypeId: '1', transmission: 'Automatic', fuelType: 'Petrol', colors: 'Red' },
      ],
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [currentModel]
  );

  useEffect(() => {
    getLookUp("VEHICLECLASS").then((x: any) => {
        if (x && x.data && x.data.length > 0) {
            console.log(x.data)
            setVehicleClassLookUp(x.data);
        }
    });
    getLookUp("MANUFACTURER").then((x: any) => {
      if (x && x.data && x.data.length > 0) {
          console.log(x.data)
          setManufacturerLookUp(x.data);
      }
  });
  },[]);

  const methods = useForm<FormValuesProps>({
    resolver: yupResolver(NewUserSchema),
    defaultValues,
  });

  const {
    reset,
    watch,
    setValue,
    handleSubmit,
    formState: { isSubmitting },control
  } = methods;

  const values = watch();

  const onSubmit = useCallback(
    async (data: FormValuesProps) => {
      try {
        await new Promise((resolve) => setTimeout(resolve, 500));
        reset();
        enqueueSnackbar(currentModel ? 'Update success!' : 'Create success!');
        router.push(paths.dashboard.user.list);
        console.info('DATA', data);
      } catch (error) {
        console.error(error);
      }
    },
    [currentModel, enqueueSnackbar, reset, router]
  );

  const handleDrop = useCallback(
    (acceptedFiles: File[]) => {
      const files = values.images || [];

      const newFiles = acceptedFiles.map((file) =>
        Object.assign(file, {
          preview: URL.createObjectURL(file),
        })
      );

      setValue('images', [...files, ...newFiles], { shouldValidate: true });
    },
    [setValue, values.images]
  );

  const handleRemoveFile = useCallback(
    (inputFile: File | string) => {
      const filtered = values.images && values.images?.filter((file) => file !== inputFile);
      setValue('images', filtered);
    },
    [setValue, values.images]
  );

  const handleRemoveAllFiles = useCallback(() => {
    setValue('images', []);
  }, [setValue]);

  return (
    <Container maxWidth={settings.themeStretch ? false : 'lg'}>
    <CustomBreadcrumbs
        heading="Edit"
        links={[
            {
                name: 'Dashboard',
                href: paths.dashboard.root,
            },
            {
                name: 'New Vehicle Model',
                href: paths.dashboard.salesJouryney.enquiry.root,
            }
     
        ]}
        sx={{
            mb: { xs: 3, md: 5 },
        }}
    />
      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
    
          <Alert variant="outlined" severity="error" sx={{ mb: 2 }}>
            {t('NEWVEHICLEMODEL.FORM.ALREADY_EXISTS_ERROR')}
          </Alert>
          <Grid container spacing={3}>
            <Grid xs={12} md={4}>
              <Card sx={{ pt: 6, pb: 3, px: 2 }}>
                <Box sx={{ mb: 1 }} >
                  <RHFUpload
                    multiple
                    thumbnail
                    name="images"
                    maxSize={3145728}
                    onDrop={handleDrop}
                    onRemove={handleRemoveFile}
                    onRemoveAll={handleRemoveAllFiles}
                    onUpload={() => console.info('ON UPLOAD')}
                  />
                </Box>
              </Card>
            </Grid>
            <Grid xs={12} md={8}>
              <Card sx={{ p: 3 }}>
                <Box
                  rowGap={3}
                  columnGap={2}
                  display="grid"
                  gridTemplateColumns={{
                    xs: 'repeat(1, 1fr)',
                    sm: 'repeat(1, 1fr)',
                  }}
                >
                  <RHFTextField size={GLOBALCLASSINPUT} name="newVehicleModelName" label={t('COMMON.FORM.VEHICLEMODELNAME') as any} />
                  <RHFTextField size={GLOBALCLASSINPUT} name="newVehicleModelName" label={t('COMMON.FORM.VEHICLEFAMILYMODEL') as any} />
                  <RHFSelect size={GLOBALCLASSINPUT} name="vehicleClassId" label={t('COMMON.FORM.VEHICLECLASS') as any} >
                    <MenuItem value="">None</MenuItem>
                    <Divider sx={{ borderStyle: 'dashed' }} />
                    {vehicleClassLookUp && vehicleClassLookUp.map((item: any) => (
                      <MenuItem key={item.value + item.label} value={item.value}> {item.label} </MenuItem>
                    ))}
                  </RHFSelect>
               
                <Controller
                  name="year"
                  control={control}
                  render={({ field, fieldState: { error } }) => (
                    <DatePicker
                      views={['year']}
                      label={t('COMMON.FORM.YEAR') as any}
                      slotProps={{
                        textField: {
                          size: "small",
                          margin: 'normal',
                        },
                      }}
                    />
                  )}
                />
                  <RHFSelect size={GLOBALCLASSINPUT} name="vehicleClassId" label={t('COMMON.FORM.MANUFACTURER') as any} >
                    <MenuItem value="">None</MenuItem>
                    <Divider sx={{ borderStyle: 'dashed' }} />
                    {manufacturerLookUp && manufacturerLookUp.map((item: any) => (
                      <MenuItem key={item.value + item.label} value={item.value}> {item.label} </MenuItem>
                    ))}
                  </RHFSelect>
                  <RHFTextField size={GLOBALCLASSINPUT} name="description" label={t('COMMON.FORM.DESCRIPTION') as any} />
                </Box>
              </Card>
            </Grid>
          </Grid>
          <br/>
          <NewVehicleDetails />
      </FormProvider>
      </Container>
  );
}
