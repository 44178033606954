import { createSlice, Dispatch } from '@reduxjs/toolkit';
import { ISourceOfEnquiryState } from 'src/types/lead-settings/lead.source.type';
// utils
import axios, { API_ENDPOINTS } from 'src/utils/axios';

const initialState: ISourceOfEnquiryState = {
    sourceOfEnquiry:[],
    sourceOfEnquiryStatus:{
        loading:false,
        empty:false,
        error:null
    },
    sourceOfEnquiryDelete: {
        loading:false,
        empty:false,
        error:null
    }
}

const slice = createSlice({
    name: 'sourceOfEnquiry',
    initialState,
    reducers:{
        getSourceofEnquiryStart(state) {
            state.sourceOfEnquiryStatus.loading=true;
            state.sourceOfEnquiryStatus.empty=false;
            state.sourceOfEnquiryStatus.error = null;
        },
        getSourceofEnquiryFailure(state, action) {
            state.sourceOfEnquiryStatus.loading=true;
            state.sourceOfEnquiryStatus.empty=false;
            state.sourceOfEnquiryStatus.error = action.payload;
        },
        getSourceofEnquirySuccess(state, action) {
            const sourceOfEnquiry = action.payload;
            state.sourceOfEnquiry = sourceOfEnquiry;
            state.sourceOfEnquiryStatus.loading = false;
            state.sourceOfEnquiryStatus.empty = !sourceOfEnquiry.length;
            state.sourceOfEnquiryStatus.error = null;
        },
        deleteSourceofEnquiryStart(state) {
            state.sourceOfEnquiryDelete.loading=true;
            state.sourceOfEnquiryDelete.empty=false;
            state.sourceOfEnquiryDelete.error = null;
        },
        deleteSourceofEnquiryFailure(state, action) {
            state.sourceOfEnquiryDelete.loading=true;
            state.sourceOfEnquiryDelete.empty=false;
            state.sourceOfEnquiryDelete.error = action.payload;
        },
        deleteSourceofEnquirySuccess(state, action) {
            const sourceOfEnquiryDelete = action.payload;
            state.sourceOfEnquiryDelete = sourceOfEnquiryDelete;
            state.sourceOfEnquiryDelete.loading = false;
            state.sourceOfEnquiryDelete.empty = action.payload;
            state.sourceOfEnquiryDelete.error = null;
        }
    }
});

export default slice.reducer;

// Actions
export const {
    getSourceofEnquiryStart,
    getSourceofEnquiryFailure,
    getSourceofEnquirySuccess,
    deleteSourceofEnquiryStart,
    deleteSourceofEnquiryFailure,
    deleteSourceofEnquirySuccess
} = slice.actions;

export function getSourceofEnquiry(data:any) {
    return async (dispatch: Dispatch) => {
        dispatch(slice.actions.getSourceofEnquiryStart());
        try {
            await axios.get(API_ENDPOINTS.ENQUIRYSETTINGS.SOURCEOFENQUIRY.LIST).then((res:any)=> {
                if(res.data.statusCode === 200) {
                    dispatch(slice.actions.getSourceofEnquirySuccess(res.data.data));
                }
            else 
                 dispatch(slice.actions.getSourceofEnquirySuccess([]));
            });
        }catch(error){
            dispatch(slice.actions.getSourceofEnquiryFailure(error));
        }
    }
}

export function deleteSourceofEnquiry(id: any) {
    return  axios.delete(`${API_ENDPOINTS.ENQUIRYSETTINGS.SOURCEOFENQUIRY.DELETE}/${id}`);
}

export function addUpdateSourceofEnquiry(data: any) {
    return axios.post(API_ENDPOINTS.ENQUIRYSETTINGS.SOURCEOFENQUIRY.ADDUPDATESOURCEOFENQUIRY,data);
}
