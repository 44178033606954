import { Helmet } from "react-helmet-async";
import { useLocales } from "src/locales";
import NewVehicleModelListView from "src/sections/masters/settings/new-vehicle-model/new-vehicle-model-list-view";

export default function NewVehicleModelListPage() {
  const {t}=useLocales();
    return (
      <>
        <Helmet>
          <title> { t("NEWVEHICLEMODEL.PAGE.TITLE")}</title>
        </Helmet>
  
        <NewVehicleModelListView/>
      </>
    );
  }