import * as Yup from 'yup';
import { useSnackbar } from "src/components/snackbar";
import { CustomFile } from "src/components/upload";
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import FormProvider, { RHFAutocomplete, RHFSelect, RHFSwitch, RHFTextField } from 'src/components/hook-form';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import LoadingButton from '@mui/lab/LoadingButton';
import { Dialog, DialogTitle, Divider, MenuItem, Stack, Typography } from '@mui/material';
import { IEnquiryStatus } from 'src/types/lead-settings/lead.status.type';
import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import { useLocales } from 'src/locales';
import { GLOBALCLASSINPUT } from 'src/utils/contants/config-common';
import { getLookUp } from 'src/redux/slices/lookup';
import { ColorBox, ColorPicker } from 'material-ui-color';
import { addUpdateEnquiryStatus } from 'src/redux/slices/lead-settings/lead-status';

interface FormValuesProps extends Omit<IEnquiryStatus, 'avatarUrl'> {
    avatarUrl: CustomFile | string | null;
}

type Props = {
    title: string;
    open: boolean;
    onClose: VoidFunction;
    submitButtonText?: string;
    currentModel: IEnquiryStatus;
};

export default function LeadStatusForm({ currentModel, open, onClose, title, submitButtonText }: Props) {
    const { enqueueSnackbar } = useSnackbar();
    const { t } = useLocales();
    const [showErrorMsg, setShowErrorMsg] = useState(false as any);
    const [leadStatusLookUp, setLeadStatusLookUp] = useState([] as any);
    const [colorValue, setColorValue] = useState('#AC3131' as any)
    const NewModelSchema = Yup.object().shape({
        name: Yup.string().required(t('LEADSTATUS.FORM.REQUIRED.LEADSTATUSNAME') as any),
        enquiryCategoryId: Yup.string().required(t('LEADSTATUS.FORM.REQUIRED.LEADCATEGRY') as any),
        color: Yup.string().required(t('LEADSTATUS.FORM.REQUIRED.COLORS') as any),
        image: Yup.string(),
        description: Yup.string(),
        id: Yup.number(),
        displayOrder: Yup.number(),
        status: Yup.bool(),
    });

    const defaultValues = useMemo(() => ({
        name: currentModel?.name || "",
        enquiryCategoryId: currentModel?.enquiryCategoryId || "",
        color: currentModel?.color || "#AC3131",
        image: currentModel?.image || "",
        description: currentModel?.description || "",
        status: !(currentModel?.status),
        id: currentModel?.id || 0,
        displayOrder: currentModel?.displayOrder || 0
    }), [currentModel]);

    useEffect(() => {
        getLookUp("ENQUIRYCATEGORY").then((x: any) => {
            if (x) {
                setLeadStatusLookUp(x.data)
            }
        });
    }, []);

    const methods = useForm<FormValuesProps>({
        resolver: yupResolver(NewModelSchema),
        defaultValues
    });

    const {
        reset,
        handleSubmit,setValue,
        formState: { isSubmitting },
    } = methods;

    const onSubmit = useCallback(
        async (data: FormValuesProps) => {
            try {
                console.info('DATA', data);
                addUpdateEnquiryStatus({ ...data, status: !data.status,color:colorValue}).then((res: any) => {
                    console.log(res);
                    if (res.data.data > 0 && res.data.statusCode === 200) {
                        reset();
                        onClose();
                        enqueueSnackbar(t('LEADSTATUS.FORM.UPDATE_SUCCESS') as any);
                    } else {
                        setShowErrorMsg(true);
                    }
                });
            } catch (error) {
                console.error(error);
            }
        }, [enqueueSnackbar, onClose, reset, t, colorValue]);

    return (
        <Dialog scroll="body"
            fullWidth
            maxWidth={false}
            open={open}
            onClose={() => null}
            PaperProps={{
                sx: { maxWidth: 720 },
            }}
        >
            <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
                <DialogTitle>{title}</DialogTitle>
                <DialogContent>
                    {showErrorMsg && <Alert variant="outlined" severity="error" sx={{ mb: 3 }}>
                        {t('LEADSTATUS.FORM.ALREADY_EXISTS_ERROR')}
                    </Alert>}
                    <Stack spacing={3} sx={{ p: 1 }}>
                        <RHFTextField size={GLOBALCLASSINPUT} name="name" label={t("LEADSTATUS.FORM.LEADSTATUSNAME")} />
                        <span style={{ textAlign: "left" }}>Pick a Color</span>
                        <ColorPicker  disableAlpha   value={colorValue} onChange={(data: any) => {setColorValue(data.css.backgroundColor); }} />
                        <RHFSelect name="enquiryCategoryId" size={GLOBALCLASSINPUT} label={t('LEADSTATUS.FORM.LEADCATEGRY') as any} >
                        <MenuItem value="">None</MenuItem>
                <Divider sx={{ borderStyle: 'dashed' }} />
                            {leadStatusLookUp && leadStatusLookUp.map((item: any) => (
                                <MenuItem key={item.value + item.label} value={item.value}> {item.label} </MenuItem>
                            ))}
                        </RHFSelect>
                        <RHFTextField size={GLOBALCLASSINPUT} name="description" label={t("LEADSTATUS.FORM.DESCRIPTION")} />
                        <RHFTextField size={GLOBALCLASSINPUT} name="displayOrder" label={t("LEADSTATUS.FORM.ORDER")} />
                        {currentModel.id !== 0 && <RHFSwitch name="status" label={t("LEADSTATUS.FORM.STATUS")} />}
                    </Stack>
                </DialogContent>
                <DialogActions>
                    <Button variant="outlined" onClick={onClose}>
                        {t("CANCEL")}
                    </Button>
                    <LoadingButton type="submit" variant="contained"  loading={isSubmitting}>
                        {submitButtonText}
                    </LoadingButton>
                </DialogActions>
            </FormProvider>
        </Dialog>
    )

}