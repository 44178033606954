 
import {  useSelector } from "src/redux/store";

export default function useBankName() {
    const { bankName,bankNameStatus} = useSelector(
        (state) => state.bankName
    );
 
    return {
        bankName,bankNameStatus,
    }
}