import { IErrorType } from "./error";

export type IStateTableFilters = {
    name: string;
    status: string[];
  };
  
export type IStateTableFilterValue = string | string[];

export type IState = {
    stateId:number;
    stateName:string;
    stateCode:string;
    country:string;
    countryId:string;
    gstStateCode:string;
    image:string;
    description:string;
    status:boolean;
}
  
export type IStateState = {
  states: IState[];
  state: IState | null;
  statesStatus: {
    loading: boolean;
    empty: boolean;
    error: IErrorType;
  };
  stateStatus: {
    loading: boolean;
    error: IErrorType;
  };
  stateDelete: {
    loading:boolean;
    empty:any;
    error:IErrorType;
  }
};

export const defaultFilters = {
  name: '',
  status: [],
};