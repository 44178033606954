import { ICustomerDetail } from "../common";
import { IErrorType } from "../error";
import { INewVehicleModel } from "../new.vehicle.model";

export type IEnquiryTableFilters = {
    name: string;
    status: string[];
  };
  
export type IEnquiryTableFilterValue = string | string[];

export type IEnquiry = {
  id?:any;
    enquiryId:number;
    fullName:string;
    mobileNumber:string;
    emailAddress:string;
    enquiryStatusId:string;
    buyerTypeId:string;
    enquiryOwnerId:string;
    address:IAddress;
    corporateDetail:ICorporateDetail;
    vehicleDetail:INewVehicleModel;
    replacementDetail:IReplacementDetail;
    nextFollowUpDateAndTime:string;
    testDriveDate:string;
    birthDate:string;
    coverNoteDate:string;
    validFrom:string;
    validTo:string;
    doDate:string;
    referralDateOfBirth:string; 
    warrentyValidTo:string; 
    warrentyValidFrom:string; 
    enquiryStatus:string;
    description:string;
    status:boolean;
    customerDetail:ICustomerDetail
}

export type IAddress = {
  addressLine1:string;
  addressLine2:string;
  pincodeId:string;
  cityId:string;
  countryId:string;
  stateId:string;
  districtId:string;
  mandalId:string;
}

export type ICorporateDetail = {
  companyName:string;
  jobTitle:string;
  address:IAddress;
}

export type IReplacementDetail = {
  vehicleName:string;
  rto:string;
  manufacturerId:string;
  modelId:string;
  varientId:string;
  year:string;
  color:string;
  fuelTypeId:string;
  transmissionId:string;
  bodyTypeId:string;
  ownerId:string;
  kmsDriven:string;
  vehicleId:string;
  replacementDetailId:string;
}


export type IEnquiryState = {
  enquiry: IEnquiry[];
  enquiryStatus: {
    loading: boolean;
    empty: boolean;
    error: IErrorType;
  };
  enquiryDelete: {
    loading:boolean;
    empty:any;
    error:IErrorType;
  }
};

export const defaultFilters = {
  name: '',
  status: [],
};