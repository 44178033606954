import { IErrorType } from "./error";

export type IBodyTypeTableFilters = {
    name: string;
    status: string[];
  };
  
export type IBodyTypeTableFilterValue = string | string[];

export type IBodyType = {
    id:number;
    name:string;
    image:string;
    description:string;
    status:boolean;
}
  
export type IBodyTypeState = {
  bodyTypes: IBodyType[];
  bodyType: IBodyType | null;
  bodyTypesStatus: {
    loading: boolean;
    empty: boolean;
    error: IErrorType;
  };
  bodyTypeStatus: {
    loading: boolean;
    error: IErrorType;
  };
  bodyTypeDelete: {
    loading:boolean;
    empty:any;
    error:IErrorType;
  }
};

export const defaultFilters = {
  name: '',
  status: [],
};