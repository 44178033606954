 
import {  useSelector } from "src/redux/store";

export default function useInsuranceCompany() {
    const { insuranceCompany,insuranceCompanyStatus} = useSelector(
        (state) => state.insuranceCompany
    );
 
    return {
        insuranceCompany,insuranceCompanyStatus,
    }
}