export const ALLOTMENT_COLUMNS = ["vinNumber","modelDescription","vehicleAge","pdi","vehicleStatus","oemInvoiceNumberDate"];

export const TABLE_HEAD_ALLOTMENT = [
    { id: 'vinNumber', label: "ALLOTMENT.VIEW.GRID.VINNUMBER" },
    { id: 'modelDescription', label: "ALLOTMENT.VIEW.GRID.MODELDESCRIPTION" },
    { id: 'vehicleAge', label: "ALLOTMENT.VIEW.GRID.VEHICLEAGE" },
    { id: 'pdi', label: "ALLOTMENT.VIEW.GRID.PID" },
    { id: 'vehicleStatus', label: "ALLOTMENT.VIEW.GRID.VEHICLESTATUS" },
    { id: 'oemInvoiceNumberDate', label: "ALLOTMENT.VIEW.GRID.OEMINVOICENUMBERDATE" },
    { id: 'id', width: 88 },
  ];
  export const TABLE_HEAD_FORM_ALLOTMENT = [
    { id: 'bookingNumber', label: "ALLOTMENT.FORM.GRID.BOOKINGNUMBER" },
    { id: 'bookingDate', label: "ALLOTMENT.FORM.GRID.BOOKINGDATE" },
    { id: 'customerName', label: "ALLOTMENT.FORM.GRID.CUSTOMERNAME" },
    { id: 'mobileNumber', label: "ALLOTMENT.FORM.GRID.MOBILENUMBER" },
    { id: 'deliveryDate', label: "ALLOTMENT.FORM.GRID.DELIVERYDATE" },
    { id: 'id', width: 88 },
  ];

export const defaultFilters = {
    name: '',
    status: [],
  };
  