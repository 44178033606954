import { IErrorType } from "./error";

export type ITransmissionTableFilters = {
    name: string;
    status: string[];
  };
  
export type ITransmissionTableFilterValue = string | string[];

export type ITransmission = {
    id:number;
    name:string;
    description:string;
    status:boolean;
}
  
export type ITransmissionState = {
  transmissions: ITransmission[];
  transmission: ITransmission | null;
  transmissionsStatus: {
    loading: boolean;
    empty: boolean;
    error: IErrorType;
  };
  transmissionStatus: {
    loading: boolean;
    error: IErrorType;
  };
  transmissionDelete: {
    loading:boolean;
    empty:any;
    error:IErrorType;
  }
};

export const defaultFilters = {
  name: '',
  status: [],
};