export const ENQUIRYMANAGEMENT_COLUMNS = ["leadRefNumber","leadName","contact","leadStatus","leadSource","leadOwner"];

export const TABLE_HEAD_ENQUIRY_MANAGEMENT = [
    { id: 'leadRefNumber', label: "ENQUIRYMANAGEMENT.VIEW.GRID.LEADREFNUMBER" },
    { id: 'leadName', label: "ENQUIRYMANAGEMENT.VIEW.GRID.LEADNAME" },
    { id: 'contact', label: "ENQUIRYMANAGEMENT.VIEW.GRID.CONTACT" },
    { id: 'leadStatus', label: "ENQUIRYMANAGEMENT.VIEW.GRID.ENQUIRYSTATUS" },
    { id: 'leadSource', label: "ENQUIRYMANAGEMENT.VIEW.GRID.SOURCEOFENQUIRY" },
    { id: 'leadOwner', label: "ENQUIRYMANAGEMENT.VIEW.GRID.ENQUIRYOWNER" },
    { id: 'id', width: 88 },
  ];

  export const TABLE_HEAD_ENQUIRYTESTDRIVE = [
    { id: 'leadRefNumber', label: "COMMON.FORM.GRID.TESTDRIVEVEHICLEMODEL" },
    { id: 'status', label: "COMMON.FORM.GRID.TESTDRIVELOCATION" },
    { id: 'status', label: "COMMON.FORM.GRID.TESTDRIVEDATE" },
    { id: 'status', label: "COMMON.FORM.GRID.TESTDRIVEMOBILENUMBER" },
    { id: 'status', label: "COMMON.FORM.GRID.TESTDRIVESTARTINGKMS" },
    { id: 'status', label: "COMMON.FORM.GRID.TESTDRIVEENDINGKMS" },
    { id: 'status', label: "COMMON.FORM.GRID.TESTDRIVEGENERATENUMBER" },
    { id: 'id', width: 88 },
  ];
  export const TABLE_HEAD_ENQUIRYFOLLOWUP = [
    { id: 'leadRefNumber', label: "ENQUIRYMANAGEMENT.FORM.GRID.FOLLOWUPENQUIRYSTATUS" },
    { id: 'status', label: "ENQUIRYMANAGEMENT.FORM.GRID.FOLLOWUPVISIT" },
    { id: 'status', label: "ENQUIRYMANAGEMENT.FORM.GRID.FOLLOWUPDATE" },
    { id: 'id', width: 88 },
  ];

export const defaultFilters = {
    name: '',
    status: [],
  };
  