import { Suspense, lazy } from 'react';
import { Outlet } from 'react-router-dom';
// auth
// import { AuthGuard } from 'src/auth/guard';
// layouts
import DashboardLayout from 'src/layouts/dashboard';
// components
import { LoadingScreen } from 'src/components/loading-screen';
import VehicleClassListPage from 'src/pages/dashboard/masters/settings/vehicle-class/list';
import VehicleClassListView from 'src/sections/masters/settings/vehicle-class/vehicle-class';
import SegmentDetailListPage from 'src/pages/dashboard/masters/settings/segment-detail';
import BodyTypeListPage from 'src/pages/dashboard/masters/settings/body-type';
import BodyTypeListView from 'src/sections/masters/settings/body-type/body-type-list-view';
import ManufacturerListPage from 'src/pages/dashboard/masters/settings/manufacturer';
import FuelTypeListPage from 'src/pages/dashboard/masters/settings/fuel-type';
import ColorPalatteListPage from 'src/pages/dashboard/masters/settings/color-palatte';
import TransmissionListPage from 'src/pages/dashboard/masters/settings/transmission';
import VarientTypeListPage from 'src/pages/dashboard/masters/settings/varient-type';
import NewVehicleModelListPage from 'src/pages/dashboard/masters/settings/new-vehicle-model';
import UsedVehicleModelListPage from 'src/pages/dashboard/masters/settings/used-vehicle-model';
import CountryListPage from 'src/pages/dashboard/masters/geography-settings/countries';
import StatesListPage from 'src/pages/dashboard/masters/geography-settings/states';
import DistrictsListPage from 'src/pages/dashboard/masters/geography-settings/districts';
import CitiesListPage from 'src/pages/dashboard/masters/geography-settings/cities';
import MandalListPage from 'src/pages/dashboard/masters/geography-settings/mandal';
import PincodeListPage from 'src/pages/dashboard/masters/geography-settings/pincode';
import CountriesListView from 'src/sections/masters/geography-settings/countries/countries-list-view';
import StatesListView from 'src/sections/masters/geography-settings/states/states-list-view';
import DistrictsListView from 'src/sections/masters/geography-settings/districts/districts-list-view';
import CitiesListView from 'src/sections/masters/geography-settings/cities/cities-list-view';
import MandalsListView from 'src/sections/masters/geography-settings/mandal/mandal-list-view';
import PincodesListView from 'src/sections/masters/geography-settings/pincode/pincode-list-view';
import EnquiryListPage from 'src/pages/dashboard/enquiry-management';
import OwnersListPage from 'src/pages/dashboard/masters/vehicle-settings/owners';
import VehicleHistoryListPage from 'src/pages/dashboard/masters/vehicle-settings/vehicle-history';
import VehicleStatusListPage from 'src/pages/dashboard/masters/vehicle-settings/vehicle-status';
import LeadStatusListPage from 'src/pages/dashboard/masters/lead-settings';
import LeadCategoryListPage from 'src/pages/dashboard/masters/lead-settings/lead-category';
import LeadSourceListPage from 'src/pages/dashboard/masters/lead-settings/lead-source';
import BuyerTypeListPage from 'src/pages/dashboard/masters/lead-settings/buyer-type';
import OrganisationPage from 'src/pages/dashboard/organisation-settings/organisation';
import EnquiryForm from 'src/sections/enquiry-management/enquiry-form';
import SalutationsListPage from 'src/pages/dashboard/masters/general-settings/salutation';
import SalutationListView from 'src/sections/masters/general-settings/salutation/salutation-list-view';
import GendersListPage from 'src/pages/dashboard/masters/general-settings/gender';
import GenderListView from 'src/sections/masters/general-settings/gender/gender-list-view';
import SchemeTypeListPage from 'src/pages/dashboard/masters/general-settings/scheme-type';
import SchemeTypeListView from 'src/sections/masters/general-settings/scheme-type/scheme-type-list-view';
import SchemeCategoryListPage from 'src/pages/dashboard/masters/general-settings/scheme-category';
import SchemeCategoryListView from 'src/sections/masters/general-settings/scheme-category/scheme-category-list-view';
import InsuranceCompanyListView from 'src/sections/masters/general-settings/insurance-company/insurance-company-list-view';
import InsuranceCompanyListPage from 'src/pages/dashboard/masters/general-settings/insurance-company';
import BankNameListPage from 'src/pages/dashboard/masters/general-settings/bankname';
import BankNameListView from 'src/sections/masters/general-settings/bankname/bankname-list-view';
import RelationShipListPage from 'src/pages/dashboard/masters/general-settings/relation-ship';
import RelationShipListView from 'src/sections/masters/general-settings/relation-ship/relation-ship-list-view';
import CustomerTypeListPage from 'src/pages/dashboard/masters/general-settings/customer-type';
import CustomerTypeListView from 'src/sections/masters/general-settings/customer-type/customer-type-list-view';
import VisitListPage from 'src/pages/dashboard/masters/general-settings/visit';
import VisitListView from 'src/sections/masters/general-settings/visit/visit-list-view';
import FinanceArrangeByListView from 'src/sections/masters/general-settings/finance-arrange-by/finace-arrange-by-list-view';
import FinanceArrangeByListPage from 'src/pages/dashboard/masters/general-settings/finance-arrange-by';
import BookingListPage from 'src/pages/dashboard/booking';
import HypothecatedListView from 'src/sections/masters/general-settings/hypothecated/hypothecated-list-view';
import HypothecatedListPage from 'src/pages/dashboard/masters/general-settings/hypothecated';
import VehicleModelListPage from 'src/pages/dashboard/masters/settings/vehicle.model';
import VehicleModelListView from 'src/sections/masters/settings/vehicle-model/vehicle-model-list-view';
import EnquiryListView from 'src/sections/enquiry-management/enquiry-list-view';
import BookingListView from 'src/sections/booking/booking-list-view';
import AllotmentListView from 'src/sections/sales-journey/allotment/allotment-list-view';
import AllotmentListPage from 'src/pages/dashboard/sales-journey/allotment';
import InvoiceListView from 'src/sections/sales-journey/invoice/invoice-list-view';
import DeliveryNoteListPage from 'src/pages/dashboard/sales-journey/delivery-note';
import DeliveryNoteListView from 'src/sections/sales-journey/delivery-note/delivery-note-list-view';
import CustomerListView from 'src/sections/sales-journey/customer/customer-list-view';
import CustomerListPage from 'src/pages/dashboard/sales-journey/customer';
import NewVehicleModelForm from 'src/sections/masters/settings/new-vehicle-model/new-vehicle-model-form';
import AllotmentForm from 'src/sections/sales-journey/allotment/allotment-form';
import InvoiceForm from 'src/sections/sales-journey/invoice/invoice-form';
import DeliveryNoteForm from 'src/sections/sales-journey/delivery-note/delivery-note-form';
import CustomerForm from 'src/sections/sales-journey/customer/customer-form';
import BookingForm from 'src/sections/booking/booking-form';

// ----------------------------------------------------------------------

// OVERVIEW
const IndexPage = lazy(() => import('src/pages/dashboard/app'));
const OverviewEcommercePage = lazy(() => import('src/pages/dashboard/ecommerce'));
const OverviewAnalyticsPage = lazy(() => import('src/pages/dashboard/analytics'));
const OverviewBankingPage = lazy(() => import('src/pages/dashboard/banking'));
const OverviewBookingPage = lazy(() => import('src/pages/dashboard/booking'));
const OverviewFilePage = lazy(() => import('src/pages/dashboard/file'));
// PRODUCT
const ProductDetailsPage = lazy(() => import('src/pages/dashboard/product/details'));
const ProductListPage = lazy(() => import('src/pages/dashboard/product/list'));
const ProductCreatePage = lazy(() => import('src/pages/dashboard/product/new'));
const ProductEditPage = lazy(() => import('src/pages/dashboard/product/edit'));
// EMAIL TEMPLATES
const EmailTemplatesListPage = lazy(() => import('src/pages/dashboard/email-templates/list'));
const EmailTemplatesCreatePage = lazy(() => import('src/pages/dashboard/email-templates/new'));
const EmailTemplatesEditPage = lazy(() => import('src/pages/dashboard/email-templates/edit'));
// ORDER
const OrderListPage = lazy(() => import('src/pages/dashboard/order/list'));
const OrderDetailsPage = lazy(() => import('src/pages/dashboard/order/details'));
// INVOICE
const InvoiceListPage = lazy(() => import('src/pages/dashboard/accounting/invoice/list'));
const InvoiceDetailsPage = lazy(() => import('src/pages/dashboard/accounting/invoice/details'));
const InvoiceCreatePage = lazy(() => import('src/pages/dashboard/accounting/invoice/new'));
const InvoiceEditPage = lazy(() => import('src/pages/dashboard/accounting/invoice/edit'));
// USER
const UserProfilePage = lazy(() => import('src/pages/dashboard/user/profile'));
const UserCardsPage = lazy(() => import('src/pages/dashboard/user/cards'));
const UserListPage = lazy(() => import('src/pages/dashboard/user/list'));
const UserAccountPage = lazy(() => import('src/pages/dashboard/user/account'));
const UserCreatePage = lazy(() => import('src/pages/dashboard/user/new'));
const UserEditPage = lazy(() => import('src/pages/dashboard/user/edit'));
// BLOG
const BlogPostsPage = lazy(() => import('src/pages/dashboard/post/list'));
const BlogPostPage = lazy(() => import('src/pages/dashboard/post/details'));
const BlogNewPostPage = lazy(() => import('src/pages/dashboard/post/new'));
const BlogEditPostPage = lazy(() => import('src/pages/dashboard/post/edit'));
// JOB
const JobDetailsPage = lazy(() => import('src/pages/dashboard/job/details'));
const JobListPage = lazy(() => import('src/pages/dashboard/job/list'));
const JobCreatePage = lazy(() => import('src/pages/dashboard/job/new'));
const JobEditPage = lazy(() => import('src/pages/dashboard/job/edit'));
// TOUR
const TourDetailsPage = lazy(() => import('src/pages/dashboard/tour/details'));
const TourListPage = lazy(() => import('src/pages/dashboard/tour/list'));
const TourCreatePage = lazy(() => import('src/pages/dashboard/tour/new'));
const TourEditPage = lazy(() => import('src/pages/dashboard/tour/edit'));
// FILE MANAGER
const FileManagerPage = lazy(() => import('src/pages/dashboard/file-manager'));
// APP
const ChatPage = lazy(() => import('src/pages/dashboard/chat'));
const MailPage = lazy(() => import('src/pages/dashboard/mail'));
const CalendarPage = lazy(() => import('src/pages/dashboard/calendar'));
const KanbanPage = lazy(() => import('src/pages/dashboard/kanban'));
// TEST RENDER PAGE BY ROLE
const PermissionDeniedPage = lazy(() => import('src/pages/dashboard/permission'));
// BLANK PAGE
const BlankPage = lazy(() => import('src/pages/dashboard/blank'));

// ----------------------------------------------------------------------

export const dashboardRoutes = [
  {
    path: 'dashboard',
    element: (
        <DashboardLayout>
          <Suspense fallback={<LoadingScreen />}>
            <Outlet />
          </Suspense>
        </DashboardLayout>
    ),
    children: [
      { element: <IndexPage />, index: true },
      { path: 'ecommerce', element: <OverviewEcommercePage /> },
      { path: 'analytics', element: <OverviewAnalyticsPage /> },
      { path: 'banking', element: <OverviewBankingPage /> },
      { path: 'booking', element: <OverviewBookingPage /> },
      { path: 'file', element: <OverviewFilePage /> },
      {
        path: 'user',
        children: [
          { element: <UserProfilePage />, index: true },
          { path: 'profile', element: <UserProfilePage /> },
          { path: 'cards', element: <UserCardsPage /> },
          { path: 'list', element: <UserListPage /> },
          { path: 'new', element: <UserCreatePage /> },
          { path: ':id/edit', element: <UserEditPage /> },
          { path: 'account', element: <UserAccountPage /> },
        ],
      },
      {
        path: 'product',
        children: [
          { element: <ProductListPage />, index: true },
          { path: 'list', element: <ProductListPage /> },
          { path: ':id', element: <ProductDetailsPage /> },
          { path: 'new', element: <ProductCreatePage /> },
          { path: ':id/edit', element: <ProductEditPage /> },
        ],
      },
      {
        path: 'organisation-settings',
        children: [
            {
              path: 'organisation',
              children: [
                { element: <OrganisationPage />, index: true },
                { path: 'list', element: <OrganisationPage /> },
              ],
            }
          ]
        },
      {
        path: 'email-templates',
        children: [
          { element: <EmailTemplatesListPage />, index: true },
          { path: 'list', element: <EmailTemplatesListPage /> },
          { path: ':id', element: <ProductDetailsPage /> },
          { path: 'new', element: <EmailTemplatesCreatePage /> },
          { path: ':id/edit', element: <EmailTemplatesEditPage /> },
        ],
      },
      {
        path: 'sales-journey',
        children: [
          {
            path: 'customer',
            children: [
              { element: <CustomerListPage />, index: true },
              { path: 'list', element: <CustomerListView /> },
              { path: 'new', element: <CustomerForm /> },
              { path: 'edit/:id', element: <CustomerForm /> },
            ],
          },
            {
              path: 'enquiry',
              children: [
                { element: <EnquiryListPage />, index: true },
                { path: 'list', element: <EnquiryListView /> },
                { path: 'add-edit', element: <EnquiryForm /> },
                { path: 'edit/:id', element: <EnquiryForm /> },
              ],
            },
            {
              path: 'booking',
              children: [
                { element: <BookingListPage />, index: true },
                { path: 'list', element: <BookingListView /> },
                { path: 'new', element: <BookingForm /> },
                { path: 'edit/:id', element: <BookingForm /> },
              ],
            },
            {
              path: 'allotment',
              children: [
                { element: <AllotmentListPage/>, index: true },
                { path: 'list', element: <AllotmentListView /> },
                { path: 'new', element: <AllotmentForm /> },
                { path: 'edit/:id', element: <AllotmentForm /> },
              ],
            },
            {
              path: 'invoice',
              children: [
                { element: <InvoiceListPage/>, index: true },
                { path: 'list', element: <InvoiceListView /> },
               
              ],
            },
            {
              path: 'delivery-note',
              children: [
                { element: <DeliveryNoteListPage/>, index: true },
                { path: 'list', element: <DeliveryNoteListView /> },
                { path: 'new', element: <DeliveryNoteForm /> },
                { path: 'edit/:id', element: <DeliveryNoteForm /> },
              ],
            },
          ]
        },
      {
        path: 'settings',
        children: [
            {
              path: 'vehicle-class',
              children: [
                { element: <VehicleClassListPage />, index: true },
                { path: 'list', element: <VehicleClassListView /> },
              ],
            },
            {
              path: 'vehicle-model',
              children: [
                { element: <VehicleModelListPage />, index: true },
                { path: 'list', element: <VehicleModelListView /> },
              ],
            },
            {
              path: 'segment-detail',
              children: [
                { element: <SegmentDetailListPage />, index: true },
                { path: 'list', element: <SegmentDetailListPage /> },
              ],
            },
            {
              path: 'body-type',
              children: [
                { element: <BodyTypeListPage />, index: true },
                { path: 'list', element: <BodyTypeListView /> },
              ],
            },
            {
              path: 'manufacturer',
              children: [
                { element: <ManufacturerListPage />, index: true },
                { path: 'list', element: <ManufacturerListPage /> },
              ],
            },
            {
              path: 'fuel-type',
              children: [
                { element: <FuelTypeListPage />, index: true },
                { path: 'list', element: <FuelTypeListPage /> },
              ],
            },
            {
              path: 'color-palatte',
              children: [
                { element: <ColorPalatteListPage />, index: true },
                { path: 'list', element: <ColorPalatteListPage /> },
              ],
            },
            {
              path: 'transmission',
              children: [
                { element: <TransmissionListPage />, index: true },
                { path: 'list', element: <TransmissionListPage /> },
              ],
            },
            {
              path: 'varient-type',
              children: [
                { element: <VarientTypeListPage />, index: true },
                { path: 'list', element: <VarientTypeListPage /> },
              ],
            },
            {
              path: 'new-vehicle-model',
              children: [
                { element: <NewVehicleModelListPage />, index: true },
                { path: 'list', element: <NewVehicleModelListPage /> },
                { path: 'new', element: <NewVehicleModelForm /> },
                { path: 'edit', element: <NewVehicleModelForm /> },
              ],
            },
            {
              path: 'used-vehicle-model',
              children: [
                { element: <UsedVehicleModelListPage />, index: true },
                { path: 'list', element: <UsedVehicleModelListPage /> }
              ],
            },
        ],
      },

      {
        path: 'vehicle-settings',
        children: [
            {
              path: 'owners',
              children: [
                { element: <OwnersListPage />, index: true },
                { path: 'list', element: <OwnersListPage /> },
              ],
            },
            {
              path: 'vehicle-history',
              children: [
                { element: <VehicleHistoryListPage />, index: true },
                { path: 'list', element: <VehicleHistoryListPage /> },
              ],
            },
            {
              path: 'vehicle-status',
              children: [
                { element: <VehicleStatusListPage />, index: true },
                { path: 'list', element: <VehicleStatusListPage /> },
              ],
            }
          ]
        },
        {
          path: 'booking',
          children: [
            { element: <BookingListPage />, index: true },
            { path: 'list', element: <BookingListPage /> },
            // { path: 'create', element: <EnquiryForm /> },
          ],
        },
      {
        path: 'lead-management',
        children: [
          { element: <EnquiryListPage />, index: true },
          { path: 'list', element: <EnquiryListPage /> },
          // { path: 'create', element: <EnquiryForm /> },
        ],
      },

      {
        path: 'geography-settings',
        children: [
            {
              path: 'countries',
              children: [
                { element: <CountryListPage />, index: true },
                { path: 'list', element: <CountriesListView /> },
              ],
            },
            {
              path: 'states',
              children: [
                { element: <StatesListPage />, index: true },
                { path: 'list', element: <StatesListView /> },
              ],
            },
            {
              path: 'districts',
              children: [
                { element: <DistrictsListPage />, index: true },
                { path: 'list', element: <DistrictsListView /> },
              ],
            },
            {
              path: 'cities',
              children: [
                { element: <CitiesListPage />, index: true },
                { path: 'list', element: <CitiesListView /> },
              ],
            },
            {
              path: 'mandal',
              children: [
                { element: <MandalListPage />, index: true },
                { path: 'list', element: <MandalsListView /> },
              ],
            },
            {
              path: 'pincode',
              children: [
                { element: <PincodeListPage />, index: true },
                { path: 'list', element: <PincodesListView /> },
              ],
            },
        ],
      },
      {
        path: 'lead-settings',
        children: [
          {
            path: 'lead-status',
            children: [
              { element: <LeadStatusListPage />, index: true },
              { path: 'list', element: <LeadStatusListPage /> },
            ],
          },
          {
            path: 'lead-category',
            children: [
              { element: <LeadCategoryListPage />, index: true },
              { path: 'list', element: <LeadCategoryListPage /> },
            ],
          },
          {
            path: 'lead-source',
            children: [
              { element: <LeadSourceListPage />, index: true },
              { path: 'list', element: <LeadSourceListPage /> },
            ],
          },
          {
            path: 'buyer-type',
            children: [
              { element: <BuyerTypeListPage />, index: true },
              { path: 'list', element: <BuyerTypeListPage /> },
            ],
          },
        ]
      },
      {
        path: 'scheme-settings',
        children: [
          {
            path: 'scheme-type',
            children: [
              { element: <SchemeTypeListPage />, index: true },
              { path: 'list', element: <SchemeTypeListView /> },
            ],
          },
          {
            path: 'scheme-category',
            children: [
              { element: <SchemeCategoryListPage />, index: true },
              { path: 'list', element: <SchemeCategoryListView /> },
            ],
          },
        ]
      },
      {
        path: 'general-settings',
        children: [
            {
              path: 'salutation',
              children: [
                { element: <SalutationsListPage />, index: true },
                { path: 'list', element: <SalutationListView /> },
              ],
            },
            {
              path: 'gender',
              children: [
                { element: <GendersListPage />, index: true },
                { path: 'list', element: <GenderListView /> },
              ],
            },
           
            {
              path: 'insurance-company',
              children: [
                { element: <InsuranceCompanyListPage />, index: true },
                { path: 'list', element: <InsuranceCompanyListView /> },
              ],
            },
            {
              path: 'finance-arrange-by',
              children: [
                { element: <FinanceArrangeByListPage />, index: true },
                { path: 'list', element: <FinanceArrangeByListView /> },
              ],
            },
            {
              path: 'bankname',
              children: [
                { element: <BankNameListPage />, index: true },
                { path: 'list', element: <BankNameListView /> },
              ],
            },
            {
              path: 'relation-ship',
              children: [
                { element: <RelationShipListPage />, index: true },
                { path: 'list', element: <RelationShipListView /> },
              ],
            },
            {
              path: 'customer-type',
              children: [
                { element: <CustomerTypeListPage />, index: true },
                { path: 'list', element: <CustomerTypeListView /> },
              ],
            },
            {
              path: 'visit',
              children: [
                { element: <VisitListPage />, index: true },
                { path: 'list', element: <VisitListView /> },
              ],
            },
            {
              path: 'hypothecated',
              children: [
                { element: <HypothecatedListPage />, index: true },
                { path: 'list', element: <HypothecatedListView /> },
              ],
            },
        ]
      },
      {
        path: 'order',
        children: [
          { element: <OrderListPage />, index: true },
          { path: 'list', element: <OrderListPage /> },
          { path: ':id', element: <OrderDetailsPage /> },
        ],
      },
      {
        path: 'invoice',
        children: [
          { element: <InvoiceListPage />, index: true },
          { path: 'list', element: <InvoiceListPage /> },
          { path: ':id', element: <InvoiceDetailsPage /> },
          { path: ':id/edit', element: <InvoiceEditPage /> },
          { path: 'new', element: <InvoiceCreatePage /> },
        ],
      },
      {
        path: 'post',
        children: [
          { element: <BlogPostsPage />, index: true },
          { path: 'list', element: <BlogPostsPage /> },
          { path: ':title', element: <BlogPostPage /> },
          { path: ':title/edit', element: <BlogEditPostPage /> },
          { path: 'new', element: <BlogNewPostPage /> },
        ],
      },
      {
        path: 'job',
        children: [
          { element: <JobListPage />, index: true },
          { path: 'list', element: <JobListPage /> },
          { path: ':id', element: <JobDetailsPage /> },
          { path: 'new', element: <JobCreatePage /> },
          { path: ':id/edit', element: <JobEditPage /> },
        ],
      },
      {
        path: 'tour',
        children: [
          { element: <TourListPage />, index: true },
          { path: 'list', element: <TourListPage /> },
          { path: ':id', element: <TourDetailsPage /> },
          { path: 'new', element: <TourCreatePage /> },
          { path: ':id/edit', element: <TourEditPage /> },
        ],
      },
      { path: 'file-manager', element: <FileManagerPage /> },
      { path: 'mail', element: <MailPage /> },
      { path: 'chat', element: <ChatPage /> },
      { path: 'calendar', element: <CalendarPage /> },
      { path: 'kanban', element: <KanbanPage /> },
      { path: 'permission', element: <PermissionDeniedPage /> },
      { path: 'blank', element: <BlankPage /> },
    ],
  },
];
