 
import {  useSelector } from "src/redux/store";

export default function useCustomer() {
    const { customer,customerStatus} = useSelector(
        (state) => state.customer
    );
 
    return {
        customer,customerStatus,
    }
}