import { IErrorType } from "../error";

export type IHypothecatedTableFilters = {
    name: string;
    status: string[];
  };
  
export type IHypothecatedTableFilterValue = string | string[];

export type IHypothecated = {
    id:number;
    name:string;
    description:string;
    displayOrder:number;
    status:boolean;
}
 
export type IHypothecatedState = {
  hypothecated: IHypothecated[];
  hypothecatedStatus: {
    loading: boolean;
    empty: boolean;
    error: IErrorType;
  };
  hypothecatedDelete: {
    loading:boolean;
    empty:any;
    error:IErrorType;
  }
};

export const defaultFilters = {
  name: '',
  status: [],
};