 
import {  useSelector } from "src/redux/store";

export default function useCustomerType() {
    const { customerType,customerTypeStatus} = useSelector(
        (state) => state.customerType
    );
 
    return {
        customerType,customerTypeStatus,
    }
}