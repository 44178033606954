import { Helmet } from "react-helmet-async";
import { useLocales } from "src/locales";
import VehicleModelListView from "src/sections/masters/settings/vehicle-model/vehicle-model-list-view";


export default function VehicleModelListPage() {
  const {t}=useLocales();
    return (
      <>
        <Helmet>
          <title> { t("VEHICLEMODEL.PAGE.TITLE")}</title>
        </Helmet>
        <VehicleModelListView />
      </>
    );
  }