import { IErrorType } from "./error";

export type IPincodeTableFilters = {
    name: string;
    status: string[];
  };
  
export type IPincodeTableFilterValue = string | string[];

export type IPincode = {
    pincodeId:number;
    pincode:string;
    locality:string;
    countryId:string;
    stateId:string;
    districtId:string;
    cityId:string;
    mandalId:string;
    image:string;
    description:string;
    status:boolean;
}
  
export type IPincodeState = {
  pincodes: IPincode[];
  pincode: IPincode | null;
  pincodesStatus: {
    loading: boolean;
    empty: boolean;
    error: IErrorType;
  };
  pincodeStatus: {
    loading: boolean;
    error: IErrorType;
  };
  pincodeDelete: {
    loading:boolean;
    empty:any;
    error:IErrorType;
  }
};

export const defaultFilters = {
  name: '',
  status: [],
};