import { createSlice, Dispatch } from '@reduxjs/toolkit';
import { ISchemeCategoryState } from 'src/types/general-settings/scheme.category';
// utils
import axios, { API_ENDPOINTS } from 'src/utils/axios';

const initialState: ISchemeCategoryState = {
    schemeCategory:[],
    schemeCategoryStatus:{
        loading:false,
        empty:false,
        error:null
    },
    schemeCategoryDelete: {
        loading:false,
        empty:false,
        error:null
    }
}

const slice = createSlice({
    name: 'schemeCategory',
    initialState,
    reducers:{
        getSchemeCategoryStart(state) {
            state.schemeCategoryStatus.loading=true;
            state.schemeCategoryStatus.empty=false;
            state.schemeCategoryStatus.error = null;
        },
        getSchemeCategoryFailure(state, action) {
            state.schemeCategoryStatus.loading=true;
            state.schemeCategoryStatus.empty=false;
            state.schemeCategoryStatus.error = action.payload;
        },
        getSchemeCategorySuccess(state, action) {
            const schemeCategory = action.payload;
            state.schemeCategory = schemeCategory;
            state.schemeCategoryStatus.loading = false;
            state.schemeCategoryStatus.empty = !schemeCategory.length;
            state.schemeCategoryStatus.error = null;
        },
        deleteSchemeCategoryStart(state) {
            state.schemeCategoryDelete.loading=true;
            state.schemeCategoryDelete.empty=false;
            state.schemeCategoryDelete.error = null;
        },
        deleteSchemeCategoryFailure(state, action) {
            state.schemeCategoryDelete.loading=true;
            state.schemeCategoryDelete.empty=false;
            state.schemeCategoryDelete.error = action.payload;
        },
        deleteSchemeCategorySuccess(state, action) {
            const schemeCategoryDelete = action.payload;
            state.schemeCategoryDelete = schemeCategoryDelete;
            state.schemeCategoryDelete.loading = false;
            state.schemeCategoryDelete.empty = action.payload;
            state.schemeCategoryDelete.error = null;
        }
    }
});

export default slice.reducer;

// Actions
export const {
    getSchemeCategoryStart,
    getSchemeCategoryFailure,
    getSchemeCategorySuccess,
    deleteSchemeCategoryStart,
    deleteSchemeCategoryFailure,
    deleteSchemeCategorySuccess
} = slice.actions;
 

export function getSchemeCategories(data:any) {
    return async (dispatch: Dispatch) => {
        dispatch(slice.actions.getSchemeCategoryStart());
        try {
            await axios.get(API_ENDPOINTS.GENERALSETTINGS.SCHEMECATEGORY.LIST).then((res:any)=> {
                if(res.data.statusCode === 200)
                dispatch(slice.actions.getSchemeCategorySuccess(res.data.data));
            else 
                 dispatch(slice.actions.getSchemeCategoryFailure([]));
            });
        }catch(error){
            dispatch(slice.actions.getSchemeCategoryFailure(error));
        }
    }
}

export function deleteSchemeCategory(id: any) {
return  axios.delete(`${API_ENDPOINTS.GENERALSETTINGS.SCHEMECATEGORY.DELETE}/${id}`);
}

export function addUpdateSchemeCategory(data: any) {
return axios.post(API_ENDPOINTS.GENERALSETTINGS.SCHEMECATEGORY.ADDUPDATESCHEMECATEGORY,data);
}