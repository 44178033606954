import { createSlice, Dispatch } from '@reduxjs/toolkit';
import { ISchemeTypeState } from 'src/types/general-settings/scheme.type';
// utils
import axios, { API_ENDPOINTS } from 'src/utils/axios';

const initialState: ISchemeTypeState = {
    schemeType:[],
    schemeTypeStatus:{
        loading:false,
        empty:false,
        error:null
    },
    schemeTypeDelete: {
        loading:false,
        empty:false,
        error:null
    }
}

const slice = createSlice({
    name: 'schemeType',
    initialState,
    reducers:{
        getSchemeTypeStart(state) {
            state.schemeTypeStatus.loading=true;
            state.schemeTypeStatus.empty=false;
            state.schemeTypeStatus.error = null;
        },
        getSchemeTypeFailure(state, action) {
            state.schemeTypeStatus.loading=true;
            state.schemeTypeStatus.empty=false;
            state.schemeTypeStatus.error = action.payload;
        },
        getSchemeTypeSuccess(state, action) {
            const schemeType = action.payload;
            state.schemeType = schemeType;
            state.schemeTypeStatus.loading = false;
            state.schemeTypeStatus.empty = !schemeType.length;
            state.schemeTypeStatus.error = null;
        },
        deleteSchemeTypeStart(state) {
            state.schemeTypeDelete.loading=true;
            state.schemeTypeDelete.empty=false;
            state.schemeTypeDelete.error = null;
        },
        deleteSchemeTypeFailure(state, action) {
            state.schemeTypeDelete.loading=true;
            state.schemeTypeDelete.empty=false;
            state.schemeTypeDelete.error = action.payload;
        },
        deleteSchemeTypeSuccess(state, action) {
            const schemeTypeDelete = action.payload;
            state.schemeTypeDelete = schemeTypeDelete;
            state.schemeTypeDelete.loading = false;
            state.schemeTypeDelete.empty = action.payload;
            state.schemeTypeDelete.error = null;
        }
    }
});

export default slice.reducer;

// Actions
export const {
    getSchemeTypeStart,
    getSchemeTypeFailure,
    getSchemeTypeSuccess,
    deleteSchemeTypeStart,
    deleteSchemeTypeFailure,
    deleteSchemeTypeSuccess
} = slice.actions;
 

export function getSchemeTypes(data:any) {
    return async (dispatch: Dispatch) => {
        dispatch(slice.actions.getSchemeTypeStart());
        try {
            await axios.get(API_ENDPOINTS.GENERALSETTINGS.SCHEMETYPE.LIST).then((res:any)=> {
                if(res.data.statusCode === 200)
                dispatch(slice.actions.getSchemeTypeSuccess(res.data.data));
            else 
                 dispatch(slice.actions.getSchemeTypeFailure([]));
            });
        }catch(error){
            dispatch(slice.actions.getSchemeTypeFailure(error));
        }
    }
}

export function deleteSchemeType(id: any) {
return  axios.delete(`${API_ENDPOINTS.GENERALSETTINGS.SCHEMETYPE.DELETE}/${id}`);
}

export function addUpdateSchemeType(data: any) {
return axios.post(API_ENDPOINTS.GENERALSETTINGS.SCHEMETYPE.ADDUPDATESCHEMETYPE,data);
}