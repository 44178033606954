import * as Yup from 'yup';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import Box from '@mui/material/Box';
import {
    RHFTextField,
    RHFSelect,
    RHFSwitch,
} from 'src/components/hook-form';
import { Accordion, AccordionDetails, AccordionSummary, Divider, Grid, MenuItem, Typography } from '@mui/material';
import { GLOBALCLASSINPUT } from 'src/utils/contants/config-common';
import { getLookUp } from 'src/redux/slices/lookup';
import { useLocales } from 'src/locales';
import { DatePicker } from '@mui/x-date-pickers';
import { CustomerBooking, CustomerDetail, ICustomerBooking, ICustomerDetail } from 'src/types/common';
import { Controller, useForm } from 'react-hook-form';
import { IAddressItem } from 'src/types/address';
import { CustomFile } from 'src/components/upload';
import Iconify from 'src/components/iconify';
import AddressForm from './address';


// ----------------------------------------------------------------------

interface FormValuesProps extends Omit<ICustomerBooking, 'avatarUrl'> {
    avatarUrl: CustomFile | string | null;
}


type Props = {
    onChange: (address: ICustomerBooking) => void;
    gridTemplateColumns: any;
    currentModel:ICustomerBooking
};

export default function CustomerBookingDetailForm({ onChange, gridTemplateColumns = 1,currentModel={...CustomerBooking} as ICustomerBooking }: Props) {
    const [salutationLookUp, setSalutationLookUp] = useState([] as any);
    const [genderLookUp, setGenderLookUp] = useState([] as any);
    const [controlled, setControlled] = useState<string | false>("BOOKINGDETAILS");
    const { t } = useLocales();

    const NewCustomerDetailSchema = Yup.object().shape({
        salutationId: Yup.string().required('Salutation is required'),
        fullName: Yup.string().required('FullName is required'),
        mobileNumber: Yup.string().required('Customer Amount is required'),
        alternateNumber: Yup.string().required('Cover Note Date is required'),
        genderId: Yup.string().required('Registration Number is required'),
        panNumber: Yup.string().required('Registration Number is required'),
        gsinNumber: Yup.string().required('Registration Number is required'),
        birthDate: Yup.string().required('Registration Number is required'),
        emailAddress: Yup.string().required('Registration Number is required'),
        sourceOfEnquiryId: Yup.string().required('Registration Number is required'),
        buyerTypeId: Yup.string().required('Registration Number is required'),
        enquiryOwnerId: Yup.string().required('Registration Number is required'),
        isSameAsBillingAddress: Yup.string()
    });

    const defaultValues = useMemo(()=>({
         ...CustomerBooking,...currentModel
    }),[currentModel]);

    useEffect(() => {
        getLookUp("SALUTATION").then((x: any) => {
            if (x && x.data && x.data.length > 0) {
                console.log(x.data)
                setSalutationLookUp(x.data);
            }
        });
        getLookUp("GENDER").then((x: any) => {
            if (x && x.data && x.data.length > 0) {
                console.log(x.data)
                setGenderLookUp(x.data);
            }
        });

    }, [])

    const onAddressChange = useCallback(
        (address: IAddressItem) => {

        },
        []
    );

    const handleChangeControlled =
        (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
            setControlled(isExpanded ? panel : false);
        };

    const methods = useForm<FormValuesProps>({
        resolver: yupResolver(NewCustomerDetailSchema),
        defaultValues,
    });

    const {
        handleSubmit, control, watch,
        // formState: { isSubmitting },
    } = methods;
    const values = watch();
    const onInputChange = useCallback(
        async (data: FormValuesProps) => {
            try {
                onChange({
                    salutationId: data.salutationId,
                    fullName: data.fullName,
                    mobileNumber: data.mobileNumber,
                    alternateNumber: data.alternateNumber,
                    genderId: data.genderId,
                    panNumber: data.panNumber,
                    gsinNumber: data.gsinNumber,
                    birthDate: data.birthDate,
                    emailAddress: data.emailAddress,
                    sourceOfEnquiryId: data.sourceOfEnquiryId,
                    buyerTypeId: data.buyerTypeId,
                    enquiryOwnerId: data.enquiryOwnerId,
                    isSameAsBillingAddress: data.isSameAsBillingAddress
                });
            } catch (error) {
                console.error(error);
            }
        },
        [onChange]
    );

    const renderCustomerRowElemen = () => (
        <>
            <RHFTextField size={GLOBALCLASSINPUT} name="customerName" label={t('COMMON.FORM.CUSTOMERNAME') as any} onChange={() => onInputChange} />
            <RHFTextField size={GLOBALCLASSINPUT} name="fatherName" label={t('COMMON.FORM.FATHERNAME') as any} onChange={() => onInputChange} />
            <RHFTextField size={GLOBALCLASSINPUT} name="customerId" label={t('COMMON.FORM.CUSTOMERID') as any} onChange={() => onInputChange} />
            <RHFTextField size={GLOBALCLASSINPUT} name="mobileNumber" label={t('COMMON.FORM.MOBILENUMBER') as any} onChange={() => onInputChange} />
            <RHFTextField size={GLOBALCLASSINPUT} name="alternateNumber" label={t('COMMON.FORM.ALTERNATENUMBER') as any} onChange={() => onInputChange} />
            <RHFTextField size={GLOBALCLASSINPUT} name="emailAddress" label={t('COMMON.FORM.EMAILADDRESS') as any} onChange={() => onInputChange} />
            <RHFTextField size={GLOBALCLASSINPUT} name="age" label={t('COMMON.FORM.AGE') as any} onChange={() => onInputChange} />
            <RHFSelect name="genderId" size={GLOBALCLASSINPUT} label={t('COMMON.FORM.GENDER') as any} onChange={() => onInputChange}>
                <MenuItem value="">None</MenuItem>
                <Divider sx={{ borderStyle: 'dashed' }} />
                {genderLookUp && genderLookUp.map((item: any) => (
                    <MenuItem key={item.value + item.label} value={item.value}> {item.label} </MenuItem>
                ))}
            </RHFSelect>

            <Controller
                name="birthDate"
                control={control}
                render={({ field, fieldState: { error } }) => (
                    <DatePicker
                        label={t('COMMON.FORM.BIRTHDATE') as any}
                        value={field.value}
                        onChange={(newValue) => {
                            field.onChange(newValue);
                        }}
                        format="dd-MMM-yyyy"
                        slotProps={{
                            textField: {
                                size: 'small',
                                error: !!error,
                                helperText: error?.message,
                            },
                        }}
                    />
                )}
            />
            <RHFTextField size={GLOBALCLASSINPUT} name="panNumber" label={t('COMMON.FORM.PANNUMBER') as any} onChange={() => onInputChange} />
            <RHFTextField size={GLOBALCLASSINPUT} name="gsinNumber" label={t('COMMON.FORM.GSINNUMBER') as any} onChange={() => onInputChange} />
            <AddressForm onChange={onAddressChange} />
        </>
    )

    return (
        <>
          {defaultValues.isCustomerDetail && defaultValues.isBillingDetail && <>  <Accordion key='BOOKINGDETAILS'
                expanded={controlled === 'BOOKINGDETAILS'}
                onChange={handleChangeControlled('BOOKINGDETAILS')}>
                <AccordionSummary expandIcon={<Iconify icon="eva:arrow-ios-downward-fill" />}>
                    <Typography variant="subtitle1" style={{ width: "100%" }}>Ship To/ Booking Details </Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Box rowGap={2} columnGap={2} display="grid" gridTemplateColumns={{ xs: 'repeat(1, 1fr)', sm: `repeat(${gridTemplateColumns}, 1fr)` }}>
                        {renderCustomerRowElemen()}
                    </Box>
                </AccordionDetails>
            </Accordion>
            <Accordion key='BILLINGDETAILS'
                expanded={controlled === 'BILLINGDETAILS'}
                onChange={handleChangeControlled('BILLINGDETAILS')}>
                <AccordionSummary expandIcon={<Iconify icon="eva:arrow-ios-downward-fill" />}>
                    <Typography variant="subtitle1" style={{ width: "100%" }}>Bill To/ Billing Details </Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Box rowGap={2} columnGap={2} display="grid" gridTemplateColumns={{ xs: 'repeat(1, 1fr)', sm: `repeat(${gridTemplateColumns}, 1fr)` }} onChange={() => onInputChange}>
                        {renderCustomerRowElemen()}
                    </Box>
                </AccordionDetails>
            </Accordion>
            </>
            }  
            {defaultValues.isOnlyBillingDetail &&  <Box rowGap={2} columnGap={2} display="grid" gridTemplateColumns={{ xs: 'repeat(1, 1fr)', sm: `repeat(${gridTemplateColumns}, 1fr)` }} onChange={() => onInputChange}>
                        {renderCustomerRowElemen()}
                    </Box>}
        </>
    );
}
