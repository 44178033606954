 
import {  useSelector } from "src/redux/store";

export default function useDeliveryNote() {
    const { deliveryNote,deliveryNoteStatus} = useSelector(
        (state) => state.deliveryNote
    );
 
    return {
        deliveryNote,deliveryNoteStatus,
    }
}