import { createSlice, Dispatch } from '@reduxjs/toolkit';
import { IVehicleClassState } from 'src/types/vehicle-class';
// utils
import axios, { API_ENDPOINTS } from 'src/utils/axios';


const initialState: IVehicleClassState = {
    vehicleClasses:[],
    vehicleClass:null,
    vehicleClassesStatus:{
        loading:false,
        empty:false,
        error:null
    },
    vehicleClassStatus:{
        loading:false,
        error:null
    },
    vehicleClassDelete: {
        loading:false,
        empty:false,
        error:null
    }
}


const slice = createSlice({
    name: 'vehicleClass',
    initialState,
    reducers:{
        getVehicleClassesStart(state) {
            state.vehicleClassesStatus.loading=true;
            state.vehicleClassesStatus.empty=false;
            state.vehicleClassesStatus.error = null;
        },
        getVehicleClassesFailure(state, action) {
            state.vehicleClassesStatus.loading=true;
            state.vehicleClassesStatus.empty=false;
            state.vehicleClassesStatus.error = action.payload;
        },
        getVehicleClassesSuccess(state, action) {
            const vehicleClasses = action.payload;
            state.vehicleClasses = vehicleClasses;
            state.vehicleClassesStatus.loading = false;
            state.vehicleClassesStatus.empty = !vehicleClasses.length;
            state.vehicleClassesStatus.error = null;
        }
        ,
        deleteVehicleClassStart(state) {
            state.vehicleClassesStatus.loading=true;
            state.vehicleClassesStatus.empty=false;
            state.vehicleClassesStatus.error = null;
        },
        deleteVehicleClassFailure(state, action) {
            state.vehicleClassesStatus.loading=true;
            state.vehicleClassesStatus.empty=false;
            state.vehicleClassesStatus.error = action.payload;
        },
        deleteVehicleClassSuccess(state, action) {
            const vehicleClassDelete = action.payload;
            state.vehicleClassDelete = vehicleClassDelete;
            state.vehicleClassesStatus.loading = false;
            state.vehicleClassesStatus.empty = action.payload;
            state.vehicleClassesStatus.error = null;
        }
    }
});

export default slice.reducer;

// Actions
export const {
    getVehicleClassesStart,
    getVehicleClassesFailure,
    getVehicleClassesSuccess,
    deleteVehicleClassStart,
    deleteVehicleClassFailure,
    deleteVehicleClassSuccess
}= slice.actions;
 
export function getVehicleClasses(data:any) {
    return async (dispatch: Dispatch) => {
        dispatch(slice.actions.getVehicleClassesStart());
        try {
            await axios.get(API_ENDPOINTS.PRODUCTSETTTINGS.VEHICLECLASS.LIST).then((res:any)=> {
                if(res.data.statusCode === 200)
                dispatch(slice.actions.getVehicleClassesSuccess(res.data.data));
            else 
                 dispatch(slice.actions.getVehicleClassesFailure([]));
            });
        }catch(error){
            dispatch(slice.actions.getVehicleClassesFailure(error));
        }
    }
}

export function deleteVehicleClass(id: any) {
return  axios.delete(`${API_ENDPOINTS.PRODUCTSETTTINGS.VEHICLECLASS.DELETE}/${id}`);
}

export function addUpdateVehicleClass(data: any) {
return axios.post(API_ENDPOINTS.PRODUCTSETTTINGS.VEHICLECLASS.ADDUPDATEVEHICLECLASS,data);
}