export const INVOICE_COLUMNS = ["invoiceNumber","customerName","bookingNumber","modelDescription","invoiceDate"];

export const TABLE_HEAD_INVOICE = [
    { id: 'invoiceNumber', label: "INVOICE.VIEW.GRID.INVOICENUMBER" },
    { id: 'customerName', label: "INVOICE.VIEW.GRID.CUSTOMERNAME" },
    { id: 'bookingNumber', label: "INVOICE.VIEW.GRID.BOOKINGNUMBER" },
    { id: 'modelDescription', label: "INVOICE.VIEW.GRID.MODELDESCRIPTION" },
    { id: 'invoiceDate', label: "INVOICE.VIEW.GRID.INVOICEDATE" },
    { id: 'id', width: 88 },
  ];

  export const TABLE_HEAD_FORM_INVOICE = [
    { id: 'bookingNumber', label: "INVOICE.FORM.GRID.BOOKINGNUMBER" },
    { id: 'bookingDate', label: "INVOICE.FORM.GRID.BOOKINGDATE" },
    { id: 'customerName', label: "INVOICE.FORM.GRID.CUSTOMERNAME" },
    { id: 'mobileNumber', label: "INVOICE.FORM.GRID.MOBILENUMBER" },
    { id: 'deliveryDate', label: "INVOICE.FORM.GRID.DELIVERYDATE" },
    { id: 'id', width: 88 },
  ];

export const defaultFilters = {
    name: '',
    status: [],
  };
    