export const VARIENTTYPE_COLUMNS = ["varientType","status"];

export const TABLE_HEAD_VARIENTTYPE = [
    { id: 'varientType', label: "VARIENTTYPE.VIEW.GRID.VARIENTTYPE" },
    { id: 'status', label: "VARIENTTYPE.VIEW.GRID.STATUS" },
    { id: 'id', width: 88 },
  ];

  
export const defaultFilters = {
    name: '',
    status: [],
  };
  