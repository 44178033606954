import { Helmet } from "react-helmet-async";
import { useLocales } from "src/locales";
import BookingListView from "src/sections/booking/booking-list-view";

export default function BookingListPage() {
  const {t}=useLocales();
    return (
      <>
        <Helmet>
          <title> { t("BOOKING.PAGE.TITLE")}</title>
        </Helmet>
        <BookingListView />
      </>
    );
  }