import { Helmet } from "react-helmet-async";
import { useLocales } from "src/locales";
import VisitListView from "src/sections/masters/general-settings/visit/visit-list-view";

export default function VisitListPage() {
  const {t}=useLocales();
    return (
      <>
        <Helmet>
          <title> { t("VISIT.PAGE.TITLE")}</title>
        </Helmet>
  
        <VisitListView/>
      </>
    );
  }