import { createSlice, Dispatch } from '@reduxjs/toolkit';
import { ISegmentDetail, ISegmentDetailState } from 'src/types/segment.details.type';
// utils
import axios, { API_ENDPOINTS } from 'src/utils/axios';


const initialState: ISegmentDetailState = {
    segmentDetails: [],
    segmentDetail: null,
    segmentDetailsStatus: {
        loading: false,
        empty: false,
        error: null
    },
    segmentDetailStatus: {
        loading: false,
        error: null
    },
    segmentDetailDelete: {
        loading: false,
        empty: false,
        error: null
    }
}

const slice = createSlice({
    name: 'segmentDetail',
    initialState,
    reducers: {
        getSegmentDetailsStart(state) {
            state.segmentDetailsStatus.loading = true;
            state.segmentDetailsStatus.empty = false;
            state.segmentDetailsStatus.error = null;
        },
        getSegmentDetailsFailure(state, action) {
            state.segmentDetailsStatus.loading = true;
            state.segmentDetailsStatus.empty = false;
            state.segmentDetailsStatus.error = action.payload;
        },
        getSegmentDetailsSuccess(state, action) {
            const segmentDetails = action.payload;
            state.segmentDetails = segmentDetails;
            state.segmentDetailsStatus.loading = false;
            state.segmentDetailsStatus.empty = !segmentDetails.length;
            state.segmentDetailsStatus.error = null;
        }
        ,
        deleteSegmentDetailStart(state) {
            state.segmentDetailDelete.loading = true;
            state.segmentDetailDelete.empty = false;
            state.segmentDetailDelete.error = null;
        },
        deleteSegmentDetailFailure(state, action) {
            state.segmentDetailDelete.loading = true;
            state.segmentDetailDelete.empty = false;
            state.segmentDetailDelete.error = action.payload;
        },
        deleteSegmentDetailSuccess(state, action) {
            const segmentDetailDelete = action.payload;
            state.segmentDetailDelete = segmentDetailDelete;
            state.segmentDetailDelete.loading = false;
            state.segmentDetailDelete.empty = action.payload;
            state.segmentDetailDelete.error = null;
        }
    }
});

export default slice.reducer;

// Actions
export const {
    getSegmentDetailsStart,
    getSegmentDetailsFailure,
    getSegmentDetailsSuccess,
    deleteSegmentDetailStart,
    deleteSegmentDetailFailure,
    deleteSegmentDetailSuccess
} = slice.actions;

export function getSegmentDetails(data: any) {
    return async (dispatch: Dispatch) => {
        dispatch(slice.actions.getSegmentDetailsStart());
        try {
            await axios.get(API_ENDPOINTS.PRODUCTSETTTINGS.SEGMENTDETAILS.LIST).then((res: any) => {
                if (res.data.statusCode === 200)
                    dispatch(slice.actions.getSegmentDetailsSuccess(res.data.data));
                else
                    dispatch(slice.actions.getSegmentDetailsFailure([]));
            });
        } catch (error) {
            dispatch(slice.actions.getSegmentDetailsFailure(error));
        }
    }
}

export function deleteSegmentDetails(id: any) {
    return axios.delete(`${API_ENDPOINTS.PRODUCTSETTTINGS.SEGMENTDETAILS.DELETE}/${id}`);
}

export function addUpdateSegmentDetails(data: any) {
    return axios.post(API_ENDPOINTS.PRODUCTSETTTINGS.SEGMENTDETAILS.ADDUPDATESEGMENTDETAILS, data);
}