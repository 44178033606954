import { Helmet } from "react-helmet-async";
import { useLocales } from "src/locales";
import VehicleStatusListView from "src/sections/masters/vehicle-settings/vehicle-status/vehicle-status-list-view";


export default function VehicleStatusListPage() {
  const {t}=useLocales();
    return (
      <>
        <Helmet>
          <title> { t("VEHICLESTATUS.PAGE.TITLE")}</title>
        </Helmet>
  
        <VehicleStatusListView />
      </>
    );
  }