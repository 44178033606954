import { Helmet } from 'react-helmet-async';
import { useLocales } from 'src/locales';
import VehicleClassListView from 'src/sections/masters/settings/vehicle-class/vehicle-class';
// ----------------------------------------------------------------------

export default function VehicleClassListPage() {
  const {t} = useLocales();
  return (
    <>
      <Helmet>
        <title> {t("VEHICLECLASS.PAGE.TITLE")}</title>
      </Helmet>

      <VehicleClassListView />
    </>
  );
}
