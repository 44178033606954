export const PINCODE_COLUMNS = ["pincode","locality","cityName","districtName","state","countryName","status"];

export const TABLE_HEAD_PINCODE = [
    { id: 'pincode', label: "PINCODE.VIEW.GRID.PINCODE" },
    { id: 'locality', label: "PINCODE.VIEW.GRID.LOCALITY" },
    { id: 'cityName', label: "PINCODE.VIEW.GRID.CITY" },
    { id: 'districtName', label: "PINCODE.VIEW.GRID.DISTRICT" },
    { id: 'state', label: "PINCODE.VIEW.GRID.STATE" },
    { id: 'countryName', label: "PINCODE.VIEW.GRID.COUNTRY" },
    { id: 'status', label: "PINCODE.VIEW.GRID.STATUS" },
    { id: 'id', width: 88 },
  ];

export const defaultFilters = {
    name: '',
    status: [],
  };
  