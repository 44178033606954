import * as Yup from 'yup';
import { useSnackbar } from "src/components/snackbar";
import { CustomFile } from "src/components/upload";
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import FormProvider, { RHFAutocomplete, RHFSwitch, RHFTextField } from 'src/components/hook-form';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import LoadingButton from '@mui/lab/LoadingButton';
import { Dialog, DialogTitle, Stack } from '@mui/material';
import { ICity} from 'src/types/city';
import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import { useLocales } from 'src/locales';
import { GLOBALCLASSINPUT } from 'src/utils/contants/config-common';
import { getAllCountries } from 'src/redux/slices/geography-settings/country';
import useCity from '../../hooks/geography-settings/use-city';

interface FormValuesProps extends Omit<ICity, 'avatarUrl'> {
    avatarUrl: CustomFile | string | null;
}

type Props = {
    title: string;
    open: boolean;
    onClose: VoidFunction;
    submitButtonText?: string;
    currentModel: ICity;
};

export default function CityForm({ currentModel, open, onClose, title, submitButtonText }: Props) {
    const { enqueueSnackbar } = useSnackbar();
    const { t } = useLocales();
    const [countryLookUp, setCountryLookUp] = useState([] as any);
    const NewModelSchema = Yup.object().shape({
        cityName: Yup.string().required(t('CITIES.FORM.REQUIRED.CITYCODE') as any),
        cityCode: Yup.string().required(t('CITIES.FORM.REQUIRED.CITYNAME') as any),
        countryId: Yup.string().required(t('CITIES.FORM.REQUIRED.COUNTRY') as any),
        stateId: Yup.string().required(t('CITIES.FORM.REQUIRED.STATE') as any),
        districtId: Yup.string().required(t('CITIES.FORM.REQUIRED.DISTRICT') as any),
        image: Yup.string(),
        description: Yup.string(),
        status: Yup.bool(),
    });

    const defaultValues = useMemo(() => ({
        cityName: currentModel?.cityName || "",
        cityCode: currentModel?.cityCode || "",
        countryId: currentModel?.countryId || "",
        stateId: currentModel?.stateId || "",
        districtId: currentModel?.districtId || "",
        image: currentModel?.image || "",
        description: currentModel?.description || "",
        status: !(currentModel?.status),
        cityId: currentModel?.cityId || 0
    }), [currentModel]);

    const methods = useForm<FormValuesProps>({
        resolver: yupResolver(NewModelSchema),
        defaultValues
    });

    useEffect(() => {
        getAllCountries()?.then((x: any) => {
            if (x) {
                setCountryLookUp([...x])
            }

        });
    }, []);


    const { reset, handleSubmit, setValue, formState: { isSubmitting } } = methods;

    const onSubmit = useCallback(
        async (data: FormValuesProps) => {
            try {
                await new Promise((resolve) => setTimeout(resolve, 500));
                reset();
                onClose();
                enqueueSnackbar(t('CITIES.FORM.UPDATE_SUCCESS') as any);
                console.info('DATA', data);
            } catch (error) {
                console.error(error);
            }
        }, [enqueueSnackbar, onClose, reset, t]);

    return (
        <Dialog scroll="body"
            fullWidth
            maxWidth={false}
            open={open}
            onClose={() => null}
            PaperProps={{
                sx: { maxWidth: 720 },
            }}
        >
            <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
                <DialogTitle>{title}</DialogTitle>
                <DialogContent>
                    <Alert variant="outlined" severity="error" sx={{ mb: 3 }}>
                        {t('CITIES.FORM.ALREADY_EXISTS_ERROR')}
                    </Alert>
                    <Stack spacing={3} sx={{ p: 1 }}>
                        <RHFTextField size={GLOBALCLASSINPUT} name="cityName" label={t("CITIES.FORM.CITYCODE")} />
                        <RHFTextField size={GLOBALCLASSINPUT} name="cityCode" label={t("CITIES.FORM.CITYNAME")} />
                        <RHFAutocomplete size={GLOBALCLASSINPUT} name="countryId" label={t("CITIES.FORM.COUNTRY") as any}
                            options={countryLookUp}
                            inputValue={currentModel?.countryId}
                            onChange={(event: any) => {
                                const data = (countryLookUp.filter((x: any) => x.label === event.target.innerText)[0])
                                if (event.target.innerText && data) {
                                    setValue('countryId', data?.value);
                                } else {
                                    setValue("countryId", "")
                                }
                            }}
                            getOptionLabel={(option: any) => {
                                const val = typeof option === 'string' ? option : option.value
                                const data = (countryLookUp.filter((x: any) => x.value === val)[0]);
                                return data ? data.label : ""
                            }}
                            isOptionEqualToValue={(option, value) => {
                                const val = typeof value === 'string' ? value : option.value
                                return option.value === val
                            }
                            }
                            renderOption={(props, option: any) => (
                                <li {...props} key={option.value}>
                                    {option.label}
                                </li>
                            )} />
                            <RHFAutocomplete size={GLOBALCLASSINPUT} name="stateId" label={t("CITIES.FORM.STATE") as any}
                            options={countryLookUp}
                            inputValue={currentModel?.stateId}
                            onChange={(event: any) => {
                                const data = (countryLookUp.filter((x: any) => x.label === event.target.innerText)[0])
                                if (event.target.innerText && data) {
                                    setValue('stateId', data?.value);
                                } else {
                                    setValue("stateId", "")
                                }
                            }}
                            getOptionLabel={(option: any) => {
                                const val = typeof option === 'string' ? option : option.value
                                const data = (countryLookUp.filter((x: any) => x.value === val)[0]);
                                return data ? data.label : ""
                            }}
                            isOptionEqualToValue={(option, value) => {
                                const val = typeof value === 'string' ? value : option.value
                                return option.value === val
                            }
                            }
                            renderOption={(props, option: any) => (
                                <li {...props} key={option.value}>
                                    {option.label}
                                </li>
                            )} />
                             <RHFAutocomplete size={GLOBALCLASSINPUT} name="districtId" label={t("CITIES.FORM.DISTRICT") as any}
                            options={countryLookUp}
                            inputValue={currentModel?.stateId}
                            onChange={(event: any) => {
                                const data = (countryLookUp.filter((x: any) => x.label === event.target.innerText)[0])
                                if (event.target.innerText && data) {
                                    setValue('districtId', data?.value);
                                } else {
                                    setValue("districtId", "")
                                }
                            }}
                            getOptionLabel={(option: any) => {
                                const val = typeof option === 'string' ? option : option.value
                                const data = (countryLookUp.filter((x: any) => x.value === val)[0]);
                                return data ? data.label : ""
                            }}
                            isOptionEqualToValue={(option, value) => {
                                const val = typeof value === 'string' ? value : option.value
                                return option.value === val
                            }
                            }
                            renderOption={(props, option: any) => (
                                <li {...props} key={option.value}>
                                    {option.label}
                                </li>
                            )} />
                        <RHFTextField size={GLOBALCLASSINPUT} name="description" label={t("CITIES.FORM.DESCRIPTION")} />
                        {currentModel.cityId !== 0 && <RHFSwitch name="status" label={t("CITIES.FORM.STATUS")} />}
                    </Stack>
                </DialogContent>
                <DialogActions>
                    <Button variant="outlined" onClick={onClose}>
                        {t("CANCEL")}
                    </Button>
                    <LoadingButton type="submit" variant="contained" loading={isSubmitting}>
                        {submitButtonText}
                    </LoadingButton>
                </DialogActions>
            </FormProvider>
        </Dialog>
    )

}

function dispatch(arg0: (dispatch: import("redux").Dispatch<import("redux").AnyAction>) => Promise<void>) {
    throw new Error('Function not implemented.');
}
