import * as Yup from 'yup';
import { useCallback, useEffect, useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import Box from '@mui/material/Box';
import {
    RHFTextField,
    RHFSelect,
} from 'src/components/hook-form';
import { Divider, Grid, MenuItem } from '@mui/material';
import { GLOBALCLASSINPUT } from 'src/utils/contants/config-common';
import { getLookUp } from 'src/redux/slices/lookup';
import { useLocales } from 'src/locales';
import { DatePicker } from '@mui/x-date-pickers';
import { IFinanceDetail } from 'src/types/common';
import { Controller, useForm } from 'react-hook-form';

// ----------------------------------------------------------------------


export interface FormValuesProps extends IFinanceDetail {

}

type Props = {
    onChange: (address: IFinanceDetail) => void;
};

export default function FinanceDetailForm({ onChange }: Props) {
    const [financeArranceByLookUp, setFinanceArranceByLookUp] = useState([] as any);
    const [bankLookUp, setBankLookUp] = useState([] as any);
    const [doReceivedLookUp, setDoReceivedLookUp] = useState([] as any);
    const { t } = useLocales();
    const NewInsuranceDetailSchema = Yup.object().shape({
        financeArranceById: Yup.string().required('Insurance Company is required'),
        bankId: Yup.string().required('Cover Note is required'),
        branchName: Yup.string().required('Insurance Amount is required'),
        fileNumber: Yup.string().required('Cover Note Date is required'),
        doReceivedId: Yup.string().required('Registration Number is required'),
        doNumber: Yup.string().required('Registration Number is required'),
        doDate: Yup.string().required('Registration Number is required'),
    });

    const defaultValues = {
        financeArranceById: '',
        bankId: '',
        branchName: '',
        fileNumber: '',
        doReceivedId: '',
        doNumber: '',
        doDate: '',
    };

    useEffect(() => {
        getLookUp("FINANCEARRANGEBY").then((x: any) => {
            if (x && x.data && x.data.length > 0) {
                console.log(x.data)
                setFinanceArranceByLookUp(x.data);
            }
        });
        getLookUp("BANKNAME").then((x: any) => {
            if (x && x.data && x.data.length > 0) {
                console.log(x.data)
                setBankLookUp(x.data);
            }
        });
        getLookUp("DELIVERYORDERRECEIVED").then((x: any) => {
            if (x && x.data && x.data.length > 0) {
                console.log(x.data)
                setDoReceivedLookUp(x.data);
            }
        });
    }, [])

    const methods = useForm<FormValuesProps>({
        resolver: yupResolver(NewInsuranceDetailSchema),
        defaultValues,
    });

    const {
        handleSubmit, control,
        // formState: { isSubmitting },
    } = methods;

    const onInputChange = useCallback(
        async (data: FormValuesProps) => {
            try {
                onChange({
                    financeArranceById: data.financeArranceById,
                    bankId: data.bankId,
                    branchName: data.branchName,
                    fileNumber: data.fileNumber,
                    doReceivedId: data.doReceivedId,
                    doNumber: data.doNumber,
                    doDate: data.doDate,
                });
            } catch (error) {
                console.error(error);
            }
        },
        [onChange]
    );

    return (
        <>
            <RHFSelect name="financeArranceById" size={GLOBALCLASSINPUT} label={t('ENQUIRYMANAGEMENT.FORM.FINANCEARRANGEBY') as any} >
            <MenuItem value="">None</MenuItem>
                <Divider sx={{ borderStyle: 'dashed' }} />
                {financeArranceByLookUp && financeArranceByLookUp.map((item: any) => (
                    <MenuItem key={item.value + item.label} value={item.value}> {item.label} </MenuItem>
                ))}
            </RHFSelect>
            <RHFSelect name="bankId" size={GLOBALCLASSINPUT} label={t('ENQUIRYMANAGEMENT.FORM.BANKNAME') as any} >
            <MenuItem value="">None</MenuItem>
                <Divider sx={{ borderStyle: 'dashed' }} />
                {bankLookUp && bankLookUp.map((item: any) => (
                    <MenuItem key={item.value + item.label} value={item.value}> {item.label} </MenuItem>
                ))}
            </RHFSelect>
            <RHFTextField size={GLOBALCLASSINPUT} name="branchName" label={t('ENQUIRYMANAGEMENT.FORM.BRANCHNAME') as any} />
            <RHFTextField size={GLOBALCLASSINPUT} name="fileNumber" label={t('ENQUIRYMANAGEMENT.FORM.FILENUMBER') as any} />
            <RHFSelect name="doReceivedId" size={GLOBALCLASSINPUT} label={t('ENQUIRYMANAGEMENT.FORM.DORECEIVED') as any} >
            <MenuItem value="">None</MenuItem>
                <Divider sx={{ borderStyle: 'dashed' }} />
                {doReceivedLookUp && doReceivedLookUp.map((item: any) => (
                    <MenuItem key={item.value + item.label} value={item.value}> {item.label} </MenuItem>
                ))}
            </RHFSelect>
            <RHFTextField size={GLOBALCLASSINPUT} name="doNumber" label={t('ENQUIRYMANAGEMENT.FORM.DONUMBER') as any} />
            <Controller
                name="doDate"
                control={control}
                render={({ field, fieldState: { error } }) => (
                    <DatePicker
                        label={t('ENQUIRYMANAGEMENT.FORM.DODATE') as any}
                        value={field.value}
                        onChange={(newValue) => {
                            field.onChange(newValue);
                        }}
                        format="dd-MMM-yyyy"
                        slotProps={{
                            textField: {
                                size: 'small',
                                error: !!error,
                                helperText: error?.message,
                            },
                        }}
                    />
                )}
            />
        </>
    );
}
