import { createSlice, Dispatch } from '@reduxjs/toolkit';
import { IRelationShipState } from 'src/types/general-settings/relation.ship';
// utils
import axios, { API_ENDPOINTS } from 'src/utils/axios';

const initialState: IRelationShipState = {
    relationShip:[],
    relationShipStatus:{
        loading:false,
        empty:false,
        error:null
    },
    relationShipDelete: {
        loading:false,
        empty:false,
        error:null
    }
}

const slice = createSlice({
    name: 'relationShip',
    initialState,
    reducers:{
        getRelationShipStart(state) {
            state.relationShipStatus.loading=true;
            state.relationShipStatus.empty=false;
            state.relationShipStatus.error = null;
        },
        getRelationShipFailure(state, action) {
            state.relationShipStatus.loading=true;
            state.relationShipStatus.empty=false;
            state.relationShipStatus.error = action.payload;
        },
        getRelationShipSuccess(state, action) {
            const relationShip = action.payload;
            state.relationShip = relationShip;
            state.relationShipStatus.loading = false;
            state.relationShipStatus.empty = !relationShip.length;
            state.relationShipStatus.error = null;
        },
        deleteRelationShipStart(state) {
            state.relationShipDelete.loading=true;
            state.relationShipDelete.empty=false;
            state.relationShipDelete.error = null;
        },
        deleteRelationShipFailure(state, action) {
            state.relationShipDelete.loading=true;
            state.relationShipDelete.empty=false;
            state.relationShipDelete.error = action.payload;
        },
        deleteRelationShipSuccess(state, action) {
            const relationShipDelete = action.payload;
            state.relationShipDelete = relationShipDelete;
            state.relationShipDelete.loading = false;
            state.relationShipDelete.empty = action.payload;
            state.relationShipDelete.error = null;
        }
    }
});

export default slice.reducer;

// Actions
export const {
    getRelationShipStart,
    getRelationShipFailure,
    getRelationShipSuccess,
    deleteRelationShipStart,
    deleteRelationShipFailure,
    deleteRelationShipSuccess
} = slice.actions;
 

export function getRelationShips(data:any) {
    return async (dispatch: Dispatch) => {
        dispatch(slice.actions.getRelationShipStart());
        try {
            await axios.get(API_ENDPOINTS.GENERALSETTINGS.RELATIONSHIP.LIST).then((res:any)=> {
                if(res.data.statusCode === 200)
                dispatch(slice.actions.getRelationShipSuccess(res.data.data));
            else 
                 dispatch(slice.actions.getRelationShipFailure([]));
            });
        }catch(error){
            dispatch(slice.actions.getRelationShipFailure(error));
        }
    }
}

export function deleteRelationShip(id: any) {
return  axios.delete(`${API_ENDPOINTS.GENERALSETTINGS.RELATIONSHIP.DELETE}/${id}`);
}

export function addUpdateRelationShip(data: any) {
return axios.post(API_ENDPOINTS.GENERALSETTINGS.RELATIONSHIP.ADDUPDATERELATIONSHIP,data);
}