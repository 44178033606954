import { useCallback } from "react";
import { deleteUsedVehicleModel } from "src/redux/slices/used.vehicle.model";
import { useDispatch, useSelector } from "src/redux/store";

export default function useUsedVehicleModel() {
    const dispatch = useDispatch();
    const { usedVehicleModel,usedVehicleModelDelete,usedVehicleModelsStatus,usedVehicleModels} = useSelector(
        (state) => state.usedVehicleModel
    );

    const onDeleteUsedVehicleModel = useCallback(
        (id: string) => {
            dispatch(deleteUsedVehicleModel(id));
        },
        [dispatch]
    );

    return {
        usedVehicleModel,usedVehicleModelDelete,usedVehicleModelsStatus,usedVehicleModels,
        onDeleteUsedVehicleModel
    }
}