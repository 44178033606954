import { IErrorType } from "../error";

export type ISourceOfEnquiryTableFilters = {
    name: string;
    status: string[];
  };
  
export type ISourceOfEnquiryTableFilterValue = string | string[];

export type ISourceOfEnquiry = {
    id:number;
    name:string;
    description:string;
    displayOrder:number;
    status:boolean;
}
  
export type ISourceOfEnquiryState = {
  sourceOfEnquiry: ISourceOfEnquiry[];
  sourceOfEnquiryStatus: {
    loading: boolean;
    empty: boolean;
    error: IErrorType;
  };
  sourceOfEnquiryDelete: {
    loading:boolean;
    empty:any;
    error:IErrorType;
  }
};

export const defaultFilters = {
  name: '',
  status: [],
};