import { Helmet } from "react-helmet-async";
import { useLocales } from "src/locales";
import BodyTypeListView from "src/sections/masters/settings/body-type/body-type-list-view";


export default function BodyTypeListPage() {
  const {t}=useLocales();
    return (
      <>
        <Helmet>
          <title> { t("BODYTYPE.PAGE.TITLE")}</title>
        </Helmet>
  
        <BodyTypeListView />
      </>
    );
  }