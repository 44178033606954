import { IErrorType } from "./error";

export type ICountryTableFilters = {
    name: string;
    status: string[];
  };
  
export type ICountryTableFilterValue = string | string[];

export type ICountry = {
    countryId:number;
    countryCode:string;
    countryName:string;
    currency:string;
    image:string;
    description:string;
    status:boolean;
}
  
export type ICountryState = {
  countries: ICountry[];
  country: ICountry | null;
  countriesStatus: {
    loading: boolean;
    empty: boolean;
    error: IErrorType;
  };
  countryStatus: {
    loading: boolean;
    error: IErrorType;
  };
  countryDelete: {
    loading:boolean;
    empty:any;
    error:IErrorType;
  }
};

export const defaultFilters = {
  name: '',
  status: [],
};