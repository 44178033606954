import { createSlice, Dispatch } from '@reduxjs/toolkit';
import { IBuyerTypeState } from 'src/types/lead-settings/buyer.type';
// utils
import axios, { API_ENDPOINTS } from 'src/utils/axios';

const initialState: IBuyerTypeState = {
    buyerType:[],
    buyerTypeStatus:{
        loading:false,
        empty:false,
        error:null
    },
    buyerTypeDelete: {
        loading:false,
        empty:false,
        error:null
    }
}

const slice = createSlice({
    name: 'buyerType',
    initialState,
    reducers:{
        getBuyerTypeStart(state) {
            state.buyerTypeStatus.loading=true;
            state.buyerTypeStatus.empty=false;
            state.buyerTypeStatus.error = null;
        },
        getBuyerTypeFailure(state, action) {
            state.buyerTypeStatus.loading=true;
            state.buyerTypeStatus.empty=false;
            state.buyerTypeStatus.error = action.payload;
        },
        getBuyerTypeSuccess(state, action) {
            const buyerType = action.payload;
            state.buyerType = buyerType;
            state.buyerTypeStatus.loading = false;
            state.buyerTypeStatus.empty = !buyerType.length;
            state.buyerTypeStatus.error = null;
        },
        deleteBuyerTypeStart(state) {
            state.buyerTypeDelete.loading=true;
            state.buyerTypeDelete.empty=false;
            state.buyerTypeDelete.error = null;
        },
        deleteBuyerTypeFailure(state, action) {
            state.buyerTypeDelete.loading=true;
            state.buyerTypeDelete.empty=false;
            state.buyerTypeDelete.error = action.payload;
        },
        deleteBuyerTypeSuccess(state, action) {
            const buyerTypeDelete = action.payload;
            state.buyerTypeDelete = buyerTypeDelete;
            state.buyerTypeDelete.loading = false;
            state.buyerTypeDelete.empty = action.payload;
            state.buyerTypeDelete.error = null;
        }
    }
});

export default slice.reducer;

// Actions
export const {
    getBuyerTypeStart,
    getBuyerTypeFailure,
    getBuyerTypeSuccess,
    deleteBuyerTypeStart,
    deleteBuyerTypeFailure,
    deleteBuyerTypeSuccess
} = slice.actions;

export function getBuyerType(data:any) {
        return async (dispatch: Dispatch) => {
            dispatch(slice.actions.getBuyerTypeStart());
            try {
                await axios.get(API_ENDPOINTS.ENQUIRYSETTINGS.BUYERTYPE.LIST).then((res:any)=> {
                    if(res.data.statusCode === 200)
                    dispatch(slice.actions.getBuyerTypeSuccess(res.data.data));
                else 
                     dispatch(slice.actions.getBuyerTypeSuccess([]));
                });
            }catch(error){
                dispatch(slice.actions.getBuyerTypeFailure(error));
            }
        }
}

export function deleteBuyerType(id: any) {
    return  axios.delete(`${API_ENDPOINTS.ENQUIRYSETTINGS.BUYERTYPE.DELETE}/${id}`);
}

export function addUpdateBuyerType(data: any) {
    return axios.post(API_ENDPOINTS.ENQUIRYSETTINGS.BUYERTYPE.ADDUPDATEBUYERTYPE,data);
}