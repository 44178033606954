import {  useSelector } from "src/redux/store";

export default function useTransmission() {
    const { transmissionsStatus,transmissions} = useSelector(
        (state) => state.transmission
    );

    return {
       transmissionsStatus,transmissions,
    }
}