import { Helmet } from "react-helmet-async";
import { useLocales } from "src/locales";
import AllotmentListView from "src/sections/sales-journey/allotment/allotment-list-view";

export default function AllotmentListPage() {
  const {t}=useLocales();
    return (
      <>
        <Helmet>
          <title> { t("ENQUIRYMANAGEMENT.PAGE.TITLE")}</title>
        </Helmet>
        <AllotmentListView />
      </>
    );
  }