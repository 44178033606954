import * as Yup from 'yup';
import { useCallback, useEffect, useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { RHFSwitch } from 'src/components/hook-form';
import { Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material';
import { SCHEMEANDOFFERS_GRID_COLUMNS } from 'src/utils/contants/config-common';
import { getLookUp } from 'src/redux/slices/lookup';
import { useLocales } from 'src/locales';
import { useForm } from 'react-hook-form';
import { Props, FormValuesProps } from './scheme-offer';


export default function SchemeOffersForm({ onChange, gridTemplateColumns = 3 }: Props) {
    const [schemeTypeLookUp, setSchemeTypeLookUp] = useState([] as any);
    const [schemeCategoryLookUp, setSchemeCategoryLookUp] = useState([] as any);
    const { t } = useLocales();
    const NewSchemesOffersSchema = Yup.object().shape({
        schemeTypeId: Yup.string(),
        schemeCategoryId: Yup.string(),
        schemeAmount: Yup.string(),
        validFrom: Yup.string(),
        validTo: Yup.string(),
        dealerDiscount: Yup.string(),
        consumerSchemeId: Yup.string(),
        oemDiscount: Yup.string(),
        oemFOCScheme: Yup.string(),
    });

    const defaultValues = {
        schemeTypeId: '',
        schemeCategoryId: '',
        schemeAmount: '',
        validFrom: '',
        validTo: '',
        dealerDiscount: '',
        consumerSchemeId: '',
        oemDiscount: '',
        oemFOCScheme: '',
    };

    useEffect(() => {
        getLookUp("SCHEMETYPE").then((x: any) => {
            if (x && x.data && x.data.length > 0) {
                console.log(x.data);
                setSchemeTypeLookUp(x.data);
            }
        });
        getLookUp("SCHEMECATEGORY").then((x: any) => {
            if (x && x.data && x.data.length > 0) {
                console.log(x.data);
                setSchemeCategoryLookUp(x.data);
            }
        });
    }, []);

    const methods = useForm<FormValuesProps>({
        resolver: yupResolver(NewSchemesOffersSchema),
        defaultValues,
    });

    const {
        handleSubmit, control, watch,
        // formState: { isSubmitting },
    } = methods;
    const values = watch();
    const onInputChange = useCallback(
        async (data: FormValuesProps) => {
            try {
                onChange({
                    schemeTypeId: data.schemeTypeId,
                    schemeCategoryId: data.schemeCategoryId,
                    schemeAmount: data.schemeAmount,
                    validFrom: data.validFrom,
                    validTo: data.validTo,
                    dealerDiscount: data.dealerDiscount,
                    consumerSchemeId: data.consumerSchemeId,
                    oemDiscount: data.oemDiscount,
                    oemFOCScheme: data.oemFOCScheme,
                });
            } catch (error) {
                console.error(error);
            }
        },
        [onChange]
    );

    return (
        <>
            {/* <Box rowGap={3} columnGap={2} display="grid" gridTemplateColumns={{ xs: 'repeat(1, 1fr)', sm: `repeat(${gridTemplateColumns}, 1fr)` }}>
                <RHFSelect name="schemeType" size={GLOBALCLASSINPUT} label={t('ENQUIRYMANAGEMENT.FORM.SCHEMETYPE') as any} >
                    <MenuItem value="">None</MenuItem>
                    <Divider sx={{ borderStyle: 'dashed' }} />
                    {schemeTypeLookUp && schemeTypeLookUp.map((item: any) => (
                        <MenuItem key={item.value + item.label} value={item.value}> {item.label} </MenuItem>
                    ))}
                </RHFSelect>
                <RHFSelect name="schemeCategory" size={GLOBALCLASSINPUT} label={t('ENQUIRYMANAGEMENT.FORM.SCHEMECATEGORY') as any} >
                    <MenuItem value="">None</MenuItem>
                    <Divider sx={{ borderStyle: 'dashed' }} />
                    {schemeCategoryLookUp && schemeCategoryLookUp.map((item: any) => (
                        <MenuItem key={item.value + item.label} value={item.value}> {item.label} </MenuItem>
                    ))}
                </RHFSelect>
                <RHFTextField size={GLOBALCLASSINPUT} name="schemeAmount" label={t('ENQUIRYMANAGEMENT.FORM.SCHEMEAMOUNT') as any} />
                <Controller
                    name="validFrom"
                    control={control}
                    render={({ field, fieldState: { error } }) => (
                        <DatePicker
                            label={t('ENQUIRYMANAGEMENT.FORM.VALIDFROM') as any}
                            value={field.value}
                            onChange={(newValue) => {
                                field.onChange(newValue);
                            }}
                            format="dd-MMM-yyyy"
                            slotProps={{
                                textField: {
                                    size: 'small',
                                    error: !!error,
                                    helperText: error?.message,
                                },
                            }}
                        />
                    )}
                />
                <Controller
                    name="validTo"
                    control={control}
                    render={({ field, fieldState: { error } }) => (
                        <DatePicker
                            label={t('ENQUIRYMANAGEMENT.FORM.VALIDTO') as any}
                            value={field.value}
                            onChange={(newValue) => {
                                field.onChange(newValue);
                            }}
                            format="dd-MMM-yyyy"
                            slotProps={{
                                textField: {
                                    size: 'small',
                                    error: !!error,
                                    helperText: error?.message,
                                },
                            }}
                        />
                    )}
                />
                <Box rowGap={3} columnGap={2} display="grid" gridTemplateColumns={{ xs: 'repeat(1, 1fr)', sm: 'repeat(2, 1fr)' }}>
                    <Button variant="contained" >  Add </Button>
                    <Button >  Clear  </Button>
                </Box>
            </Box>

            <br /> */}
            <Table sx={{ minWidth: "100%" }}>
                <TableHead>
                    <TableRow>
                        {SCHEMEANDOFFERS_GRID_COLUMNS && SCHEMEANDOFFERS_GRID_COLUMNS.map((item: any) => (
                            <TableCell key={item.label} style={{ padding: "4px", fontSize: '10px', textAlign: 'center' }}>
                                {t(item)}
                            </TableCell>
                        ))}
                    </TableRow>
                </TableHead>
                <TableBody>
                    <TableRow>
                        <TableCell align="center" style={{ padding: "4px"}}>
                           Booking Offer
                        </TableCell>
                        <TableCell align="center" style={{ padding: "4px"}}>
                            <Typography variant="caption" sx={{ mb: 0.5 }}>  Booking Scheme </Typography>
                        </TableCell>
                        <TableCell align="center" style={{ padding: "4px"}}>
                            <Typography variant="caption" sx={{ mb: 0.5 }}>  100 </Typography>
                        </TableCell>
                        <TableCell align="center" style={{ padding: "4px"}}>
                            <Typography variant="caption" sx={{ mb: 0.5 }}>  15-DEC-2023 </Typography>
                        </TableCell>
                        <TableCell align="center" style={{ padding: "4px"}}>
                            <Typography variant="caption" sx={{ mb: 0.5 }}>  20-DEC-2023 </Typography>
                        </TableCell>
                        <TableCell align="center" sx={{ px: 1, whiteSpace: 'nowrap' }}>
                           <input type="radio" name="schemeOffer"/>
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell align="center" style={{ padding: "4px"}}>
                             Corporate offer 
                        </TableCell>
                        <TableCell align="center" style={{ padding: "4px"}}>
                            <Typography variant="caption" sx={{ mb: 0.5 }}> Retail Scheme </Typography>
                        </TableCell>
                        <TableCell align="center" style={{ padding: "4px"}}>
                            <Typography variant="caption" sx={{ mb: 0.5 }}>  1002 </Typography>
                        </TableCell>
                        <TableCell align="center" style={{ padding: "4px"}}>
                            <Typography variant="caption" sx={{ mb: 0.5 }}>  15-DEC-2023 </Typography>
                        </TableCell>
                        <TableCell align="center" style={{ padding: "4px"}}>
                            <Typography variant="caption" sx={{ mb: 0.5 }}>  20-DEC-2023 </Typography>
                        </TableCell>
                        <TableCell align="center" sx={{ px: 1, whiteSpace: 'nowrap' }}>
                        <input type="radio" name="schemeOffer"/>
                        </TableCell>
                    </TableRow>
                </TableBody>
            </Table>
        </>
    );
}
