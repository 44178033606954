import { IErrorType } from "./error";

export type IUsedVehicleModelTableFilters = {
    name: string;
    status: string[];
  };
  
export type IUsedVehicleModelTableFilterValue = string | string[];

export type IUsedVehicleModel = {
    usedVehicleModelId:number;
    usedVehicleModelName:string;
    varient:string;
    manufacturer:string;
    year:string;
    color:string;
    kmsDriven:string;
    fuelType:string;
    bodyType:string;
    transmission:string;
    engineSize:string;
    vehicleNumber:string;
    rto:string;
    owners:string;
    registeredIn:string;
    chasisNumber:string;
    engineNumber:string;
    vehicleHistory:any[]
    vehicleStatus:string;
    description:string;
    images:string[];
    status:boolean;
}
  
export type IUsedVehicleModelState = {
  usedVehicleModels: IUsedVehicleModel[];
  usedVehicleModel: IUsedVehicleModel | null;
  usedVehicleModelsStatus: {
    loading: boolean;
    empty: boolean;
    error: IErrorType;
  };
  usedVehicleModelStatus: {
    loading: boolean;
    error: IErrorType;
  };
  usedVehicleModelDelete: {
    loading:boolean;
    empty:any;
    error:IErrorType;
  }
};

export const defaultFilters = {
  name: '',
  status: [],
};