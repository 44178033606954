import * as Yup from 'yup';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import Box from '@mui/material/Box';
import {
    RHFTextField,
    RHFSelect,
} from 'src/components/hook-form';
import { Divider, Grid, MenuItem } from '@mui/material';
import { GLOBALCLASSINPUT } from 'src/utils/contants/config-common';
import { getLookUp } from 'src/redux/slices/lookup';
import { useLocales } from 'src/locales';
import { DatePicker } from '@mui/x-date-pickers';
import { BookingDetail, IBookingDetail } from 'src/types/common';
import { Controller, useForm } from 'react-hook-form';

// ----------------------------------------------------------------------

export interface FormValuesProps extends IBookingDetail {

}

type Props = {
    onChange: (address: IBookingDetail) => void;
    currentModel?: IBookingDetail;
    gridTemplateColumns:any;
};

export default function BookingDetailForm({ onChange, currentModel = {...BookingDetail} as IBookingDetail ,gridTemplateColumns=3 }: Props) {
    const [modeOfPaymentLookUp, setModeOfPaymentLookUp] = useState([] as any);
    const [salesExecutiveLookUp, setSalesExecutiveLookUp] = useState([] as any);
    const [teamLeaderLookUp, setTeamLeaderLookUp] = useState([] as any);
    const [salesManagerLookUp, setSalesManagerLookUp] = useState([] as any);
    const [buyerTypeLookUp, setBuyerTypeLookUp] = useState([] as any);
    const [enquiryOwnerLookUp, setEnquiryOwnerLookUp] = useState([] as any);
    const [sourceOfEnquiryLookUp, setSourceOfEnquiryLookUp] = useState([] as any);
    const [colorLookUp, setColorLookUp] = useState([] as any);
    const [vehicleModelLookUp, setVehicleModelLookUp] = useState([] as any);
    const [varientTypeLookUp, setVarientTypeLookUp] = useState([] as any);
    const [isBoxRequired, setIsBoxRequired] = useState(currentModel?.isBoxRequired);

    const { t } = useLocales();
    const NewBookingDetailSchema = Yup.object().shape({
        bookingAmount: Yup.string().required('Booking Amount is required'),
        placeOfRegistration: Yup.string().required('Place of Registration is required'),
        specialRequest: Yup.string().required('Special Request is required'),
        modePaymentId: Yup.string().required('Mode of Payment is required'),
        sourceOfEnquiryId: Yup.string().required('Source of Enquiry is required'),
        deliveryDate: Yup.string().required('Delivery Date is required'),
        deliveryAt: Yup.string().required('Delivery At is required'),
        customerExpectedDeliveryDate: Yup.string().required('Customer Expected Delivery Date is required'),
        salesExecutiveId: Yup.string().required('Sales Executive is required'),
        teamLeaderId: Yup.string().required('Team Leader is required'),
        salesManagerId: Yup.string().required('Sales Manger is required'),
        vehicleRegistrationNumber: Yup.string(),
        vehicleModelId: Yup.string(),
        varientId: Yup.string(),
        color: Yup.string(),
    });

    const defaultValues = useMemo(() => ({
        bookingAmount: currentModel?.bookingAmount || "",
        isBookingAmount: currentModel?.isBookingAmount || false,
        placeOfRegistration: currentModel?.placeOfRegistration || "",
        isPlaceOfRegistration: currentModel?.isPlaceOfRegistration || false,
        specialRequest: currentModel?.specialRequest || "",
        isSpecialRequest: currentModel?.isSpecialRequest || false,
        modePaymentId: currentModel?.modePaymentId || "",
        isModePaymentId: currentModel?.isModePaymentId || false,
        sourceOfEnquiryId: currentModel?.sourceOfEnquiryId || "",
        enquiryOwnerId: currentModel?.enquiryOwnerId || "",
        isEnquiryOwnerId: currentModel?.isEnquiryOwnerId || false,
        buyerTypeId: currentModel?.buyerTypeId || "",
        isBuyerTypeId: currentModel?.isBuyerTypeId || false,
        isSourceOfEnquiryId: currentModel?.isSourceOfEnquiryId || false,
        deliveryDate: currentModel?.deliveryDate || "",
        isDeliveryDate: currentModel?.isDeliveryDate || false,
        deliveryAt: currentModel?.deliveryAt || "",
        isDeliveryAt: currentModel?.isDeliveryAt || false,
        customerExpectedDeliveryDate: currentModel?.customerExpectedDeliveryDate || "",
        isCustomerExpectedDeliveryDate: currentModel?.isCustomerExpectedDeliveryDate || false,
        salesExecutiveId: currentModel?.salesExecutiveId || "",
        isSalesExecutiveId: currentModel?.isSalesExecutiveId || false,
        teamLeaderId: currentModel?.teamLeaderId || "",
        isTeamLeaderId: currentModel?.isTeamLeaderId || false,
        salesManagerId: currentModel?.salesManagerId || "",
        isSalesManagerId: currentModel?.isSalesManagerId || false,
        vehicleRegistrationNumber: currentModel?.vehicleRegistrationNumber || "",
        isVehicleRegistrationNumber: currentModel?.isVehicleRegistrationNumber || false,
        vehicleModelId: currentModel?.vehicleModelId || "",
        isVehicleModelId: currentModel?.isVehicleModelId || false,
        varientId: currentModel?.varientId || "",
        isVarientId: currentModel?.isVarientId || false,
        color: currentModel?.color || "",
        isColor: currentModel?.isColor || false,
        isAllEnable: currentModel?.isAllEnable || false,
    }), [currentModel])

    useEffect(() => {
        getLookUp("MODEOFPAYMENT").then((x: any) => {
            if (x && x.data && x.data.length > 0) {
                console.log(x)
                setModeOfPaymentLookUp(x.data);
            }
        });
        getLookUp("COLOR").then((x: any) => {
            if (x && x.data && x.data.length > 0) {
                console.log(x)
                setColorLookUp(x.data);
            }
        });
        getLookUp("VEHICLEMODEL").then((x: any) => {
            if (x && x.data && x.data.length > 0) {
                console.log(x)
                setVehicleModelLookUp(x.data);
            }
        });
        getLookUp("VARIENTTYPE").then((x: any) => {
            if (x && x.data && x.data.length > 0) {
                console.log(x)
                setVarientTypeLookUp(x.data);
            }
        });
        getLookUp("SALESEXECUTIVE").then((x: any) => {
            if (x && x.data && x.data.length > 0) {
                console.log(x)
                setSalesExecutiveLookUp(x.data);
            }
        });
        getLookUp("TEAMLEADER").then((x: any) => {
            if (x && x.data && x.data.length > 0) {
                console.log(x)
                setTeamLeaderLookUp(x.data);
            }
        });
        getLookUp("SALESMANAGER").then((x: any) => {
            if (x && x.data && x.data.length > 0) {
                console.log(x)
                setSalesManagerLookUp(x.data);
            }
        });
        getLookUp("ENQUIRYOWNER").then((x: any) => {
            if (x && x.data && x.data.length > 0) {
                console.log(x.data)
                setEnquiryOwnerLookUp(x.data);
            }
        });
        getLookUp("BUYERTYPE").then((x: any) => {
            if (x && x.data && x.data.length > 0) {
                console.log(x.data)
                setBuyerTypeLookUp(x.data);
            }
        });
        getLookUp("SOURCEOFENQUIRY").then((x: any) => {
            if (x && x.data && x.data.length > 0) {
                console.log(x)
                setSourceOfEnquiryLookUp(x.data);
            }
        });
    }, [])

    const methods = useForm<FormValuesProps>({
        resolver: yupResolver(NewBookingDetailSchema),
        defaultValues,
    });

    const {
        handleSubmit, control,
        // formState: { isSubmitting },
    } = methods;

    const onInputChange = useCallback(
        async (data: FormValuesProps) => {
            try {
                onChange({
                    bookingAmount: data.bookingAmount,
                    placeOfRegistration: data.placeOfRegistration,
                    specialRequest: data.specialRequest || "",
                    modePaymentId: data.modePaymentId || "0",
                    sourceOfEnquiryId: data.sourceOfEnquiryId || "0",
                    deliveryDate: data.deliveryDate,
                    deliveryAt: data.deliveryAt,
                    customerExpectedDeliveryDate: data.customerExpectedDeliveryDate,
                    salesExecutiveId: data.salesExecutiveId || "0",
                    teamLeaderId: data.teamLeaderId || "0",
                    salesManagerId: data.salesManagerId || "0",
                    vehicleRegistrationNumber: data.vehicleRegistrationNumber,
                    vehicleModelId: data.vehicleModelId,
                    varientId: data.varientId,
                    color: data.color
                });
            } catch (error) {
                console.error(error);
            }
        },
        [onChange]
    );

    const renderRowElement = () => (
          <>
         {(defaultValues.isBookingAmount || defaultValues.isAllEnable) && <RHFTextField size={GLOBALCLASSINPUT} name="bookingAmount" label={t('COMMON.FORM.BOOKINGAMOUNT') as any} onChange={() => onInputChange} />}
            {(defaultValues.isPlaceOfRegistration || defaultValues.isAllEnable) && <RHFTextField size={GLOBALCLASSINPUT} name="placeOfRegistration" label={t('COMMON.FORM.PLACEOFREGISTRATION') as any} onChange={() => onInputChange} />}
            {(defaultValues.isSpecialRequest || defaultValues.isAllEnable) && <RHFTextField size={GLOBALCLASSINPUT} name="specialRequest" label={t('COMMON.FORM.SPECIALREQUEST') as any} onChange={() => onInputChange} />}
            {(defaultValues.isModePaymentId || defaultValues.isAllEnable) && <RHFSelect name="modePaymentId" size={GLOBALCLASSINPUT} label={t('COMMON.FORM.MODEOFPAYMENT') as any} onChange={() => onInputChange}>
                <MenuItem value="">None</MenuItem>
                <Divider sx={{ borderStyle: 'dashed' }} />
                {modeOfPaymentLookUp && modeOfPaymentLookUp.map((item: any) => (
                    <MenuItem key={item.value + item.label} value={item.value}> {item.label} </MenuItem>
                ))}
            </RHFSelect>}
            {(defaultValues.isSourceOfEnquiryId || defaultValues.isAllEnable) && <RHFSelect name="sourceOfEnquiryId" size={GLOBALCLASSINPUT} label={t('COMMON.FORM.SOURCEOFENQUIRY') as any} onChange={() => onInputChange}>
                <MenuItem value="">None</MenuItem>
                <Divider sx={{ borderStyle: 'dashed' }} />
                {sourceOfEnquiryLookUp && sourceOfEnquiryLookUp.map((item: any) => (
                    <MenuItem key={item.value + item.label} value={item.value}> {item.label} </MenuItem>
                ))}
            </RHFSelect>}
            {(defaultValues.isDeliveryDate || defaultValues.isAllEnable) && <Controller
                name="deliveryDate"
                control={control}
                render={({ field, fieldState: { error } }) => (
                    <DatePicker
                        label={t('COMMON.FORM.DELIVERYDATE') as any}
                        value={field.value}
                        onChange={(newValue) => {
                            field.onChange(newValue);
                        }}
                        format="dd-MMM-yyyy"
                        slotProps={{
                            textField: {
                                size: 'small',
                                error: !!error,
                                helperText: error?.message,
                            },
                        }}
                    />
                )}
            />}
            {(defaultValues.isDeliveryAt || defaultValues.isAllEnable) && <RHFTextField size={GLOBALCLASSINPUT} name="deliveryAt" label={t('COMMON.FORM.DELIVERYAT') as any} onChange={() => onInputChange} />}
            {(defaultValues.isCustomerExpectedDeliveryDate || defaultValues.isAllEnable) && <Controller
                name="customerExpectedDeliveryDate"
                control={control}
                render={({ field, fieldState: { error } }) => (
                    <DatePicker
                        label={t('COMMON.FORM.CUSTOMEREXPECTEDDELIVERYDATE') as any}
                        value={field.value}
                        onChange={(newValue) => {
                            field.onChange(newValue);
                        }}
                        format="dd-MMM-yyyy"
                        slotProps={{
                            textField: {
                                size: 'small',
                                error: !!error,
                                helperText: error?.message,
                            },
                        }}
                    />
                )}
            />}
            {(defaultValues.isSalesExecutiveId || defaultValues.isAllEnable) && <RHFSelect name="salesExecutiveId" size={GLOBALCLASSINPUT} label={t('COMMON.FORM.SALESEXECUTIVE') as any} onChange={() => onInputChange}>
                <MenuItem value="">None</MenuItem>
                <Divider sx={{ borderStyle: 'dashed' }} />
                {salesExecutiveLookUp && salesExecutiveLookUp.map((item: any) => (
                    <MenuItem key={item.value + item.label} value={item.value}> {item.label} </MenuItem>
                ))}
            </RHFSelect>}
            {(defaultValues.isTeamLeaderId || defaultValues.isAllEnable) && <RHFSelect name="teamLeaderId" size={GLOBALCLASSINPUT} label={t('COMMON.FORM.TEAMLEADER') as any} onChange={() => onInputChange}>
                <MenuItem value="">None</MenuItem>
                <Divider sx={{ borderStyle: 'dashed' }} />
                {teamLeaderLookUp && teamLeaderLookUp.map((item: any) => (
                    <MenuItem key={item.value + item.label} value={item.value}> {item.label} </MenuItem>
                ))}
            </RHFSelect>}
            {(defaultValues.isSalesManagerId || defaultValues.isAllEnable) && <RHFSelect name="salesManagerId" size={GLOBALCLASSINPUT} label={t('COMMON.FORM.SALESMANAGER') as any} onChange={() => onInputChange}>
                <MenuItem value="">None</MenuItem>
                <Divider sx={{ borderStyle: 'dashed' }} />
                {salesManagerLookUp && salesManagerLookUp.map((item: any) => (
                    <MenuItem key={item.value + item.label} value={item.value}> {item.label} </MenuItem>
                ))}
            </RHFSelect>}
            {(defaultValues.isSourceOfEnquiryId || defaultValues.isAllEnable) && <RHFSelect name="sourceOfEnquiryId" size={GLOBALCLASSINPUT} label={t('COMMON.FORM.SOURCEOFENQUIRY') as any} onChange={() => onInputChange}>
                <MenuItem value="">None</MenuItem>
                <Divider sx={{ borderStyle: 'dashed' }} />
                {sourceOfEnquiryLookUp && sourceOfEnquiryLookUp.map((item: any) => (
                    <MenuItem key={item.value + item.label} value={item.value}> {item.label} </MenuItem>
                ))}
            </RHFSelect>}
            {(defaultValues.isBuyerTypeId || defaultValues.isAllEnable) && <RHFSelect name="buyerTypeId" size={GLOBALCLASSINPUT} label={t('COMMON.FORM.BUYERTYPE') as any} onChange={() => onInputChange}>
                <MenuItem value="">None</MenuItem>
                <Divider sx={{ borderStyle: 'dashed' }} />
                {buyerTypeLookUp && buyerTypeLookUp.map((item: any) => (
                    <MenuItem key={item.value + item.label} value={item.value}> {item.label} </MenuItem>
                ))}
            </RHFSelect>}
            {(defaultValues.isEnquiryOwnerId || defaultValues.isAllEnable) && <RHFSelect name="enquiryOwnerId" size={GLOBALCLASSINPUT} label={t('COMMON.FORM.ENQUIRYOWNER') as any} onChange={() => onInputChange}>
                <MenuItem value="">None</MenuItem>
                <Divider sx={{ borderStyle: 'dashed' }} />
                {enquiryOwnerLookUp && enquiryOwnerLookUp.map((item: any) => (
                    <MenuItem key={item.value + item.label} value={item.value}> {item.label} </MenuItem>
                ))}
            </RHFSelect>}
            {(defaultValues.isVehicleRegistrationNumber || defaultValues.isAllEnable) && <RHFTextField size={GLOBALCLASSINPUT} name="vehicleRegistrationNumber" label={t('COMMON.FORM.VEHICLEREGISTRATIONNUMBER') as any} />}
            {(defaultValues.isVehicleModelId || defaultValues.isAllEnable) && <RHFSelect name="vehicleModelId" size={GLOBALCLASSINPUT} label={t('COMMON.FORM.VEHICLEMODEL') as any} >
                <MenuItem value="">None</MenuItem>
                <Divider sx={{ borderStyle: 'dashed' }} />
                {vehicleModelLookUp && vehicleModelLookUp.map((item: any) => (
                    <MenuItem key={item.value + item.label} value={item.value}> {item.label} </MenuItem>
                ))}
            </RHFSelect>}
            {(defaultValues.isVarientId || defaultValues.isAllEnable) && <RHFSelect name="varientId" size={GLOBALCLASSINPUT} label={t('COMMON.FORM.VARIENTTYPE') as any} >
                <MenuItem value="">None</MenuItem>
                <Divider sx={{ borderStyle: 'dashed' }} />
                {varientTypeLookUp && varientTypeLookUp.map((item: any) => (
                    <MenuItem key={item.value + item.label} value={item.value}> {item.label} </MenuItem>
                ))}
            </RHFSelect>}
            {(defaultValues.isColor || defaultValues.isAllEnable) && <RHFSelect name="colorId" size={GLOBALCLASSINPUT} label={t('COMMON.FORM.COLOR') as any} >
                <MenuItem value="">None</MenuItem>
                <Divider sx={{ borderStyle: 'dashed' }} />
                {colorLookUp && colorLookUp.map((item: any) => (
                    <MenuItem key={item.value + item.label} value={item.value} > <span style={{ backgroundColor: item.data, padding: "3px 10px", borderRadius: "50%", marginRight: "5px", width: "20px", height: "20px" }}>&nbsp;</span> {item.label}    </MenuItem>
                ))}
            </RHFSelect>}
        </>
    )

    return (
        <>
        {!isBoxRequired && <Box rowGap={2} columnGap={2} display="grid" gridTemplateColumns={{ xs: 'repeat(1, 1fr)', sm: `repeat(${gridTemplateColumns}, 1fr)` }}>
          { renderRowElement()}
            </Box>
            }
             {isBoxRequired && renderRowElement()}
        </>
    );
}
