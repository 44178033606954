import { IErrorType } from "../error";

export type IEnquiryCategoryTableFilters = {
    name: string;
    status: string[];
  };
  
export type IEnquiryCategoryTableFilterValue = string | string[];

export type IEnquiryCategory = {
    id:number;
    name:string;
    description:string;
    displayOrder:number;
    status:boolean;
}
  
export type IEnquiryCategoryState = {
  enquiryCategory: IEnquiryCategory[];
  enquiryCategoryStatus: {
    loading: boolean;
    empty: boolean;
    error: IErrorType;
  };
  enquiryCategoryDelete: {
    loading:boolean;
    empty:any;
    error:IErrorType;
  }
};

export const defaultFilters = {
  name: '',
  status: [],
};