 
import {  useSelector } from "src/redux/store";

export default function useFinanceArrangeBy() {
    const { financeArrangeBy,financeArrangeByStatus} = useSelector(
        (state) => state.financeArrangeBy
    );
 
    return {
        financeArrangeBy,financeArrangeByStatus,
    }
}