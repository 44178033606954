import * as Yup from 'yup';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import LoadingButton from '@mui/lab/LoadingButton';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Unstable_Grid2';
import Typography from '@mui/material/Typography';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
// utils
import { fData } from 'src/utils/format-number';
// routes
import { paths } from 'src/routes/paths';
import { useParams, useRouter } from 'src/routes/hook';
// components
import Iconify from 'src/components/iconify';
import { CustomFile } from 'src/components/upload';
import { useSnackbar } from 'src/components/snackbar';
import { IEnquiry } from 'src/types/enquiry/enquiry.type';
import FormProvider, {
    RHFSwitch,
    RHFTextField,
    RHFUploadAvatar,
    RHFAutocomplete,
    RHFSelect,
} from 'src/components/hook-form';
import { useLocales } from 'src/locales';
import { GLOBALCLASSINPUT, PUBLISH_OPTIONS } from 'src/utils/contants/config-common';
import { Dialog, DialogTitle, DialogContent, Alert, DialogActions, Tabs, Tab, Paper, Step, StepContent, StepLabel, Stepper, alpha, Link, MenuItem, Stack, useTheme, Table, TableCell, TableRow, TableBody, IconButton, TableHead, CardContent, Divider, Container } from '@mui/material';
import { IAddressItem } from 'src/types/address';
import { getLookUp } from 'src/redux/slices/lookup';
import { PRODUCT_PUBLISH_OPTIONS, _mock } from 'src/_mock';
import { DateTimePicker } from '@mui/x-date-pickers';
import { bgGradient } from 'src/theme/css';
import { UpgradeStorageIllustration } from 'src/assets/illustrations';
import { TableHeadCustom, useTable } from 'src/components/table';
import { TABLE_HEAD_ENQUIRYFOLLOWUP, TABLE_HEAD_ENQUIRYTESTDRIVE, TABLE_HEAD_ENQUIRY_MANAGEMENT } from 'src/utils/contants/enquiry-management/config-enquiry-type';
import { CustomerDetail, IAddOn, ICustomerDetail, IExchange, IFinanceDetail, IInsuranceDetail, IReferrals, ISchemesOffers, ITestDrive } from 'src/types/common';
import Carousel, { CarouselArrows, useCarousel } from 'src/components/carousel';
import { CloseIcon } from 'src/components/lightbox';
import TextMaxLine from 'src/components/text-max-line';
import { useSettingsContext } from 'src/components/settings';
import CustomBreadcrumbs from 'src/components/custom-breadcrumbs';
import AddressForm from '../_common-components/address';
import AppFeatured from '../overview/app/app-featured';
import InsuranceDetailForm from '../_common-components/insurance-detail';
import FinanceDetailForm from '../_common-components/finance-detail';
import SchemeOffersForm from '../_common-components/SchemeOffersForm';
import CustomerDetailForm from '../_common-components/customer-detail';
import ExchangeForm from '../_common-components/exchange';
import ReferralsForm from '../_common-components/referrals';
import AddOnForm from '../_common-components/add-on';
import TestDriveForm from '../_common-components/test-drive';
import Toolbar from '../_common-components/toolbar';

// ----------------------------------------------------------------------
const ALLSTEPS = [
    {
        label: 'Customer Details',
        description: `For each ad campaign that you create, you can control how much
                you're willing to spend on clicks and conversions, which networks
                and geographical locations you want your ads to show on, and more.`,
    },
    {
        label: 'Address Details',
        description: 'An ad group contains one or more ads which target a shared set of keywords.',
    },
    {
        label: 'Vehicle Details',
        description: `Try out different ad text to see what brings in the most customers,
                and learn how to enhance your ads using features like ad extensions.
                If you run into any problems with your ads, find out how to tell if
                they're running and how to resolve approval issues.`,
    },
    {
        label: 'Schemes and Offers',
        description: `Try out different ad text to see what brings in the most customers,
                and learn how to enhance your ads using features like ad extensions.
                If you run into any problems with your ads, find out how to tell if
                they're running and how to resolve approval issues.`,
    },
    {
        label: 'Insurance Details',
        description: `Try out different ad text to see what brings in the most customers,
                and learn how to enhance your ads using features like ad extensions.
                If you run into any problems with your ads, find out how to tell if
                they're running and how to resolve approval issues.`,
    },
    {
        label: 'Finance Details',
        description: `Try out different ad text to see what brings in the most customers,
                and learn how to enhance your ads using features like ad extensions.
                If you run into any problems with your ads, find out how to tell if
                they're running and how to resolve approval issues.`,
    },
    {
        label: 'Exchange',
        description: `Try out different ad text to see what brings in the most customers,
                and learn how to enhance your ads using features like ad extensions.
                If you run into any problems with your ads, find out how to tell if
                they're running and how to resolve approval issues.`,
    },
    {
        label: 'Referrals',
        description: `Try out different ad text to see what brings in the most customers,
                and learn how to enhance your ads using features like ad extensions.
                If you run into any problems with your ads, find out how to tell if
                they're running and how to resolve approval issues.`,
    },
    {
        label: 'Loyality',
        description: `Try out different ad text to see what brings in the most customers,
                and learn how to enhance your ads using features like ad extensions.
                If you run into any problems with your ads, find out how to tell if
                they're running and how to resolve approval issues.`,
    },
    {
        label: 'Add-Ons',
        description: `Try out different ad text to see what brings in the most customers,
                and learn how to enhance your ads using features like ad extensions.
                If you run into any problems with your ads, find out how to tell if
                they're running and how to resolve approval issues.`,
    },
    {
        label: 'Test Drive',
        description: `Try out different ad text to see what brings in the most customers,
                and learn how to enhance your ads using features like ad extensions.
                If you run into any problems with your ads, find out how to tell if
                they're running and how to resolve approval issues.`,
    },
    {
        label: 'Enquiry Follow-up',
        description: `Try out different ad text to see what brings in the most customers,
                and learn how to enhance your ads using features like ad extensions.
                If you run into any problems with your ads, find out how to tell if
                they're running and how to resolve approval issues.`,
    },
    {
        label: 'Finish',
        description: `Try out different ad text to see what brings in the most customers,
                and learn how to enhance your ads using features like ad extensions.
                If you run into any problems with your ads, find out how to tell if
                they're running and how to resolve approval issues.`,
    },
];

export const _appFeatured = [...Array(3)].map((_, index) => ({
    id: _mock.id(index),
    title: _mock.postTitle(index),
    description: _mock.sentence(index),
    coverUrl: _mock.image.cover(index),
}));

interface FormValuesProps extends Omit<IEnquiry, 'avatarUrl'> {
    avatarUrl: CustomFile | string | null;
}

type Props = {
    title: string;
    open: boolean;
    onClose: VoidFunction;
    submitButtonText?: string;
    currentModel: IEnquiry;
};

// export default function EnquiryForm({ currentModel, open, onClose, title, submitButtonText }: Props) {
export default function EnquiryForm() {
    const settings = useSettingsContext();
    const router = useRouter();
    const { t } = useLocales();
    const theme = useTheme();
    const { enqueueSnackbar } = useSnackbar();
    const [varientLookUp, setVarientLookUp] = useState([] as any);
    const tableTestDrive = useTable({ defaultDense: true });
    const [tableTestDriveData, setTableTestDriveData] = useState<any[]>([]);
    const [currentTab, setCurrentTab] = useState('addressDetail');
    const [enquiryStatusLookUp, setEnquiryStatusLookUp] = useState([] as any);
    const [vehicleModelLookUp, setVehicleModelLookUp] = useState([] as any);
    const [visitLookUp, setVisitLookUp] = useState([] as any);
    const [activeStep, setActiveStep] = useState(0);
    const [activeVehicleId, setActiveVehicleId] = useState(0 as any);
    const [activeSteps, setActiveSteps] = useState(0);
    const [steps, setSteps] = useState([] as any);
    const [currentModel, setCurrentModelRow] = useState({ customerDetail: CustomerDetail } as IEnquiry);
    const [selectedRow, setSelectedRow] = useState({ customerDetail: CustomerDetail } as IEnquiry);
    const [gridTemplateColumns, setGridTemplateColumns] = useState(4);
    const [colorLookUp, setColorLookUp] = useState([] as any);
    const [seatingCapacityLookUp, setSeatingCapacityLookUp] = useState([] as any);
    const [vehicleDetailListLookUp, setVehicleDetailListLookUp] = useState([1, 2, 3, 4, 5] as any);
    const params = useParams();
    const { id } = params;

    const carousel = useCarousel({
        slidesToShow: 3,
        centerMode: true,
        centerPadding: '60px',
        responsive: [
            {
                breakpoint: 1024,
                settings: { slidesToShow: 2 },
            },
            {
                breakpoint: 600,
                settings: { slidesToShow: 2 },
            },
            {
                breakpoint: 480,
                settings: { slidesToShow: 1, centerPadding: '0' },
            },
        ],
    });

    const stepss = [
        {
            label: 'Booked',
        },
        {
            label: 'Alloted',
        },
        {
            label: 'Invoked',
        },
        {
            label: 'Delivery',

        },
    ]



    const NewUserSchema = Yup.object().shape({
        fullName: Yup.string().required(t('ENQUIRYMANAGEMENT.FORM.FULLNAME') as any),
        mobileNumber: Yup.string().required(t('ENQUIRYMANAGEMENT.FORM.MOBILENUMBER') as any),
        emailAddress: Yup.string().required(t('ENQUIRYMANAGEMENT.FORM.EMAILADDRESS') as any),
        enquiryStatusId: Yup.string().required(t('ENQUIRYMANAGEMENT.FORM.SOURCEOFENQUIRY') as any),
        buyerTypeId: Yup.string().required(t('ENQUIRYMANAGEMENT.FORM.BUYERTYPE') as any),
        enquiryOwnerId: Yup.string().required(t('ENQUIRYMANAGEMENT.FORM.ENQUIRYOWNER') as any),
        address: Yup.object(),
        corporateDetail: Yup.object(),
        vehicleDetail: Yup.object(),
        replacementDetail: Yup.object(),
        nextFollowUpDateAndTime: Yup.string(),
        testDriveDate: Yup.string(),
        birthDate: Yup.string(),
        coverNoteDate: Yup.string(),
        validTo: Yup.string(),
        validFrom: Yup.string(),
        doDate: Yup.string(),
        referralDateOfBirth: Yup.string(),
        warrentyValidTo: Yup.string(),
        warrentyValidFrom: Yup.string(),
        enquiryStatus: Yup.string(),
        description: Yup.string(),
        status: Yup.bool()
    });

    const defaultValues = useMemo(
        () => ({
            fullName: currentModel?.fullName || '',
            mobileNumber: currentModel?.mobileNumber || '',
            emailAddress: currentModel?.emailAddress || '',
            enquiryStatusId: currentModel?.enquiryStatusId || '',
            buyerTypeId: currentModel?.buyerTypeId || '',
            enquiryOwnerId: currentModel?.enquiryOwnerId || '',
            address: currentModel?.address,
            corporateDetail: currentModel?.corporateDetail || '',
            vehicleDetail: currentModel?.vehicleDetail || '',
            replacementDetail: currentModel?.replacementDetail || '',
            nextFollowUpDateAndTime: currentModel?.nextFollowUpDateAndTime || '',
            testDriveDate: currentModel?.testDriveDate || '',
            birthDate: currentModel?.birthDate || '',
            coverNoteDate: currentModel?.coverNoteDate || '',
            enquiryStatus: currentModel?.enquiryStatus || "",
            validTo: currentModel?.validTo || "",
            validFrom: currentModel?.validFrom || "",
            doDate: currentModel?.doDate || "",
            referralDateOfBirth: currentModel?.referralDateOfBirth || "",
            warrentyValidTo: currentModel?.warrentyValidTo || "",
            warrentyValidFrom: currentModel?.warrentyValidFrom || "",
            description: currentModel?.description || '',
            status: !(currentModel?.status),
            CustomerDetail: currentModel?.customerDetail || { ...CustomerDetail }
        }),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [currentModel]
    );

    useEffect(() => {
        setSteps(ALLSTEPS);
        getLookUp("VARIENT").then((x: any) => {
            if (x && x.data && x.data.length > 0) {
                console.log(x.data)
                setVarientLookUp(x.data);
            }
        });

        getLookUp("VEHICLEMODEL").then((x: any) => {
            if (x && x.data && x.data.length > 0) {
                console.log(x.data)
                setVehicleModelLookUp(x.data);
            }
        });


        getLookUp("VISIT").then((x: any) => {
            if (x && x.data && x.data.length > 0) {
                console.log(x.data)
                setVisitLookUp(x.data);
            }
        });

        getLookUp("COLOR").then((x: any) => {
            if (x && x.data && x.data.length > 0) {
                console.log(x.data)
                setColorLookUp(x.data);
            }
        });

        getLookUp("SEATINGCAPACITY").then((x: any) => {
            if (x && x.data && x.data.length > 0) {
                console.log(x.data)
                setSeatingCapacityLookUp(x.data);
            }
        });

    }, []);



    const methods = useForm<FormValuesProps>({
        resolver: yupResolver(NewUserSchema),
        defaultValues,
    });

    const {
        reset,
        control,
        setValue,
        handleSubmit,
        formState: { isSubmitting },
    } = methods;

    const onSubmit = useCallback(
        async (data: FormValuesProps) => {
            try {
                await new Promise((resolve) => setTimeout(resolve, 500));
                reset();
                enqueueSnackbar(currentModel ? 'Update success!' : 'Create success!');
                router.push(paths.dashboard.user.list);
                console.info('DATA', data);
            } catch (error) {
                console.error(error);
            }
        },
        [currentModel, enqueueSnackbar, reset, router]
    );

    const onCustomerDetailChange = useCallback(
        (customerDetail: ICustomerDetail) => {

        },
        []
    );

    const onAddressChange = useCallback(
        (address: IAddressItem) => {

        },
        []
    );

    const onIInsuranceDetailChange = useCallback(
        (insuranceDetail: IInsuranceDetail) => {

        },
        []
    );

    const onIFinanceDetailChange = useCallback(
        (finaceDetail: IFinanceDetail) => {

        },
        []
    );
    const onSchemeOffersChange = useCallback(
        (schemeOffers: ISchemesOffers) => {

        },
        []
    );

    const onExchangeChange = useCallback(
        (exchange: IExchange) => {

        },
        []
    );

    const onReferralsChange = useCallback(
        (referrals: IReferrals) => {

        },
        []
    );

    const onAddOnChange = useCallback(
        (addOn: IAddOn) => {

        },
        []
    );

    const onTestDriveChange = useCallback(
        (testDrive: ITestDrive) => {

        },
        []
    );

    const handleDrop = useCallback(
        (acceptedFiles: File[]) => {
            const file = acceptedFiles[0];

            const newFile = Object.assign(file, {
                preview: URL.createObjectURL(file),
            });

            if (file) {
                setValue('avatarUrl', newFile, { shouldValidate: true });
            }
        },
        [setValue]
    );

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleReset = () => {
        setActiveStep(0);
    };

    const renderRowElement = (step: any, index: any) => {
        switch (step.label) {
            case "Customer Details": return <Step key={step.label}>
                <StepLabel onClick={() => setActiveStep(index)}
                    optional={index === steps.length - 1 ? <Typography variant="caption">Last step</Typography> : null}
                >
                    {step.label}
                </StepLabel>
                <StepContent>
                    <Card sx={{ p: 3 }}>
                        <Box rowGap={3} columnGap={2} display="grid" gridTemplateColumns={{ xs: 'repeat(1, 1fr)', sm: `repeat(${gridTemplateColumns}, 1fr)` }}>
                            {selectedRow && <CustomerDetailForm onChange={onCustomerDetailChange} customerDetails={selectedRow.customerDetail} />}
                        </Box>
                    </Card>
                    <Box sx={{ mt: 3 }}>
                        <Button variant="contained" onClick={handleNext}>
                            {index === steps.length - 1 ? 'Finish' : 'Continue'}
                        </Button>
                        <Button disabled={index === 0} onClick={handleBack}>
                            Back
                        </Button>
                    </Box>
                </StepContent>
            </Step>
            case "Address Details": return <Step key={step.label}>
                <StepLabel onClick={() => setActiveStep(index)}
                    optional={index === steps.length - 1 ? <Typography variant="caption">Last step</Typography> : null}
                >
                    {step.label}
                </StepLabel>
                <StepContent>
                    <Card sx={{ p: 3 }}>
                        <Box rowGap={3} columnGap={2} display="grid" gridTemplateColumns={{ xs: 'repeat(1, 1fr)', sm: `repeat(${gridTemplateColumns}, 1fr)` }}>
                            <AddressForm onChange={onAddressChange} />
                        </Box>
                    </Card>
                    <Box sx={{ mt: 3 }}>
                        <Button variant="contained" onClick={handleNext}>
                            {index === steps.length - 1 ? 'Finish' : 'Continue'}
                        </Button>
                        <Button disabled={index === 0} onClick={handleBack}>
                            Back
                        </Button>
                    </Box>
                </StepContent>
            </Step>
            case "Vehicle Details": return <Step key={step.label}>
                <StepLabel onClick={() => setActiveStep(index)} optional={index === steps.length - 1 ? <Typography variant="caption">Last step</Typography> : null}>
                    {step.label}
                </StepLabel>
                <StepContent>
                    <Card sx={{ p: 3 }}>
                        <Box sx={{ overflow: 'hidden', position: 'relative' }}>
                            <CarouselArrows filled onNext={carousel.onNext} onPrev={carousel.onPrev} >
                                <Carousel ref={carousel.carouselRef} {...carousel.carouselSettings}>
                                    {vehicleDetailListLookUp && vehicleDetailListLookUp.map((itemValue: any) => (
                                        <Box key={itemValue} sx={{ px: 1 }} >
                                            <Card sx={{ textAlign: 'center', padding: "10px" }} onClick={() => setActiveVehicleId(itemValue)} style={{ marginTop: "2px", boxShadow: `${itemValue === activeVehicleId ? "0 0 2px 0 rgb(24 159 0), 0 12px 24px -4px rgb(14 173 4 / 12%)" : "0 0 2px 0 rgba(145, 158, 171, 0.2), 0 12px 24px -4px rgba(145, 158, 171, 0.12)"}` }}>
                                                <Box rowGap={2} columnGap={2} display="grid" gridTemplateColumns={{ xs: 'repeat(1, 1fr)', sm: 'repeat(1, 1fr)' }}>
                                                    <Iconify icon="ep:success-filled" className='success' style={{ color: `${itemValue === activeVehicleId ? "green" : "gray"}` }} />
                                                    <UpgradeStorageIllustration />
                                                    <RHFSelect name="vehicleModelId" size={GLOBALCLASSINPUT} label={t('COMMON.FORM.VEHICLEMODEL') as any} >
                                                        <MenuItem value="">None</MenuItem>
                                                        <Divider sx={{ borderStyle: 'dashed' }} />
                                                        {vehicleModelLookUp && vehicleModelLookUp.map((item: any) => (
                                                            <MenuItem key={item.value + item.label} value={item.value}> {item.label} </MenuItem>
                                                        ))}
                                                    </RHFSelect>
                                                    <RHFSelect name="varientTypeId" size={GLOBALCLASSINPUT} label={t('COMMON.FORM.VARIENTTYPE') as any} >
                                                        <MenuItem value="">None</MenuItem>
                                                        <Divider sx={{ borderStyle: 'dashed' }} />
                                                        {varientLookUp && varientLookUp.map((item: any) => (
                                                            <MenuItem key={item.value + item.label} value={item.value}> {item.label} </MenuItem>
                                                        ))}
                                                    </RHFSelect>
                                                    <RHFSelect name="colorId" size={GLOBALCLASSINPUT} label={t('COMMON.FORM.COLOR') as any} >
                                                        <MenuItem value="">None</MenuItem>
                                                        <Divider sx={{ borderStyle: 'dashed' }} />
                                                        {colorLookUp && colorLookUp.map((item: any) => (
                                                            <MenuItem key={item.value + item.label} value={item.value} > <span style={{ backgroundColor: item.data, padding: "10px", borderRadius: "50%", marginRight: "5px", width: "20px", height: "20px" }}>&nbsp;</span> {item.label}    </MenuItem>
                                                        ))}
                                                    </RHFSelect>
                                                    <RHFSelect name="seatingCapacityId" size={GLOBALCLASSINPUT} label={t('COMMON.FORM.SEATINGCAPACITY') as any} >
                                                        <MenuItem value="">None</MenuItem>
                                                        <Divider sx={{ borderStyle: 'dashed' }} />
                                                        {varientLookUp && varientLookUp.map((item: any) => (
                                                            <MenuItem key={item.value + item.label} value={item.value}> {item.label} </MenuItem>
                                                        ))}
                                                    </RHFSelect>
                                                </Box>
                                            </Card>
                                        </Box>
                                    ))}
                                </Carousel>
                            </CarouselArrows>
                        </Box>
                    </Card>
                    <Box sx={{ mt: 3 }}>
                        <Button variant="contained" onClick={handleNext}>
                            {index === steps.length - 1 ? 'Finish' : 'Continue'}
                        </Button>
                        <Button disabled={index === 0} onClick={handleBack}>
                            Back
                        </Button>
                    </Box>
                </StepContent>
            </Step>
            case "Schemes and Offers": return <Step key={step.label}>
                <StepLabel onClick={() => setActiveStep(index)}
                    optional={index === steps.length - 1 ? <Typography variant="caption">Last step</Typography> : null}
                >
                    {step.label}
                </StepLabel>
                <StepContent>
                    <Card sx={{ p: 3 }}>
                        <SchemeOffersForm onChange={onSchemeOffersChange} gridTemplateColumns={gridTemplateColumns} />
                    </Card>
                    <Box sx={{ mt: 3 }}>
                        <Button variant="contained" onClick={handleNext}>
                            {index === steps.length - 1 ? 'Finish' : 'Continue'}
                        </Button>
                        <Button disabled={index === 0} onClick={handleBack}>
                            Back
                        </Button>
                    </Box>
                </StepContent>
            </Step>
            case "Insurance Details": return <Step key={step.label}>
                <StepLabel onClick={() => setActiveStep(index)}
                    optional={index === steps.length - 1 ? <Typography variant="caption">Last step</Typography> : null}
                >
                    {step.label}
                </StepLabel>
                <StepContent>
                    <Card sx={{ p: 3 }}>
                        <Box rowGap={3} columnGap={2} display="grid" gridTemplateColumns={{ xs: 'repeat(1, 1fr)', sm: `repeat(${gridTemplateColumns}, 1fr)` }}>
                            <InsuranceDetailForm onChange={onIInsuranceDetailChange} />
                        </Box>
                    </Card>
                    <Box sx={{ mt: 3 }}>
                        <Button variant="contained" onClick={handleNext}>
                            {index === steps.length - 1 ? 'Finish' : 'Continue'}
                        </Button>
                        <Button disabled={index === 0} onClick={handleBack}>
                            Back
                        </Button>
                    </Box>
                </StepContent>
            </Step>
            case "Finance Details": return <Step key={step.label}>
                <StepLabel onClick={() => setActiveStep(index)}
                    optional={index === steps.length - 1 ? <Typography variant="caption">Last step</Typography> : null}
                >
                    {step.label}
                </StepLabel>
                <StepContent>
                    <Card sx={{ p: 3 }}>
                        <Box rowGap={3} columnGap={2} display="grid" gridTemplateColumns={{ xs: 'repeat(1, 1fr)', sm: `repeat(${gridTemplateColumns}, 1fr)` }}>
                            <FinanceDetailForm onChange={onIFinanceDetailChange} />
                        </Box>
                    </Card>
                    <Box sx={{ mt: 3 }}>
                        <Button variant="contained" onClick={handleNext}>
                            {index === steps.length - 1 ? 'Finish' : 'Continue'}
                        </Button>
                        <Button disabled={index === 0} onClick={handleBack}>
                            Back
                        </Button>
                    </Box>
                </StepContent>
            </Step>
            case "Exchange": return <Step key={step.label}>
                <StepLabel onClick={() => setActiveStep(index)}
                    optional={index === steps.length - 1 ? <Typography variant="caption">Last step</Typography> : null}
                >
                    {step.label}
                </StepLabel>
                <StepContent>
                    <ExchangeForm onChange={onExchangeChange} isLoyality='false' gridTemplateColumns={gridTemplateColumns} />
                    <Box sx={{ mt: 3 }}>
                        <Button variant="contained" onClick={handleNext}>
                            {index === steps.length - 1 ? 'Finish' : 'Continue'}
                        </Button>
                        <Button disabled={index === 0} onClick={handleBack}>
                            Back
                        </Button>
                    </Box>
                </StepContent>
            </Step>
            case "Referrals": return <Step key={step.label}>
                <StepLabel onClick={() => setActiveStep(index)}
                    optional={index === steps.length - 1 ? <Typography variant="caption">Last step</Typography> : null}
                >
                    {step.label}
                </StepLabel>
                <StepContent>
                    <Card sx={{ p: 3 }}>
                        <Box rowGap={3} columnGap={2} display="grid" gridTemplateColumns={{ xs: 'repeat(1, 1fr)', sm: `repeat(${gridTemplateColumns}, 1fr)` }}>
                            <ReferralsForm onChange={onReferralsChange} />
                        </Box>
                    </Card>
                    <Box sx={{ mt: 3 }}>
                        <Button variant="contained" onClick={handleNext}>
                            {index === steps.length - 1 ? 'Finish' : 'Continue'}
                        </Button>
                        <Button disabled={index === 0} onClick={handleBack}>
                            Back
                        </Button>
                    </Box>
                </StepContent>
            </Step>
            case "Loyality": return <Step key={step.label}>
                <StepLabel onClick={() => setActiveStep(index)}
                    optional={index === steps.length - 1 ? <Typography variant="caption">Last step</Typography> : null}
                >
                    {step.label}
                </StepLabel>
                <StepContent>
                    <ExchangeForm onChange={onExchangeChange} isLoyality='true' gridTemplateColumns={gridTemplateColumns} />
                    <Box sx={{ mt: 3 }}>
                        <Button variant="contained" onClick={handleNext}>
                            {index === steps.length - 1 ? 'Finish' : 'Continue'}
                        </Button>
                        <Button disabled={index === 0} onClick={handleBack}>
                            Back
                        </Button>
                    </Box>
                </StepContent>
            </Step>
            case "Add-Ons": return <Step key={step.label}>
                <StepLabel onClick={() => setActiveStep(index)}
                    optional={index === steps.length - 1 ? <Typography variant="caption">Last step</Typography> : null}
                >
                    {step.label}
                </StepLabel>
                <StepContent>
                    <AddOnForm onChange={onAddOnChange} gridTemplateColumns={gridTemplateColumns} />
                    <Box sx={{ mt: 3 }}>
                        <Button variant="contained" onClick={handleNext}>
                            {index === steps.length - 1 ? 'Finish' : 'Continue'}
                        </Button>
                        <Button disabled={index === 0} onClick={handleBack}>
                            Back
                        </Button>
                    </Box>
                </StepContent>
            </Step>
            case "Test Drive": return <Step key={step.label}>
                <StepLabel onClick={() => setActiveStep(index)}
                    optional={index === steps.length - 1 ? <Typography variant="caption">&nbsp;</Typography> : null}
                >
                    {step.label}
                </StepLabel>
                <StepContent>
                    <TestDriveForm onChange={onTestDriveChange} gridTemplateColumns={gridTemplateColumns} />
                    <Box sx={{ mt: 3 }}>
                        <Button variant="contained" onClick={handleNext}>
                            {index === steps.length - 1 ? 'Finish' : 'Continue'}
                        </Button>
                        <Button disabled={index === 0} onClick={handleBack}>
                            Back
                        </Button>
                    </Box>
                </StepContent>
            </Step>
            case "Enquiry Follow-up": return <Step key={step.label}>
                <StepLabel onClick={() => setActiveStep(index)} >
                    {step.label}
                </StepLabel>
                <StepContent>
                    <Card sx={{ p: 3 }}>
                        <Box rowGap={3} columnGap={2} display="grid" gridTemplateColumns={{ xs: 'repeat(1, 1fr)', sm: `repeat(${gridTemplateColumns}, 1fr)` }}>

                            <RHFSelect size={GLOBALCLASSINPUT} name="followupEnquiryStatusId" label={t('ENQUIRYMANAGEMENT.FORM.FOLLOWUPENQUIRYSTATUS') as any} >
                                <MenuItem value="">None</MenuItem>
                                <Divider sx={{ borderStyle: 'dashed' }} />
                                {enquiryStatusLookUp && enquiryStatusLookUp.map((item: any) => (
                                    <MenuItem key={item.value + item.label} value={item.value}> {item.label} </MenuItem>
                                ))}
                            </RHFSelect>
                            <RHFSelect size={GLOBALCLASSINPUT} name="followupVisit" label={t('ENQUIRYMANAGEMENT.FORM.FOLLOWUPVISIT') as any} >
                                <MenuItem value="">None</MenuItem>
                                <Divider sx={{ borderStyle: 'dashed' }} />
                                {visitLookUp && visitLookUp.map((item: any) => (
                                    <MenuItem key={item.value + item.label} value={item.value}> {item.label} </MenuItem>
                                ))}
                            </RHFSelect>
                            <Controller
                                name="nextFollowUpDateAndTime"
                                control={control}
                                render={({ field, fieldState: { error } }) => (
                                    <DateTimePicker
                                        label={t('ENQUIRYMANAGEMENT.FORM.NEXTFOLLOWUPDATEANDTIME') as any}
                                        value={field.value}
                                        onChange={(newValue) => {
                                            field.onChange(newValue);
                                        }}
                                        slotProps={{
                                            textField: {
                                                size: 'small',
                                                error: !!error,
                                                helperText: error?.message,
                                            },
                                        }}
                                    />
                                )}
                            />
                            <RHFTextField size={GLOBALCLASSINPUT} name="remark" label={t('COMMON.FORM.REMARKS') as any} />
                            <Box rowGap={3} columnGap={2} display="grid" gridTemplateColumns={{ xs: 'repeat(1, 1fr)', sm: 'repeat(2, 1fr)' }}>
                                <Button variant="contained" >
                                    Add
                                </Button>
                                <Button >
                                    Clear
                                </Button>
                            </Box>
                        </Box>
                        <br />
                        <Table sx={{ minWidth: "100%" }}>
                            <TableHead>
                                <TableRow>
                                    <TableCell style={{ padding: "4px", width: "118px" }}>Enquiry Status</TableCell>
                                    <TableCell style={{ padding: "4px", width: "118px" }}>Visit</TableCell>
                                    <TableCell style={{ padding: "4px", width: "118px" }}>Follow-up Date and Time</TableCell>
                                    <TableCell style={{ padding: "4px", width: "118px" }}>Remarks</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <TableRow>
                                    <TableCell style={{ padding: "4px", width: "118px" }}>
                                        <Typography variant="caption" sx={{ mb: 0.5 }}>  Customer Name: </Typography>
                                    </TableCell>
                                    <TableCell style={{ padding: "4px", width: "118px" }}>
                                        <Typography variant="caption" sx={{ mb: 0.5 }}>   HOME </Typography>
                                    </TableCell>
                                    <TableCell style={{ padding: "4px", width: "118px" }}>
                                        <Typography variant="caption" sx={{ mb: 0.5 }}>   12-DEC-2023 </Typography>
                                    </TableCell>
                                    <TableCell style={{ padding: "4px", width: "118px" }}>
                                        <Typography variant="caption" sx={{ mb: 0.5 }}>  Remark Description 1 </Typography>
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell style={{ padding: "4px", width: "118px" }}>
                                        <Typography variant="caption" sx={{ mb: 0.5 }}> Mobile Number: </Typography>
                                    </TableCell>
                                    <TableCell style={{ padding: "4px", width: "118px" }}>
                                        <Typography variant="caption" sx={{ mb: 0.5 }}> (812)123-5466 </Typography>
                                    </TableCell>
                                    <TableCell style={{ padding: "4px", width: "118px" }}>
                                        <Typography variant="caption" sx={{ mb: 0.5 }}>  12-DEC-2023 </Typography>
                                    </TableCell>
                                    <TableCell style={{ padding: "4px", width: "118px" }}>
                                        <Typography variant="caption" sx={{ mb: 0.5 }}>  Remark Description </Typography>
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                        <br />
                    </Card>
                    <Box sx={{ mt: 3 }}>
                        <Button variant="contained" onClick={handleNext}>
                            {index === steps.length - 1 ? 'Generate' : 'Continue'}
                        </Button>
                        <Button disabled={index === 0} onClick={handleBack}>
                            Back
                        </Button>
                    </Box>
                </StepContent>
            </Step>
            case "Finish": return <Step key={step.label}>
                <StepLabel onClick={() => setActiveStep(index)}
                    optional={index === steps.length - 1 ? <Typography variant="caption">Last step</Typography> : null}
                >
                    {step.label}
                </StepLabel>
                <StepContent>
                    <Card sx={{ p: 3 }}>
                        <Alert variant="outlined" style={{ backgroundColor: 'gray', color: '#ffffff' }} severity="success" sx={{ mb: 3 }}>
                            All steps completed - you&apos;re finished
                        </Alert>
                    </Card>
                </StepContent>
            </Step>
            default: return <>
            </>;
        }
    }

    return (
        <Container maxWidth={settings.themeStretch ? false : 'lg'}>
            <CustomBreadcrumbs
                heading="Enquiry Management"
                links={[
                    {
                        name: 'Dashboard',
                        href: paths.dashboard.root,
                    },
                    {
                        name: 'Enquiry Management',
                        href: paths.dashboard.salesJouryney.enquiry.root,
                    }

                ]}
                sx={{
                    mb: { xs: 3, md: 5 },
                }}
            />
            <Toolbar
                backLink={paths.dashboard.salesJouryney.enquiry.root}
                editLink={paths.dashboard.product.edit(`${selectedRow?.id}`)}
                liveLink={paths.dashboard.salesJouryney.enquiry.root}
                publish={id ? 'Update' : 'Add'}
                onChangePublish={() => { }}
                publishOptions={PUBLISH_OPTIONS}
            />
            <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
                {/* <Stepper activeStep={activeStep} sx={{ marginRight: "40px", width: "40%", float: "right", marginTop: "10px" }}>
                        {stepss.map((data, index) => {
                            const stepProps: { completed?: boolean } = {};
                            const labelProps: {
                                optional?: React.ReactNode;
                            } = {};
                            return (
                                <Step key={data.label} {...stepProps}>
                                    <StepLabel {...labelProps}>{data.label}</StepLabel>
                                </Step>
                            );
                        })}
                    </Stepper> */}
                <Grid container spacing={3} >
                    <Grid xs={12} md={3}>
                        <Card sx={{ pt: 5, pb: 3, px: 3 }}>
                            <RHFUploadAvatar
                                name="avatarUrl"
                                maxSize={3000000}
                                onDrop={handleDrop}
                                helperText={
                                    <Typography
                                        variant="caption"
                                        sx={{
                                            mt: 1,
                                            mx: 'auto',
                                            display: 'block',
                                            textAlign: 'center',
                                            color: 'text.disabled',
                                        }}
                                    >
                                        Allowed *.jpeg, *.jpg, *.png, *.gif
                                        <br /> max size of {fData(3000000)}
                                    </Typography>
                                }
                            />
                            <br />
                            <Table sx={{ minWidth: 300 }}>
                                <TableBody>
                                    <TableRow>
                                        <TableCell style={{ padding: "4px", width: "118px" }}>
                                            <Typography variant="caption" sx={{ mb: 0.5 }}>  Customer Name: </Typography>
                                        </TableCell>
                                        <TableCell style={{ padding: "4px", width: "118px" }}>
                                            <Typography variant="caption" sx={{ mb: 0.5 }}>  Bindu Menon: </Typography>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell style={{ padding: "4px", width: "118px" }}>
                                            <Typography variant="caption" sx={{ mb: 0.5 }}> Mobile Number: </Typography>
                                        </TableCell>
                                        <TableCell style={{ padding: "4px", width: "118px" }}>
                                            <Typography variant="caption" sx={{ mb: 0.5 }}> (812)123-5466 </Typography>
                                        </TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>

                            <br />
                            <Typography variant="subtitle2" sx={{ mb: 0.5 }}>
                                Vehicle Details
                            </Typography>

                            <Table sx={{ minWidth: 300 }}>
                                <TableBody>
                                    <TableRow>
                                        <TableCell style={{ padding: "4px", width: "118px" }}>
                                            <Typography variant="caption" sx={{ mb: 0.5 }}>  Vehicle Model: </Typography>
                                        </TableCell>
                                        <TableCell style={{ padding: "4px", width: "118px" }}>
                                            <Typography variant="caption" sx={{ mb: 0.5 }}>  Maruthi Brezza </Typography>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell style={{ padding: "4px", width: "118px" }}>
                                            <Typography variant="caption" sx={{ mb: 0.5 }}>  Varient Type: </Typography>
                                        </TableCell>
                                        <TableCell style={{ padding: "4px", width: "118px" }}>
                                            <Typography variant="caption" sx={{ mb: 0.5 }}>  ZXI </Typography>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell style={{ padding: "4px", width: "118px" }}>
                                            <Typography variant="caption" sx={{ mb: 0.5 }}>  Color: </Typography>
                                        </TableCell>
                                        <TableCell style={{ padding: "4px", width: "118px" }}>
                                            <Typography variant="caption" sx={{ mb: 0.5 }}>  Black </Typography>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell style={{ padding: "4px", width: "118px" }}>
                                            <Typography variant="caption" sx={{ mb: 0.5 }}>  Seating Capacity: </Typography>
                                        </TableCell>
                                        <TableCell style={{ padding: "4px", width: "118px" }}>
                                            <Typography variant="caption" sx={{ mb: 0.5 }}>  5 </Typography>
                                        </TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                            <br />
                            <RHFSwitch
                                name="isVerified"
                                labelPlacement="start"
                                label={
                                    <>
                                        <Typography variant="subtitle2" sx={{ mb: 0.5 }}>
                                            Email Verified
                                        </Typography>
                                        <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                                            Disabling this will automatically send the user a verification email
                                        </Typography>
                                    </>
                                }
                                sx={{ mx: 0, width: 1, justifyContent: 'space-between' }}
                            />

                        </Card>
                        <br />
                        <Grid xs={12} md={12}>
                            <AppFeatured list={_appFeatured} />
                        </Grid>
                    </Grid>
                    <Grid xs={12} md={9} >
                        <Stepper activeStep={activeStep} orientation="vertical">
                            {steps.map((step: any, index: any) => (
                                renderRowElement(step, index)
                            ))}
                        </Stepper>
                    </Grid>
                </Grid>
            </FormProvider>
        </Container>
    );
}
