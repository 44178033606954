import { IErrorType } from "./error";

export type IMandalTableFilters = {
    name: string;
    status: string[];
  };
  
export type IMandalTableFilterValue = string | string[];

export type IMandal = {
    mandalId:number;
    mandalCode:string;
    mandalName:string;
    countryId:string;
    stateId:string;
    districtId:string;
    cityId:string;
    image:string;
    description:string;
    status:boolean;
}
  
export type IMandalState = {
  mandals: IMandal[];
  mandal: IMandal | null;
  mandalsStatus: {
    loading: boolean;
    empty: boolean;
    error: IErrorType;
  };
  mandalStatus: {
    loading: boolean;
    error: IErrorType;
  };
  mandalDelete: {
    loading:boolean;
    empty:any;
    error:IErrorType;
  }
};

export const defaultFilters = {
  name: '',
  status: [],
};