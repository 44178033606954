import { IErrorType } from "./error";

export type IDistrictTableFilters = {
    name: string;
    status: string[];
  };
  
export type IDistrictTableFilterValue = string | string[];

export type IDistrict = {
    districtId:number;
    districtName:string;
    districtCode:string;
    countryId:string;
    stateId:string;
    image:string;
    description:string;
    status:boolean;
}
  
export type IDistrictState = {
  districts: IDistrict[];
  district: IDistrict | null;
  districtsStatus: {
    loading: boolean;
    empty: boolean;
    error: IErrorType;
  };
  districtStatus: {
    loading: boolean;
    error: IErrorType;
  };
  districtDelete: {
    loading:boolean;
    empty:any;
    error:IErrorType;
  }
};

export const defaultFilters = {
  name: '',
  status: [],
};