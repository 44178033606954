import * as Yup from 'yup';
import { useCallback, useEffect, useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import Box from '@mui/material/Box';
import {
    RHFTextField,
    RHFSelect,
} from 'src/components/hook-form';
import { Divider, Grid, MenuItem } from '@mui/material';
import { GLOBALCLASSINPUT } from 'src/utils/contants/config-common';
import { getLookUp } from 'src/redux/slices/lookup';
import { useLocales } from 'src/locales';
import { DatePicker } from '@mui/x-date-pickers';
import { IInsuranceDetail } from 'src/types/common';
import { Controller, useForm } from 'react-hook-form';

// ----------------------------------------------------------------------
 

export interface FormValuesProps extends IInsuranceDetail {
  
}

type Props = {
    onChange: (address: IInsuranceDetail) => void;
};

export default function InsuranceDetailForm({ onChange }: Props) {
    const [insuranceCompanyLookUp, setInsuranceCompanyLookUp] = useState([] as any);
    const { t } = useLocales();
    const NewInsuranceDetailSchema = Yup.object().shape({
        insurenceCompany: Yup.string().required('Insurance Company is required'),
        coverNote: Yup.string().required('Cover Note is required'),
        insuranceAmount: Yup.string().required('Insurance Amount is required'),
        coverNoteDate: Yup.string().required('Cover Note Date is required'),
        registrationNumber: Yup.string().required('Registration Number is required'),
    });

    const defaultValues = {
        insurenceCompany: '',
        coverNote: '',
        insuranceAmount: '',
        coverNoteDate: '',
        registrationNumber: '',
    };

    useEffect(() => {
        getLookUp("INSURANCECOMPANY").then((x: any) => {
            if (x && x.data && x.data.length > 0) {
                console.log(x.data)
                setInsuranceCompanyLookUp(x.data);
            }
        });
    }, [])

    const methods = useForm<FormValuesProps>({
        resolver: yupResolver(NewInsuranceDetailSchema),
        defaultValues,
    });

    const {
        handleSubmit, control,
        // formState: { isSubmitting },
    } = methods;

    const onInputChange = useCallback(
        async (data: FormValuesProps) => {
            try {
                onChange({
                    insurenceCompany: data.insurenceCompany,
                    coverNote: data.coverNote,
                    insuranceAmount: data.insuranceAmount,
                    coverNoteDate: data.coverNoteDate,
                    registrationNumber: data.registrationNumber,
                });
            } catch (error) {
                console.error(error);
            }
        },
        [onChange]
    );

    return (
        <>
                <RHFSelect size={GLOBALCLASSINPUT} name="insurenceCompany" label={t('ENQUIRYMANAGEMENT.FORM.INSURANCECOMPANY') as any} >
                <MenuItem value="">None</MenuItem>
                <Divider sx={{ borderStyle: 'dashed' }} />
                    {insuranceCompanyLookUp && insuranceCompanyLookUp.map((item: any) => (
                        <MenuItem key={item.value + item.label} value={item.value}> {item.label} </MenuItem>
                    ))}
                </RHFSelect>

                <RHFTextField size={GLOBALCLASSINPUT} name="coverNote" label={t('ENQUIRYMANAGEMENT.FORM.COVERNOTE') as any} />
                <RHFTextField size={GLOBALCLASSINPUT} name="insuranceAmount" label={t('ENQUIRYMANAGEMENT.FORM.INSURANCEAMOUNT') as any} />
                <Controller
                    name="coverNoteDate"
                    control={control}
                    render={({ field, fieldState: { error } }) => (
                        <DatePicker
                            label={t('ENQUIRYMANAGEMENT.FORM.COVERNOTEDATE') as any}
                            value={field.value}
                            onChange={(newValue) => {
                                field.onChange(newValue);
                            }}
                            format="dd-MMM-yyyy"
                            slotProps={{
                                textField: {
                                    size: 'small',
                                    error: !!error,
                                    helperText: error?.message,
                                },
                            }}
                        />
                    )}
                />
                <RHFTextField size={GLOBALCLASSINPUT} name="registrationNumber" label={t('ENQUIRYMANAGEMENT.FORM.REGISTRATIONNUMBER') as any} />
        </>
    );
}
