import { createSlice, Dispatch } from '@reduxjs/toolkit';
import { IDistrictState } from 'src/types/district';
// utils
import axios, { API_ENDPOINTS } from 'src/utils/axios';


const initialState: IDistrictState = {
    districts:[],
    district:null,
    districtsStatus:{
        loading:false,
        empty:false,
        error:null
    },
    districtStatus:{
        loading:false,
        error:null
    },
    districtDelete: {
        loading:false,
        empty:false,
        error:null
    }
}

const slice = createSlice({
    name: 'district',
    initialState,
    reducers:{
        getDistrictsStart(state) {
            state.districtsStatus.loading=true;
            state.districtsStatus.empty=false;
            state.districtsStatus.error = null;
        },
        getDistrictsFailure(state, action) {
            state.districtsStatus.loading=true;
            state.districtsStatus.empty=false;
            state.districtsStatus.error = action.payload;
        },
        getDistrictsSuccess(state, action) {
            const districts = action.payload;
            state.districts = districts;
            state.districtsStatus.loading = false;
            state.districtsStatus.empty = !districts.length;
            state.districtsStatus.error = null;
        }
        ,
        deleteDistrictStart(state) {
            state.districtDelete.loading=true;
            state.districtDelete.empty=false;
            state.districtDelete.error = null;
        },
        deleteDistrictFailure(state, action) {
            state.districtDelete.loading=true;
            state.districtDelete.empty=false;
            state.districtDelete.error = action.payload;
        },
        deleteDistrictSuccess(state, action) {
            const districtDelete = action.payload;
            state.districtDelete = districtDelete;
            state.districtDelete.loading = false;
            state.districtDelete.empty = action.payload;
            state.districtDelete.error = null;
        }
    }
});

export default slice.reducer;

// Actions
export const {
    getDistrictsStart,
    getDistrictsFailure,
    getDistrictsSuccess,
    deleteDistrictStart,
    deleteDistrictFailure,
    deleteDistrictSuccess
} = slice.actions;

export function getDistricts(data:any) {
        return async (dispatch: Dispatch) => {
            dispatch(slice.actions.getDistrictsStart());
            try{
               // const response = await axios.post(API_ENDPOINTS.VEHICLECLASS.SEARCH,data);
                dispatch(slice.actions.getDistrictsSuccess([
                    {id:1,districtName:'Hyderabad - Central',districtCode:"TS-09",countryName:"India",state:"Telangana",status:true},
                    {id:1,districtName:'Hyderabad - North',districtCode:"TS-10",countryName:"India",state:"Telangana",status:true},
                    {id:1,districtName:'Hyderabad - East',districtCode:"TS-11",countryName:"India",state:"Telangana",status:true},
                ]
                    ));
            }catch(error){
                dispatch(slice.actions.getDistrictsFailure(error));
            }
        }
}

export function deleteDistrict(id: any) {
    return async (dispatch: Dispatch) => {
        dispatch(slice.actions.deleteDistrictStart());
        try{
            const response = await axios.get(API_ENDPOINTS.BODYTYPE.DELETE+id);
            dispatch(slice.actions.deleteDistrictSuccess(response.data));
        } catch(error){
            dispatch(slice.actions.deleteDistrictFailure(error));
        }
    }
}
