 
import {  useSelector } from "src/redux/store";

export default function useOwner() {
    const { owner,ownerStatus} = useSelector(
        (state) => state.owner
    );
 
    return {
        owner,ownerStatus,
    }
}