import { IErrorType } from "./error";

export type IColorPalatteTableFilters = {
    name: string;
    status: string[];
  };
  
export type IColorPalatteTableFilterValue = string | string[];

export type IColorPalatte = {
    id:number;
    name:string;
    code:string;
    image:any;
    description:string;
    status:boolean;
}
  
export type IColorPalatteState = {
  colorPalattes: IColorPalatte[];
  colorPalatte: IColorPalatte | null;
  colorPalattesStatus: {
    loading: boolean;
    empty: boolean;
    error: IErrorType;
  };
  colorPalatteStatus: {
    loading: boolean;
    error: IErrorType;
  };
  colorPalatteDelete: {
    loading:boolean;
    empty:any;
    error:IErrorType;
  }
};

export const defaultFilters = {
  name: '',
  status: [],
};