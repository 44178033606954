export const CITIES_COLUMNS = ["cityCode","cityName","districtName","state","countryName","status"];

export const TABLE_HEAD_CITIES = [
    { id: 'cityCode', label: "CITIES.VIEW.GRID.CITYCODE" },
    { id: 'cityName', label: "CITIES.VIEW.GRID.CITYNAME" },
    { id: 'districtName', label: "CITIES.VIEW.GRID.DISTRICT" },
    { id: 'state', label: "CITIES.VIEW.GRID.STATE" },
    { id: 'countryName', label: "CITIES.VIEW.GRID.COUNTRY" },
    { id: 'status', label: "CITIES.VIEW.GRID.STATUS" },
    { id: 'id', width: 88 },
  ];

export const defaultFilters = {
    name: '',
    status: [],
  };
  