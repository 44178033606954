import { IErrorType } from "./error";

export type IVehicleClassTableFilters = {
    name: string;
    status: string[];
  };
  
export type IVehicleClassTableFilterValue = string | string[];

export type IVehicleClass = {
  id:number;
  name:string;
  description:string;
  status:any;
}
  
export type IVehicleClassState = {
  vehicleClasses: IVehicleClass[];
  vehicleClass: IVehicleClass | null;
  vehicleClassesStatus: {
    loading: boolean;
    empty: boolean;
    error: IErrorType;
  };
  vehicleClassStatus: {
    loading: boolean;
    error: IErrorType;
  };
  vehicleClassDelete: {
    loading:boolean;
    empty:any;
    error:IErrorType;
  }
};


export const defaultFilters = {
  name: '',
  status: [],
};