import { IErrorType } from "../error";

export type ISalutationTableFilters = {
    name: string;
    status: string[];
  };
  
export type ISalutationTableFilterValue = string | string[];

export type ISalutation = {
    id:number;
    name:string;
    description:string;
    displayOrder:number;
    status:boolean;
}
 
export type ISalutationState = {
  salutation: ISalutation[];
  salutationStatus: {
    loading: boolean;
    empty: boolean;
    error: IErrorType;
  };
  salutationDelete: {
    loading:boolean;
    empty:any;
    error:IErrorType;
  }
};

export const defaultFilters = {
  name: '',
  status: [],
};