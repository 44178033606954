import { Helmet } from "react-helmet-async";
import { useLocales } from "src/locales";
import CountriesListView from "src/sections/masters/geography-settings/countries/countries-list-view";


export default function CountryListPage() {
  const {t}=useLocales();
    return (
      <>
        <Helmet>
          <title> { t("COUNTRIES.PAGE.TITLE")}</title>
        </Helmet>
  
        <CountriesListView />
      </>
    );
  }