import * as Yup from 'yup';
import { useCallback, useEffect, useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import Box from '@mui/material/Box';
import {
    RHFTextField,
    RHFSelect,
} from 'src/components/hook-form';
import { Divider, Grid, MenuItem } from '@mui/material';
import { GLOBALCLASSINPUT } from 'src/utils/contants/config-common';
import { getLookUp } from 'src/redux/slices/lookup';
import { useLocales } from 'src/locales';
import { DatePicker } from '@mui/x-date-pickers';
import { IReferrals } from 'src/types/common';
import { Controller, useForm } from 'react-hook-form';

// ----------------------------------------------------------------------


export interface FormValuesProps extends IReferrals {

}

type Props = {
    onChange: (address: IReferrals) => void;
};

export default function ReferralsForm({ onChange }: Props) {
    const [customerTypeLookUp, setCustomerTypeLookUp] = useState([] as any);
    const { t } = useLocales();
    const NewReferralsSchema = Yup.object().shape({
        referralRegistrationNumber: Yup.string().required('Insurance Company is required'),
        referralVINNumber: Yup.string().required('Cover Note is required'),
        referralCustomerCode: Yup.string().required('Insurance Amount is required'),
        referralCustomerType: Yup.string().required('Cover Note Date is required'),
        referralCustomerName: Yup.string().required('Registration Number is required'),
        referralMobileNumber: Yup.string().required('Registration Number is required'),
        referralEmailAddress: Yup.string().required('Registration Number is required'),
        referralDateOfBirth: Yup.string().required('Registration Number is required'),
    });

    const defaultValues = {
        referralRegistrationNumber: '',
        referralVINNumber: '',
        referralCustomerCode: '',
        referralCustomerType: '',
        referralCustomerName: '',
        referralMobileNumber: '',
        referralEmailAddress: '',
        referralDateOfBirth: '',
    };

    useEffect(() => {
        getLookUp("CUSTOMERTYPE").then((x: any) => {
            if (x && x.data && x.data.length > 0) {
                console.log(x.data)
                setCustomerTypeLookUp(x.data);
            }
        });
    }, [])

    const methods = useForm<FormValuesProps>({
        resolver: yupResolver(NewReferralsSchema),
        defaultValues,
    });

    const {
        handleSubmit, control,
        // formState: { isSubmitting },
    } = methods;

    const onInputChange = useCallback(
        async (data: FormValuesProps) => {
            try {
                onChange({
                    referralRegistrationNumber: data.referralRegistrationNumber,
                    referralVINNumber: data.referralVINNumber,
                    referralCustomerCode: data.referralCustomerCode,
                    referralCustomerType: data.referralCustomerType,
                    referralCustomerName: data.referralCustomerName,
                    referralMobileNumber: data.referralMobileNumber,
                    referralEmailAddress: data.referralEmailAddress,
                    referralDateOfBirth: data.referralDateOfBirth,
                });
            } catch (error) {
                console.error(error);
            }
        },
        [onChange]
    );

    return (
        <>
            <RHFTextField size={GLOBALCLASSINPUT} name="referralRegistrationNumber" label={t('COMMON.FORM.REFERRALREGISTRATIONNUMBER') as any} onChange={() => onInputChange} />
            <RHFTextField size={GLOBALCLASSINPUT} name="referralVinNumber" label={t('COMMON.FORM.REFERRALVINNUMBER') as any} onChange={() => onInputChange} />
            <RHFTextField size={GLOBALCLASSINPUT} name="referralCustomerCode" label={t('COMMON.FORM.REFERRALCUSTOMERCODE') as any} onChange={() => onInputChange} />
            <RHFSelect size={GLOBALCLASSINPUT} name="referralCustomerType" label={t('COMMON.FORM.REFERRALCUSTOMERTYPE') as any} onChange={() => onInputChange}>
            <MenuItem value="">None</MenuItem>
                <Divider sx={{ borderStyle: 'dashed' }} />
                {customerTypeLookUp && customerTypeLookUp.map((item: any) => (
                    <MenuItem key={item.value + item.label} value={item.value}> {item.label} </MenuItem>
                ))}
            </RHFSelect>
            <RHFTextField size={GLOBALCLASSINPUT} name="referralCustomerName" label={t('COMMON.FORM.REFERRALCUSTOMERNAME') as any} onChange={() => onInputChange} />
            <RHFTextField size={GLOBALCLASSINPUT} name="referralMobileNumber" label={t('COMMON.FORM.REFERRALMOBILENUMBER') as any} onChange={() => onInputChange} />
            <RHFTextField size={GLOBALCLASSINPUT} name="referralEmailId" label={t('COMMON.FORM.REFERRALEMAILID') as any} onChange={() => onInputChange} />
            <Controller
                name="referralDateOfBirth"
                control={control}
                render={({ field, fieldState: { error } }) => (
                    <DatePicker
                        label={t('COMMON.FORM.REFERRALDATEOFBIRTH') as any}
                        value={field.value}
                        onChange={(newValue) => {
                            field.onChange(newValue);
                        }}
                        format="dd-MMM-yyyy"
                        slotProps={{
                            textField: {
                                size: 'small',
                                error: !!error,
                                helperText: error?.message,
                            },
                        }}
                    />
                )}
            />
        </>
    );
}
