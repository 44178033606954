import { IErrorType } from "./error";

export type IFuelTypeTableFilters = {
    name: string;
    status: string[];
  };
  
export type IFuelTypeTableFilterValue = string | string[];

export type IFuelType = {
    id:number;
    name:string;
    image:any;
    description:string;
    status:boolean;
}
  
export type IFuelTypeState = {
  fuelTypes: IFuelType[];
  fuelType: IFuelType | null;
  fuelTypesStatus: {
    loading: boolean;
    empty: boolean;
    error: IErrorType;
  };
  fuelTypeStatus: {
    loading: boolean;
    error: IErrorType;
  };
  fuelTypeDelete: {
    loading:boolean;
    empty:any;
    error:IErrorType;
  }
};

export const defaultFilters = {
  name: '',
  status: [],
};