export const SCHEMETYPE_COLUMNS = ["name","applySchemeCategory","displayOrder","status"];

export const TABLE_HEAD_SCHEMETYPE = [
    { id: 'name', label: "SCHEMETYPE.VIEW.GRID.NAME" },
    { id: 'applySchemeCategory', label: "SCHEMETYPE.VIEW.GRID.APPLYSCHEMECATEGORY" },
    { id: 'displayOrder', label: "SCHEMETYPE.VIEW.GRID.DISPLAYORDER" },
    { id: 'status', label: "SCHEMETYPE.VIEW.GRID.STATUS" },
    { id: 'id', width: 88 },
  ];

export const defaultFilters = {
    name: '',
    status: [],
  };
  