import { IErrorType } from "./error";

export type ISegmentDetailTableFilters = {
    name: string;
    status: string[];
  };
  
export type ISegmentDetailTableFilterValue = string | string[];

export type ISegmentDetail = {
    id:any;
    vehicleClassId:any;
    engineSize:string;
    length:string;
    description:string;
    status:boolean;
}
  
export type ISegmentDetailState = {
  segmentDetails: ISegmentDetail[];
  segmentDetail: ISegmentDetail | null;
  segmentDetailsStatus: {
    loading: boolean;
    empty: boolean;
    error: IErrorType;
  };
  segmentDetailStatus: {
    loading: boolean;
    error: IErrorType;
  };
  segmentDetailDelete: {
    loading:boolean;
    empty:any;
    error:IErrorType;
  }
};

export const defaultFilters = {
  name: '',
  status: [],
};