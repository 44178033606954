import { IErrorType } from "../error";

export type ISchemeTypeTableFilters = {
    name: string;
    status: string[];
  };
  
export type ISchemeTypeTableFilterValue = string | string[];

export type ISchemeType = {
    id:number;
    name:string;
    description:string;
    displayOrder:number;
    status:boolean;
}
 
export type ISchemeTypeState = {
  schemeType: ISchemeType[];
  schemeTypeStatus: {
    loading: boolean;
    empty: boolean;
    error: IErrorType;
  };
  schemeTypeDelete: {
    loading:boolean;
    empty:any;
    error:IErrorType;
  }
};

export const defaultFilters = {
  name: '',
  status: [],
};