import { IErrorType } from "../error";

export type IFinanceArrangeByTableFilters = {
    name: string;
    status: string[];
  };
  
export type IFinanceArrangeByTableFilterValue = string | string[];

export type IFinanceArrangeBy = {
    id:number;
    name:string;
    description:string;
    displayOrder:number;
    status:boolean;
}
 
export type IFinanceArrangeByState = {
  financeArrangeBy: IFinanceArrangeBy[];
  financeArrangeByStatus: {
    loading: boolean;
    empty: boolean;
    error: IErrorType;
  };
  financeArrangeByDelete: {
    loading:boolean;
    empty:any;
    error:IErrorType;
  }
};

export const defaultFilters = {
  name: '',
  status: [],
};