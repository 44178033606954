import {  useSelector } from "src/redux/store";

export default function useLeadCategory() {
    const { enquiryCategory,enquiryCategoryDelete,enquiryCategoryStatus} = useSelector(
        (state) => state.leadCategory
    );

   
    return {
        enquiryCategory,enquiryCategoryDelete,enquiryCategoryStatus,
    }
}