import { createSlice, Dispatch } from '@reduxjs/toolkit';
import { ICustomerTypeState } from 'src/types/general-settings/customer.type';
// utils
import axios, { API_ENDPOINTS } from 'src/utils/axios';

const initialState: ICustomerTypeState = {
    customerType:[],
    customerTypeStatus:{
        loading:false,
        empty:false,
        error:null
    },
    customerTypeDelete: {
        loading:false,
        empty:false,
        error:null
    }
}

const slice = createSlice({
    name: 'customerType',
    initialState,
    reducers:{
        getCustomerTypeStart(state) {
            state.customerTypeStatus.loading=true;
            state.customerTypeStatus.empty=false;
            state.customerTypeStatus.error = null;
        },
        getCustomerTypeFailure(state, action) {
            state.customerTypeStatus.loading=true;
            state.customerTypeStatus.empty=false;
            state.customerTypeStatus.error = action.payload;
        },
        getCustomerTypeSuccess(state, action) {
            const customerType = action.payload;
            state.customerType = customerType;
            state.customerTypeStatus.loading = false;
            state.customerTypeStatus.empty = !customerType.length;
            state.customerTypeStatus.error = null;
        },
        deleteCustomerTypeStart(state) {
            state.customerTypeDelete.loading=true;
            state.customerTypeDelete.empty=false;
            state.customerTypeDelete.error = null;
        },
        deleteCustomerTypeFailure(state, action) {
            state.customerTypeDelete.loading=true;
            state.customerTypeDelete.empty=false;
            state.customerTypeDelete.error = action.payload;
        },
        deleteCustomerTypeSuccess(state, action) {
            const customerTypeDelete = action.payload;
            state.customerTypeDelete = customerTypeDelete;
            state.customerTypeDelete.loading = false;
            state.customerTypeDelete.empty = action.payload;
            state.customerTypeDelete.error = null;
        }
    }
});

export default slice.reducer;

// Actions
export const {
    getCustomerTypeStart,
    getCustomerTypeFailure,
    getCustomerTypeSuccess,
    deleteCustomerTypeStart,
    deleteCustomerTypeFailure,
    deleteCustomerTypeSuccess
} = slice.actions;
 

export function getCustomerTypes(data:any) {
    return async (dispatch: Dispatch) => {
        dispatch(slice.actions.getCustomerTypeStart());
        try {
            await axios.get(API_ENDPOINTS.GENERALSETTINGS.CUSTOMERTYPE.LIST).then((res:any)=> {
                if(res.data.statusCode === 200)
                dispatch(slice.actions.getCustomerTypeSuccess(res.data.data));
            else 
                 dispatch(slice.actions.getCustomerTypeFailure([]));
            });
        }catch(error){
            dispatch(slice.actions.getCustomerTypeFailure(error));
        }
    }
}

export function deleteCustomerType(id: any) {
return  axios.delete(`${API_ENDPOINTS.GENERALSETTINGS.CUSTOMERTYPE.DELETE}/${id}`);
}

export function addUpdateCustomerType(data: any) {
return axios.post(API_ENDPOINTS.GENERALSETTINGS.CUSTOMERTYPE.ADDUPDATECUSTOMERTYPE,data);
}