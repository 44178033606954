import { createSlice, Dispatch } from '@reduxjs/toolkit';
import { IDeliveryNoteState } from 'src/types/sales-journey/delivery.note';
// utils
import axios, { API_ENDPOINTS } from 'src/utils/axios';

const initialState: IDeliveryNoteState = {
    deliveryNote:[],
    deliveryNoteStatus:{
        loading:false,
        empty:false,
        error:null
    },
    deliveryNoteDelete: {
        loading:false,
        empty:false,
        error:null
    }
}

const slice = createSlice({
    name: 'deliveryNote',
    initialState,
    reducers:{
        getDeliveryNoteStart(state) {
            state.deliveryNoteStatus.loading=true;
            state.deliveryNoteStatus.empty=false;
            state.deliveryNoteStatus.error = null;
        },
        getDeliveryNoteFailure(state, action) {
            state.deliveryNoteStatus.loading=true;
            state.deliveryNoteStatus.empty=false;
            state.deliveryNoteStatus.error = action.payload;
        },
        getDeliveryNoteSuccess(state, action) {
            const deliveryNote = action.payload;
            state.deliveryNote = deliveryNote;
            state.deliveryNoteStatus.loading = false;
            state.deliveryNoteStatus.empty = !deliveryNote.length;
            state.deliveryNoteStatus.error = null;
        },
        deleteDeliveryNoteStart(state) {
            state.deliveryNoteDelete.loading=true;
            state.deliveryNoteDelete.empty=false;
            state.deliveryNoteDelete.error = null;
        },
        deleteDeliveryNoteFailure(state, action) {
            state.deliveryNoteDelete.loading=true;
            state.deliveryNoteDelete.empty=false;
            state.deliveryNoteDelete.error = action.payload;
        },
        deleteDeliveryNoteSuccess(state, action) {
            const deliveryNoteDelete = action.payload;
            state.deliveryNoteDelete = deliveryNoteDelete;
            state.deliveryNoteDelete.loading = false;
            state.deliveryNoteDelete.empty = action.payload;
            state.deliveryNoteDelete.error = null;
        }
    }
});

export default slice.reducer;

// Actions
export const {
    getDeliveryNoteStart,
    getDeliveryNoteFailure,
    getDeliveryNoteSuccess,
    deleteDeliveryNoteStart,
    deleteDeliveryNoteFailure,
    deleteDeliveryNoteSuccess
} = slice.actions;
 

export function getDeliveryNotes(data:any) {
    return async (dispatch: Dispatch) => {
        dispatch(slice.actions.getDeliveryNoteStart());
        try {
            await axios.get(API_ENDPOINTS.SALESJOURNEY.DELIVERYNOTE.LIST).then((res:any)=> {
                if(res.data.statusCode === 200)
                dispatch(slice.actions.getDeliveryNoteSuccess(res.data.data));
            else 
                 dispatch(slice.actions.getDeliveryNoteFailure([]));
            });
        }catch(error){
            dispatch(slice.actions.getDeliveryNoteFailure(error));
        }
    }
}

export function deleteDeliveryNote(id: any) {
return  axios.delete(`${API_ENDPOINTS.SALESJOURNEY.DELIVERYNOTE.DELETE}/${id}`);
}

export function addUpdateDeliveryNote(data: any) {
return axios.post(API_ENDPOINTS.SALESJOURNEY.DELIVERYNOTE.ADDUPDATEDELIVERYNOTE,data);
}