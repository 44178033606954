// utils
import axios, { API_ENDPOINTS } from 'src/utils/axios';

// export async function getLookUp(key: any) {
//     return ([{ value: "1", label: 'Open' }, { value: "2", label: 'Converted' }, { value: "3", label: 'Closed' }])
// }

export async function getLookUp(key: any) {
    const url =`${API_ENDPOINTS.LOOKUP.LIST}${key}`;
    return axios.get(url);
}