import { Helmet } from "react-helmet-async";
import { useLocales } from "src/locales";
import SalutationListView from "src/sections/masters/general-settings/salutation/salutation-list-view";


export default function SalutationsListPage() {
  const {t}=useLocales();
    return (
      <>
        <Helmet>
          <title> { t("SALUTATION.PAGE.TITLE")}</title>
        </Helmet>
        <SalutationListView/>
      </>
    );
  }