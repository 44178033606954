import { Helmet } from "react-helmet-async";
import { useLocales } from "src/locales";
import DeliveryNoteListView from "src/sections/sales-journey/delivery-note/delivery-note-list-view";

export default function DeliveryNoteListPage() {
  const {t}=useLocales();
    return (
      <>
        <Helmet>
          <title> { t("DELIVERYNOTE.PAGE.TITLE")}</title>
        </Helmet>
        <DeliveryNoteListView />
      </>
    );
  }