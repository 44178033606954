import isEqual from 'lodash/isEqual';
import { useState, useEffect, useCallback } from 'react';
import { useLocales } from 'src/locales';
// @mui
import Card from '@mui/material/Card';
import Table from '@mui/material/Table';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import Container from '@mui/material/Container';
import TableBody from '@mui/material/TableBody';
import IconButton from '@mui/material/IconButton';
import TableContainer from '@mui/material/TableContainer';
// redux
import { useDispatch } from 'src/redux/store';
// routes
import { paths } from 'src/routes/paths';
// hooks
import { useBoolean } from 'src/hooks/use-boolean';
// components
import { useSettingsContext } from 'src/components/settings';
import {
  useTable,
  getComparator,
  emptyRows,
  TableNoData,
  TableSkeleton,
  TableEmptyRows,
  TableHeadCustom,
  TableSelectedAction,
  TablePaginationCustom,
} from 'src/components/table';
import Iconify from 'src/components/iconify';
import Scrollbar from 'src/components/scrollbar';
import { ConfirmDialog } from 'src/components/custom-dialog';
import CustomBreadcrumbs from 'src/components/custom-breadcrumbs';
import { Alert, Box, Collapse } from '@mui/material';
import CommonTemplateTableRow from 'src/sections/_common-components/common-template-table-row';
import { IEnquiryCategory,IEnquiryCategoryTableFilterValue, defaultFilters } from 'src/types/lead-settings/lead.category.type';
import { LEADCATEGORY_COLUMNS, TABLE_HEAD_LEAD_CATEGORY } from 'src/utils/contants/lead-settings/config-lead-category';
import { deleteEnquiryCategory, getLeadCategory } from 'src/redux/slices/lead-settings/lead-category';
import TableToolbar from 'src/sections/_common-components/table-toolbar';
import TableFiltersResult from 'src/sections/_common-components/table-filters-result';
import useLeadCategory from '../../hooks/lead-settings/use-lead-category';
import LeadCategoryForm from './lead-category-form';

function useInitial() {
  const dispatch = useDispatch();
  const getLeadCategoryCallback = useCallback(() => {
    dispatch(getLeadCategory({}));
  }, [dispatch]);

  useEffect(() => {
    getLeadCategoryCallback();
  }, [getLeadCategoryCallback]);

  return null;
}

// ----------------------------------------------------------------------

export default function LeadCategoryListView() {
  useInitial();
  const dispatch = useDispatch();
  const { t } = useLocales();
  const table = useTable({ defaultDense: true });
  const quickEdit = useBoolean();
  const settings = useSettingsContext();
  const [open, setOpen] = useState(false);
  const { enquiryCategory,enquiryCategoryStatus} = useLeadCategory();
  const [tableData, setTableData] = useState<any[]>([]);
  const [filters, setFilters] = useState(defaultFilters);
  const [title, setTitle] = useState("");
  const [selectedRow, setSelectedRow] = useState({} as IEnquiryCategory);
  const [submitButtonText, setSubmitButtonText] = useState();

  const confirm = useBoolean();
  const denseHeight = table.dense ? 60 : 80;
  const canReset = !isEqual(defaultFilters, filters);

  useEffect(() => {
    if (enquiryCategory.length) {
      setTableData(enquiryCategory);
    }
  }, [enquiryCategory]);

  const dataFiltered = applyFilter({
    inputData: tableData,
    comparator: getComparator(table.order, table.orderBy),
    filters,
  });

  const dataInPage = dataFiltered.slice(
    table.page * table.rowsPerPage,
    table.page * table.rowsPerPage + table.rowsPerPage
  );

  const notFound =
    (!dataFiltered.length && canReset) || (!enquiryCategoryStatus.loading && !dataFiltered.length);

  const handleFilters = useCallback(
    (name: string, value: IEnquiryCategoryTableFilterValue) => {
      table.onResetPage();
      setFilters((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    },
    [table]
  );

  const handleDeleteRow = (id: string) => {
    deleteEnquiryCategory(id).then((res: any) => {
      if (res.data.data === 1 && res.data.statusCode === 200) {
        confirm.onFalse();
        dispatch(getLeadCategory({}))
        setOpen(true);
        setTimeout(() => {
          setOpen(false)
        }, 5000);
      }
    });
  }


  const handleEditRow = useCallback(
    (row: IEnquiryCategory) => {
      setSelectedRow({...row})
      setTitle(t("LEADCATEGORY.VIEW.EDIT_TITLE") as any);
      setSubmitButtonText(t("LEADCATEGORY.VIEW.SUBMIT_BUTTON_TEXT_UPDATE") as any);
      quickEdit.onTrue()
    },
    [quickEdit, t]
  );

  const handleResetFilters = useCallback(() => {
    setFilters(defaultFilters);
  }, []);
  
  return (
    <>
      <Container maxWidth={settings.themeStretch ? false : 'lg'}>
        {quickEdit.value && <LeadCategoryForm submitButtonText={submitButtonText} currentModel={selectedRow} title={title} open={quickEdit.value} onClose={()=>{quickEdit.onFalse();dispatch(getLeadCategory({}));}} />}
        <CustomBreadcrumbs
          heading={t("LEADCATEGORY.VIEW.VIEW_HEADING") as any}
          links={[
            { name: t("DASHBOARD") as any, href: paths.dashboard.root },
            {
              name: t("LEADCATEGORY.VIEW.VIEW_NAME") as any,
              href: paths.dashboard.leadSettings.root,
            },
            { name: t("LEADCATEGORY.VIEW.LIST") as any },
          ]}
          action={
            <Button
              variant="contained"
              startIcon={<Iconify icon="mingcute:add-line" />}
              onClick={() => {
                setSelectedRow({ id: 0,status:true } as IEnquiryCategory);
                setTitle(t("LEADCATEGORY.VIEW.ADD_TITLE") as any);
                setSubmitButtonText(t("LEADCATEGORY.VIEW.SUBMIT_BUTTON_TEXT_INSERT") as any);
                quickEdit.onTrue()
              }}>
              {t("LEADCATEGORY.VIEW.ADD_BUTTON_TEXT") as any}
            </Button>
          }
          sx={{ mb: { xs: 3, md: 5 } }}
        />

        <Card>
          <TableToolbar
            filters={filters}
            onFilters={handleFilters}
          />

          {canReset && (
            <TableFiltersResult
              filters={filters}
              onFilters={handleFilters}
              onResetFilters={handleResetFilters}
              results={dataFiltered.length}
              sx={{ p: 2.5, pt: 0 }}
            />
          )}

          <TableContainer sx={{ position: 'relative', overflow: 'unset' }}>
            <TableSelectedAction
              dense={table.dense}
              numSelected={table.selected.length}
              rowCount={tableData.length}
              onSelectAllRows={(checked: any) =>
                table.onSelectAllRows(
                  checked,
                  tableData.map((row) => row.id)
                )
              }
              action={
                <Tooltip title={t("LEADCATEGORY.VIEW.ICON.TOOLTIP_DELETE") as any}>
                  <IconButton color="primary" onClick={confirm.onTrue}>
                    <Iconify icon="solar:trash-bin-trash-bold" />
                  </IconButton>
                </Tooltip>
              }
            />

            <Scrollbar>
              <Table size={table.dense ? 'small' : 'medium'} sx={{ minWidth: 960 }}>
                <TableHeadCustom
                  order={table.order}
                  orderBy={table.orderBy}
                  headLabel={TABLE_HEAD_LEAD_CATEGORY}
                  rowCount={tableData.length}
                  numSelected={table.selected.length}
                  onSort={table.onSort}
                  onSelectAllRows={(checked: any) =>
                    table.onSelectAllRows(
                      checked,
                      tableData.map((row) => row.id)
                    )
                  }
                />

                <TableBody>
                  {enquiryCategoryStatus.loading ? (
                    [...Array(table.rowsPerPage)].map((i, index) => (
                      <TableSkeleton key={index} sx={{ height: denseHeight }} />
                    ))
                  ) : (
                    <>
                      {dataFiltered
                        .slice(
                          table.page * table.rowsPerPage,
                          table.page * table.rowsPerPage + table.rowsPerPage
                        )
                        .map((row) => (
                          <CommonTemplateTableRow
                            key={row.id}
                            row={row}
                            columnNames={LEADCATEGORY_COLUMNS}
                            selected={table.selected.includes(row.id)}
                            onSelectRow={() => table.onSelectRow(row.id)}
                            onDeleteRow={() => handleDeleteRow(row.id)}
                            onEditRow={() => { handleEditRow(row); }}
                            onViewRow={() => { }}

                          />
                        ))}
                    </>
                  )}

                  <TableEmptyRows
                    height={denseHeight}
                    emptyRows={emptyRows(table.page, table.rowsPerPage, tableData.length)}
                  />
                  <TableNoData notFound={notFound} />
                </TableBody>
              </Table>
            </Scrollbar>
          </TableContainer>

          <TablePaginationCustom
            count={dataFiltered.length}
            page={table.page}
            rowsPerPage={table.rowsPerPage}
            onPageChange={table.onChangePage}
            onRowsPerPageChange={table.onChangeRowsPerPage}
            //
            dense={table.dense}
            onChangeDense={table.onChangeDense}
          />
        </Card>
      </Container>

      <ConfirmDialog
        open={confirm.value}
        onClose={confirm.onFalse}
        title={t("LEADCATEGORY.VIEW.CONFIRM_DIALOG_DELETE_TITLE") as any}
        content={
          <>
            {t("LEADCATEGORY.VIEW.CONFIRM_DIALOG_DELETE_MSG").replace("{0}", (table.selected.length) as any)}
          </>
        }
        action={
          <Button
            variant="contained"
            color="error"
            onClick={() => {
              // handleDeleteRows();
              // confirm.onFalse();
            }}
          >
            {t("LEADCATEGORY.VIEW.CONFIRM_DIALOG_DELETE_BUTTON")}
          </Button>
        }
      />
      <Box sx={{ position: 'relative' }}>
        <Collapse in={open}>
          <Alert
            severity="error"
            // icon={<CheckIcon fontSize="error" />}
            // icon={false}
            sx={{
              maxWidth: 'fit-content',
              position: 'fixed',
              zIndex: 11,
              left: 0,
              right: 0,
              bottom: '2%',
              margin: 'auto',
              mb: 2
            }}
            action={
              <IconButton
                aria-label="close"
                color="error"
                size="small"
                onClick={() => {
                  setOpen(false);
                }}
              >
                {/*  */}
              </IconButton>
            }
          >
            {t("LEADCATEGORY.VIEW.CONFIRM_DIALOG_DELETE_SUCCESS_MSG")}
          </Alert>
        </Collapse>
      </Box>
    </>
  );
}

// ----------------------------------------------------------------------

function applyFilter({
  inputData,
  comparator,
  filters,
}: {
  inputData: any[];
  comparator: (a: any, b: any) => number;
  filters: any;
}) {
  const { name, status } = filters;

  const stabilizedThis = inputData.map((el, index) => [el, index] as const);

  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });

  inputData = stabilizedThis.map((el) => el[0]);

  if (name) {
    inputData = inputData.filter(
      (template) => template.name.toLowerCase().indexOf(name.toLowerCase()) !== -1
    );
  }

  if (status.length) {
    inputData = inputData.filter((template) => status.includes(template.status));
  }

  return inputData;
}
