import { createSlice, Dispatch } from '@reduxjs/toolkit';
import { IManufacturerState } from 'src/types/manufacturer';
// utils
import axios, { API_ENDPOINTS } from 'src/utils/axios';


const initialState: IManufacturerState = {
    manufacturers:[],
    manufacturer:null,
    manufacturersStatus:{
        loading:false,
        empty:false,
        error:null
    },
    manufacturerStatus:{
        loading:false,
        error:null
    },
    manufacturerDelete: {
        loading:false,
        empty:false,
        error:null
    }
}

const slice = createSlice({
    name: 'manufacturer',
    initialState,
    reducers:{
        getManufacturersStart(state) {
            state.manufacturersStatus.loading=true;
            state.manufacturersStatus.empty=false;
            state.manufacturersStatus.error = null;
        },
        getManufacturersFailure(state, action) {
            state.manufacturersStatus.loading=true;
            state.manufacturersStatus.empty=false;
            state.manufacturersStatus.error = action.payload;
        },
        getManufacturersSuccess(state, action) {
            const manufacturers = action.payload;
            state.manufacturers = manufacturers;
            state.manufacturersStatus.loading = false;
            state.manufacturersStatus.empty = !manufacturers.length;
            state.manufacturersStatus.error = null;
        }
        ,
        deleteManufacturerStart(state) {
            state.manufacturerDelete.loading=true;
            state.manufacturerDelete.empty=false;
            state.manufacturerDelete.error = null;
        },
        deleteManufacturerFailure(state, action) {
            state.manufacturerDelete.loading=true;
            state.manufacturerDelete.empty=false;
            state.manufacturerDelete.error = action.payload;
        },
        deleteManufacturerSuccess(state, action) {
            const manufacturerDelete = action.payload;
            state.manufacturerDelete = manufacturerDelete;
            state.manufacturerDelete.loading = false;
            state.manufacturerDelete.empty = action.payload;
            state.manufacturerDelete.error = null;
        }
    }
});

export default slice.reducer;

// Actions
export const {
    getManufacturersStart,
    getManufacturersFailure,
    getManufacturersSuccess,
    deleteManufacturerStart,
    deleteManufacturerFailure,
    deleteManufacturerSuccess
} = slice.actions;
 
export function getManufacturers(data: any) {
    return async (dispatch: Dispatch) => {
        dispatch(slice.actions.getManufacturersStart());
        try {
            await axios.get(API_ENDPOINTS.PRODUCTSETTTINGS.MANUFACTURER.LIST).then((res: any) => {
                if (res.data.statusCode === 200)
                    dispatch(slice.actions.getManufacturersSuccess(res.data.data));
                else
                    dispatch(slice.actions.getManufacturersFailure([]));
            });
        } catch (error) {
            dispatch(slice.actions.getManufacturersFailure(error));
        }
    }
}

export function deleteManufacturer(id: any) {
    return axios.delete(`${API_ENDPOINTS.PRODUCTSETTTINGS.MANUFACTURER.DELETE}/${id}`);
}

export function addUpdateManufacturer(data: any) {
    return axios.post(API_ENDPOINTS.PRODUCTSETTTINGS.MANUFACTURER.ADDUPDATEMANUFACTURER, data);
}