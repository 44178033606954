import { createSlice, Dispatch } from '@reduxjs/toolkit';
import { IInvoiceState } from 'src/types/sales-journey/invoice';
// utils
import axios, { API_ENDPOINTS } from 'src/utils/axios';

const initialState: IInvoiceState = {
    invoice:[],
    invoiceStatus:{
        loading:false,
        empty:false,
        error:null
    },
    invoiceDelete: {
        loading:false,
        empty:false,
        error:null
    }
}

const slice = createSlice({
    name: 'invoice',
    initialState,
    reducers:{
        getInvoiceStart(state) {
            state.invoiceStatus.loading=true;
            state.invoiceStatus.empty=false;
            state.invoiceStatus.error = null;
        },
        getInvoiceFailure(state, action) {
            state.invoiceStatus.loading=true;
            state.invoiceStatus.empty=false;
            state.invoiceStatus.error = action.payload;
        },
        getInvoiceSuccess(state, action) {
            const invoice = action.payload;
            state.invoice = invoice;
            state.invoiceStatus.loading = false;
            state.invoiceStatus.empty = !invoice.length;
            state.invoiceStatus.error = null;
        },
        deleteInvoiceStart(state) {
            state.invoiceDelete.loading=true;
            state.invoiceDelete.empty=false;
            state.invoiceDelete.error = null;
        },
        deleteInvoiceFailure(state, action) {
            state.invoiceDelete.loading=true;
            state.invoiceDelete.empty=false;
            state.invoiceDelete.error = action.payload;
        },
        deleteInvoiceSuccess(state, action) {
            const invoiceDelete = action.payload;
            state.invoiceDelete = invoiceDelete;
            state.invoiceDelete.loading = false;
            state.invoiceDelete.empty = action.payload;
            state.invoiceDelete.error = null;
        }
    }
});

export default slice.reducer;

// Actions
export const {
    getInvoiceStart,
    getInvoiceFailure,
    getInvoiceSuccess,
    deleteInvoiceStart,
    deleteInvoiceFailure,
    deleteInvoiceSuccess
} = slice.actions;
 

export function getInvoices(data:any) {
    return async (dispatch: Dispatch) => {
        dispatch(slice.actions.getInvoiceStart());
        try {
            await axios.get(API_ENDPOINTS.SALESJOURNEY.INVOICE.LIST).then((res:any)=> {
                if(res.data.statusCode === 200)
                dispatch(slice.actions.getInvoiceSuccess(res.data.data));
            else 
                 dispatch(slice.actions.getInvoiceFailure([]));
            });
        }catch(error){
            dispatch(slice.actions.getInvoiceFailure(error));
        }
    }
}

export function deleteInvoice(id: any) {
return  axios.delete(`${API_ENDPOINTS.GENERALSETTINGS.BANKNAME.DELETE}/${id}`);
}

export function addUpdateInvoice(data: any) {
return axios.post(API_ENDPOINTS.GENERALSETTINGS.BANKNAME.ADDUPDATEBANKNAME,data);
}