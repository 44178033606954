 
import {  useSelector } from "src/redux/store";

export default function useInvoice() {
    const { invoice,invoiceStatus} = useSelector(
        (state) => state.invoice
    );
 
    return {
        invoice,invoiceStatus,
    }
}