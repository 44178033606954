import * as Yup from 'yup';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import LoadingButton from '@mui/lab/LoadingButton';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Unstable_Grid2';
import Typography from '@mui/material/Typography';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
// utils
import { fData } from 'src/utils/format-number';
// routes
import { paths } from 'src/routes/paths';
import { useParams, useRouter } from 'src/routes/hook';
// components
import Iconify from 'src/components/iconify';
import { CustomFile } from 'src/components/upload';
import { useSnackbar } from 'src/components/snackbar';
import { IBooking } from 'src/types/booking/booking.type';
import FormProvider, {
    RHFSwitch,
    RHFTextField,
    RHFUploadAvatar,
    RHFAutocomplete,
    RHFSelect,
} from 'src/components/hook-form';
import { useLocales } from 'src/locales';
import { GLOBALCLASSINPUT, PUBLISH_OPTIONS } from 'src/utils/contants/config-common';
import { Dialog, DialogTitle, DialogContent, Alert, DialogActions, Tabs, Tab, Paper, Step, StepContent, StepLabel, Stepper, alpha, Link, MenuItem, Stack, useTheme, Table, TableCell, TableRow, TableBody, IconButton, TableHead, Tooltip, Accordion, AccordionDetails, AccordionSummary, Container } from '@mui/material';
import { IAddressItem } from 'src/types/address';
import { getLookUp } from 'src/redux/slices/lookup';
import { _mock } from 'src/_mock';
import { DateTimePicker } from '@mui/x-date-pickers';
import { bgGradient } from 'src/theme/css';
import { UpgradeStorageIllustration } from 'src/assets/illustrations';
import { TableHeadCustom, useTable } from 'src/components/table';
import { CloseIcon } from 'src/components/lightbox';
import { BookingDetail, CustomerBooking, IAddOn, IBookingDetail, ICustomerBooking, ICustomerDetail, IExchange, IFinanceDetail, IInsuranceDetail, IReferrals, ISchemesOffers, ITaxDetail, ITestDrive, IVehicleDetail, VehicleDetail } from 'src/types/common';
import { TABLE_HEAD_ENQUIRYTESTDRIVE } from 'src/utils/contants/booking/config-booking';
import CustomBreadcrumbs from 'src/components/custom-breadcrumbs';
import { useSettingsContext } from 'src/components/settings';
import AppFeatured from '../overview/app/app-featured';
import InsuranceDetailForm from '../_common-components/insurance-detail';
import FinanceDetailForm from '../_common-components/finance-detail';
import SchemeOffersForm from '../_common-components/SchemeOffersForm';
import CustomerDetailForm from '../_common-components/customer-detail';
import ExchangeForm from '../_common-components/exchange';
import ReferralsForm from '../_common-components/referrals';
import BookingDetailForm from '../_common-components/booking-detail';
import CustomerBookingDetailForm from '../_common-components/customer-booking-detail';
import VehicleDetailForm from '../_common-components/vehicle-details';
import TaxDetailForm from '../_common-components/tax-detail';
import AddOnForm from '../_common-components/add-on';
import TestDriveForm from '../_common-components/test-drive';
import Toolbar from '../_common-components/toolbar';
// ----------------------------------------------------------------------

export const _appFeatured = [...Array(3)].map((_, index) => ({
    id: _mock.id(index),
    title: _mock.postTitle(index),
    description: _mock.sentence(index),
    coverUrl: _mock.image.cover(index),
}));

interface FormValuesProps extends Omit<IBooking, 'avatarUrl'> {
    avatarUrl: CustomFile | string | null;
}

type Props = {
    title: string;
    open: boolean;
    onClose: VoidFunction;
    submitButtonText?: string;
    currentModel: IBooking;
};

// export default function BookingForm({ currentModel, open, onClose, title, submitButtonText }: Props) {
    export default function BookingForm() {
    const router = useRouter();
    const { t } = useLocales();
    const theme = useTheme();
    const { enqueueSnackbar } = useSnackbar();
    const [varientLookUp, setVarientLookUp] = useState([] as any);
    const [currentTab, setCurrentTab] = useState('addressDetail');
    const [bookingStatusLookUp, setBookingStatusLookUp] = useState([] as any);
    const [visitLookUp, setVisitLookUp] = useState([] as any);
    const [activeStep, setActiveStep] = useState(0);
    const [activeSteps, setActiveSteps] = useState(0);
    const [gridTemplateColumns, setGridTemplateColumns] = useState(4);
    const [currentModel, setCurrentModel] = useState({} as IBooking);
    const [selectedRow, setSelectedRow] = useState({} as IBooking);
    const [submitButtonText, setSubmitButtonText] = useState("Generate Booking");
    const settings = useSettingsContext();
    const params = useParams();
    const { id } = params;

    const stepss = [
        {
            label: 'Booked',
        },
        {
            label: 'Alloted',
        },
        {
            label: 'Invoked',
        },
        {
            label: 'Delivery',

        },
    ]
    const steps = [
        {
            label: 'Booking Details',
            description: `For each ad campaign that you create, you can control how much
                    you're willing to spend on clicks and conversions, which networks
                    and geographical locations you want your ads to show on, and more.`,
        },
        {
            label: 'Customer Detail',
            description: 'An ad group contains one or more ads which target a shared set of keywords.',
        },
        {
            label: 'Vehicle Details',
            description: `Try out different ad text to see what brings in the most customers,
                    and learn how to enhance your ads using features like ad extensions.
                    If you run into any problems with your ads, find out how to tell if
                    they're running and how to resolve approval issues.`,
        },
        {
            label: 'Schemes and Offers',
            description: `Try out different ad text to see what brings in the most customers,
                    and learn how to enhance your ads using features like ad extensions.
                    If you run into any problems with your ads, find out how to tell if
                    they're running and how to resolve approval issues.`,
        },
        {
            label: 'Insurance Details',
            description: `Try out different ad text to see what brings in the most customers,
                    and learn how to enhance your ads using features like ad extensions.
                    If you run into any problems with your ads, find out how to tell if
                    they're running and how to resolve approval issues.`,
        },
        {
            label: 'Finance Details',
            description: `Try out different ad text to see what brings in the most customers,
                    and learn how to enhance your ads using features like ad extensions.
                    If you run into any problems with your ads, find out how to tell if
                    they're running and how to resolve approval issues.`,
        },
        {
            label: 'Exchange',
            description: `Try out different ad text to see what brings in the most customers,
                    and learn how to enhance your ads using features like ad extensions.
                    If you run into any problems with your ads, find out how to tell if
                    they're running and how to resolve approval issues.`,
        },
        {
            label: 'Add-Ons',
            description: `Try out different ad text to see what brings in the most customers,
                    and learn how to enhance your ads using features like ad extensions.
                    If you run into any problems with your ads, find out how to tell if
                    they're running and how to resolve approval issues.`,
        },
        {
            label: 'Finish',
            description: `Try out different ad text to see what brings in the most customers,
                    and learn how to enhance your ads using features like ad extensions.
                    If you run into any problems with your ads, find out how to tell if
                    they're running and how to resolve approval issues.`,
        },
    ];

    const NewUserSchema = Yup.object().shape({
        fullName: Yup.string().required(t('ENQUIRYMANAGEMENT.FORM.FULLNAME') as any),
        mobileNumber: Yup.string().required(t('ENQUIRYMANAGEMENT.FORM.MOBILENUMBER') as any),
        emailAddress: Yup.string().required(t('ENQUIRYMANAGEMENT.FORM.EMAILADDRESS') as any),
        bookingStatusId: Yup.string().required(t('ENQUIRYMANAGEMENT.FORM.SOURCEOFENQUIRY') as any),
        buyerTypeId: Yup.string().required(t('ENQUIRYMANAGEMENT.FORM.BUYERTYPE') as any),
        bookingOwnerId: Yup.string().required(t('ENQUIRYMANAGEMENT.FORM.ENQUIRYOWNER') as any),
        address: Yup.object(),
        corporateDetail: Yup.object(),
        bookingDetail: Yup.object(),
        bookingCustomer: Yup.object(),
        billingCustomer: Yup.object(),
        vehicleDetail: Yup.object(),
        replacementDetail: Yup.object(),
        nextFollowUpDateAndTime: Yup.string(),
        testDriveDate: Yup.string(),
        birthDate: Yup.string(),
        coverNoteDate: Yup.string(),
        validTo: Yup.string(),
        validFrom: Yup.string(),
        doDate: Yup.string(),
        referralDateOfBirth: Yup.string(),
        warrentyValidTo: Yup.string(),
        warrentyValidFrom: Yup.string(),
        bookingStatus: Yup.string(),
        description: Yup.string(),
        isSameAsBillingAddress: Yup.bool(),
        status: Yup.bool()
    });

    const defaultValues = useMemo(
        () => ({
            fullName: currentModel?.fullName || '',
            mobileNumber: currentModel?.mobileNumber || '',
            emailAddress: currentModel?.emailAddress || '',
            bookingStatusId: currentModel?.bookingStatusId || '',
            buyerTypeId: currentModel?.buyerTypeId || '',
            bookingOwnerId: currentModel?.bookingOwnerId || '',
            address: currentModel?.address,
            corporateDetail: currentModel?.corporateDetail || '',
            replacementDetail: currentModel?.replacementDetail || '',
            nextFollowUpDateAndTime: currentModel?.nextFollowUpDateAndTime || '',
            testDriveDate: currentModel?.testDriveDate || '',
            birthDate: currentModel?.birthDate || '',
            coverNoteDate: currentModel?.coverNoteDate || '',
            bookingStatus: currentModel?.bookingStatus || "",
            validTo: currentModel?.validTo || "",
            validFrom: currentModel?.validFrom || "",
            doDate: currentModel?.doDate || "",
            referralDateOfBirth: currentModel?.referralDateOfBirth || "",
            warrentyValidTo: currentModel?.warrentyValidTo || "",
            warrentyValidFrom: currentModel?.warrentyValidFrom || "",
            description: currentModel?.description || '',
            status: !(currentModel?.status),
            serviceName: currentModel.serviceName,
            serviceAmount: currentModel.serviceAmount,
            isSameAsBillingAddress: currentModel.isSameAsBillingAddress,
            bookingDetail: currentModel?.bookingDetail || { ...BookingDetail, isAllEnable: true } as IBookingDetail,
            bookingCustomer: currentModel?.bookingCustomer || { ...CustomerBooking, isBillingDetail: true, isCustomerDetail: true } as ICustomerBooking,
            billingCustomer: currentModel?.billingCustomer || { ...CustomerBooking, isOnlyBillingDetail: true } as ICustomerBooking,
            vehicleDetail: currentModel?.vehicleDetail || { ...VehicleDetail, isAllEnable: true } as IVehicleDetail
        }),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [currentModel]
    );

    useEffect(() => {
        getLookUp("VARIENT").then((x: any) => {
            if (x && x.data && x.data.length > 0) {
                console.log(x.data)
                setVarientLookUp(x.data);
            }
        });




        getLookUp("VISIT").then((x: any) => {
            if (x && x.data && x.data.length > 0) {
                console.log(x.data)
                setVisitLookUp(x.data);
            }
        });

    }, []);

    const methods = useForm<FormValuesProps>({
        resolver: yupResolver(NewUserSchema),
        defaultValues,
    });

    const {
        reset,
        control,
        setValue,
        handleSubmit, watch,
        formState: { isSubmitting },
    } = methods;

    const values = watch();

    const onSubmit = useCallback(
        async (data: FormValuesProps) => {
            try {
                await new Promise((resolve) => setTimeout(resolve, 500));
                reset();
                enqueueSnackbar(currentModel ? 'Update success!' : 'Create success!');
                router.push(paths.dashboard.user.list);
                console.info('DATA', data);
            } catch (error) {
                console.error(error);
            }
        },
        [currentModel, enqueueSnackbar, reset, router]
    );

    const onBookingDetailChange = useCallback(
        (bookingDetail: IBookingDetail) => {

        },
        []
    );

    const onCustomerDetailChange = useCallback(
        (customerDetail: ICustomerBooking) => {

        },
        []
    );

    const onVehicleDetailChange = useCallback(
        (vehicleDetail: IVehicleDetail) => {

        },
        []
    );

    const onTaxDetailChange = useCallback(
        (taxDetail: ITaxDetail) => {

        },
        []
    );

    const onIInsuranceDetailChange = useCallback(
        (insuranceDetail: IInsuranceDetail) => {

        },
        []
    );

    const onIFinanceDetailChange = useCallback(
        (finaceDetail: IFinanceDetail) => {

        },
        []
    );

    const onSchemeOffersChange = useCallback(
        (schemeOffers: ISchemesOffers) => {

        },
        []
    );

    const onExchangeChange = useCallback(
        (exchange: IExchange) => {

        },
        []
    );

    const onAddOnChange = useCallback(
        (addOn: IAddOn) => {

        },
        []
    );

    const onReferralsChange = useCallback(
        (referrals: IReferrals) => {

        },
        []
    );

    const onTestDriveChange = useCallback(
        (testDrive: ITestDrive) => {

        },
        []
    );


    const handleDrop = useCallback(
        (acceptedFiles: File[]) => {
            const file = acceptedFiles[0];

            const newFile = Object.assign(file, {
                preview: URL.createObjectURL(file),
            });

            if (file) {
                setValue('avatarUrl', newFile, { shouldValidate: true });
            }
        },
        [setValue]
    );

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleReset = () => {
        setActiveStep(0);
    };

    const renderRowElement = (step: any, index: any) => {
        switch (step.label) {
            case "Booking Details": return <Step key={step.label}>
                <StepLabel onClick={() => setActiveStep(index)}
                    optional={index === steps.length - 1 ? <Typography variant="caption">Last step</Typography> : null}
                >
                    {step.label}
                </StepLabel>
                <StepContent>
                    <Card sx={{ p: 3 }}>
                        <BookingDetailForm onChange={onBookingDetailChange} currentModel={defaultValues.bookingDetail} gridTemplateColumns={gridTemplateColumns} />
                    </Card>
                    <Box sx={{ mt: 3 }}>
                        <Button variant="contained" onClick={handleNext}>
                            {index === steps.length - 1 ? 'Finish' : 'Continue'}
                        </Button>
                        <Button disabled={index === 0} onClick={handleBack}>
                            Back
                        </Button>
                    </Box>
                </StepContent>
            </Step>
            case "Customer Detail": return <Step key={step.label}>
                <StepLabel onClick={() => setActiveStep(index)} optional={index === steps.length - 1 ? <Typography variant="caption">Last step</Typography> : null} >
                    <span style={{ float: "left", display: 'inline', marginTop: "8px" }}> {step.label}</span> <RHFSwitch style={{ display: "inline", float: 'right' }} name="isSameAsBillingAddress" label={t("Same as Billing Address")} />
                </StepLabel>
                <StepContent>
                    <CustomerBookingDetailForm onChange={onCustomerDetailChange} currentModel={defaultValues.bookingCustomer} gridTemplateColumns={gridTemplateColumns} />
                    <Box sx={{ mt: 3 }}>
                        <Button variant="contained" onClick={handleNext}>
                            {index === steps.length - 1 ? 'Finish' : 'Continue'}
                        </Button>
                        <Button disabled={index === 0} onClick={handleBack}>
                            Back
                        </Button>
                    </Box>
                </StepContent>
            </Step>
            case "Billing Detail" && values.isSameAsBillingAddress: return <Step key={step.label}>
                <StepLabel onClick={() => setActiveStep(index)} optional={index === steps.length - 1 ? <Typography variant="caption">Last step</Typography> : null} >
                    {step.label}
                </StepLabel>
                <StepContent>
                    <CustomerBookingDetailForm onChange={onCustomerDetailChange} currentModel={defaultValues.bookingCustomer} gridTemplateColumns={gridTemplateColumns} />
                    <Box sx={{ mt: 3 }}>
                        <Button variant="contained" onClick={handleNext}>
                            {index === steps.length - 1 ? 'Finish' : 'Continue'}
                        </Button>
                        <Button disabled={index === 0} onClick={handleBack}>
                            Back
                        </Button>
                    </Box>
                </StepContent>
            </Step>
            case "Vehicle Details": return <Step key={step.label}>
                <StepLabel onClick={() => setActiveStep(index)} optional={index === steps.length - 1 ? <Typography variant="caption">Last step</Typography> : null}>
                    {step.label}
                </StepLabel>
                <StepContent>
                    <VehicleDetailForm onChange={onVehicleDetailChange} gridTemplateColumns={gridTemplateColumns} currentModel={defaultValues.vehicleDetail} />
                    <Box sx={{ mt: 3 }}>
                        <Button variant="contained" onClick={handleNext}>
                            {index === steps.length - 1 ? 'Finish' : 'Continue'}
                        </Button>
                        <Button disabled={index === 0} onClick={handleBack}>
                            Back
                        </Button>
                    </Box>
                </StepContent>
            </Step>
            case "Schemes and Offers": return <Step key={step.label}>
                <StepLabel onClick={() => setActiveStep(index)}
                    optional={index === steps.length - 1 ? <Typography variant="caption">Last step</Typography> : null}
                >
                    {step.label}
                </StepLabel>
                <StepContent>
                    <Card sx={{ p: 3 }}>
                        <SchemeOffersForm onChange={onSchemeOffersChange} gridTemplateColumns={gridTemplateColumns} />
                    </Card>
                    <Box sx={{ mt: 3 }}>
                        <Button variant="contained" onClick={handleNext}>
                            {index === steps.length - 1 ? 'Finish' : 'Continue'}
                        </Button>
                        <Button disabled={index === 0} onClick={handleBack}>
                            Back
                        </Button>
                    </Box>
                </StepContent>
            </Step>
            case "Insurance Details": return <Step key={step.label}>
                <StepLabel onClick={() => setActiveStep(index)}
                    optional={index === steps.length - 1 ? <Typography variant="caption">Last step</Typography> : null}
                >
                    {step.label}
                </StepLabel>
                <StepContent>
                    <Card sx={{ p: 3 }}>
                        <Box rowGap={3} columnGap={2} display="grid" gridTemplateColumns={{ xs: 'repeat(1, 1fr)', sm: `repeat(${gridTemplateColumns}, 1fr)` }}>
                            <InsuranceDetailForm onChange={onIInsuranceDetailChange} />
                        </Box>
                    </Card>
                    <Box sx={{ mt: 3 }}>
                        <Button variant="contained" onClick={handleNext}>
                            {index === steps.length - 1 ? 'Finish' : 'Continue'}
                        </Button>
                        <Button disabled={index === 0} onClick={handleBack}>
                            Back
                        </Button>
                    </Box>
                </StepContent>
            </Step>
            case "Finance Details": return <Step key={step.label}>
                <StepLabel onClick={() => setActiveStep(index)}
                    optional={index === steps.length - 1 ? <Typography variant="caption">Last step</Typography> : null}
                >
                    {step.label}
                </StepLabel>
                <StepContent>
                    <Card sx={{ p: 3 }}>
                        <Box rowGap={3} columnGap={2} display="grid" gridTemplateColumns={{ xs: 'repeat(1, 1fr)', sm: `repeat(${gridTemplateColumns}, 1fr)` }}>
                            <FinanceDetailForm onChange={onIFinanceDetailChange} />
                        </Box>
                    </Card>
                    <Box sx={{ mt: 3 }}>
                        <Button variant="contained" onClick={handleNext}>
                            {index === steps.length - 1 ? 'Finish' : 'Continue'}
                        </Button>
                        <Button disabled={index === 0} onClick={handleBack}>
                            Back
                        </Button>
                    </Box>
                </StepContent>
            </Step>
            case "Exchange": return <Step key={step.label}>
                <StepLabel onClick={() => setActiveStep(index)}
                    optional={index === steps.length - 1 ? <Typography variant="caption">Last step</Typography> : null}
                >
                    {step.label}
                </StepLabel>
                <StepContent>
                    <ExchangeForm onChange={onExchangeChange} isLoyality='false' gridTemplateColumns={gridTemplateColumns} />
                    <Box sx={{ mt: 3 }}>
                        <Button variant="contained" onClick={handleNext}>
                            {index === steps.length - 1 ? 'Finish' : 'Continue'}
                        </Button>
                        <Button disabled={index === 0} onClick={handleBack}>
                            Back
                        </Button>
                    </Box>
                </StepContent>
            </Step>
            case "Add-Ons": return <Step key={step.label}>
                <StepLabel onClick={() => setActiveStep(index)}
                    optional={index === steps.length - 1 ? <Typography variant="caption">Last step</Typography> : null}
                >
                    {step.label}
                </StepLabel>
                <StepContent>
                    <AddOnForm onChange={onAddOnChange} gridTemplateColumns={gridTemplateColumns} />
                    <Box sx={{ mt: 3 }}>
                        <Button variant="contained" onClick={handleNext}>
                            {index === steps.length - 1 ? 'Finish' : 'Continue'}
                        </Button>
                        <Button disabled={index === 0} onClick={handleBack}>
                            Back
                        </Button>
                    </Box>
                </StepContent>
            </Step>
            case "Test Drive": return <Step key={step.label}>
                <StepLabel onClick={() => setActiveStep(index)}
                    optional={index === steps.length - 1 ? <Typography variant="caption">&nbsp;</Typography> : null}
                >
                    {step.label}
                </StepLabel>
                <StepContent>
                    <TestDriveForm onChange={onTestDriveChange} gridTemplateColumns={gridTemplateColumns} />
                    <Box sx={{ mt: 3 }}>
                        <Button variant="contained" onClick={handleNext}>
                            {index === steps.length - 1 ? 'Finish' : 'Continue'}
                        </Button>
                        <Button disabled={index === 0} onClick={handleBack}>
                            Back
                        </Button>
                    </Box>
                </StepContent>
            </Step>
            case "Finish": return <Step key={step.label}>
                <StepLabel onClick={() => setActiveStep(index)}
                    optional={index === steps.length - 1 ? <Typography variant="caption">Last step</Typography> : null}
                >
                    {step.label}
                </StepLabel>
                <StepContent>
                    <Card sx={{ p: 3 }}>
                        <Alert variant="outlined" style={{ backgroundColor: 'gray', color: '#ffffff' }} severity="success" sx={{ mb: 3 }}>
                            All steps completed - you&apos;re finished
                        </Alert>
                    </Card>
                </StepContent>
            </Step>
            default: return <>
            </>;
        }
    }

    return (
        <Container maxWidth={settings.themeStretch ? false : 'lg'}>
            <CustomBreadcrumbs
                heading={t("BOOKING.VIEW.VIEW_HEADING") as any}
                links={[
                    { name: t("DASHBOARD") as any, href: paths.dashboard.root },
                    {
                      name: t("BOOKING.VIEW.VIEW_NAME") as any,
                      href: paths.dashboard.salesJouryney.booking.root,
                    },
                ]}
                sx={{
                    mb: { xs: 3, md: 5 },
                }}
            />
            <Toolbar
                backLink={paths.dashboard.salesJouryney.booking.root}
                editLink={paths.dashboard.salesJouryney.booking.edit(`${selectedRow?.id}`)}
                liveLink={paths.dashboard.salesJouryney.booking.root}
                publish={id ? 'Update' : 'Add'}
                onChangePublish={() => { }}
                publishOptions={PUBLISH_OPTIONS}
            />
            <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
                {/* <Stepper activeStep={activeStep} sx={{ marginRight: "40px",width:"40%",float:"right",marginTop:"10px" }}>
                        {stepss.map((data, index) => {
                            const stepProps: { completed?: boolean } = {};
                            const labelProps: {
                                optional?: React.ReactNode;
                            } = {};
                            return (
                                <Step key={data.label} {...stepProps}>
                                    <StepLabel {...labelProps}>{data.label}</StepLabel>
                                </Step>
                            );
                        })}
                    </Stepper> */}
                <Grid container spacing={3}>
                    <Grid xs={12} md={3}>
                        <Card sx={{ pt: 5, pb: 3, px: 3 }}>
                            <RHFUploadAvatar
                                name="avatarUrl"
                                maxSize={3000000}
                                onDrop={handleDrop}
                                helperText={
                                    <Typography
                                        variant="caption"
                                        sx={{
                                            mt: 1,
                                            mx: 'auto',
                                            display: 'block',
                                            textAlign: 'center',
                                            color: 'text.disabled',
                                        }}
                                    >
                                        Allowed *.jpeg, *.jpg, *.png, *.gif
                                        <br /> max size of {fData(3000000)}
                                    </Typography>
                                }
                            />
                            <br />
                            <Table sx={{ maxWidth: 300 }}>
                                <TableBody>
                                    <TableRow>
                                        <TableCell style={{ padding: "4px", width: "118px" }}>
                                            <Typography variant="caption" sx={{ mb: 0.5 }}>  Customer Name: </Typography>
                                        </TableCell>
                                        <TableCell style={{ padding: "4px", width: "118px" }}>
                                            <Typography variant="caption" sx={{ mb: 0.5 }}>  Bindu Menon: </Typography>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell style={{ padding: "4px", width: "118px" }}>
                                            <Typography variant="caption" sx={{ mb: 0.5 }}> Mobile Number: </Typography>
                                        </TableCell>
                                        <TableCell style={{ padding: "4px", width: "118px" }}>
                                            <Typography variant="caption" sx={{ mb: 0.5 }}> (812)123-5466 </Typography>
                                        </TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>

                            <br />
                            <Typography variant="subtitle2" sx={{ mb: 0.5 }}>
                                Vehicle Details
                            </Typography>

                            <Table sx={{ maxWidth: 300 }}>
                                <TableBody>
                                    <TableRow>
                                        <TableCell style={{ padding: "4px", width: "112px", wordBreak: "break-word" }}>
                                            <Typography variant="caption" sx={{ mb: 0.5 }}>  Vehicle Model: </Typography>
                                        </TableCell>
                                        <TableCell style={{ padding: "4px", width: "112px", wordBreak: "break-word" }}>
                                            <Typography variant="caption" sx={{ mb: 0.5 }}>  Maruthi Brezza ZXI Black </Typography>
                                        </TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                            <br />
                            <RHFSwitch
                                name="isVerified"
                                labelPlacement="start"
                                label={
                                    <>
                                        <Typography variant="subtitle2" sx={{ mb: 0.5 }}>
                                            Email Verified
                                        </Typography>
                                        <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                                            Disabling this will automatically send the user a verification email
                                        </Typography>
                                    </>
                                }
                                sx={{ mx: 0, width: 1, justifyContent: 'space-between' }}
                            />

                        </Card>
                        <br />
                        <Grid xs={12} md={12}>
                            <AppFeatured list={_appFeatured} />
                        </Grid>
                    </Grid>
                    <Grid xs={12} md={9} >
                        <Stepper activeStep={activeStep} orientation="vertical">
                            {steps.map((step, index) => (
                                renderRowElement(step, index)
                            ))}
                        </Stepper>
                    </Grid>
                </Grid>
            </FormProvider>
        </Container>
    );
}
