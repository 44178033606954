import { useCallback } from "react";
import { deleteEnquiry } from "src/redux/slices/enquiry-management/enquiry";
import { useDispatch, useSelector } from "src/redux/store";
import { useRouter } from "src/routes/hook";

export default function useEnquiry() {
    const dispatch = useDispatch();
    const { enquiry,enquiryDelete,enquiryStatus} = useSelector(
        (state) => state.enquiry
    );

    const onDeleteEnquiry = useCallback(
        (id: string) => {
            dispatch(deleteEnquiry(id));
        },
        [dispatch]
    );

    return {
        enquiry,enquiryDelete,enquiryStatus,
        onDeleteEnquiry
    }
}