import { createSlice, Dispatch } from '@reduxjs/toolkit';
import { IGenderState } from 'src/types/general-settings/gender';
// utils
import axios, { API_ENDPOINTS } from 'src/utils/axios';

const initialState: IGenderState = {
    gender:[],
    genderStatus:{
        loading:false,
        empty:false,
        error:null
    },
    genderDelete: {
        loading:false,
        empty:false,
        error:null
    }
}

const slice = createSlice({
    name: 'gender',
    initialState,
    reducers:{
        getGenderStart(state) {
            state.genderStatus.loading=true;
            state.genderStatus.empty=false;
            state.genderStatus.error = null;
        },
        getGenderFailure(state, action) {
            state.genderStatus.loading=true;
            state.genderStatus.empty=false;
            state.genderStatus.error = action.payload;
        },
        getGenderSuccess(state, action) {
            const gender = action.payload;
            state.gender = gender;
            state.genderStatus.loading = false;
            state.genderStatus.empty = !gender.length;
            state.genderStatus.error = null;
        },
        deleteGenderStart(state) {
            state.genderDelete.loading=true;
            state.genderDelete.empty=false;
            state.genderDelete.error = null;
        },
        deleteGenderFailure(state, action) {
            state.genderDelete.loading=true;
            state.genderDelete.empty=false;
            state.genderDelete.error = action.payload;
        },
        deleteGenderSuccess(state, action) {
            const genderDelete = action.payload;
            state.genderDelete = genderDelete;
            state.genderDelete.loading = false;
            state.genderDelete.empty = action.payload;
            state.genderDelete.error = null;
        }
    }
});

export default slice.reducer;

// Actions
export const {
    getGenderStart,
    getGenderFailure,
    getGenderSuccess,
    deleteGenderStart,
    deleteGenderFailure,
    deleteGenderSuccess
} = slice.actions;
 

export function getGenders(data:any) {
    return async (dispatch: Dispatch) => {
        dispatch(slice.actions.getGenderStart());
        try {
            await axios.get(API_ENDPOINTS.GENERALSETTINGS.GENDER.LIST).then((res:any)=> {
                if(res.data.statusCode === 200)
                dispatch(slice.actions.getGenderSuccess(res.data.data));
            else 
                 dispatch(slice.actions.getGenderFailure([]));
            });
        }catch(error){
            dispatch(slice.actions.getGenderFailure(error));
        }
    }
}

export function deleteGender(id: any) {
return  axios.delete(`${API_ENDPOINTS.GENERALSETTINGS.GENDER.DELETE}/${id}`);
}

export function addUpdateGender(data: any) {
return axios.post(API_ENDPOINTS.GENERALSETTINGS.GENDER.ADDUPDATEGENDER,data);
}