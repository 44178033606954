import { Helmet } from "react-helmet-async";
import { useLocales } from "src/locales";


export default function MandalListPage() {
  const {t}=useLocales();
    return (
      <>
        <Helmet>
          <title> { t("BODYTYPE.PAGE.TITLE")}</title>
        </Helmet>
  
        {/* <BodyTypeListView /> */}
      </>
    );
  }