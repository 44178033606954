import { Helmet } from "react-helmet-async";
import { useLocales } from "src/locales";
import InsuranceCompanyListView from "src/sections/masters/general-settings/insurance-company/insurance-company-list-view";


export default function InsuranceCompanyListPage() {
  const {t}=useLocales();
    return (
      <>
        <Helmet>
          <title> { t("INSURANCECOMPANY.PAGE.TITLE")}</title>
        </Helmet>
        <InsuranceCompanyListView/>
      </>
    );
  }