import * as Yup from 'yup';
import { useCallback, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import LoadingButton from '@mui/lab/LoadingButton';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Unstable_Grid2';
// routes
import { paths } from 'src/routes/paths';
import { useRouter } from 'src/routes/hook';
// components
import { CustomFile } from 'src/components/upload';
import { useSnackbar } from 'src/components/snackbar';
import { IUsedVehicleModel } from 'src/types/used.vehicle.model';
import FormProvider, {
  RHFTextField,
  RHFUpload,
} from 'src/components/hook-form';
import { useLocales } from 'src/locales';
import { GLOBALCLASSINPUT } from 'src/utils/contants/config-common';
import { Dialog, DialogTitle, DialogContent, Alert, DialogActions } from '@mui/material';

// ----------------------------------------------------------------------

interface FormValuesProps extends Omit<IUsedVehicleModel, 'images'> {
  images: (CustomFile | string)[];
}

type Props = {
  title: string;
  open: boolean;
  onClose: VoidFunction;
  submitButtonText?: string;
  currentModel: IUsedVehicleModel;
};

export default function UsedVehicleModelForm({ currentModel, open, onClose, title, submitButtonText }: Props) {
  const router = useRouter();
  const { t } = useLocales();
  const { enqueueSnackbar } = useSnackbar();
  
  const NewUserSchema = Yup.object().shape({
    usedVehicleModelName: Yup.string().required(t('USEDVEHICLEMODEL.FORM.VEHICLECLASSNAME_REQUIRED') as any),
    varient: Yup.string().required(t('USEDVEHICLEMODEL.FORM.CLASS_REQUIRED') as any),
    manufacturer: Yup.string().required(t('USEDVEHICLEMODEL.FORM.YEAR_REQUIRED') as any),
    year: Yup.string().required(t('USEDVEHICLEMODEL.FORM.MANUFACTURER_REQUIRED') as any),
    color: Yup.string().required(t('USEDVEHICLEMODEL.FORM.MANUFACTURER_REQUIRED') as any),
    kmsDriven: Yup.string().required(t('USEDVEHICLEMODEL.FORM.MANUFACTURER_REQUIRED') as any),
    fuelType: Yup.string().required(t('USEDVEHICLEMODEL.FORM.MANUFACTURER_REQUIRED') as any),
    bodyType: Yup.string().required(t('USEDVEHICLEMODEL.FORM.MANUFACTURER_REQUIRED') as any),
    transmission: Yup.string().required(t('USEDVEHICLEMODEL.FORM.MANUFACTURER_REQUIRED') as any),
    engineSize: Yup.string().required(t('USEDVEHICLEMODEL.FORM.MANUFACTURER_REQUIRED') as any),
    vehicleNumber: Yup.string(),
    rto: Yup.string(),
    owners: Yup.string(),
    registeredIn: Yup.string(),
    chasisNumber: Yup.string(),
    engineNumber: Yup.string(),
    vehicleHistory: Yup.array(),
    vehicleStatus: Yup.string(),
    description: Yup.string(),
    varientDetails: Yup.array(),
    status: Yup.bool()
  });

  const defaultValues = useMemo(
    () => ({
      usedVehicleModelName: currentModel?.usedVehicleModelName || '',
      varient: currentModel?.varient || '',
      manufacturer: currentModel?.manufacturer || '',
      year: currentModel?.year || '',
      color: currentModel?.color || '',
      kmsDriven: currentModel?.kmsDriven || '',
      fuelType: currentModel?.fuelType || '',
      bodyType: currentModel?.bodyType || '',
      transmission: currentModel?.transmission || '',
      engineSize: currentModel?.engineSize || '',
      vehicleNumber: currentModel?.vehicleNumber || '',
      rto: currentModel?.rto || "",
      owners: currentModel?.owners || '',
      registeredIn: currentModel?.registeredIn || '',
      chasisNumber: currentModel?.chasisNumber || '',
      engineNumber: currentModel?.engineNumber || '',
      vehicleHistory: currentModel?.vehicleHistory || [],
      vehicleStatus: currentModel?.vehicleStatus || '',
      description: currentModel?.description || '',
      status: !(currentModel?.status),
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [currentModel]
  );

  const methods = useForm<FormValuesProps>({
    resolver: yupResolver(NewUserSchema),
    defaultValues,
  });

  const {
    reset,
    watch,
    setValue,
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const values = watch();

  const onSubmit = useCallback(
    async (data: FormValuesProps) => {
      try {
        await new Promise((resolve) => setTimeout(resolve, 500));
        reset();
        enqueueSnackbar(currentModel ? 'Update success!' : 'Create success!');
        router.push(paths.dashboard.user.list);
        console.info('DATA', data);
      } catch (error) {
        console.error(error);
      }
    },
    [currentModel, enqueueSnackbar, reset, router]
  );

  const handleDrop = useCallback(
    (acceptedFiles: File[]) => {
      const files = values.images || [];

      const newFiles = acceptedFiles.map((file) =>
        Object.assign(file, {
          preview: URL.createObjectURL(file),
        })
      );

      setValue('images', [...files, ...newFiles], { shouldValidate: true });
    },
    [setValue, values.images]
  );

  const handleRemoveFile = useCallback(
    (inputFile: File | string) => {
      const filtered = values.images && values.images?.filter((file) => file !== inputFile);
      setValue('images', filtered);
    },
    [setValue, values.images]
  );

  const handleRemoveAllFiles = useCallback(() => {
    setValue('images', []);
  }, [setValue]);

  return (
    
    <Dialog scroll="body"
    fullWidth
    maxWidth={false}
    open={open}
    onClose={() => null}
    PaperProps={{
        sx: { width:"80%" },
    }}
>
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>
            <Alert variant="outlined"  severity="error" sx={{ mb: 2 }}>
                {t('USEDVEHICLEMODEL.FORM.ALREADY_EXISTS_ERROR')}
            </Alert>
      <Grid container spacing={3}>
        <Grid xs={12} md={4}>
          <Card sx={{ pt: 5, pb: 3, px: 3 }}>
               <RHFUpload
                multiple
                thumbnail
                name="images"
                maxSize={3145728}
                onDrop={handleDrop}
                onRemove={handleRemoveFile}
                onRemoveAll={handleRemoveAllFiles}
                onUpload={() => console.info('ON UPLOAD')}
              />
          </Card>
        </Grid>
        <Grid xs={12} md={8}>
          <Card sx={{ p: 3 }}>
            <Box rowGap={3} columnGap={2} display="grid" gridTemplateColumns={{ xs: 'repeat(1, 1fr)', sm: 'repeat(2, 1fr)'}}>
              <RHFTextField size={GLOBALCLASSINPUT} name="newVehicleModelName" label={t('USEDVEHICLEMODEL.FORM.USEDVEHICLEMODELNAME') as any} />
              <RHFTextField size={GLOBALCLASSINPUT} name="class" label={t('USEDVEHICLEMODEL.FORM.VARIENT') as any} />
              <RHFTextField size={GLOBALCLASSINPUT} name="year" label={t('USEDVEHICLEMODEL.FORM.MANUFACTURER') as any} />
              <RHFTextField size={GLOBALCLASSINPUT} name="manufacturer" label={t('USEDVEHICLEMODEL.FORM.YEAR') as any} />
              <RHFTextField size={GLOBALCLASSINPUT} name="color" label={t('USEDVEHICLEMODEL.FORM.COLOR') as any} />
              <RHFTextField size={GLOBALCLASSINPUT} name="kmsDriven" label={t('USEDVEHICLEMODEL.FORM.KMSDRIVEN') as any} />
              <RHFTextField size={GLOBALCLASSINPUT} name="fuelType" label={t('USEDVEHICLEMODEL.FORM.FUELTYPE') as any} />
              <RHFTextField size={GLOBALCLASSINPUT} name="bodyType" label={t('USEDVEHICLEMODEL.FORM.BODYTYPE') as any} />
              <RHFTextField size={GLOBALCLASSINPUT} name="transmission" label={t('USEDVEHICLEMODEL.FORM.TRANSMISSION') as any} />
              <RHFTextField size={GLOBALCLASSINPUT} name="engineSize" label={t('USEDVEHICLEMODEL.FORM.ENGINESIZE') as any} />
              </Box>
            </Card>
        </Grid>
        <Grid xs={12} md={12}>
          <Card sx={{ p: 3 }}>
            <Box rowGap={3} columnGap={3} display="grid" gridTemplateColumns={{ xs: 'repeat(1, 1fr)', sm: 'repeat(3, 1fr)'}}>
              <RHFTextField size={GLOBALCLASSINPUT} name="vehicleNumber" label={t('USEDVEHICLEMODEL.FORM.VEHICLENUMBER') as any} />
              <RHFTextField size={GLOBALCLASSINPUT} name="rto" label={t('USEDVEHICLEMODEL.FORM.RTO') as any} />
              <RHFTextField size={GLOBALCLASSINPUT} name="owners" label={t('USEDVEHICLEMODEL.FORM.OWNERS') as any} />
              <RHFTextField size={GLOBALCLASSINPUT} name="registeredIn" label={t('USEDVEHICLEMODEL.FORM.REGISTEREDIN') as any} />
              <RHFTextField size={GLOBALCLASSINPUT} name="chasisNumber" label={t('USEDVEHICLEMODEL.FORM.CHASISNUMBER') as any} />
              <RHFTextField size={GLOBALCLASSINPUT} name="engineSize" label={t('USEDVEHICLEMODEL.FORM.ENGINENUMBER') as any} />
              <RHFTextField size={GLOBALCLASSINPUT} name="owners" label={t('USEDVEHICLEMODEL.FORM.OWNERS') as any} />
            </Box>
            </Card>
        </Grid>
      </Grid>
      </DialogContent>
                <DialogActions>
                    <Button variant="outlined" onClick={onClose}>
                        {t("CANCEL")}
                    </Button>
                    <LoadingButton type="submit" variant="contained" loading={isSubmitting}>
                        {submitButtonText}
                    </LoadingButton>
                </DialogActions>
            </FormProvider>
        </Dialog>
  );
}
