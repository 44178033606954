import { createSlice, Dispatch } from '@reduxjs/toolkit';
import { IAllotmentState } from 'src/types/sales-journey/allotment';
// utils
import axios, { API_ENDPOINTS } from 'src/utils/axios';

const initialState: IAllotmentState = {
    allotment:[],
    allotmentStatus:{
        loading:false,
        empty:false,
        error:null
    },
    allotmentDelete: {
        loading:false,
        empty:false,
        error:null
    }
}

const slice = createSlice({
    name: 'allotment',
    initialState,
    reducers:{
        getAllotmentStart(state) {
            state.allotmentStatus.loading=true;
            state.allotmentStatus.empty=false;
            state.allotmentStatus.error = null;
        },
        getAllotmentFailure(state, action) {
            state.allotmentStatus.loading=true;
            state.allotmentStatus.empty=false;
            state.allotmentStatus.error = action.payload;
        },
        getAllotmentSuccess(state, action) {
            const allotment = action.payload;
            state.allotment = allotment;
            state.allotmentStatus.loading = false;
            state.allotmentStatus.empty = !allotment.length;
            state.allotmentStatus.error = null;
        },
        deleteAllotmentStart(state) {
            state.allotmentDelete.loading=true;
            state.allotmentDelete.empty=false;
            state.allotmentDelete.error = null;
        },
        deleteAllotmentFailure(state, action) {
            state.allotmentDelete.loading=true;
            state.allotmentDelete.empty=false;
            state.allotmentDelete.error = action.payload;
        },
        deleteAllotmentSuccess(state, action) {
            const allotmentDelete = action.payload;
            state.allotmentDelete = allotmentDelete;
            state.allotmentDelete.loading = false;
            state.allotmentDelete.empty = action.payload;
            state.allotmentDelete.error = null;
        }
    }
});

export default slice.reducer;

// Actions
export const {
    getAllotmentStart,
    getAllotmentFailure,
    getAllotmentSuccess,
    deleteAllotmentStart,
    deleteAllotmentFailure,
    deleteAllotmentSuccess
} = slice.actions;
 

export function getAllotments(data:any) {
    return async (dispatch: Dispatch) => {
        dispatch(slice.actions.getAllotmentStart());
        try {
            await axios.get(API_ENDPOINTS.SALESJOURNEY.ALLOTMENT.LIST).then((res:any)=> {
                if(res.data.statusCode === 200)
                dispatch(slice.actions.getAllotmentSuccess(res.data.data));
            else 
                 dispatch(slice.actions.getAllotmentFailure([]));
            });
        }catch(error){
            dispatch(slice.actions.getAllotmentFailure(error));
        }
    }
}

export function deleteAllotment(id: any) {
return  axios.delete(`${API_ENDPOINTS.GENERALSETTINGS.BANKNAME.DELETE}/${id}`);
}

export function addUpdateAllotment(data: any) {
return axios.post(API_ENDPOINTS.GENERALSETTINGS.BANKNAME.ADDUPDATEBANKNAME,data);
}