import * as Yup from 'yup';
import { useSnackbar } from "src/components/snackbar";
import { CustomFile } from "src/components/upload";
import { useCallback, useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import FormProvider, { RHFCode, RHFSelect, RHFSwitch, RHFTextField, RHFUploadAvatar } from 'src/components/hook-form';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import LoadingButton from '@mui/lab/LoadingButton';
import { Accordion, AccordionDetails, AccordionSummary, Box, Card, Dialog, DialogTitle, Divider, Grid, IconButton, MenuItem, Stack, Step, StepContent, StepLabel, Stepper, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material';
import { IInvoice } from 'src/types/sales-journey/invoice';
import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import { useLocales } from 'src/locales';
import { GLOBALCLASSINPUT } from 'src/utils/contants/config-common';
import { addUpdateInvoice } from 'src/redux/slices/sales-journey/invoice';
import { CloseIcon } from 'src/components/lightbox';
import { fData } from 'src/utils/format-number';
import { DatePicker } from '@mui/x-date-pickers';
import { TABLE_HEAD_FORM_ALLOTMENT } from 'src/utils/contants/sales-journey/allotment';
import { BookingDetail, CustomerBooking, IBookingDetail, ICustomerBooking, ICustomerDetail, IExchange, IFinanceDetail, IInsuranceDetail, IReferrals, ISchemesOffers, IVehicleDetail, VehicleDetail } from 'src/types/common';
import Iconify from 'src/components/iconify/iconify';
import BookingDetailForm from 'src/sections/_common-components/booking-detail';
import CustomerBookingDetailForm from 'src/sections/_common-components/customer-booking-detail';
import VehicleDetailForm from 'src/sections/_common-components/vehicle-details';
import SchemeOffersForm from 'src/sections/_common-components/SchemeOffersForm';
import InsuranceDetailForm from 'src/sections/_common-components/insurance-detail';
import FinanceDetailForm from 'src/sections/_common-components/finance-detail';
import ExchangeForm from 'src/sections/_common-components/exchange';
import ReferralsForm from 'src/sections/_common-components/referrals';

interface FormValuesProps extends Omit<IInvoice, 'avatarUrl'> {
    avatarUrl: CustomFile | string | null;
}

type Props = {
    title: string;
    open: boolean;
    onClose: VoidFunction;
    submitButtonText?: string;
    currentModel: IInvoice;
};

export default function InvoiceForm({ currentModel, open, onClose, title, submitButtonText }: Props) {
    const { enqueueSnackbar } = useSnackbar();
    const { t } = useLocales();
    const [showErrorMsg, setShowErrorMsg] = useState(false as any);
    const [controlled, setControlled] = useState<string | false>("BOOKINGDETAILS");
    const [gridTemplateColumns, setGridTemplateColumns] = useState(4);
    const [activeStep, setActiveStep] = useState(0);

    const steps = [
        {
            label: 'Invoice Details',
            description: `For each ad campaign that you create, you can control how much
                    you're willing to spend on clicks and conversions, which networks
                    and geographical locations you want your ads to show on, and more.`,
        },
        {
            label: 'Customer Detail',
            description: 'An ad group contains one or more ads which target a shared set of keywords.',
        },
        {
            label: 'Vehicle Details',
            description: `Try out different ad text to see what brings in the most customers,
                    and learn how to enhance your ads using features like ad extensions.
                    If you run into any problems with your ads, find out how to tell if
                    they're running and how to resolve approval issues.`,
        },
        {
            label: 'Schemes and Offers',
            description: `Try out different ad text to see what brings in the most customers,
                    and learn how to enhance your ads using features like ad extensions.
                    If you run into any problems with your ads, find out how to tell if
                    they're running and how to resolve approval issues.`,
        },
        {
            label: 'Insurance Details',
            description: `Try out different ad text to see what brings in the most customers,
                    and learn how to enhance your ads using features like ad extensions.
                    If you run into any problems with your ads, find out how to tell if
                    they're running and how to resolve approval issues.`,
        },
        {
            label: 'Finance Details',
            description: `Try out different ad text to see what brings in the most customers,
                    and learn how to enhance your ads using features like ad extensions.
                    If you run into any problems with your ads, find out how to tell if
                    they're running and how to resolve approval issues.`,
        },
        {
            label: 'Exchange',
            description: `Try out different ad text to see what brings in the most customers,
                    and learn how to enhance your ads using features like ad extensions.
                    If you run into any problems with your ads, find out how to tell if
                    they're running and how to resolve approval issues.`,
        },
        {
            label: 'Referrals',
            description: `Try out different ad text to see what brings in the most customers,
                    and learn how to enhance your ads using features like ad extensions.
                    If you run into any problems with your ads, find out how to tell if
                    they're running and how to resolve approval issues.`,
        },
        {
            label: 'Finish',
            description: `Try out different ad text to see what brings in the most customers,
                    and learn how to enhance your ads using features like ad extensions.
                    If you run into any problems with your ads, find out how to tell if
                    they're running and how to resolve approval issues.`,
        },
    ];

    const NewModelSchema = Yup.object().shape({
        search: Yup.string(),
        taxCalculation: Yup.string(),
        bookingDate: Yup.string(),
        taxPayableOnReverseCharge: Yup.string(),
        bookingDetail: Yup.object(),
        bookingCustomer: Yup.object(),
        billingCustomer: Yup.object(),
        vehicleDetail: Yup.object()
    });

    const defaultValues = useMemo(() => ({
        search: currentModel?.search || "",
        taxCalculation: currentModel?.taxCalculation || "",
        bookingDate: currentModel?.taxCalculation || "",
        taxPayableOnReverseCharge: currentModel?.taxPayableOnReverseCharge || "",
        bookingDetail: currentModel?.bookingDetail || {...BookingDetail,isSalesExecutiveId:true,isTeamLeaderId:true,isAllEnable:true,isBoxRequired:true},
        bookingCustomer: currentModel?.bookingCustomer || {...CustomerBooking,isBillingDetail:true,isCustomerDetail:true} as ICustomerBooking,
        billingCustomer: currentModel?.billingCustomer || CustomerBooking,
        vehicleDetail: currentModel?.vehicleDetail || {...VehicleDetail,isModelFamilyId:true,isModelCodeId:true,isVinNumber:true,isSeatingCapacityId:true,isSales
            :true,isPriceTypeId:true,isVehicleBasicPrice:true,isVehicleOnRoadPrice:true,isDealerDiscount:true} as IVehicleDetail,
        id: currentModel?.id || 0
    }), [currentModel]);

    const handleChangeControlled =
        (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
            setControlled(isExpanded ? panel : false);
        };

    const onBookingDetailChange = useCallback(
        (bookingDetail: IBookingDetail) => {

        },
        []
    );

    const onCustomerDetailChange = useCallback(
        (customerDetail: ICustomerBooking) => {

        },
        []
    );
    
    const onVehicleDetailChange = useCallback(
        (vehicleDetail: IVehicleDetail) => {

        },
        []
    );

    const onSchemeOffersChange = useCallback(
        (schemeOffers: ISchemesOffers) => {

        },
        []
    );

    const onIInsuranceDetailChange = useCallback(
        (insuranceDetail: IInsuranceDetail) => {

        },
        []
    );

    const onIFinanceDetailChange = useCallback(
        (finaceDetail: IFinanceDetail) => {

        },
        []
    );

    const onExchangeChange = useCallback(
        (exchange: IExchange) => {

        },
        []
    );

    const onReferralsChange = useCallback(
        (referrals: IReferrals) => {

        },
        []
    );
    
    const methods = useForm<FormValuesProps>({
        resolver: yupResolver(NewModelSchema),
        defaultValues
    });

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleReset = () => {
        setActiveStep(0);
    };

    const {
        reset,
        handleSubmit, control,watch,
        formState: { isSubmitting },
    } = methods;
    
    const values = watch();

    const renderRowElement = (step: any, index: any) => {
        switch (step.label) {
            case "Invoice Details": return <Step key={step.label}>
                <StepLabel onClick={() => setActiveStep(index)}
                    optional={index === steps.length-1 ? <Typography variant="caption">Last step</Typography> : null}
                >
                    {step.label}
                </StepLabel>
                <StepContent>
                <Card sx={{ p: 3 }}>
                <Box rowGap={3} columnGap={2} display="grid" gridTemplateColumns={{ xs: 'repeat(1, 1fr)', sm: 'repeat(1, 1fr)' }}>
                        <RHFTextField size={GLOBALCLASSINPUT} fullWidth name="searchBar" label={t('COMMON.SEARCHBAR') as any} />
                    </Box>
                    <br />
                    <Box rowGap={3} columnGap={2} display="grid" gridTemplateColumns={{ xs: 'repeat(1, 1fr)', sm: `repeat(${3}, 1fr)` }}>
                    <RHFTextField size={GLOBALCLASSINPUT} fullWidth name="" label={t('') as any} />
                            <Controller
                                name="bookingDate"
                                control={control}
                                render={({ field, fieldState: { error } }) => (
                                    <DatePicker
                                        label={t('COMMON.BOOKINGDATE') as any}
                                        value={field.value}
                                        onChange={(newValue) => {
                                            field.onChange(newValue);
                                        }}
                                        format="dd-MMM-yyyy"
                                        slotProps={{
                                            textField: {
                                                size: 'small',
                                                error: !!error,
                                                helperText: error?.message,
                                            },
                                        }}
                                    />
                                )}
                            />
                    <RHFTextField size={GLOBALCLASSINPUT} fullWidth name="taxCalcunlation" label={t('COMMON.TAXCALCULATION') as any} />
                    <RHFTextField size={GLOBALCLASSINPUT} fullWidth name="taxpayableonreversecharge" label={t('COMMON.TAXPAYABLEONREVERSECHARGE') as any} />
                    <BookingDetailForm onChange={onBookingDetailChange} currentModel={defaultValues.bookingDetail}  gridTemplateColumns={gridTemplateColumns} />
                    </Box>
                    <br />
                    
                    </Card>
                    <Box sx={{ mt: 3 }}>
                        <Button variant="contained" onClick={handleNext}>
                            {index === steps.length-1 ? 'Finish' : 'Continue'}
                        </Button>
                        <Button disabled={index === 0} onClick={handleBack}>
                            Back
                        </Button>
                    </Box>
                </StepContent>
            </Step>
            case "Customer Detail": return <Step key={step.label}>
                <StepLabel onClick={() => setActiveStep(index)} optional={index === steps.length-1 ? <Typography variant="caption">Last step</Typography> : null} >
                   <span style={{float:"left",display:'inline',marginTop:"8px"}}> {step.label}</span> <RHFSwitch style={{display:"inline",float:'right'}} name="isSameAsBillingAddress"  label={t("Same as Billing Address")}  />
                </StepLabel>
                <StepContent>
                    <CustomerBookingDetailForm onChange={onCustomerDetailChange} currentModel={defaultValues.bookingCustomer} gridTemplateColumns={gridTemplateColumns} />
                    <Box sx={{ mt: 3 }}>
                        <Button variant="contained" onClick={handleNext}>
                            {index === steps.length-1 ? 'Finish' : 'Continue'}
                        </Button>
                        <Button disabled={index === 0} onClick={handleBack}>
                            Back
                        </Button>
                    </Box>
                </StepContent>
            </Step>
            case "Vehicle Details": return <Step key={step.label}>
                <StepLabel onClick={() => setActiveStep(index)} optional={index === steps.length-1  ? <Typography variant="caption">Last step</Typography> : null}>
                    {step.label}
                </StepLabel>
                <StepContent>
                  
                        <VehicleDetailForm onChange={onVehicleDetailChange} gridTemplateColumns={gridTemplateColumns} currentModel={defaultValues.vehicleDetail}/>
                    <Box sx={{ mt: 3 }}>
                        <Button variant="contained" onClick={handleNext}>
                            {index === steps.length-1 ? 'Finish' : 'Continue'}
                        </Button>
                        <Button disabled={index === 0} onClick={handleBack}>
                            Back
                        </Button>
                    </Box>
                </StepContent>
            </Step>
            case "Schemes and Offers": return <Step key={step.label}>
                <StepLabel onClick={() => setActiveStep(index)}
                    optional={index === steps.length-1  ? <Typography variant="caption">Last step</Typography> : null}
                >
                    {step.label}
                </StepLabel>
                <StepContent>
                    <Card sx={{ p: 3 }}>
                            <SchemeOffersForm onChange={onSchemeOffersChange} gridTemplateColumns={gridTemplateColumns}/>
                    </Card>
                    <Box sx={{ mt: 3 }}>
                        <Button variant="contained" onClick={handleNext}>
                            {index === steps.length-1 ? 'Finish' : 'Continue'}
                        </Button>
                        <Button disabled={index === 0} onClick={handleBack}>
                            Back
                        </Button>
                    </Box>
                </StepContent>
            </Step>
            case "Insurance Details": return <Step key={step.label}>
                <StepLabel onClick={() => setActiveStep(index)}
                    optional={index === steps.length-1  ? <Typography variant="caption">Last step</Typography> : null}
                >
                    {step.label}
                </StepLabel>
                <StepContent>
                    <Card sx={{ p: 3 }}>
                        <Box rowGap={3} columnGap={2} display="grid" gridTemplateColumns={{ xs: 'repeat(1, 1fr)', sm: `repeat(${gridTemplateColumns}, 1fr)` }}>
                            <InsuranceDetailForm onChange={onIInsuranceDetailChange} />
                        </Box>
                    </Card>
                    <Box sx={{ mt: 3 }}>
                        <Button variant="contained" onClick={handleNext}>
                            {index === steps.length-1 ? 'Finish' : 'Continue'}
                        </Button>
                        <Button disabled={index === 0} onClick={handleBack}>
                            Back
                        </Button>
                    </Box>
                </StepContent>
            </Step>
            case "Finance Details": return <Step key={step.label}>
                <StepLabel onClick={() => setActiveStep(index)}
                    optional={index === steps.length-1 ? <Typography variant="caption">Last step</Typography> : null}
                >
                    {step.label}
                </StepLabel>
                <StepContent>
                    <Card sx={{ p: 3 }}>
                        <Box rowGap={3} columnGap={2} display="grid" gridTemplateColumns={{ xs: 'repeat(1, 1fr)', sm: `repeat(${gridTemplateColumns}, 1fr)` }}>
                            <FinanceDetailForm onChange={onIFinanceDetailChange} />
                        </Box>
                    </Card>
                    <Box sx={{ mt: 3 }}>
                        <Button variant="contained" onClick={handleNext}>
                            {index === steps.length-1 ? 'Finish' : 'Continue'}
                        </Button>
                        <Button disabled={index === 0} onClick={handleBack}>
                            Back
                        </Button>
                    </Box>
                </StepContent>
            </Step>
            case "Exchange": return <Step key={step.label}>
                <StepLabel onClick={() => setActiveStep(index)}
                    optional={index === steps.length-1? <Typography variant="caption">Last step</Typography> : null}
                >
                    {step.label}
                </StepLabel>
                <StepContent>
                        <ExchangeForm onChange={onExchangeChange} isLoyality='false' gridTemplateColumns={gridTemplateColumns}/>
                    <Box sx={{ mt: 3 }}>
                        <Button variant="contained" onClick={handleNext}>
                            {index === steps.length-1 ? 'Finish' : 'Continue'}
                        </Button>
                        <Button disabled={index === 0} onClick={handleBack}>
                            Back
                        </Button>
                    </Box>
                </StepContent>
            </Step>
               case "Referrals": return <Step key={step.label}>
               <StepLabel onClick={() => setActiveStep(index)}
                   optional={index === steps.length - 1 ? <Typography variant="caption">Last step</Typography> : null}
               >
                   {step.label}
               </StepLabel>
               <StepContent>
                   <Card sx={{ p: 3 }}>
                       <Box rowGap={3} columnGap={2} display="grid" gridTemplateColumns={{ xs: 'repeat(1, 1fr)', sm: `repeat(${gridTemplateColumns}, 1fr)` }}>
                           <ReferralsForm onChange={onReferralsChange} />
                       </Box>
                   </Card>
                   <Box sx={{ mt: 3 }}>
                       <Button variant="contained" onClick={handleNext}>
                           {index === steps.length - 1 ? 'Finish' : 'Continue'}
                       </Button>
                       <Button disabled={index === 0} onClick={handleBack}>
                           Back
                       </Button>
                   </Box>
               </StepContent>
           </Step>
            case "Finish": return <Step key={step.label}>
                <StepLabel onClick={() => setActiveStep(index)}
                    optional={index === steps.length-1 ? <Typography variant="caption">Last step</Typography> : null}
                >
                    {step.label}
                </StepLabel>
                <StepContent>
                    <Card sx={{ p: 3 }}>
                        <Alert variant="outlined" style={{ backgroundColor: 'gray', color: '#ffffff' }} severity="success" sx={{ mb: 3 }}>
                            All steps completed - you&apos;re finished
                        </Alert>
                    </Card>
                </StepContent>
            </Step>
            default: return <>
            </>;
        }
    }
    
    const onSubmit = useCallback(
        async (data: FormValuesProps) => {
            try {
                addUpdateInvoice({ ...data }).then((res: any) => {
                    console.log(res);
                    if (res.data.data > 0 && res.data.statusCode === 200) {
                        reset();
                        onClose();
                        enqueueSnackbar(t('INVOICE.FORM.UPDATE_SUCCESS') as any);
                    } else {
                        setShowErrorMsg(true);
                    }
                });
                console.info('DATA', data);
            } catch (error) {
                console.error(error);
            }
        }, [enqueueSnackbar, onClose, reset, t]);

    return (
        <Dialog scroll='body' fullWidth
            maxWidth="xl"
            open={open}
            onClose={() => null}
            PaperProps={{
                sx: { maxWidth: 720 },
            }}
        >
            <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
                <DialogTitle style={{ lineHeight: "1" }}>{title}  <IconButton style={{ float: 'right' }} onClick={onClose}>
                    <CloseIcon />
                </IconButton></DialogTitle>
                <DialogContent style={{ maxHeight: "200", overflow: "hidden", clear: "both" }}>
                    {showErrorMsg && <Alert variant="outlined" severity="error" sx={{ mb: 3 }}>
                        {t('INVOICE.FORM.ALREADY_EXISTS_ERROR')}
                    </Alert>}
                    <Grid container spacing={3} style={{ padding: "25px" }}>
                        <Grid xs={12} md={3}>
                            <Card sx={{ pt: 5, pb: 3, px: 3 }}>
                                <RHFUploadAvatar
                                    name="avatarUrl"
                                    maxSize={3000000}
                                    helperText={
                                        <Typography
                                            variant="caption"
                                            sx={{
                                                mt: 1,
                                                mx: 'auto',
                                                display: 'block',
                                                textAlign: 'center',
                                                color: 'text.disabled',
                                            }}
                                        >
                                            Allowed *.jpeg, *.jpg, *.png, *.gif
                                            <br /> max size of {fData(3000000)}
                                        </Typography>
                                    }
                                />
                                <br />
                                <Table sx={{ maxWidth: 300 }}>
                                    <TableBody>
                                        <TableRow>
                                            <TableCell style={{ padding: "4px", width: "118px" }}>
                                                <Typography variant="caption" sx={{ mb: 0.5 }}>  Customer Name: </Typography>
                                            </TableCell>
                                            <TableCell style={{ padding: "4px", width: "118px" }}>
                                                <Typography variant="caption" sx={{ mb: 0.5 }}>  Raghu Varan </Typography>
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell style={{ padding: "4px", width: "118px" }}>
                                                <Typography variant="caption" sx={{ mb: 0.5 }}> Customer ID: </Typography>
                                            </TableCell>
                                            <TableCell style={{ padding: "4px", width: "118px" }}>
                                                <Typography variant="caption" sx={{ mb: 0.5 }}> 23546 </Typography>
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell style={{ padding: "4px", width: "118px" }}>
                                                <Typography variant="caption" sx={{ mb: 0.5 }}> Invoice Date: </Typography>
                                            </TableCell>
                                            <TableCell style={{ padding: "4px", width: "118px" }}>
                                                <Typography variant="caption" sx={{ mb: 0.5 }}> 20-DEC-2023 </Typography>
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell style={{ padding: "4px", width: "118px" }}>
                                                <Typography variant="caption" sx={{ mb: 0.5 }}> Invoice Number: </Typography>
                                            </TableCell>
                                            <TableCell style={{ padding: "4px", width: "118px" }}>
                                                <Typography variant="caption" sx={{ mb: 0.5 }}> INV20122013 </Typography>
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell style={{ padding: "4px", width: "118px" }}>
                                                <Typography variant="caption" sx={{ mb: 0.5 }}> Invoice Status: </Typography>
                                            </TableCell>
                                            <TableCell style={{ padding: "4px", width: "118px" }}>
                                                <Typography variant="caption" sx={{ mb: 0.5 }}> 25-DEC-2023 </Typography>
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell style={{ padding: "4px", width: "118px" }}>
                                                <Typography variant="caption" sx={{ mb: 0.5 }}> Booking Number: </Typography>
                                            </TableCell>
                                            <TableCell style={{ padding: "4px", width: "118px" }}>
                                                <Typography variant="caption" sx={{ mb: 0.5 }}> B23456321 </Typography>
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell style={{ padding: "4px", width: "118px" }}>
                                                <Typography variant="caption" sx={{ mb: 0.5 }}> Booking Date: </Typography>
                                            </TableCell>
                                            <TableCell style={{ padding: "4px", width: "118px" }}>
                                                <Typography variant="caption" sx={{ mb: 0.5 }}> 25-DEC-2023 </Typography>
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>

                                <br />
                                <Typography variant="subtitle2" sx={{ mb: 0.5 }}>
                                    Vehicle Details
                                </Typography>

                                <Table sx={{ maxWidth: 300 }}>
                                    <TableBody>
                                        <TableRow>
                                            <TableCell style={{ padding: "4px", width: "112px", wordBreak: "break-word" }}>
                                                <Typography variant="caption" sx={{ mb: 0.5 }}>  Vehicle Model: </Typography>
                                            </TableCell>
                                            <TableCell style={{ padding: "4px", width: "112px", wordBreak: "break-word" }}>
                                                <Typography variant="caption" sx={{ mb: 0.5 }}>  Maruthi Brezza ZXI Black </Typography>
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                                <br />
                                <RHFSwitch name="isVerified" labelPlacement="start" label={
                                    <>
                                        <Typography variant="subtitle2" sx={{ mb: 0.5 }}>
                                            Email Verified
                                        </Typography>
                                        <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                                            Disabling this will automatically send the user a verification email
                                        </Typography>
                                    </>
                                }
                                    sx={{ mx: 0, width: 1, justifyContent: 'space-between' }}
                                />
                            </Card>
                        </Grid>
                        <Grid xs={12} md={9} style={{ paddingLeft: "18px" }}>
                        <Stepper activeStep={activeStep} orientation="vertical">
                                {steps.map((step, index) => (
                                    renderRowElement(step, index)
                                ))}
                            </Stepper>
                            {/* <Accordion key='BOOKINGDETAILS'
                                expanded={controlled === 'BOOKINGDETAILS'}
                                onChange={handleChangeControlled('BOOKINGDETAILS')}>
                                <AccordionSummary expandIcon={<Iconify icon="eva:arrow-ios-downward-fill" />}>
                                    <Typography variant="subtitle1" style={{ width: "100%" }}>Customer Details </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Box rowGap={2} columnGap={2} display="grid" gridTemplateColumns={{ xs: 'repeat(1, 1fr)', sm: `repeat(${gridTemplateColumns}, 1fr)` }}>
                                        <BookingDetailForm onChange={onBookingDetailChange} />
                                    </Box>
                                </AccordionDetails>
                            </Accordion>
                            <Accordion key='BOOKINGCUSTOMER'
                                expanded={controlled === 'BOOKINGCUSTOMER'}
                                onChange={handleChangeControlled('BOOKINGCUSTOMER')}>
                                <AccordionSummary expandIcon={<Iconify icon="eva:arrow-ios-downward-fill" />}>
                                    <Typography variant="subtitle1" style={{ width: "100%" }}>Booking Customer </Typography>
                                </AccordionSummary>
                                <CustomerBookingDetailForm onChange={onCustomerDetailChange} gridTemplateColumns={gridTemplateColumns} />
                            </Accordion>
                            <Accordion key='VEHICLEDETAILS'
                                expanded={controlled === 'VEHICLEDETAILS'}
                                onChange={handleChangeControlled('VEHICLEDETAILS')}>
                                <AccordionSummary expandIcon={<Iconify icon="eva:arrow-ios-downward-fill" />}>
                                    <Typography variant="subtitle1" style={{ width: "100%" }}>Vehicle Details </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                <VehicleDetailForm onChange={onVehicleDetailChange}   gridTemplateColumns={gridTemplateColumns}/> 
                                </AccordionDetails>
                            </Accordion> */}
                            <br />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button variant="outlined" onClick={onClose}>
                        {t("CANCEL")}
                    </Button>
                    <LoadingButton type="submit" variant="contained" loading={isSubmitting}>
                        {submitButtonText}
                    </LoadingButton>
                </DialogActions>
            </FormProvider>
        </Dialog>
    )

}