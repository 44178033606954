export const NEWVEHICLEMODEL_COLUMNS = ["img","vehicleName","year","specification","status"];

export const TABLE_HEAD_NEWVEHICLEMODEL = [
    { id: 'img', label: "" },
    { id: 'vehicleName', label: "NEWVEHICLEMODEL.VIEW.GRID.VEHICLENAME" },
    { id: 'year', label: "NEWVEHICLEMODEL.VIEW.GRID.YEAR" },
    { id: 'specification', label: "NEWVEHICLEMODEL.VIEW.GRID.SPECIFICATION" },
    { id: 'status', label: "NEWVEHICLEMODEL.VIEW.GRID.STATUS" },
    { id: 'id', width: 88 },
  ];

export const defaultFilters = {
    name: '',
    status: [],
  };
  