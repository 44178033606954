import * as Yup from 'yup';
import { useSnackbar } from "src/components/snackbar";
import { CustomFile } from "src/components/upload";
import { useCallback, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import FormProvider, { RHFSwitch, RHFTextField } from 'src/components/hook-form';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import LoadingButton from '@mui/lab/LoadingButton';
import { Dialog, DialogTitle, Stack } from '@mui/material';
import { IVehicleHistory } from 'src/types/vehicle-settings/vehicle.history';
import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import { useLocales } from 'src/locales';
import { GLOBALCLASSINPUT } from 'src/utils/contants/config-common';
import { addUpdateVehicleHistory } from 'src/redux/slices/vehicle-settings/vehicle.history';

interface FormValuesProps extends Omit<IVehicleHistory, 'avatarUrl'> {
    avatarUrl: CustomFile | string | null;
}

type Props = {
    title: string;
    open: boolean;
    onClose: VoidFunction;
    submitButtonText?: string;
    currentModel: IVehicleHistory;
};

export default function VehicleHistoryForm({ currentModel, open, onClose, title, submitButtonText }: Props) {
    const { enqueueSnackbar } = useSnackbar();
    const { t } = useLocales();
    const [showErrorMsg, setShowErrorMsg] = useState(false as any);
    const NewModelSchema = Yup.object().shape({
        name: Yup.string().required(t('VEHICLEHISTORY.FORM.NAME_REQUIRED') as any),
        description: Yup.string(),
        displayOrder: Yup.number(),
        status: Yup.bool(),
    });

    const defaultValues = useMemo(() => ({
        name: currentModel?.name || "",
        description: currentModel?.description || "",
        status: !(currentModel?.status),
        displayOrder: (currentModel?.displayOrder) || 0,
        id: currentModel?.id || 0
    }), [currentModel]);

    const methods = useForm<FormValuesProps>({
        resolver: yupResolver(NewModelSchema),
        defaultValues
    });

    const {
        reset,
        handleSubmit,
        formState: { isSubmitting },
    } = methods;

    const onSubmit = useCallback(
        async (data: FormValuesProps) => {
            try {
                addUpdateVehicleHistory({...data,status:!data.status}).then((res:any)=>{
                    console.log(res);
                    if(res.data.data > 0 && res.data.statusCode===200) {
                    reset();
                    onClose();
                    enqueueSnackbar(t('VEHICLEHISTORY.FORM.UPDATE_SUCCESS') as any);
                } else {
                    setShowErrorMsg(true);
                }
                });
                console.info('DATA', data);
            } catch (error) {
                console.error(error);
            }
        }, [enqueueSnackbar, onClose, reset, t]);

    return (
        <Dialog scroll="body"
            fullWidth
            maxWidth={false}
            open={open}
            onClose={() => null}
            PaperProps={{
                sx: { maxWidth: 720 },
            }}
        >
            <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
                <DialogTitle>{title}</DialogTitle>
                <DialogContent>
                {showErrorMsg &&   <Alert variant="outlined" severity="error" sx={{ mb: 3 }}>
                        {t('VEHICLEHISTORY.FORM.ALREADY_EXISTS_ERROR')}
                    </Alert> }
                    <Stack spacing={3} sx={{ p: 1 }}>
                        <RHFTextField size={GLOBALCLASSINPUT} name="name" label={t("VEHICLEHISTORY.FORM.VEHICLEHISTORYNAME")} />
                        <RHFTextField size={GLOBALCLASSINPUT} name="description" label={t("VEHICLEHISTORY.FORM.DESCRIPTION")} />
                        <RHFTextField size={GLOBALCLASSINPUT} type='number' name="displayOrder" label={t("OWNERS.FORM.DISPLAYORDER")} />
                        {currentModel.id !== 0 && <RHFSwitch name="status" label={t("VEHICLEHISTORY.FORM.STATUS")} />}
                    </Stack>
                </DialogContent>
                <DialogActions>
                    <Button variant="outlined" onClick={onClose}>
                        {t("CANCEL")}
                    </Button>
                    <LoadingButton type="submit" variant="contained" loading={isSubmitting}>
                        {submitButtonText}
                    </LoadingButton>
                </DialogActions>
            </FormProvider>
        </Dialog>
    )

}