import { IBookingDetail, ICustomerBooking, IDeliveryNoteInvoice, IExchange, IFinanceDetail, IInsuranceDetail, IReferrals, ISchemesOffers, IVehicleDetail } from "../common";
import { IErrorType } from "../error";

export type IDeliveryNoteTableFilters = {
  name: string;
  status: string[];
};

export type IDeliveryNoteTableFilterValue = string | string[];



export type IDeliveryNote = {
  id: number; 
  deliveryNoteInvoice: IDeliveryNoteInvoice;
  bookingDetail: IBookingDetail;
  bookingCustomer: ICustomerBooking;
  billingCustomer: ICustomerBooking;
  vehicleDetail:IVehicleDetail;
  schemeOffers:ISchemesOffers;
  insurance:IInsuranceDetail;
  finance:IFinanceDetail;
  exchange:IExchange;
  loyality:any;
  referral:IReferrals;
}

export type IDeliveryNoteState = {
  deliveryNote: IDeliveryNote[];
  deliveryNoteStatus: {
    loading: boolean;
    empty: boolean;
    error: IErrorType;
  };
  deliveryNoteDelete: {
    loading: boolean;
    empty: any;
    error: IErrorType;
  }
};

export const defaultFilters = {
  name: '',
  status: [],
};