
import { useSelector } from "src/redux/store";

export default function useVehicleHistory() {
    const { vehicleHistory,vehicleHistoryStatus} = useSelector(
        (state) => state.vehicleHistory
    );

    return {
        vehicleHistory,vehicleHistoryStatus,
       
    }
}