 
import {  useSelector } from "src/redux/store";

export default function useSchemeType() {
    const { schemeType,schemeTypeStatus} = useSelector(
        (state) => state.schemeType
    );
 
    return {
        schemeType,schemeTypeStatus,
    }
}