export const MANUFACTURER_COLUMNS = ["img","name","status"];

export const TABLE_HEAD_MANUFACTURER = [
    { id: 'img', label: "" },
    { id: 'name', label: "MANUFACTURER.VIEW.GRID.MANUFACTURERNAME" },
    { id: 'status', label: "MANUFACTURER.VIEW.GRID.STATUS" },
    { id: 'id', width: 88 },
  ];

export const defaultFilters = {
    name: '',
    status: [],
  };
  