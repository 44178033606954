import { Helmet } from "react-helmet-async";
import { useLocales } from "src/locales";
import BuyerTypeListView from "src/sections/masters/lead-settings/buyer-type/buyer-type-list-view";

export default function BuyerTypeListPage() {
  const {t}=useLocales();
    return (
      <>
        <Helmet>
          <title> { t("BUYERTYPE.PAGE.TITLE")}</title>
        </Helmet>
  
        <BuyerTypeListView />
      </>
    );
  }