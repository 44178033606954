export const DELIVERYNOTE_COLUMNS = ["customerName","modelDescription","bookingNumber","invoiceNoteNumber","deliveryNoteNumber"];

export const TABLE_HEAD_DELIVERYNOTE = [
    { id: 'customerName', label: "DELIVERYNOTE.VIEW.GRID.CUSTOMERNAME" },
    { id: 'modelDescription', label: "DELIVERYNOTE.VIEW.GRID.MODELDESCRIPTION" },
    { id: 'bookingNumber', label: "DELIVERYNOTE.VIEW.GRID.BOOKINGNUMBER" },
    { id: 'invoiceNoteNumber', label: "DELIVERYNOTE.VIEW.GRID.INVOICENOTENUMBER" },
    { id: 'deliveryNoteNumber', label: "DELIVERYNOTE.VIEW.GRID.DELIVERYNOTENUMBER" },
    { id: 'id', width: 88 },
  ];

export const defaultFilters = {
    name: '',
    status: [],
  };
  