import { Helmet } from "react-helmet-async";
import { useLocales } from "src/locales";
import DistrictsListView from "src/sections/masters/geography-settings/districts/districts-list-view";


export default function DistrictsListPage() {
  const {t}=useLocales();
    return (
      <>
        <Helmet>
          <title> { t("DISTRICT.PAGE.TITLE")}</title>
        </Helmet>
  
        <DistrictsListView /> 
      </>
    );
  }