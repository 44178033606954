import * as Yup from 'yup';
import { useSnackbar } from "src/components/snackbar";
import { CustomFile } from "src/components/upload";
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import FormProvider, { RHFCode, RHFSelect, RHFSwitch, RHFTextField, RHFUploadAvatar } from 'src/components/hook-form';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import LoadingButton from '@mui/lab/LoadingButton';
import { Box, Card, Container, Dialog, DialogTitle, Divider, Grid, IconButton, MenuItem, Stack, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material';
import { IAllotment } from 'src/types/sales-journey/allotment';
import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import { useLocales } from 'src/locales';
import { GLOBALCLASSINPUT, PUBLISH_OPTIONS } from 'src/utils/contants/config-common';
import { addUpdateAllotment } from 'src/redux/slices/sales-journey/allotment';
import { DatePicker } from '@mui/x-date-pickers';
import { getLookUp } from 'src/redux/slices/lookup';
import { TABLE_HEAD_ENQUIRYTESTDRIVE } from 'src/utils/contants/booking/config-booking';
import { TABLE_HEAD_FORM_ALLOTMENT } from 'src/utils/contants/sales-journey/allotment';
import { fData } from 'src/utils/format-number';
import { CloseIcon } from 'src/components/lightbox';
import CustomBreadcrumbs from 'src/components/custom-breadcrumbs';
import { useSettingsContext } from 'src/components/settings';
import { paths } from 'src/routes/paths';
import Toolbar from 'src/sections/_common-components/toolbar';
import { useParams, useRouter } from 'src/routes/hook';

interface FormValuesProps extends Omit<IAllotment, 'avatarUrl'> {
    avatarUrl: CustomFile | string | null;
}

type Props = {
    title: string;
    open: boolean;
    onClose: VoidFunction;
    submitButtonText?: string;
    currentModel: IAllotment;
};

// export default function AllotmentForm({ currentModel, open, onClose, title, submitButtonText }: Props) {
    export default function AllotmentForm() {
    const settings = useSettingsContext();
    const router = useRouter();
    const params = useParams();
    const { id } = params;
    const { enqueueSnackbar } = useSnackbar();
    const [gridTemplateColumns, setGridTemplateColumns] = useState(4);
    const [colorLookUp, setColorLookUp] = useState([] as any);
    const [vehicleModelLookUp, setVehicleModelLookUp] = useState([] as any);
    const [varientTypeLookUp, setVarientTypeLookUp] = useState([] as any);
    const [vehicleStatusLookUp, setVehicleStatusLookUp] = useState([] as any);
    const [seatingCapacityLookUp, setSeatingCapacityLookUp] = useState([] as any);
    const [currentModel, setCurrentModel] = useState({} as IAllotment);
    const [selectedRow, setSelectedRow] = useState({} as IAllotment);
    const [submitButtonText, setSubmitButtonText] = useState();
    const { t } = useLocales();
    const [showErrorMsg, setShowErrorMsg] = useState(false as any);
    const NewModelSchema = Yup.object().shape({
        vinNumber: Yup.string().required(t('BANKNAME.FORM.NAME_REQUIRED') as any),
        vechicleAge: Yup.string(),
        pid: Yup.string(),
        vehicleStatusId: Yup.string(),
        oemInvoiceDate: Yup.string(),
        invoiceNumber: Yup.string(),
        vehicleReceivedDate: Yup.string(),
        modelFamily: Yup.string(),
        model: Yup.string(),
        varientTypeId: Yup.string(),
        colorId: Yup.string(),
        seatingCapacityId: Yup.string(),
        modelCodeId: Yup.string(),
        grnNumber: Yup.string(),
        grnDate: Yup.string(),
        bookingNumber: Yup.string(),
        description: Yup.string(),
        displayOrder: Yup.number(),
        status: Yup.bool(),
    });
    useEffect(() => {
        getLookUp("COLOR").then((x: any) => {
            if (x && x.data && x.data.length > 0) {
                console.log(x)
                setColorLookUp(x.data);
            }
        });
        getLookUp("VEHICLEMODEL").then((x: any) => {
            if (x && x.data && x.data.length > 0) {
                console.log(x)
                setVehicleModelLookUp(x.data);
            }
        });
        getLookUp("VARIENTTYPE").then((x: any) => {
            if (x && x.data && x.data.length > 0) {
                console.log(x)
                setVarientTypeLookUp(x.data);
            }
        });
        getLookUp("VEHICLESTATUS").then((x: any) => {
            if (x && x.data && x.data.length > 0) {
                console.log(x)
                setVehicleStatusLookUp(x.data);
            }
        });
        getLookUp("SEATINGCAPACITY").then((x: any) => {
            if (x && x.data && x.data.length > 0) {
                console.log(x)
                setSeatingCapacityLookUp(x.data);
            }
        });

    }, [])

    const defaultValues = useMemo(() => ({
        vinNumber: currentModel?.vinNumber || "",
        vechicleAge: currentModel?.vechicleAge || "",
        pid: currentModel?.pid || "",
        vehicleStatusId: currentModel?.vehicleStatusId || "",
        oemInvoiceDate: currentModel?.oemInvoiceDate || "",
        invoiceNumber: currentModel?.invoiceNumber || "",
        vehicleReceivedDate: currentModel?.vehicleReceivedDate || "",
        modelFamily: currentModel?.modelFamily || "",
        model: currentModel?.model || "",
        varientTypeId: currentModel?.varientTypeId || "",
        colorId: currentModel?.colorId || "",
        seatingCapacityId: currentModel?.seatingCapacityId || "",
        modelCodeId: currentModel?.modelCodeId || "",
        grnNumber: currentModel?.grnNumber || "",
        grnDate: currentModel?.grnDate || "",
        bookingNumber: currentModel?.bookingNumber || "",
        description: currentModel?.description || "",
        id: currentModel?.id || 0
    }), [currentModel]);

    const methods = useForm<FormValuesProps>({
        resolver: yupResolver(NewModelSchema),
        defaultValues
    });

    const {
        reset,
        handleSubmit, control, watch,
        formState: { isSubmitting },
    } = methods;
    const values = watch();
    const onSubmit = useCallback(
        async (data: FormValuesProps) => {
            try {
                addUpdateAllotment({ ...data }).then((res: any) => {
                    console.log(res);
                    if (res.data.data > 0 && res.data.statusCode === 200) {
                        reset();
                        enqueueSnackbar(t('BANKNAME.FORM.UPDATE_SUCCESS') as any);
                    } else {
                        setShowErrorMsg(true);
                    }
                });
                console.info('DATA', data);
            } catch (error) {
                console.error(error);
            }
        }, [enqueueSnackbar, reset, t]);

    return (
        <Container maxWidth={settings.themeStretch ? false : 'lg'}>
            <CustomBreadcrumbs
                heading={t("ALLOTMENT.VIEW.VIEW_HEADING") as any}
                links={[
                    { name: t("DASHBOARD") as any, href: paths.dashboard.root },
            {
              name: t("ALLOTMENT.VIEW.VIEW_NAME") as any,
              href: paths.dashboard.blank,
            },

                ]}
                sx={{
                    mb: { xs: 3, md: 5 },
                }}
            />
             <Toolbar
                backLink={paths.dashboard.salesJouryney.allotment.root}
                editLink={paths.dashboard.salesJouryney.allotment.edit(`${selectedRow?.id}`)}
                liveLink={paths.dashboard.salesJouryney.enquiry.root}
                publish={id ? 'Update' : 'Add'}
                onChangePublish={() => { }}
                publishOptions={PUBLISH_OPTIONS}
            />
            <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
                {showErrorMsg && <Alert variant="outlined" severity="error" sx={{ mb: 3 }}>
                    {t('BANKNAME.FORM.ALREADY_EXISTS_ERROR')}
                </Alert>}
                <Grid container spacing={3} style={{ padding: "25px" }}>
                    <Grid xs={12} md={3}>
                        <Card sx={{ pt: 5, pb: 3, px: 3 }}>
                            <RHFUploadAvatar
                                name="avatarUrl"
                                maxSize={3000000}
                                helperText={
                                    <Typography
                                        variant="caption"
                                        sx={{
                                            mt: 1,
                                            mx: 'auto',
                                            display: 'block',
                                            textAlign: 'center',
                                            color: 'text.disabled',
                                        }}
                                    >
                                        Allowed *.jpeg, *.jpg, *.png, *.gif
                                        <br /> max size of {fData(3000000)}
                                    </Typography>
                                }
                            />
                            <br />
                            <Table sx={{ maxWidth: 300 }}>
                                <TableBody>
                                    <TableRow>
                                        <TableCell style={{ padding: "4px", width: "118px" }}>
                                            <Typography variant="caption" sx={{ mb: 0.5 }}>  Customer Name: </Typography>
                                        </TableCell>
                                        <TableCell style={{ padding: "4px", width: "118px" }}>
                                            <Typography variant="caption" sx={{ mb: 0.5 }}>  Raghu Varan </Typography>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell style={{ padding: "4px", width: "118px" }}>
                                            <Typography variant="caption" sx={{ mb: 0.5 }}> Customer Type: </Typography>
                                        </TableCell>
                                        <TableCell style={{ padding: "4px", width: "118px" }}>
                                            <Typography variant="caption" sx={{ mb: 0.5 }}> Direct </Typography>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell style={{ padding: "4px", width: "118px" }}>
                                            <Typography variant="caption" sx={{ mb: 0.5 }}> Booking Date: </Typography>
                                        </TableCell>
                                        <TableCell style={{ padding: "4px", width: "118px" }}>
                                            <Typography variant="caption" sx={{ mb: 0.5 }}> 20-DEC-2023 </Typography>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell style={{ padding: "4px", width: "118px" }}>
                                            <Typography variant="caption" sx={{ mb: 0.5 }}> Delivery Date: </Typography>
                                        </TableCell>
                                        <TableCell style={{ padding: "4px", width: "118px" }}>
                                            <Typography variant="caption" sx={{ mb: 0.5 }}> 25-DEC-2023 </Typography>
                                        </TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>

                            <br />
                            <Typography variant="subtitle2" sx={{ mb: 0.5 }}>
                                Vehicle Details
                            </Typography>

                            <Table sx={{ maxWidth: 300 }}>
                                <TableBody>
                                    <TableRow>
                                        <TableCell style={{ padding: "4px", width: "112px", wordBreak: "break-word" }}>
                                            <Typography variant="caption" sx={{ mb: 0.5 }}>  Vehicle Model: </Typography>
                                        </TableCell>
                                        <TableCell style={{ padding: "4px", width: "112px", wordBreak: "break-word" }}>
                                            <Typography variant="caption" sx={{ mb: 0.5 }}>  Maruthi Brezza ZXI Black </Typography>
                                        </TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                            <br />
                            <RHFSwitch
                                name="isVerified"
                                labelPlacement="start"
                                label={
                                    <>
                                        <Typography variant="subtitle2" sx={{ mb: 0.5 }}>
                                            Email Verified
                                        </Typography>
                                        <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                                            Disabling this will automatically send the user a verification email
                                        </Typography>
                                    </>
                                }
                                sx={{ mx: 0, width: 1, justifyContent: 'space-between' }}
                            />

                        </Card>
                    </Grid>
                    <Grid xs={12} md={9} style={{ paddingLeft: "18px" }}>
                        <Typography variant="subtitle1" style={{ width: "100%" }}>Vehicle Details <hr /> </Typography>
                        <Box rowGap={2} columnGap={2} display="grid" gridTemplateColumns={{ xs: 'repeat(1, 1fr)', sm: `repeat(${gridTemplateColumns}, 1fr)` }}>
                            <RHFTextField size={GLOBALCLASSINPUT} name="vinNumber" label={t("COMMON.VINNUMBER")} />
                            <RHFTextField size={GLOBALCLASSINPUT} name="vechicleAge" label={t("COMMON.VEHICLEAGE")} />
                            <RHFTextField size={GLOBALCLASSINPUT} name="pid" label={t("COMMON.PID")} />
                            <RHFSelect name="vehicleStatusId" size={GLOBALCLASSINPUT} label={t('COMMON.VEHICLESTATUS') as any} >
                                <MenuItem value="">None</MenuItem>
                                <Divider sx={{ borderStyle: 'dashed' }} />
                                {vehicleStatusLookUp && vehicleStatusLookUp.map((item: any) => (
                                    <MenuItem key={item.value + item.label} value={item.value}> {item.label} </MenuItem>
                                ))}
                            </RHFSelect>
                            <Controller
                                name="oemInvoiceDate"
                                control={control}
                                render={({ field, fieldState: { error } }) => (
                                    <DatePicker
                                        label={t('COMMON.OEMINVOICEDATE') as any}
                                        value={field.value}
                                        onChange={(newValue) => {
                                            field.onChange(newValue);
                                        }}
                                        format="dd-MMM-yyyy"
                                        slotProps={{
                                            textField: {
                                                size: 'small',
                                                error: !!error,
                                                helperText: error?.message,
                                            },
                                        }}
                                    />
                                )}
                            />
                            <RHFTextField size={GLOBALCLASSINPUT} name="invoiceNumber" label={t("COMMON.INVOICENUMBER")} />
                            <Controller
                                name="vehicleReceivedDate"
                                control={control}
                                render={({ field, fieldState: { error } }) => (
                                    <DatePicker
                                        label={t('COMMON.VEHICLERECEIVEDDATE') as any}
                                        value={field.value}
                                        onChange={(newValue) => {
                                            field.onChange(newValue);
                                        }}
                                        format="dd-MMM-yyyy"
                                        slotProps={{
                                            textField: {
                                                size: 'small',
                                                error: !!error,
                                                helperText: error?.message,
                                            },
                                        }}
                                    />
                                )}
                            />
                            <RHFTextField size={GLOBALCLASSINPUT} name="modelFamily" label={t("COMMON.MODELFAMILY")} />
                            <RHFSelect name="model" size={GLOBALCLASSINPUT} label={t('COMMON.MODEL') as any} >
                                <MenuItem value="">None</MenuItem>
                                <Divider sx={{ borderStyle: 'dashed' }} />
                                {vehicleModelLookUp && vehicleModelLookUp.map((item: any) => (
                                    <MenuItem key={item.value + item.label} value={item.value}> {item.label} </MenuItem>
                                ))}
                            </RHFSelect>
                            <RHFSelect name="varientTypeId" size={GLOBALCLASSINPUT} label={t('COMMON.VARIENT') as any} >
                                <MenuItem value="">None</MenuItem>
                                <Divider sx={{ borderStyle: 'dashed' }} />
                                {varientTypeLookUp && varientTypeLookUp.map((item: any) => (
                                    <MenuItem key={item.value + item.label} value={item.value}> {item.label} </MenuItem>
                                ))}
                            </RHFSelect>
                            <RHFSelect name="colorId" size={GLOBALCLASSINPUT} label={t('COMMON.COLOR') as any} >
                                <MenuItem value="">None</MenuItem>
                                <Divider sx={{ borderStyle: 'dashed' }} />
                                {colorLookUp && colorLookUp.map((item: any) => (
                                    <MenuItem key={item.value + item.label} value={item.value} > <span style={{ backgroundColor: item.data, padding: "3px 10px", borderRadius: "50%", marginRight: "5px", width: "20px", height: "20px" }}>&nbsp;</span> {item.label}    </MenuItem>
                                ))}
                            </RHFSelect>
                            <RHFSelect name="seatingCapacityId" size={GLOBALCLASSINPUT} label={t('COMMON.SEATINGCAPACITY') as any} >
                                <MenuItem value="">None</MenuItem>
                                <Divider sx={{ borderStyle: 'dashed' }} />
                                {seatingCapacityLookUp && seatingCapacityLookUp.map((item: any) => (
                                    <MenuItem key={item.value + item.label} value={item.value}> {item.label} </MenuItem>
                                ))}
                            </RHFSelect>
                            <RHFTextField size={GLOBALCLASSINPUT} name="modelCodeId" label={t("COMMON.MODELCODE")} />
                            <RHFTextField size={GLOBALCLASSINPUT} name="grnNumber" label={t("COMMON.GRNNUMBER")} />
                            <RHFTextField size={GLOBALCLASSINPUT} name="grnDate" label={t("COMMON.GRNDATE")} />
                            <RHFTextField size={GLOBALCLASSINPUT} name="description" label={t("BANKNAME.FORM.DESCRIPTION")} />
                        </Box>
                        <br />
                        <Typography variant="subtitle1" style={{ width: "100%" }}>Allotment <hr /> </Typography>
                        <Stack spacing={3} sx={{ p: 1 }} rowGap={2} columnGap={2} display="grid" gridTemplateColumns={{ xs: 'repeat(1, 1fr)', sm: `repeat(1, 1fr)` }}>
                            <RHFTextField size={GLOBALCLASSINPUT} name="vinNumber" label={t("ALLOTMENT.FORM.SEARCH")} />
                        </Stack>
                        <Table sx={{ minWidth: "100%" }}>
                            <TableHead>
                                <TableRow>
                                    {TABLE_HEAD_FORM_ALLOTMENT && TABLE_HEAD_FORM_ALLOTMENT.map((item: any) => (
                                        <TableCell key={item.label} style={{ padding: "4px", fontSize: '10px', textAlign: 'center' }}>
                                            <Typography variant="caption" sx={{ mb: 0.5 }}>  {t(item.label)} </Typography>
                                        </TableCell>
                                    ))}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <TableRow>
                                    <TableCell style={{ fontSize: '10px', textAlign: 'center' }}>
                                        <Typography variant="caption" sx={{ mb: 0.5 }}>  986576 </Typography>
                                    </TableCell>
                                    <TableCell style={{ fontSize: '10px', textAlign: 'center' }}>
                                        <Typography variant="caption" sx={{ mb: 0.5 }}>  12-DEC-2023 </Typography>
                                    </TableCell>
                                    <TableCell style={{ fontSize: '10px', textAlign: 'center' }}>
                                        <Typography variant="caption" sx={{ mb: 0.5 }}>  RAGHU VARAN </Typography>
                                    </TableCell>
                                    <TableCell style={{ fontSize: '10px', textAlign: 'center' }}>
                                        <Typography variant="caption" sx={{ mb: 0.5 }}>  9848266666 </Typography>
                                    </TableCell>
                                    <TableCell style={{ fontSize: '10px', textAlign: 'center' }}>
                                        <Typography variant="caption" sx={{ mb: 0.5 }}>   20-DEC-2023  </Typography>
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </Grid>
                </Grid>
                <LoadingButton type="submit" variant="contained" loading={isSubmitting}>
                    {submitButtonText}
                </LoadingButton>
            </FormProvider>
        </Container>
    )
}