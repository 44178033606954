import { IErrorType } from "../error";

export type IVehicleHistoryTableFilters = {
    name: string;
    status: string[];
  };
  
export type IVehicleHistoryTableFilterValue = string | string[];

export type IVehicleHistory = {
    id:number;
    name:string;
    description:string;
    displayOrder:number;
    status:boolean;
}
 
export type IVehicleHistoryState = {
  vehicleHistory: IVehicleHistory[];
  vehicleHistoryStatus: {
    loading: boolean;
    empty: boolean;
    error: IErrorType;
  };
  vehicleHistoryDelete: {
    loading:boolean;
    empty:any;
    error:IErrorType;
  }
};

export const defaultFilters = {
  name: '',
  status: [],
};