import { createSlice, Dispatch } from '@reduxjs/toolkit';
import { INewVehicleModelState } from 'src/types/new.vehicle.model';
// utils
import axios, { API_ENDPOINTS } from 'src/utils/axios';


const initialState: INewVehicleModelState = {
    newVehicleModels:[],
    newVehicleModel:null,
    newVehicleModelsStatus:{
        loading:false,
        empty:false,
        error:null
    },
    newVehicleModelStatus:{
        loading:false,
        error:null
    },
    newVehicleModelDelete: {
        loading:false,
        empty:false,
        error:null
    }
}

const slice = createSlice({
    name: 'newVehicleModel',
    initialState,
    reducers:{
        getNewVehicleModelsStart(state) {
            state.newVehicleModelsStatus.loading=true;
            state.newVehicleModelsStatus.empty=false;
            state.newVehicleModelsStatus.error = null;
        },
        getNewVehicleModelsFailure(state, action) {
            state.newVehicleModelsStatus.loading=true;
            state.newVehicleModelsStatus.empty=false;
            state.newVehicleModelsStatus.error = action.payload;
        },
        getNewVehicleModelsSuccess(state, action) {
            const newVehicleModels = action.payload;
            state.newVehicleModels = newVehicleModels;
            state.newVehicleModelsStatus.loading = false;
            state.newVehicleModelsStatus.empty = !newVehicleModels.length;
            state.newVehicleModelsStatus.error = null;
        }
        ,
        deleteNewVehicleModelStart(state) {
            state.newVehicleModelDelete.loading=true;
            state.newVehicleModelDelete.empty=false;
            state.newVehicleModelDelete.error = null;
        },
        deleteNewVehicleModelFailure(state, action) {
            state.newVehicleModelDelete.loading=true;
            state.newVehicleModelDelete.empty=false;
            state.newVehicleModelDelete.error = action.payload;
        },
        deleteNewVehicleModelSuccess(state, action) {
            const newVehicleModelDelete = action.payload;
            state.newVehicleModelDelete = newVehicleModelDelete;
            state.newVehicleModelDelete.loading = false;
            state.newVehicleModelDelete.empty = action.payload;
            state.newVehicleModelDelete.error = null;
        }
    }
});

export default slice.reducer;

// Actions
export const {
    getNewVehicleModelsStart,
    getNewVehicleModelsFailure,
    getNewVehicleModelsSuccess,
    deleteNewVehicleModelStart,
    deleteNewVehicleModelFailure,
    deleteNewVehicleModelSuccess
} = slice.actions;

export function getNewVehicleModels(data:any) {
        return async (dispatch: Dispatch) => {
            dispatch(slice.actions.getNewVehicleModelsStart());
            try{
               // const response = await axios.post(API_ENDPOINTS.VEHICLECLASS.SEARCH,data);
                dispatch(slice.actions.getNewVehicleModelsSuccess([
                    {id:1,logo:'IMAGE',vehicleName:'Swift Dezire',year:"2022",specification:"AC",status:true},
                    {id:2,logo:'IMAGE',vehicleName:'Iginis',year:"2021",specification:"No AC",status:false}
                ]
                    ));
            }catch(error){
                dispatch(slice.actions.getNewVehicleModelsFailure(error));
            }
        }
}

export function deleteNewVehicleModel(id: any) {
    return async (dispatch: Dispatch) => {
        dispatch(slice.actions.deleteNewVehicleModelStart());
        try{
            const response = await axios.get(API_ENDPOINTS.BODYTYPE.DELETE+id);
            dispatch(slice.actions.deleteNewVehicleModelSuccess(response.data));
        } catch(error){
            dispatch(slice.actions.deleteNewVehicleModelFailure(error));
        }
    }
}
