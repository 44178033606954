export const USEDVEHICLEMODEL_COLUMNS = ["img","vehicleName","varient","fuelType","year","kmsdriven","status"];

export const TABLE_HEAD_USEDVEHICLEMODEL = [
    { id: 'img', label: "" },
    { id: 'vehicleName', label: "USEDVEHICLEMODEL.VIEW.GRID.VEHICLENAME" },
    { id: 'varient', label: "USEDVEHICLEMODEL.VIEW.GRID.VARIENT" },
    { id: 'fuelType', label: "USEDVEHICLEMODEL.VIEW.GRID.FUELTYPE" },
    { id: 'year', label: "USEDVEHICLEMODEL.VIEW.GRID.YEAR" },
    { id: 'kmsdriven', label: "USEDVEHICLEMODEL.VIEW.GRID.KMSDRIVEN" },
    { id: 'status', label: "USEDVEHICLEMODEL.VIEW.GRID.STATUS" },
    { id: 'id', width: 88 },
  ];

export const defaultFilters = {
    name: '',
    status: [],
  };
  