export const COUNTRIES_COLUMNS = ["countryCode","countryName","currency","status"];

export const TABLE_HEAD_COUNTRIES = [
    { id: 'countryCode', label: "COUNTRIES.VIEW.GRID.COUNTRYCODE" },
    { id: 'countryName', label: "COUNTRIES.VIEW.GRID.COUNTRYNAME" },
    { id: 'currency', label: "COUNTRIES.VIEW.GRID.CURRENCY" },
    { id: 'status', label: "COUNTRIES.VIEW.GRID.STATUS" },
    { id: 'id', width: 88 },
  ];

  
export const defaultFilters = {
    name: '',
    status: [],
  };
  