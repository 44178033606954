import { createSlice, Dispatch } from '@reduxjs/toolkit';
import { ITransmissionState } from 'src/types/transmission';
// utils
import axios, { API_ENDPOINTS } from 'src/utils/axios';


const initialState: ITransmissionState = {
    transmissions:[],
    transmission:null,
    transmissionsStatus:{
        loading:false,
        empty:false,
        error:null
    },
    transmissionStatus:{
        loading:false,
        error:null
    },
    transmissionDelete: {
        loading:false,
        empty:false,
        error:null
    }
}

const slice = createSlice({
    name: 'transmission',
    initialState,
    reducers:{
        getTransmissionsStart(state) {
            state.transmissionsStatus.loading=true;
            state.transmissionsStatus.empty=false;
            state.transmissionsStatus.error = null;
        },
        getTransmissionsFailure(state, action) {
            state.transmissionsStatus.loading=true;
            state.transmissionsStatus.empty=false;
            state.transmissionsStatus.error = action.payload;
        },
        getTransmissionsSuccess(state, action) {
            const transmissions = action.payload;
            state.transmissions = transmissions;
            state.transmissionsStatus.loading = false;
            state.transmissionsStatus.empty = !transmissions.length;
            state.transmissionsStatus.error = null;
        }
        ,
        deleteTransmissionStart(state) {
            state.transmissionDelete.loading=true;
            state.transmissionDelete.empty=false;
            state.transmissionDelete.error = null;
        },
        deleteTransmissionFailure(state, action) {
            state.transmissionDelete.loading=true;
            state.transmissionDelete.empty=false;
            state.transmissionDelete.error = action.payload;
        },
        deleteTransmissionSuccess(state, action) {
            const transmissionDelete = action.payload;
            state.transmissionDelete = transmissionDelete;
            state.transmissionDelete.loading = false;
            state.transmissionDelete.empty = action.payload;
            state.transmissionDelete.error = null;
        }
    }
});

export default slice.reducer;

// Actions
export const {
    getTransmissionsStart,
    getTransmissionsFailure,
    getTransmissionsSuccess,
    deleteTransmissionStart,
    deleteTransmissionFailure,
    deleteTransmissionSuccess
} = slice.actions;

export function getTransmissions(data:any) {
    return async (dispatch: Dispatch) => {
        dispatch(slice.actions.getTransmissionsStart());
        try {
            await axios.get(API_ENDPOINTS.PRODUCTSETTTINGS.TRANSMISSION.LIST).then((res:any)=> {
                if(res.data.statusCode === 200)
                dispatch(slice.actions.getTransmissionsSuccess(res.data.data));
            else 
                 dispatch(slice.actions.getTransmissionsFailure([]));
            });
        }catch(error){
            dispatch(slice.actions.getTransmissionsFailure(error));
        }
    }
}

export function deleteTransmission(id: any) {
return  axios.delete(`${API_ENDPOINTS.PRODUCTSETTTINGS.TRANSMISSION.DELETE}/${id}`);
}

export function addUpdateTransmission(data: any) {
return axios.post(API_ENDPOINTS.PRODUCTSETTTINGS.TRANSMISSION.ADDUPDATETRANSMISSION,data);
}