import { Helmet } from "react-helmet-async";
import { useLocales } from "src/locales";
import GenderListView from "src/sections/masters/general-settings/gender/gender-list-view";

export default function GendersListPage() {
  const {t}=useLocales();
    return (
      <>
        <Helmet>
          <title> { t("GENDER.PAGE.TITLE")}</title>
        </Helmet>
  
        <GenderListView/>
      </>
    );
  }