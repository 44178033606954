import { IBookingDetail, ICustomerBooking, ICustomerDetail, IExchange, IFinanceDetail, IInsuranceDetail, IReferrals, ISchemesOffers, IVehicleDetail } from "../common";
import { IErrorType } from "../error";
import { INewVehicleModel } from "../new.vehicle.model";

export type IBookingTableFilters = {
    name: string;
    status: string[];
  };
  
export type IBookingTableFilterValue = string | string[];

export type IBooking = {
  id?:any;
    bookingId:number;
    fullName:string;
    mobileNumber:string;
    emailAddress:string;
    bookingStatusId:string;
    buyerTypeId:string;
    bookingOwnerId:string;
    address:IAddress;
    corporateDetail:ICorporateDetail;
    replacementDetail:IReplacementDetail;
    nextFollowUpDateAndTime:string;
    testDriveDate:string;
    birthDate:string;
    coverNoteDate:string;
    validFrom:string;
    validTo:string;
    doDate:string;
    referralDateOfBirth:string; 
    warrentyValidTo:string; 
    warrentyValidFrom:string; 
    bookingStatus:string;
    description:string;
    serviceName:string;
    serviceAmount:string;
    status:boolean;
    isSameAsBillingAddress:boolean;
    bookingDetail: IBookingDetail;
    bookingCustomer: ICustomerBooking;
    billingCustomer: ICustomerBooking;
    vehicleDetail:IVehicleDetail;
    schemeOffers:ISchemesOffers;
    insurance:IInsuranceDetail;
    finance:IFinanceDetail;
    exchange:IExchange;
    loyality:any;
    referral:IReferrals;  
}

export type IAddress = {
  addressLine1:string;
  addressLine2:string;
  pincodeId:string;
  cityId:string;
  countryId:string;
  stateId:string;
  districtId:string;
  mandalId:string;
}

export type ICorporateDetail = {
  companyName:string;
  jobTitle:string;
  address:IAddress;
}

export type IReplacementDetail = {
  vehicleName:string;
  rto:string;
  manufacturerId:string;
  modelId:string;
  varientId:string;
  year:string;
  color:string;
  fuelTypeId:string;
  transmissionId:string;
  bodyTypeId:string;
  ownerId:string;
  kmsDriven:string;
  vehicleId:string;
  replacementDetailId:string;
}


export type IBookingState = {
  booking: IBooking[];
  bookingStatus: {
    loading: boolean;
    empty: boolean;
    error: IErrorType;
  };
  bookingDelete: {
    loading:boolean;
    empty:any;
    error:IErrorType;
  }
};

export const defaultFilters = {
  name: '',
  status: [],
};