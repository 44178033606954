export const STATES_COLUMNS = ["stateCode","stateName","gststatecode","countryName","status"];

export const TABLE_HEAD_STATES = [
    { id: 'stateCode', label: "STATES.VIEW.GRID.STATECODE" },
    { id: 'stateName', label: "STATES.VIEW.GRID.STATENAME" },
    { id: 'gststatecode', label: "STATES.VIEW.GRID.GSTSTATECODE" },
    { id: 'countryName', label: "STATES.VIEW.GRID.COUNTRY" },
    { id: 'status', label: "STATES.VIEW.GRID.STATUS" },
    { id: 'id', width: 88 },
  ];

  
export const defaultFilters = {
    name: '',
    status: [],
  };
  