import { useMemo } from 'react';
// routes
import { paths } from 'src/routes/paths';
// locales
import { useLocales } from 'src/locales';
// components
import Iconify from 'src/components/iconify';
import SvgColor from 'src/components/svg-color';

// ----------------------------------------------------------------------

const icon = (name: string) => (
  <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />
  // OR
  // <Iconify icon="fluent:mail-24-filled" />
  // https://icon-sets.iconify.design/solar/
  // https://www.streamlinehq.com/icons
);

const ICONS = {
  job: icon('ic_job'),
  blog: icon('ic_blog'),
  chat: icon('ic_chat'),
  mail: icon('ic_mail'),
  user: icon('ic_user'),
  file: icon('ic_file'),
  lock: icon('ic_lock'),
  tour: icon('ic_tour'),
  order: icon('ic_order'),
  label: icon('ic_label'),
  blank: icon('ic_blank'),
  kanban: icon('ic_kanban'),
  folder: icon('ic_folder'),
  banking: icon('ic_banking'),
  booking: icon('ic_booking'),
  invoice: icon('ic_invoice'),
  product: icon('ic_product'),
  calendar: icon('ic_calendar'),
  disabled: icon('ic_disabled'),
  external: icon('ic_external'),
  menuItem: icon('ic_menu_item'),
  ecommerce: icon('ic_ecommerce'),
  analytics: icon('ic_analytics'),
  dashboard: icon('ic_dashboard'),
};

// ----------------------------------------------------------------------

export function useNavData() {
  const { t } = useLocales();

  const data = useMemo(
    () => [
      // OVERVIEW
      // ----------------------------------------------------------------------
      {
        subheader: t('overview'),
        items: [
          { title: t('Dashboard'), path: paths.dashboard.root, icon: ICONS.dashboard },
          {
            title: t('Organization Settings'),
            path: paths.dashboard.organisationSettings.root,
            icon: <Iconify icon="pepicons-print:building" />,
            children: [
              { title: t('Organization'), path: paths.dashboard.user.list },
              // { title: t('Organization'), path: paths.dashboard.organisationSettings.organisation.list },
              { title: t('Department'), path: paths.dashboard.user.cards },
              { title: t('Manage Members'), path: paths.dashboard.user.cards },
              { title: t('Roles'), path: paths.dashboard.user.cards }, 
            ],
          },
          {
            title: t('Sales Journey'),
            path: paths.dashboard.salesJouryney.root,
            icon: <Iconify icon="solar:sale-bold-duotone" />,
            children: [
              { title: t('SIDE_MENU.CUSTOMER'), path: paths.dashboard.salesJouryney.customer.root },
              { title: t('SIDE_MENU.ENQUIRYMANAGEMENT'), path: paths.dashboard.salesJouryney.enquiry.root },
              { title: t('SIDE_MENU.BOOKINGS'), path: paths.dashboard.salesJouryney.booking.root },
              { title: t('SIDE_MENU.ALLOTMENT'), path: paths.dashboard.salesJouryney.allotment.root },
              { title: t('SIDE_MENU.INVOICE'), path: paths.dashboard.salesJouryney.invoice.root },
              { title: t('SIDE_MENU.DELIVERYNOTE'), path: paths.dashboard.salesJouryney.deliveryNote.root }
            ],
          },
                {
                  title: t('SIDE_MENU.ACCOUNTING'),
                  path: paths.dashboard.invoice.root,
                  icon: ICONS.invoice,
                  children: [
                    { title: t('SIDE_MENU.INVOICE'), path: paths.dashboard.invoice.root },
                  ],
                }, 
        ], 
      },

      // MANAGEMENT
      // ----------------------------------------------------------------------
      {
        subheader: t('masters'),
        items: [
         
          // USER
          {
            title: t('SIDE_MENU.BASESETTINGS'),
            path: paths.dashboard.settings.root,
            icon:  <Iconify icon="mingcute:settings-3-fill" />,
            children: [
              { title: t('SIDE_MENU.VEHICLECLASS'), path: paths.dashboard.settings.vehicleClass.root },
              // { title: t('SIDE_MENU.VEHICLEMODEL'), path: paths.dashboard.settings.vehicleModel.root },
              { title: t('SIDE_MENU.SEGMENTDETAIL'), path: paths.dashboard.settings.segmentDetails.root },
              { title: t('SIDE_MENU.BODYTYPE'), path: paths.dashboard.settings.bodyType.root },
              { title: t('SIDE_MENU.MANUFACTURER'), path: paths.dashboard.settings.manufacturer.root },
              { title: t('SIDE_MENU.FUELTYPE'), path: paths.dashboard.settings.fuelType.root },
              { title: t('SIDE_MENU.COLORPALATTE'), path: paths.dashboard.settings.colorPalatte.root },
              { title: t('SIDE_MENU.TRANSMISSION'), path: paths.dashboard.settings.transmission.root},
              { title: t('SIDE_MENU.VARIENTTYPE'), path: paths.dashboard.settings.varientType.root },
              { title: t('SIDE_MENU.NEWVEHICLEMODEL'), path: paths.dashboard.settings.newVehicleModel.root },
              // { title: t('SIDE_MENU.USEDVEHICLEMODEL'), path: paths.dashboard.settings.usedVehicleModel.root },
            ],
          },
          {
            title: t('SIDE_MENU.GEOGRAPHYSETTINGS'),
            path: paths.dashboard.geographySettings.root,
            icon:  <Iconify icon="mingcute:map-pin-fill" />,
            children: [
              { title: t('SIDE_MENU.COUNTRIES'), path: paths.dashboard.geographySettings.countries.root },
              { title: t('SIDE_MENU.STATES'), path: paths.dashboard.geographySettings.states.root },
              { title: t('SIDE_MENU.DISTRICTS'), path: paths.dashboard.geographySettings.districts.root },
              { title: t('SIDE_MENU.CITIES'), path: paths.dashboard.geographySettings.cities.root },
              { title: t('SIDE_MENU.MANDAL'), path: paths.dashboard.geographySettings.mandal.root },
              { title: t('SIDE_MENU.PINCODE'), path: paths.dashboard.geographySettings.pincode.root },
            ],
          },
          {
            title: t('SIDE_MENU.ENQUIRYSETTINGS'),
            path: paths.dashboard.leadSettings.root,
            icon: <Iconify icon="ic:twotone-chat" />,
            children: [
              { title: t('SIDE_MENU.ENQUIRYCATEGRY'), path: paths.dashboard.leadSettings.leadCategory.root},
              { title: t('SIDE_MENU.ENQUIRYSTATUS'), path: paths.dashboard.leadSettings.leadStatus.root},
              { title: t('SIDE_MENU.SOURCEOFENQUIRY'), path: paths.dashboard.leadSettings.leadSource.root},
              { title: t('SIDE_MENU.BUYERTYPE'), path: paths.dashboard.leadSettings.buyerType.root}
            ],
          },
          {
            title: t('SIDE_MENU.VEHICLESETTINGS'),
            path: paths.dashboard.vehicleSettings.root,
            icon: <Iconify icon="fluent:vehicle-car-20-filled" />,
            children: [
              { title: t('SIDE_MENU.VEHICLEOWNERS'), path: paths.dashboard.vehicleSettings.owners.root},
              { title: t('SIDE_MENU.VEHICLEHISTORY'), path: paths.dashboard.vehicleSettings.vehicleHistory.root},
              { title: t('SIDE_MENU.VEHICLESTATUS'), path: paths.dashboard.vehicleSettings.vehicleStatus.root}
            ],
          },
          {
            title: t('SIDE_MENU.SCHEMESETTINGS'),
            path: paths.dashboard.schemeSettings.root,
            icon: <Iconify icon="ic:outline-discount" />,
            children: [
              { title: t('SIDE_MENU.SCHEMETYPE'), path: paths.dashboard.schemeSettings.schemeType.root},
              { title: t('SIDE_MENU.SCHEMECATEGORY'), path: paths.dashboard.schemeSettings.schemeCategory.root},
              { title: t('SIDE_MENU.SCHEMEOFFERS'), path: paths.dashboard.schemeSettings.schemeCategory.root},
            ],
          },
          {
            title: t('SIDE_MENU.GENERALSETTINGS'),
            path: paths.dashboard.generalSettings.root,
            icon:  <Iconify icon="ant-design:setting-filled" />,
            children: [
              { title: t('SIDE_MENU.SALUTATION'), path: paths.dashboard.generalSettings.salutation.root},
              { title: t('SIDE_MENU.GENDER'), path: paths.dashboard.generalSettings.gender.root},
            
              { title: t('SIDE_MENU.INSURANCECOMPANY'), path: paths.dashboard.generalSettings.insuranceCompany.root},
              { title: t('SIDE_MENU.FINANCEARRANGEBY'), path: paths.dashboard.generalSettings.finaceArrangeBy.root},
              { title: t('SIDE_MENU.BANKNAME'), path: paths.dashboard.generalSettings.bankName.root},
              { title: t('SIDE_MENU.RELATIONSHIP'), path: paths.dashboard.generalSettings.relationShip.root},
              { title: t('SIDE_MENU.CUSTOMERTYPE'), path: paths.dashboard.generalSettings.customerType.root},
              { title: t('SIDE_MENU.VISIT'), path: paths.dashboard.generalSettings.visit.root},
              { title: t('SIDE_MENU.HYPOTHECATED'), path: paths.dashboard.generalSettings.hypothecated.root},
            ],
          },
          // // PRODUCT
          // {
          //   title: t('product'),
          //   path: paths.dashboard.product.root,
          //   icon: ICONS.product,
          //   children: [
          //     { title: t('list'), path: paths.dashboard.product.root },
          //     { title: t('details'), path: paths.dashboard.product.demo.details },
          //     { title: t('create'), path: paths.dashboard.product.new },
          //     { title: t('edit'), path: paths.dashboard.product.demo.edit },
          //   ],
          // },

      //     // ORDER
      //     {
      //       title: t('order'),
      //       path: paths.dashboard.order.root,
      //       icon: ICONS.order,
      //       children: [
      //         { title: t('list'), path: paths.dashboard.order.root },
      //         { title: t('details'), path: paths.dashboard.order.demo.details },
      //       ],
      //     },

    

      //     // BLOG
      //     {
      //       title: t('blog'),
      //       path: paths.dashboard.post.root,
      //       icon: ICONS.blog,
      //       children: [
      //         { title: t('list'), path: paths.dashboard.post.root },
      //         { title: t('details'), path: paths.dashboard.post.demo.details },
      //         { title: t('create'), path: paths.dashboard.post.new },
      //         { title: t('edit'), path: paths.dashboard.post.demo.edit },
      //       ],
      //     },

      

      //     // TOUR
      //     {
      //       title: t('tour'),
      //       path: paths.dashboard.tour.root,
      //       icon: ICONS.tour,
      //       children: [
      //         { title: t('list'), path: paths.dashboard.tour.root },
      //         { title: t('details'), path: paths.dashboard.tour.demo.details },
      //         { title: t('create'), path: paths.dashboard.tour.new },
      //         { title: t('edit'), path: paths.dashboard.tour.demo.edit },
      //       ],
      //     },

      //     // FILE MANAGER
      //     {
      //       title: t('file_manager'),
      //       path: paths.dashboard.fileManager,
      //       icon: ICONS.folder,
      //     },

      //     // MAIL
      //     {
      //       title: t('mail'),
      //       path: paths.dashboard.mail,
      //       icon: ICONS.mail,
      //       info: <Label color="error">+32</Label>,
      //     },

      //     // CHAT
      //     {
      //       title: t('chat'),
      //       path: paths.dashboard.chat,
      //       icon: ICONS.chat,
      //     },

      //     // CALENDAR
      //     {
      //       title: t('calendar'),
      //       path: paths.dashboard.calendar,
      //       icon: ICONS.calendar,
      //     },

      //     // KANBAN
      //     {
      //       title: t('kanban'),
      //       path: paths.dashboard.kanban,
      //       icon: ICONS.kanban,
      //     },
      //   ],
      // },

      // // DEMO MENU STATES
      // {
      //   subheader: t(t('other_cases')),
      //   items: [
      //     {
      //       // default roles : All roles can see this entry.
      //       // roles: ['user'] Only users can see this item.
      //       // roles: ['admin'] Only admin can see this item.
      //       // roles: ['admin', 'manager'] Only admin/manager can see this item.
      //       // Reference from 'src/guards/RoleBasedGuard'.
      //       title: t('item_by_roles'),
      //       path: paths.dashboard.permission,
      //       icon: ICONS.lock,
      //       roles: ['admin', 'manager'],
      //       caption: t('only_admin_can_see_this_item'),
      //     },
      //     {
      //       title: t('menu_level'),
      //       path: '#/dashboard/menu_level',
      //       icon: ICONS.menuItem,
      //       children: [
      //         {
      //           title: t('menu_level_1a'),
      //           path: '#/dashboard/menu_level/menu_level_1a',
      //         },
      //         {
      //           title: t('menu_level_1b'),
      //           path: '#/dashboard/menu_level/menu_level_1b',
      //           children: [
      //             {
      //               title: t('menu_level_2a'),
      //               path: '#/dashboard/menu_level/menu_level_1b/menu_level_2a',
      //             },
      //             {
      //               title: t('menu_level_2b'),
      //               path: '#/dashboard/menu_level/menu_level_1b/menu_level_2b',
      //               children: [
      //                 {
      //                   title: t('menu_level_3a'),
      //                   path: '#/dashboard/menu_level/menu_level_1b/menu_level_2b/menu_level_3a',
      //                 },
      //                 {
      //                   title: t('menu_level_3b'),
      //                   path: '#/dashboard/menu_level/menu_level_1b/menu_level_2b/menu_level_3b',
      //                 },
      //               ],
      //             },
      //           ],
      //         },
      //       ],
      //     },
      //     {
      //       title: t('item_disabled'),
      //       path: '#disabled',
      //       icon: ICONS.disabled,
      //       disabled: true,
      //     },
      //     {
      //       title: t('item_label'),
      //       path: '#label',
      //       icon: ICONS.label,
      //       info: (
      //         <Label color="info" startIcon={<Iconify icon="solar:bell-bing-bold-duotone" />}>
      //           NEW
      //         </Label>
      //       ),
      //     },
      //     {
      //       title: t('item_caption'),
      //       path: '#caption',
      //       icon: ICONS.menuItem,
      //       caption:
      //         'Quisque malesuada placerat nisl. In hac habitasse platea dictumst. Cras id dui. Pellentesque commodo eros a enim. Morbi mollis tellus ac sapien.',
      //     },
      //     {
      //       title: t('item_external_link'),
      //       path: 'https://www.google.com/',
      //       icon: ICONS.external,
      //     },
      //     {
      //       title: t('blank'),
      //       path: paths.dashboard.blank,
      //       icon: ICONS.blank,
      //     },
        ],
      },
    ],
    [t]
  );

  return data;
}
