import { Helmet } from "react-helmet-async";
import { useLocales } from "src/locales";
import SchemeCategoryListView from "src/sections/masters/general-settings/scheme-category/scheme-category-list-view";

export default function SchemeCategoryListPage() {
  const {t}=useLocales();
    return (
      <>
        <Helmet>
          <title> { t("SCHEMECATEGORY.PAGE.TITLE")}</title>
        </Helmet>
  
        <SchemeCategoryListView/>
      </>
    );
  }