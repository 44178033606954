export const CUSTOMER_COLUMNS = ["customerName","mobileNumber","whatsAppNumber","useContactAsWhatsapp","dateofBirth","gender"];

export const TABLE_HEAD_CUSTOMER = [
    { id: 'customerName', label: "CUSTOMER.VIEW.GRID.CUSTOMERNAME" },
    { id: 'mobileNumber', label: "CUSTOMER.VIEW.GRID.MOBILENUMBER" },
    { id: 'whatsAppNumber', label: "CUSTOMER.VIEW.GRID.WHATSAPPNUMBER" },
    { id: 'useContactAsWhatsapp', label: "CUSTOMER.VIEW.GRID.USECONTACTASWHATSAPP" },
    { id: 'dateofBirth', label: "CUSTOMER.VIEW.GRID.DATEOFBIRTH" },
    { id: 'gender', label: "CUSTOMER.VIEW.GRID.GENDER" },
    { id: 'id', width: 88 },
  ];

  export const TABLE_HEAD_ADDRESSDETAILS = [
    { id: 'customerName', label: "COMMON.FORM.GRID.ADDRESSLINE1" },
    { id: 'modelDescription', label: "COMMON.FORM.GRID.ADDRESSLINE2" },
    { id: 'bookingNumber', label: "COMMON.FORM.GRID.LANDMARK" },
    { id: 'invoiceNoteNumber', label: "COMMON.FORM.GRID.CITY" },
    { id: 'invoiceNoteNumber', label: "COMMON.FORM.GRID.MANDAL" },
    { id: 'invoiceNoteNumber', label: "COMMON.FORM.GRID.DISTRICT" },
    { id: 'deliveryNoteNumber', label: "COMMON.FORM.GRID.STATE" },
    { id: 'deliveryNoteNumber', label: "COMMON.FORM.GRID.COUNTRY" },
    { id: 'deliveryNoteNumber', label: "COMMON.FORM.GRID.PINCODE" },
    { id: 'id', width: 88 },
  ];

  export const TABLE_HEAD_CONTACTDETAILS = [
    { id: 'customerName', label: "COMMON.FORM.GRID.PROPOSECONTACT" },
    { id: 'modelDescription', label: "COMMON.FORM.GRID.MOBILENUMBER" },
    { id: 'bookingNumber', label: "COMMON.FORM.GRID.ALTERNATENUMBER" },
    { id: 'invoiceNoteNumber', label: "COMMON.FORM.GRID.GENDER" },
    { id: 'deliveryNoteNumber', label: "COMMON.FORM.GRID.CUSTOMERNAME" },
    { id: 'deliveryNoteNumber', label: "COMMON.FORM.GRID.EMAILADDRESS" },
    { id: 'id', width: 88 },
  ];

  export const TABLE_HEAD_DOCUMENTDETAILS = [
    { id: 'customerName', label: "COMMON.FORM.GRID.DOCUMENTTYPE" },
    { id: 'modelDescription', label: "COMMON.FORM.GRID.FILENAME" }, 
    { id: 'id', width: 88 },
  ];

export const defaultFilters = {
    name: '',
    status: [],
  };
  