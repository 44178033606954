import { IAddressItem } from "../address";
import { IContact, ICustomerAccountDetails, IFamilyDetail, ISupportingDocument } from "../common";
import { IErrorType } from "../error";

export type ICustomerTableFilters = {
    name: string;
    status: string[];
  };
  
export type ICustomerTableFilterValue = string | string[];

export type ICustomer = {
    id:number; 
    name?:string;
    mobileNumber?:string;
    customerTypeId?:string;
    emailAddress?:string;
    contactOverWhatApp?:boolean;
    useMobileNumberAsWhatApp?:boolean;
    whatAppNumber?:string;
    corporateTypeId?:string;
    corporateId?:string;
    corporateCode?:string;
    corporateCategoryId?:string;
    dateOfBirth?:string;
    genderId?:string;
    maritalStatusId?:string;
    weddingAnivarsary?:string;
    occupation?:string;
    annualIncome?:string;
    drivingLicenceNumber?:string;
    aadharNumber?:string;
    voterId?:string;
    vehicleUsed?:string;
    motherTough?:string;
    pannumber?:string;
    gsitNumber?:string;
    tinNumber?:string;
    tanNumber?:string;
    usageCategoryId?:string;
    usageSubcategoryId?:string;
    customerCategoryId?:string;
    businessDetailId?:string;
    vehicleEmployeeDetails?:string;
    keyRoleDetails?:string;
    majorRoleDetail?:string;
    addressList?:IAddressItem[];
    address?:IAddressItem[];
    contactList?:IContact[];
    contact?:IContact[];
    familyList?:IFamilyDetail[];
    family?:IFamilyDetail[];
    accountDetail?:ICustomerAccountDetails;
    supportingDocument?:ISupportingDocument[];
    supportingDocumentList?:ISupportingDocument[];
    description?:string;
}
 
export type ICustomerState = {
  customer: ICustomer[];
  customerStatus: {
    loading: boolean;
    empty: boolean;
    error: IErrorType;
  };
  customerDelete: {
    loading:boolean;
    empty:any;
    error:IErrorType;
  }
};

export const defaultFilters = {
  name: '',
  status: [],
};