import { createSlice, Dispatch } from '@reduxjs/toolkit';
import { IUsedVehicleModelState } from 'src/types/used.vehicle.model';
// utils
import axios, { API_ENDPOINTS } from 'src/utils/axios';


const initialState: IUsedVehicleModelState = {
    usedVehicleModels:[],
    usedVehicleModel:null,
    usedVehicleModelsStatus:{
        loading:false,
        empty:false,
        error:null
    },
    usedVehicleModelStatus:{
        loading:false,
        error:null
    },
    usedVehicleModelDelete: {
        loading:false,
        empty:false,
        error:null
    }
}

const slice = createSlice({
    name: 'usedVehicleModel',
    initialState,
    reducers:{
        getUsedVehicleModelsStart(state) {
            state.usedVehicleModelsStatus.loading=true;
            state.usedVehicleModelsStatus.empty=false;
            state.usedVehicleModelsStatus.error = null;
        },
        getUsedVehicleModelsFailure(state, action) {
            state.usedVehicleModelsStatus.loading=true;
            state.usedVehicleModelsStatus.empty=false;
            state.usedVehicleModelsStatus.error = action.payload;
        },
        getUsedVehicleModelsSuccess(state, action) {
            const usedVehicleModels = action.payload;
            state.usedVehicleModels = usedVehicleModels;
            state.usedVehicleModelsStatus.loading = false;
            state.usedVehicleModelsStatus.empty = !usedVehicleModels.length;
            state.usedVehicleModelsStatus.error = null;
        }
        ,
        deleteUsedVehicleModelStart(state) {
            state.usedVehicleModelDelete.loading=true;
            state.usedVehicleModelDelete.empty=false;
            state.usedVehicleModelDelete.error = null;
        },
        deleteUsedVehicleModelFailure(state, action) {
            state.usedVehicleModelDelete.loading=true;
            state.usedVehicleModelDelete.empty=false;
            state.usedVehicleModelDelete.error = action.payload;
        },
        deleteUsedVehicleModelSuccess(state, action) {
            const usedVehicleModelDelete = action.payload;
            state.usedVehicleModelDelete = usedVehicleModelDelete;
            state.usedVehicleModelDelete.loading = false;
            state.usedVehicleModelDelete.empty = action.payload;
            state.usedVehicleModelDelete.error = null;
        }
    }
});

export default slice.reducer;

// Actions
export const {
    getUsedVehicleModelsStart,
    getUsedVehicleModelsFailure,
    getUsedVehicleModelsSuccess,
    deleteUsedVehicleModelStart,
    deleteUsedVehicleModelFailure,
    deleteUsedVehicleModelSuccess
} = slice.actions;

export function getUsedVehicleModels(data:any) {
        return async (dispatch: Dispatch) => {
            dispatch(slice.actions.getUsedVehicleModelsStart());
            try{
               // const response = await axios.post(API_ENDPOINTS.VEHICLECLASS.SEARCH,data);
                dispatch(slice.actions.getUsedVehicleModelsSuccess([
                    {usedVehicleModelId:1,vehicleName:'Swift Dezire',varient:"LXI",fuelType:'PETROL',year:'2021',kmsdriven:'2000',status:true},
                    {usedVehicleModelId:2,vehicleName:'Swift',varient:"ZXI",fuelType:'DESIEL',year:'2022',kmsdriven:'1000',status:false}
                ]
                    ));
            }catch(error){
                dispatch(slice.actions.getUsedVehicleModelsFailure(error));
            }
        }
}

export function deleteUsedVehicleModel(id: any) {
    return async (dispatch: Dispatch) => {
        dispatch(slice.actions.deleteUsedVehicleModelStart());
        try{
            const response = await axios.get(API_ENDPOINTS.BODYTYPE.DELETE+id);
            dispatch(slice.actions.deleteUsedVehicleModelSuccess(response.data));
        } catch(error){
            dispatch(slice.actions.deleteUsedVehicleModelFailure(error));
        }
    }
}
