import { createSlice, Dispatch } from '@reduxjs/toolkit';
import { IEnquiryCategoryState } from 'src/types/lead-settings/lead.category.type';
// utils
import axios, { API_ENDPOINTS } from 'src/utils/axios';

const initialState: IEnquiryCategoryState = {
    enquiryCategory:[],
    enquiryCategoryStatus:{
        loading:false,
        empty:false,
        error:null
    },
    enquiryCategoryDelete: {
        loading:false,
        empty:false,
        error:null
    }
}

const slice = createSlice({
    name: 'enquiryCategory',
    initialState,
    reducers:{
        getLeadCategoryStart(state) {
            state.enquiryCategoryStatus.loading=true;
            state.enquiryCategoryStatus.empty=false;
            state.enquiryCategoryStatus.error = null;
        },
        getLeadCategoryFailure(state, action) {
            state.enquiryCategoryStatus.loading=true;
            state.enquiryCategoryStatus.empty=false;
            state.enquiryCategoryStatus.error = action.payload;
        },
        getLeadCategorySuccess(state, action) {
            const enquiryCategory = action.payload;
            state.enquiryCategory = enquiryCategory;
            state.enquiryCategoryStatus.loading = false;
            state.enquiryCategoryStatus.empty = !enquiryCategory.length;
            state.enquiryCategoryStatus.error = null;
        },
        deleteLeadCategoryStart(state) {
            state.enquiryCategoryDelete.loading=true;
            state.enquiryCategoryDelete.empty=false;
            state.enquiryCategoryDelete.error = null;
        },
        deleteLeadCategoryFailure(state, action) {
            state.enquiryCategoryDelete.loading=true;
            state.enquiryCategoryDelete.empty=false;
            state.enquiryCategoryDelete.error = action.payload;
        },
        deleteLeadCategorySuccess(state, action) {
            const enquiryCategoryDelete = action.payload;
            state.enquiryCategoryDelete = enquiryCategoryDelete;
            state.enquiryCategoryDelete.loading = false;
            state.enquiryCategoryDelete.empty = action.payload;
            state.enquiryCategoryDelete.error = null;
        }
    }
});

export default slice.reducer;

// Actions
export const {
    getLeadCategoryStart,
    getLeadCategoryFailure,
    getLeadCategorySuccess,
    deleteLeadCategoryStart,
    deleteLeadCategoryFailure,
    deleteLeadCategorySuccess
} = slice.actions;

export function getLeadCategory(data:any) {
    return async (dispatch: Dispatch) => {
        dispatch(slice.actions.getLeadCategoryStart());
        try {
            await axios.get(API_ENDPOINTS.ENQUIRYSETTINGS.ENQUIRYCATEGORY.LIST).then((res:any)=> {
                if(res.data.statusCode === 200) {
                    dispatch(slice.actions.getLeadCategorySuccess([]));
                    dispatch(slice.actions.getLeadCategorySuccess(res.data.data));
                }
            else 
                 dispatch(slice.actions.getLeadCategorySuccess([]));
            });
        }catch(error){
            dispatch(slice.actions.getLeadCategoryFailure(error));
        }
    }
}


export function deleteEnquiryCategory(id: any) {
    return  axios.delete(`${API_ENDPOINTS.ENQUIRYSETTINGS.ENQUIRYCATEGORY.DELETE}/${id}`);
}

export function addUpdateEnquiryCategory(data: any) {
    return axios.post(API_ENDPOINTS.ENQUIRYSETTINGS.ENQUIRYCATEGORY.ADDUPDATEENQUIRYCATEGORY,data);
}
