import { Helmet } from "react-helmet-async";
import { useLocales } from "src/locales";
import CustomerTypeListView from "src/sections/masters/general-settings/customer-type/customer-type-list-view";


export default function CustomerTypeListPage() {
  const {t}=useLocales();
    return (
      <>
        <Helmet>
          <title> { t("CUSTOMERTYPE.PAGE.TITLE")}</title>
        </Helmet>
        <CustomerTypeListView/>
      </>
    );
  }