import { IErrorType } from "../error";

export type IOwnerTableFilters = {
    name: string;
    status: string[];
  };
  
export type IOwnerTableFilterValue = string | string[];

export type IOwner = {
    id:number;
    name:string;
    description:string;
    displayOrder:number;
    status:boolean;
}
 
export type IOwnerState = {
  owner: IOwner[];
  ownerStatus: {
    loading: boolean;
    empty: boolean;
    error: IErrorType;
  };
  ownerDelete: {
    loading:boolean;
    empty:any;
    error:IErrorType;
  }
};

export const defaultFilters = {
  name: '',
  status: [],
};