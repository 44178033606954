import { IErrorType } from "../error";

export type IEnquiryStatusTableFilters = {
    name: string;
    status: string[];
  };
  
export type IEnquiryStatusTableFilterValue = string | string[];

export type IEnquiryStatus = {
    id:number;
    name:string;
    enquiryCategoryId:string
    color:string;
    image:string;
    description:string;
    displayOrder:number;
    status:boolean;
}
  
export type IEnquiryStatusState = {
  enquiryStatus: IEnquiryStatus[];
  enquiryStatusStatus: {
    loading: boolean;
    empty: boolean;
    error: IErrorType;
  };
  enquiryStatusDelete: {
    loading:boolean;
    empty:any;
    error:IErrorType;
  }
};

export const defaultFilters = {
  name: '',
  status: [],
};