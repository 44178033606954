import { createSlice, Dispatch } from '@reduxjs/toolkit';
import { IFuelTypeState } from 'src/types/fuel.type';
// utils
import axios, { API_ENDPOINTS } from 'src/utils/axios';


const initialState: IFuelTypeState = {
    fuelTypes:[],
    fuelType:null,
    fuelTypesStatus:{
        loading:false,
        empty:false,
        error:null
    },
    fuelTypeStatus:{
        loading:false,
        error:null
    },
    fuelTypeDelete: {
        loading:false,
        empty:false,
        error:null
    }
}

const slice = createSlice({
    name: 'fuelType',
    initialState,
    reducers:{
        getFuelTypesStart(state) {
            state.fuelTypesStatus.loading=true;
            state.fuelTypesStatus.empty=false;
            state.fuelTypesStatus.error = null;
        },
        getFuelTypesFailure(state, action) {
            state.fuelTypesStatus.loading=true;
            state.fuelTypesStatus.empty=false;
            state.fuelTypesStatus.error = action.payload;
        },
        getFuelTypesSuccess(state, action) {
            const fuelTypes = action.payload;
            state.fuelTypes = fuelTypes;
            state.fuelTypesStatus.loading = false;
            state.fuelTypesStatus.empty = !fuelTypes.length;
            state.fuelTypesStatus.error = null;
        }
        ,
        deleteFuelTypeStart(state) {
            state.fuelTypeDelete.loading=true;
            state.fuelTypeDelete.empty=false;
            state.fuelTypeDelete.error = null;
        },
        deleteFuelTypeFailure(state, action) {
            state.fuelTypeDelete.loading=true;
            state.fuelTypeDelete.empty=false;
            state.fuelTypeDelete.error = action.payload;
        },
        deleteFuelTypeSuccess(state, action) {
            const fuelTypeDelete = action.payload;
            state.fuelTypeDelete = fuelTypeDelete;
            state.fuelTypeDelete.loading = false;
            state.fuelTypeDelete.empty = action.payload;
            state.fuelTypeDelete.error = null;
        }
    }
});

export default slice.reducer;

// Actions
export const {
    getFuelTypesStart,
    getFuelTypesFailure,
    getFuelTypesSuccess,
    deleteFuelTypeStart,
    deleteFuelTypeFailure,
    deleteFuelTypeSuccess
} = slice.actions;
 
export function getFuelTypes(data:any) {
    return async (dispatch: Dispatch) => {
        dispatch(slice.actions.getFuelTypesStart());
        try {
            await axios.get(API_ENDPOINTS.PRODUCTSETTTINGS.FUELTYPE.LIST).then((res:any)=> {
                if(res.data.statusCode === 200)
                dispatch(slice.actions.getFuelTypesSuccess(res.data.data));
            else 
                 dispatch(slice.actions.getFuelTypesFailure([]));
            });
        }catch(error){
            dispatch(slice.actions.getFuelTypesFailure(error));
        }
    }
}

export function deleteFuelType(id: any) {
return  axios.delete(`${API_ENDPOINTS.PRODUCTSETTTINGS.FUELTYPE.DELETE}/${id}`);
}

export function addUpdateFuelType(data: any) {
return axios.post(API_ENDPOINTS.PRODUCTSETTTINGS.FUELTYPE.ADDUPDATEFUELTYPE,data);
}