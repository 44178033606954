import { createSlice, Dispatch } from '@reduxjs/toolkit';
import { IBankNameState } from 'src/types/general-settings/bankname';
// utils
import axios, { API_ENDPOINTS } from 'src/utils/axios';

const initialState: IBankNameState = {
    bankName:[],
    bankNameStatus:{
        loading:false,
        empty:false,
        error:null
    },
    bankNameDelete: {
        loading:false,
        empty:false,
        error:null
    }
}

const slice = createSlice({
    name: 'bankName',
    initialState,
    reducers:{
        getBankNameStart(state) {
            state.bankNameStatus.loading=true;
            state.bankNameStatus.empty=false;
            state.bankNameStatus.error = null;
        },
        getBankNameFailure(state, action) {
            state.bankNameStatus.loading=true;
            state.bankNameStatus.empty=false;
            state.bankNameStatus.error = action.payload;
        },
        getBankNameSuccess(state, action) {
            const bankName = action.payload;
            state.bankName = bankName;
            state.bankNameStatus.loading = false;
            state.bankNameStatus.empty = !bankName.length;
            state.bankNameStatus.error = null;
        },
        deleteBankNameStart(state) {
            state.bankNameDelete.loading=true;
            state.bankNameDelete.empty=false;
            state.bankNameDelete.error = null;
        },
        deleteBankNameFailure(state, action) {
            state.bankNameDelete.loading=true;
            state.bankNameDelete.empty=false;
            state.bankNameDelete.error = action.payload;
        },
        deleteBankNameSuccess(state, action) {
            const bankNameDelete = action.payload;
            state.bankNameDelete = bankNameDelete;
            state.bankNameDelete.loading = false;
            state.bankNameDelete.empty = action.payload;
            state.bankNameDelete.error = null;
        }
    }
});

export default slice.reducer;

// Actions
export const {
    getBankNameStart,
    getBankNameFailure,
    getBankNameSuccess,
    deleteBankNameStart,
    deleteBankNameFailure,
    deleteBankNameSuccess
} = slice.actions;
 

export function getBankNames(data:any) {
    return async (dispatch: Dispatch) => {
        dispatch(slice.actions.getBankNameStart());
        try {
            await axios.get(API_ENDPOINTS.GENERALSETTINGS.BANKNAME.LIST).then((res:any)=> {
                if(res.data.statusCode === 200)
                dispatch(slice.actions.getBankNameSuccess(res.data.data));
            else 
                 dispatch(slice.actions.getBankNameFailure([]));
            });
        }catch(error){
            dispatch(slice.actions.getBankNameFailure(error));
        }
    }
}

export function deleteBankName(id: any) {
return  axios.delete(`${API_ENDPOINTS.GENERALSETTINGS.BANKNAME.DELETE}/${id}`);
}

export function addUpdateBankName(data: any) {
return axios.post(API_ENDPOINTS.GENERALSETTINGS.BANKNAME.ADDUPDATEBANKNAME,data);
}