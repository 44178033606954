import { createSlice, Dispatch } from '@reduxjs/toolkit';
import { IStateState } from 'src/types/state';
// utils
import axios, { API_ENDPOINTS } from 'src/utils/axios';


const initialState: IStateState = {
    states:[],
    state:null,
    statesStatus:{
        loading:false,
        empty:false,
        error:null
    },
    stateStatus:{
        loading:false,
        error:null
    },
    stateDelete: {
        loading:false,
        empty:false,
        error:null
    }
}

const slice = createSlice({
    name: 'state',
    initialState,
    reducers:{
        getStatesStart(state) {
            state.statesStatus.loading=true;
            state.statesStatus.empty=false;
            state.statesStatus.error = null;
        },
        getStatesFailure(state, action) {
            state.statesStatus.loading=true;
            state.statesStatus.empty=false;
            state.statesStatus.error = action.payload;
        },
        getStatesSuccess(state, action) {
            const states = action.payload;
            state.states = states;
            state.statesStatus.loading = false;
            state.statesStatus.empty = !states.length;
            state.statesStatus.error = null;
        }
        ,
        deleteStateStart(state) {
            state.stateDelete.loading=true;
            state.stateDelete.empty=false;
            state.stateDelete.error = null;
        },
        deleteStateFailure(state, action) {
            state.stateDelete.loading=true;
            state.stateDelete.empty=false;
            state.stateDelete.error = action.payload;
        },
        deleteStateSuccess(state, action) {
            const stateDelete = action.payload;
            state.stateDelete = stateDelete;
            state.stateDelete.loading = false;
            state.stateDelete.empty = action.payload;
            state.stateDelete.error = null;
        }
    }
});

export default slice.reducer;

// Actions
export const {
    getStatesStart,
    getStatesFailure,
    getStatesSuccess,
    deleteStateStart,
    deleteStateFailure,
    deleteStateSuccess
} = slice.actions;

export function getStates(data:any) {
        return async (dispatch: Dispatch) => {
            dispatch(slice.actions.getStatesStart());
            try{
               // const response = await axios.post(API_ENDPOINTS.VEHICLECLASS.SEARCH,data);
                dispatch(slice.actions.getStatesSuccess([
                    {id:1,stateName:'Telangana',countryName:'India',gststatecode:"36",stateCode:"TS",status:true},
                    {id:2,stateName:'Andhra Pradesh',countryName:'India',gststatecode:"37",stateCode:"AP",status:true}
                ]
                    ));
            }catch(error){
                dispatch(slice.actions.getStatesFailure(error));
            }
        }
}

export function deleteState(id: any) {
    return async (dispatch: Dispatch) => {
        dispatch(slice.actions.deleteStateStart());
        try{
            const response = await axios.get(API_ENDPOINTS.BODYTYPE.DELETE+id);
            dispatch(slice.actions.deleteStateSuccess(response.data));
        } catch(error){
            dispatch(slice.actions.deleteStateFailure(error));
        }
    }
}
