import { IErrorType } from "../error";

export type IAllotmentTableFilters = {
    name: string;
    status: string[];
  };
  
export type IAllotmentTableFilterValue = string | string[];

export type IAllotment = {
    id:number;
    vinNumber:string;
    vechicleAge:string;
    pid:string;
    vehicleStatusId:string;
    oemInvoiceDate:string;
    invoiceNumber:string;
    vehicleReceivedDate:string;
    modelFamily:string;
    model:string;
    varientTypeId:string;
    colorId:string;
    seatingCapacityId:string;
    modelCodeId:string;
    grnNumber:string;
    grnDate:string;
    bookingNumber:string;
    description:string;
}
 
export type IAllotmentState = {
  allotment: IAllotment[];
  allotmentStatus: {
    loading: boolean;
    empty: boolean;
    error: IErrorType;
  };
  allotmentDelete: {
    loading:boolean;
    empty:any;
    error:IErrorType;
  }
};

export const defaultFilters = {
  name: '',
  status: [],
};