import {useSelector } from "src/redux/store";

export default function useHypothecated() {
    const { hypothecatedStatus,hypothecated} = useSelector(
        (state) => state.hypothecated
    );

    return {
        hypothecatedStatus,hypothecated,
    }
}