 
import {  useSelector } from "src/redux/store";

export default function useGender() {
    const { gender,genderStatus} = useSelector(
        (state) => state.gender
    );
 
    return {
        gender,genderStatus,
    }
}