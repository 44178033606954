import { createSlice, Dispatch } from '@reduxjs/toolkit';
import { IFinanceArrangeByState } from 'src/types/general-settings/finance.arrange.by';
// utils
import axios, { API_ENDPOINTS } from 'src/utils/axios';

const initialState: IFinanceArrangeByState = {
    financeArrangeBy:[],
    financeArrangeByStatus:{
        loading:false,
        empty:false,
        error:null
    },
    financeArrangeByDelete: {
        loading:false,
        empty:false,
        error:null
    }
}

const slice = createSlice({
    name: 'financeArrangeBy',
    initialState,
    reducers:{
        getFinanceArrangeByStart(state) {
            state.financeArrangeByStatus.loading=true;
            state.financeArrangeByStatus.empty=false;
            state.financeArrangeByStatus.error = null;
        },
        getFinanceArrangeByFailure(state, action) {
            state.financeArrangeByStatus.loading=true;
            state.financeArrangeByStatus.empty=false;
            state.financeArrangeByStatus.error = action.payload;
        },
        getFinanceArrangeBySuccess(state, action) {
            const financeArrangeBy = action.payload;
            state.financeArrangeBy = financeArrangeBy;
            state.financeArrangeByStatus.loading = false;
            state.financeArrangeByStatus.empty = !financeArrangeBy.length;
            state.financeArrangeByStatus.error = null;
        },
        deleteFinanceArrangeByStart(state) {
            state.financeArrangeByDelete.loading=true;
            state.financeArrangeByDelete.empty=false;
            state.financeArrangeByDelete.error = null;
        },
        deleteFinanceArrangeByFailure(state, action) {
            state.financeArrangeByDelete.loading=true;
            state.financeArrangeByDelete.empty=false;
            state.financeArrangeByDelete.error = action.payload;
        },
        deleteFinanceArrangeBySuccess(state, action) {
            const financeArrangeByDelete = action.payload;
            state.financeArrangeByDelete = financeArrangeByDelete;
            state.financeArrangeByDelete.loading = false;
            state.financeArrangeByDelete.empty = action.payload;
            state.financeArrangeByDelete.error = null;
        }
    }
});

export default slice.reducer;

// Actions
export const {
    getFinanceArrangeByStart,
    getFinanceArrangeByFailure,
    getFinanceArrangeBySuccess,
    deleteFinanceArrangeByStart,
    deleteFinanceArrangeByFailure,
    deleteFinanceArrangeBySuccess
} = slice.actions;
 

export function getFinanceArrangeBies(data:any) {
    return async (dispatch: Dispatch) => {
        dispatch(slice.actions.getFinanceArrangeByStart());
        try {
            await axios.get(API_ENDPOINTS.GENERALSETTINGS.FINANCEARRANGEBY.LIST).then((res:any)=> {
                if(res.data.statusCode === 200)
                dispatch(slice.actions.getFinanceArrangeBySuccess(res.data.data));
            else 
                 dispatch(slice.actions.getFinanceArrangeByFailure([]));
            });
        }catch(error){
            dispatch(slice.actions.getFinanceArrangeByFailure(error));
        }
    }
}

export function deleteFinanceArrangeBy(id: any) {
return  axios.delete(`${API_ENDPOINTS.GENERALSETTINGS.FINANCEARRANGEBY.DELETE}/${id}`);
}

export function addUpdateFinanceArrangeBy(data: any) {
return axios.post(API_ENDPOINTS.GENERALSETTINGS.FINANCEARRANGEBY.ADDUPDATEFINANCEARRANGEBY,data);
}