import axios from 'axios';
// config
import { HOST_API } from 'src/config-global';

// ----------------------------------------------------------------------
const axiosInstance = axios.create({ baseURL: HOST_API });

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => Promise.reject((error.response && error.response.data) || 'Something went wrong')
);

export default axiosInstance;

export const API_ENDPOINTS = {
  chat: '/api/chat',
  kanban: '/api/kanban',
  calendar: '/api/calendar',
  auth: {
    me: '/api/auth/me',
    login: '/api/auth/login',
    register: '/api/auth/register',
  },
  mail: {
    list: '/api/mail/list',
    details: '/api/mail/details',
    labels: '/api/mail/labels',
  },
  post: {
    list: '/api/post/list',
    details: '/api/post/details',
    latest: '/api/post/latest',
    search: '/api/post/search',
  },
  product: {
    list: '/api/product/list',
    details: '/api/product/details',
    search: '/api/product/search',
  },
  VEHICLECLASS: {
    LIST: '/api/vehicleClass/list',
    DELETE: '/api/vehicleClass/delete/',
    SEARCH: '/api/vehicleClass/search',
  },
  BODYTYPE: {
    LIST: '/api/vehicleClass/list',
    DELETE: '/api/vehicleClass/delete/',
    SEARCH: '/api/vehicleClass/search',
  },
  LOOKUP: {
    LIST: 'api/Lookup/GetLookupByKey/',
    DELETE: '/api/vehicleClass/delete/',
    SEARCH: '/api/vehicleClass/search',
  },
  COUNTRY: {
    LIST: '/api/lookup/',
    DELETE: '/api/vehicleClass/delete/',
    SEARCH: '/api/vehicleClass/search',
    ALL: '/api/vehicleClass/search',
  },
  VEHICLEMODEL: {
    LIST: '/api/VehicleModel/GetVehicleModels',
    ADDUPDATEVEHICLEMODEL: '/api/VehicleModel/AddUpdateVehicleModel',
    DELETE: '/api/VehicleModel/DeleteVehicleModel'
  },
  PRODUCTSETTTINGS:{
    VEHICLECLASS: {
      LIST: '/api/VehicleClass/GetVehicleClasses',
      ADDUPDATEVEHICLECLASS : '/api/VehicleClass/AddUpdateVehicleClass',
      DELETE: '/api/VehicleClass/DeleteVehicleClass',
    },
    SEGMENTDETAILS: {
      LIST: '/api/SegmentDetails/GetSegmentDetails',
      ADDUPDATESEGMENTDETAILS : '/api/SegmentDetails/AddUpdateSegmentDetails',
      DELETE: '/api/SegmentDetails/DeleteSegmentDetails',
    },
    BODYTYPE: {
      LIST: '/api/BodyType/GetBodyTypes',
      ADDUPDATEBODYTYPE : '/api/BodyType/AddUpdateBodyType',
      DELETE: '/api/BodyType/DeleteBodyType',
    },
    MANUFACTURER: {
      LIST: '/api/Manufacturer/GetManufacturers',
      ADDUPDATEMANUFACTURER : '/api/Manufacturer/AddUpdateManufacturer',
      DELETE: '/api/Manufacturer/DeleteManufacturer',
    },
    FUELTYPE: {
      LIST: '/api/FuelType/GetFuelTypes',
      ADDUPDATEFUELTYPE : '/api/FuelType/AddUpdateFuelType',
      DELETE: '/api/FuelType/DeleteFuelType'
    },
    TRANSMISSION: {
      LIST: '/api/Transmission/GetTransmissions',
      ADDUPDATETRANSMISSION: '/api/Transmission/AddUpdateTransmission',
      DELETE: '/api/Transmission/DeleteTransmission'
    },
    COLORPALATTE: {
      LIST: '/api/ColorPalatte/GetColorPalattes',
      ADDUPDATECOLORPALATTE: '/api/ColorPalatte/AddUpdateColorPalatte',
      DELETE: '/api/ColorPalatte/DeleteColorPalatte'
    }
  },
  VEHICLESETTINGS:{
    VEHICLEHISTORY: {
      LIST: '/api/VehicleHistory/GetVehicleHistories',
      ADDUPDATEVEHICLEHISTORY: '/api/VehicleHistory/AddUpdateVehicleHistory',
      DELETE: '/api/VehicleHistory/DeleteVehicleHistory'
    },
    VEHICLESTATUS: {
      LIST: '/api/VehicleStatus/GetVehicleStatuses',
      ADDUPDATEVEHICLESTATUS: '/api/VehicleStatus/AddUpdateVehicleStatus',
      DELETE: '/api/VehicleStatus/DeleteVehicleStatus'
    },
    VEHICLEOWNER: {
      LIST: '/api/VehicleOwner/GetVehicleOwners',
      ADDUPDATEVEHICLEOWNER: '/api/VehicleOwner/AddUpdateVehicleOwner',
      DELETE: '/api/VehicleOwner/DeleteVehicleOwner'
    }
  },
  SALESJOURNEY: {
    ALLOTMENT: {
      LIST: '/api/Allotment/GetAllotments',
      ADDUPDATEALLOTMENT: '/api/Allotment/AddUpdateAllotment',
      DELETE: '/api/Allotment/DeleteAllotment',
    },
    INVOICE: {
      LIST: '/api/Invoice/GetInvoices',
      ADDUPDATEINVOICE: '/api/Invoice/AddUpdateInvoice',
      DELETE: '/api/Invoice/DeleteInvoice',
    },
    DELIVERYNOTE: {
      LIST: '/api/DeliveryNote/GetDeliveryNotes',
      ADDUPDATEDELIVERYNOTE: '/api/DeliveryNote/AddUpdateDeliveryNote',
      DELETE: '/api/DeliveryNote/DeleteDeliveryNote',
    },
    CUSTOMER: {
      LIST: '/api/DeliveryNote/GetDeliveryNotes',
      ADDUPDATECUSTOMER: '/api/DeliveryNote/AddUpdateDeliveryNote',
      DELETE: '/api/DeliveryNote/DeleteDeliveryNote',
    },
  },
  ENQUIRYSETTINGS: {
    BUYERTYPE: {
      LIST: '/api/BuyerType/GetBuyerTypes',
      ADDUPDATEBUYERTYPE : '/api/BuyerType/AddUpdateBuyerType',
      DELETE: '/api/BuyerType/DeleteBuyerType',
    },
    ENQUIRYCATEGORY: {
      LIST: '/api/EnquiryCategory/GetEnquiryCategories',
      ADDUPDATEENQUIRYCATEGORY : '/api/EnquiryCategory/AddUpdateEnquiryCategory',
      DELETE: '/api/EnquiryCategory/DeleteEnquiryCategory',
    },
    ENQUIRYOWNER: {
      LIST: '/api/EnquiryOwner/GetEnquiryOwners',
      ADDUPDATEENQUIRYOWNER : '/api/EnquiryOwner/AddUpdateEnquiryOwner',
      DELETE: '/api/EnquiryOwner/DeleteEnquiryCategory',
    },
    ENQUIRYSTATUS: {
      LIST: '/api/EnquiryStatus/GetEnquiryStatuses',
      ADDUPDATEENQUIRYSTATUS : '/api/EnquiryStatus/AddUpdateEnquiryStatus',
      DELETE: '/api/EnquiryStatus/DeleteEnquiryStatus',
    },
    SALUTATION: {
      LIST: '/api/Salutation/GetSalutations',
      ADDUPDATESALUTATION : '/api/Salutation/AddUpdateSalutation',
      DELETE: '/api/Salutation/DeleteSalutation',
    },
    SOURCEOFENQUIRY: {
      LIST: '/api/SourceOfEnquiry/GetSourceOfEnquiries',
      ADDUPDATESOURCEOFENQUIRY : '/api/SourceOfEnquiry/AddUpdateSourceOfEnquiry',
      DELETE: '/api/SourceOfEnquiry/DeleteSourceOfEnquiry',
    },
    DELETE: '/api/vehicleClass/delete/',
    SEARCH: '/api/vehicleClass/search',
    ALL: '/api/vehicleClass/search',
  },
  GENERALSETTINGS:{
    SALUTATION: {
      LIST: '/api/Salutation/GetSalutations',
      ADDUPDATESALUTATION: '/api/Salutation/AddUpdateSalutation',
      DELETE: '/api/Salutation/DeleteSalutation'
    },
    GENDER: {
      LIST: '/api/Gender/GetGenders',
      ADDUPDATEGENDER: '/api/Gender/AddUpdateGender',
      DELETE: '/api/Gender/DeleteGender'
    },
    SCHEMETYPE: {
      LIST: '/api/SchemeType/GetSchemeTypes',
      ADDUPDATESCHEMETYPE: '/api/SchemeType/AddUpdateSchemeType',
      DELETE: '/api/SchemeType/DeleteSchemeType'
    },
    SCHEMECATEGORY: {
      LIST: '/api/SchemeCategory/GetSchemeCategories',
      ADDUPDATESCHEMECATEGORY: '/api/SchemeCategory/AddUpdateSchemeCategory',
      DELETE: '/api/SchemeCategory/DeleteSchemeCategory'
    },
    INSURANCECOMPANY: {
      LIST: '/api/InsuranceCompany/GetInsuranceCompanies',
      ADDUPDATEINSURANCECOMPANY: '/api/InsuranceCompany/AddUpdateInsuranceCompany',
      DELETE: '/api/InsuranceCompany/DeleteInsuranceCompany'
    },
    FINANCEARRANGEBY: {
      LIST: '/api/FinanceArrangeBy/getFinanceArrangeBies',
      ADDUPDATEFINANCEARRANGEBY: '/api/FinanceArrangeBy/AddUpdateFinanceArrangeBy',
      DELETE: '/api/FinanceArrangeBy/DeleteFinanceArrangeBy'
    },
    BANKNAME: {
      LIST: '/api/BankName/GetBankNames',
      ADDUPDATEBANKNAME: '/api/BankName/AddUpdateBankName',
      DELETE: '/api/BankName/DeleteBankName'
    },
    RELATIONSHIP: {
      LIST: '/api/RelationShip/GetRelationShips',
      ADDUPDATERELATIONSHIP: '/api/RelationShip/AddUpdateRelationShip',
      DELETE: '/api/RelationShip/DeleteRelationShip'
    },
    CUSTOMERTYPE: {
      LIST: '/api/CustomerType/GetCustomerTypes',
      ADDUPDATECUSTOMERTYPE: '/api/CustomerType/AddUpdateCustomerType',
      DELETE: '/api/CustomerType/DeleteCustomerType'
    },
    VISIT: {
      LIST: '/api/Visit/GetVisits',
      ADDUPDATEVISIT: '/api/Visit/AddUpdateVisit',
      DELETE: '/api/Visit/DeleteVisit'
    },
    HYPOTHECATED: {
      LIST: '/api/Hypothecated/GetHypothecateds',
      ADDUPDATEHYPOTHECATED: '/api/Hypothecated/AddUpdateHypothecated',
      DELETE: '/api/Hypothecated/DeleteHypothecated'
    } 
  }
};
