import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
// slices
import mailReducer from './slices/mail';
import chatReducer from './slices/chat';
import kanbanReducer from './slices/kanban';
import productReducer from './slices/product';
import calendarReducer from './slices/calendar';
import vehicleClassReducer from './slices/vehicle-class';
import bodyTypeReducer from './slices/body-type';
import segmentDetailsReducer from './slices/segment.details';
import manufacturerReducer from './slices/manufacturer';
import fuelTypeReducer from './slices/fuel.type';
import colorPalatteReducer from './slices/color.palatte';
import transmissionReducer from './slices/transmission';
import varientTypeReducer from './slices/varient.type';
import newVehicleModelReducer from './slices/new.vehicle.model';
import usedVehicleModelReducer from './slices/used.vehicle.model';
import countryReducer from './slices/geography-settings/country';
import stateReducer from './slices/geography-settings/state';
import districtReducer from './slices/geography-settings/district';
import cityReducer from './slices/geography-settings/city';
import mandalReducer from './slices/geography-settings/mandal';
import pincodeReducer from './slices/geography-settings/pincode';
import enquiryStatusReducer from './slices/lead-settings/lead-status';
import enquiryReducer from './slices/enquiry-management/enquiry';
import leadSourceReducer from './slices/lead-settings/lead-source';
import leadCategoryReducer from './slices/lead-settings/lead-category';
import buyerTypeReducer from './slices/lead-settings/buyer-type';
import ownerReducer from './slices/vehicle-settings/owner';
import vehicleHistoryReducer from './slices/vehicle-settings/vehicle.history';
import vehicleStatusReducer from './slices/vehicle-settings/vehicle.status';
import salutationReducer from './slices/general-settings/salutation';
import genderReducer from './slices/general-settings/gender';
import schemeCategoryReducer from './slices/general-settings/scheme.category';
import financeArrangeByReducer from './slices/general-settings/finance.arrange.by';
import insuranceCompanyReducer from './slices/general-settings/insurance.company';
import bankNameReducer from './slices/general-settings/bankname';
import relationShipReducer from './slices/general-settings/relation.ship';
import customerTypeReducer from './slices/general-settings/customer.type';
import visitReducer from './slices/general-settings/visit';
import schemeTypeReducer from './slices/general-settings/scheme.type';
import bookingReducer from './slices/booking/booking';
import hypothecatedReducer from './slices/general-settings/hypothecated';
import vehicleModelReducer from './slices/vehicle.model';
import languageReducer from './slices/localization/language';
import localeStringResourceReducer from './slices/localization/locale-string-resource';
import allotmentReducer from './slices/sales-journey/allotment';
import invoiceReducer from './slices/sales-journey/invoice';
import deliveryNoteReducer from './slices/sales-journey/delivery.note';
import customerReducer from './slices/sales-journey/customer';

// ----------------------------------------------------------------------

export const rootPersistConfig = {
  key: 'root',
  storage,
  keyPrefix: 'redux-',
  whitelist: [],
};

const productPersistConfig = {
  key: 'product',
  storage,
  keyPrefix: 'redux-',
  whitelist: ['checkout'],
};

export const rootReducer = combineReducers({
  customer:customerReducer,
  deliveryNote:deliveryNoteReducer,
  invoice: invoiceReducer,
  allotment: allotmentReducer,
  localeStringResource: localeStringResourceReducer,
  language: languageReducer,
  booking: bookingReducer,
  vehicleModel:vehicleModelReducer,
  hypothecated: hypothecatedReducer,
  visit: visitReducer,
  customerType: customerTypeReducer,
  relationShip: relationShipReducer,
  bankName: bankNameReducer,
  insuranceCompany: insuranceCompanyReducer,
  financeArrangeBy: financeArrangeByReducer,
  schemeType: schemeTypeReducer,
  schemeCategory: schemeCategoryReducer,
  gender: genderReducer,
  salutation: salutationReducer,
  vehicleStatus: vehicleStatusReducer,
  vehicleHistory: vehicleHistoryReducer,
  owner: ownerReducer,
  buyerType: buyerTypeReducer,
  leadCategory: leadCategoryReducer,
  leadSource: leadSourceReducer,
  enquiry: enquiryReducer,
  enquiryStatus: enquiryStatusReducer,
  country: countryReducer,
  states: stateReducer,
  district: districtReducer,
  city: cityReducer,
  mandal: mandalReducer,
  pincode: pincodeReducer,
  bodyType: bodyTypeReducer,
  vehicleClass: vehicleClassReducer,
  segmentDetail: segmentDetailsReducer,
  manufacturer: manufacturerReducer,
  fuelType: fuelTypeReducer,
  colorPalatte: colorPalatteReducer,
  transmission: transmissionReducer,
  varientType: varientTypeReducer,
  newVehicleModel: newVehicleModelReducer,
  usedVehicleModel: usedVehicleModelReducer,
  mail: mailReducer,
  chat: chatReducer,
  kanban: kanbanReducer,
  calendar: calendarReducer,
  product: persistReducer(productPersistConfig, productReducer),
});
