export const BODYTYPE_COLUMNS = ["name","status"];

export const TABLE_HEAD_BODY_TYPE = [
    { id: 'name', label: "BODYTYPE.VIEW.GRID.BODYTYPE" },
    { id: 'status', label: "BODYTYPE.VIEW.GRID.STATUS" },
    { id: 'id', width: 88 },
  ];

  
export const defaultFilters = {
    name: '',
    status: [],
  };
  