import { Helmet } from "react-helmet-async";
import { useLocales } from "src/locales";
import ColorPalatteListView from "src/sections/masters/settings/color-palatte/color-palatte-list-view";

export default function ColorPalatteListPage() {
  const {t}=useLocales();
    return (
      <>
        <Helmet>
          <title> { t("COLORPALATTE.PAGE.TITLE")}</title>
        </Helmet>
  
        <ColorPalatteListView/>
      </>
    );
  }