import { createSlice, Dispatch } from '@reduxjs/toolkit';
import { IVarientTypeState } from 'src/types/varient.type';
// utils
import axios, { API_ENDPOINTS } from 'src/utils/axios';


const initialState: IVarientTypeState = {
    varientTypes:[],
    varientType:null,
    varientTypesStatus:{
        loading:false,
        empty:false,
        error:null
    },
    varientTypeStatus:{
        loading:false,
        error:null
    },
    varientTypeDelete: {
        loading:false,
        empty:false,
        error:null
    }
}

const slice = createSlice({
    name: 'varientType',
    initialState,
    reducers:{
        getVarientTypesStart(state) {
            state.varientTypesStatus.loading=true;
            state.varientTypesStatus.empty=false;
            state.varientTypesStatus.error = null;
        },
        getVarientTypesFailure(state, action) {
            state.varientTypesStatus.loading=true;
            state.varientTypesStatus.empty=false;
            state.varientTypesStatus.error = action.payload;
        },
        getVarientTypesSuccess(state, action) {
            const varientTypes = action.payload;
            state.varientTypes = varientTypes;
            state.varientTypesStatus.loading = false;
            state.varientTypesStatus.empty = !varientTypes.length;
            state.varientTypesStatus.error = null;
        }
        ,
        deleteVarientTypeStart(state) {
            state.varientTypeDelete.loading=true;
            state.varientTypeDelete.empty=false;
            state.varientTypeDelete.error = null;
        },
        deleteVarientTypeFailure(state, action) {
            state.varientTypeDelete.loading=true;
            state.varientTypeDelete.empty=false;
            state.varientTypeDelete.error = action.payload;
        },
        deleteVarientTypeSuccess(state, action) {
            const varientTypeDelete = action.payload;
            state.varientTypeDelete = varientTypeDelete;
            state.varientTypeDelete.loading = false;
            state.varientTypeDelete.empty = action.payload;
            state.varientTypeDelete.error = null;
        }
    }
});

export default slice.reducer;

// Actions
export const {
    getVarientTypesStart,
    getVarientTypesFailure,
    getVarientTypesSuccess,
    deleteVarientTypeStart,
    deleteVarientTypeFailure,
    deleteVarientTypeSuccess
} = slice.actions;

export function getVarientTypes(data:any) {
        return async (dispatch: Dispatch) => {
            dispatch(slice.actions.getVarientTypesStart());
            try{
               // const response = await axios.post(API_ENDPOINTS.VEHICLECLASS.SEARCH,data);
                dispatch(slice.actions.getVarientTypesSuccess([
                    {id:1,varientType:'Lxi',description:"Varient",transmissionName:'transmissin Name',transmissionId:"1",fuelTypeName:"Diesel",colorCode:'#fffff',status:true},
                    {id:2,varientType:'Vxi',description:"Varient",transmissionName:'transmissin Name',transmissionId:"2",fuelTypeName:"Petrol",colorCode:'#000000',status:false}
                ]
                    ));
            }catch(error){
                dispatch(slice.actions.getVarientTypesFailure(error));
            }
        }
}

export function deleteVarientType(id: any) {
    return async (dispatch: Dispatch) => {
        dispatch(slice.actions.deleteVarientTypeStart());
        try{
            const response = await axios.get(API_ENDPOINTS.BODYTYPE.DELETE+id);
            dispatch(slice.actions.deleteVarientTypeSuccess(response.data));
        } catch(error){
            dispatch(slice.actions.deleteVarientTypeFailure(error));
        }
    }
}
