import { useCallback } from "react";
import { useSelector } from "src/redux/store";

export default function useBooking() {
    const { booking,bookingStatus} = useSelector(
        (state) => state.booking
    );


    return {
        booking,bookingStatus,
    }
}