import { useCallback } from "react";
import { deleteNewVehicleModel } from "src/redux/slices/new.vehicle.model";
import { useDispatch, useSelector } from "src/redux/store";

export default function useNewVehicleModel() {
    const dispatch = useDispatch();
    const { newVehicleModel,newVehicleModelDelete,newVehicleModelsStatus,newVehicleModels} = useSelector(
        (state) => state.newVehicleModel
    );

    const onDeleteNewVehicleModel = useCallback(
        (id: string) => {
            dispatch(deleteNewVehicleModel(id));
        },
        [dispatch]
    );

    return {
        newVehicleModel,newVehicleModelDelete,newVehicleModelsStatus,newVehicleModels,
        onDeleteNewVehicleModel
    }
}