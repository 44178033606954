import { IErrorType } from "./error";

export type IVehicleModelTableFilters = {
    name: string;
    status: string[];
  };
  
export type IVehicleModelTableFilterValue = string | string[];

export type IVehicleModel = {
    id:number;
    name:string;
    description:string;
    displayOrder:number;
    status:boolean;
}
 
export type IVehicleModelState = {
  vehicleModel: IVehicleModel[];
  vehicleModelStatus: {
    loading: boolean;
    empty: boolean;
    error: IErrorType;
  };
  vehicleModelDelete: {
    loading:boolean;
    empty:any;
    error:IErrorType;
  }
};

export const defaultFilters = {
  name: '',
  status: [],
};