export const FINANCEARRANGEBY_COLUMNS = ["name","displayOrder","status"];

export const TABLE_HEAD_FINANCEARRANGEBY = [
    { id: 'name', label: "FINANCEARRANGEBY.VIEW.GRID.NAME" },
    { id: 'displayOrder', label: "FINANCEARRANGEBY.VIEW.GRID.DISPLAYORDER" },
    { id: 'status', label: "FINANCEARRANGEBY.VIEW.GRID.STATUS" },
    { id: 'id', width: 88 },
  ];

export const defaultFilters = {
    name: '',
    status: [],
  };
  