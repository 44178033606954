export const HYPOTHECATED_COLUMNS = ["name","status"];

export const TABLE_HEAD_HYPOTHECATED = [
    { id: 'name', label: "HYPOTHECATED.VIEW.GRID.NAME" },
    { id: 'status', label: "HYPOTHECATED.VIEW.GRID.STATUS" },
    { id: 'id', width: 88 },
  ];

  
export const defaultFilters = {
    name: '',
    status: [],
  };
  