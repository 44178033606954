import { Helmet } from "react-helmet-async";
import { useLocales } from "src/locales";
import FinanceArrangeByListView from "src/sections/masters/general-settings/finance-arrange-by/finace-arrange-by-list-view";


export default function FinanceArrangeByListPage() {
  const {t}=useLocales();
    return (
      <>
        <Helmet>
          <title> { t("FINANCEARRANGEBY.PAGE.TITLE")}</title>
        </Helmet>
        <FinanceArrangeByListView/>
      </>
    );
  }