import { createSlice, Dispatch } from '@reduxjs/toolkit';
import { IBodyTypeState } from 'src/types/body.type';
// utils
import axios, { API_ENDPOINTS } from 'src/utils/axios';


const initialState: IBodyTypeState = {
    bodyTypes:[],
    bodyType:null,
    bodyTypesStatus:{
        loading:false,
        empty:false,
        error:null
    },
    bodyTypeStatus:{
        loading:false,
        error:null
    },
    bodyTypeDelete: {
        loading:false,
        empty:false,
        error:null
    }
}

const slice = createSlice({
    name: 'bodyType',
    initialState,
    reducers:{
        getBodyTypesStart(state) {
            state.bodyTypesStatus.loading=true;
            state.bodyTypesStatus.empty=false;
            state.bodyTypesStatus.error = null;
        },
        getBodyTypesFailure(state, action) {
            state.bodyTypesStatus.loading=true;
            state.bodyTypesStatus.empty=false;
            state.bodyTypesStatus.error = action.payload;
        },
        getBodyTypesSuccess(state, action) {
            const bodyTypes = action.payload;
            state.bodyTypes = bodyTypes;
            state.bodyTypesStatus.loading = false;
            state.bodyTypesStatus.empty = !bodyTypes.length;
            state.bodyTypesStatus.error = null;
        }
        ,
        deleteBodyTypeStart(state) {
            state.bodyTypeDelete.loading=true;
            state.bodyTypeDelete.empty=false;
            state.bodyTypeDelete.error = null;
        },
        deleteBodyTypeFailure(state, action) {
            state.bodyTypeDelete.loading=true;
            state.bodyTypeDelete.empty=false;
            state.bodyTypeDelete.error = action.payload;
        },
        deleteBodyTypeSuccess(state, action) {
            const bodyTypeDelete = action.payload;
            state.bodyTypeDelete = bodyTypeDelete;
            state.bodyTypeDelete.loading = false;
            state.bodyTypeDelete.empty = action.payload;
            state.bodyTypeDelete.error = null;
        }
    }
});

export default slice.reducer;

// Actions
export const {
    getBodyTypesStart,
    getBodyTypesFailure,
    getBodyTypesSuccess,
    deleteBodyTypeStart,
    deleteBodyTypeFailure,
    deleteBodyTypeSuccess
} = slice.actions;

export function getBodyTypes(data:any) {
    return async (dispatch: Dispatch) => {
        dispatch(slice.actions.getBodyTypesStart());
        try {
            await axios.get(API_ENDPOINTS.PRODUCTSETTTINGS.BODYTYPE.LIST).then((res:any)=> {
                if(res.data.statusCode === 200)
                dispatch(slice.actions.getBodyTypesSuccess(res.data.data));
            else 
                 dispatch(slice.actions.getBodyTypesFailure([]));
            });
        }catch(error){
            dispatch(slice.actions.getBodyTypesFailure(error));
        }
    }
}

export function deleteBodyType(id: any) {
return  axios.delete(`${API_ENDPOINTS.PRODUCTSETTTINGS.BODYTYPE.DELETE}/${id}`);
}

export function addUpdateBodyType(data: any) {
return axios.post(API_ENDPOINTS.PRODUCTSETTTINGS.BODYTYPE.ADDUPDATEBODYTYPE,data);
}