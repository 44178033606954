import {  useSelector } from "src/redux/store";

export default function useVarientType() {
    const { varientTypesStatus,varientTypes} = useSelector(
        (state) => state.varientType
    );


    return {
        varientTypesStatus,varientTypes,
    }
}