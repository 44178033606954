import { createSlice, Dispatch } from '@reduxjs/toolkit';
import { ICustomerState } from 'src/types/sales-journey/customer';
// utils
import axios, { API_ENDPOINTS } from 'src/utils/axios';

const initialState: ICustomerState = {
    customer:[],
    customerStatus:{
        loading:false,
        empty:false,
        error:null
    },
    customerDelete: {
        loading:false,
        empty:false,
        error:null
    }
}

const slice = createSlice({
    name: 'customer',
    initialState,
    reducers:{
        getCustomerStart(state) {
            state.customerStatus.loading=true;
            state.customerStatus.empty=false;
            state.customerStatus.error = null;
        },
        getCustomerFailure(state, action) {
            state.customerStatus.loading=true;
            state.customerStatus.empty=false;
            state.customerStatus.error = action.payload;
        },
        getCustomerSuccess(state, action) {
            const customer = action.payload;
            state.customer = customer;
            state.customerStatus.loading = false;
            state.customerStatus.empty = !customer.length;
            state.customerStatus.error = null;
        },
        deleteCustomerStart(state) {
            state.customerDelete.loading=true;
            state.customerDelete.empty=false;
            state.customerDelete.error = null;
        },
        deleteCustomerFailure(state, action) {
            state.customerDelete.loading=true;
            state.customerDelete.empty=false;
            state.customerDelete.error = action.payload;
        },
        deleteCustomerSuccess(state, action) {
            const customerDelete = action.payload;
            state.customerDelete = customerDelete;
            state.customerDelete.loading = false;
            state.customerDelete.empty = action.payload;
            state.customerDelete.error = null;
        }
    }
});

export default slice.reducer;

// Actions
export const {
    getCustomerStart,
    getCustomerFailure,
    getCustomerSuccess,
    deleteCustomerStart,
    deleteCustomerFailure,
    deleteCustomerSuccess
} = slice.actions;
 

export function getCustomers(data:any) {
    return async (dispatch: Dispatch) => {
        dispatch(slice.actions.getCustomerStart());
        try {
            await axios.get(API_ENDPOINTS.SALESJOURNEY.CUSTOMER.LIST).then((res:any)=> {
                if(res.data.statusCode === 200)
                dispatch(slice.actions.getCustomerSuccess(res.data.data));
            else 
                 dispatch(slice.actions.getCustomerFailure([]));
            });
        }catch(error){
            dispatch(slice.actions.getCustomerFailure(error));
        }
    }
}

export function deleteCustomer(id: any) {
return  axios.delete(`${API_ENDPOINTS.GENERALSETTINGS.BANKNAME.DELETE}/${id}`);
}

export function addUpdateCustomer(data: any) {
return axios.post(API_ENDPOINTS.GENERALSETTINGS.BANKNAME.ADDUPDATEBANKNAME,data);
}