import { useSelector } from "src/redux/store";

export default function useSegmentDetail() {
    const { segmentDetailsStatus,segmentDetails} = useSelector(
        (state) => state.segmentDetail
    );

    return {
      segmentDetailsStatus,segmentDetails,
    }
}