import { useCallback } from "react";
import { deleteCity } from "src/redux/slices/geography-settings/city";
import { useDispatch, useSelector } from "src/redux/store";
import { useRouter } from "src/routes/hook";

export default function useCity() {
    const dispatch = useDispatch();
    const { city,cityDelete,citiesStatus,cities} = useSelector(
        (state) => state.city
    );

    const onDeleteCity = useCallback(
        (id: string) => {
            dispatch(deleteCity(id));
        },
        [dispatch]
    );

    return {
        city,cityDelete,citiesStatus,cities,
        onDeleteCity
    }
}