import * as Yup from 'yup';
import { useSnackbar } from "src/components/snackbar";
import { CustomFile } from "src/components/upload";
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import FormProvider, { RHFAutocomplete, RHFSwitch, RHFTextField } from 'src/components/hook-form';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import LoadingButton from '@mui/lab/LoadingButton';
import { Autocomplete, Box, Chip, Dialog, DialogTitle, TextField } from '@mui/material';
import { IVarientType } from 'src/types/varient.type';
import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import { useLocales } from 'src/locales';
import { GLOBALCLASSINPUT } from 'src/utils/contants/config-common';
import { getLookUp } from 'src/redux/slices/lookup';

interface FormValuesProps extends Omit<IVarientType, 'avatarUrl'> {
    avatarUrl: CustomFile | string | null;
}

type Props = {
    title: string;
    open: boolean;
    onClose: VoidFunction;
    submitButtonText?: string;
    currentModel?: IVarientType;
};

export default function VarientTypeForm({ currentModel, open, onClose, title, submitButtonText }: Props) {
    const { enqueueSnackbar } = useSnackbar();
    const [fuelTypeLookUp, setFuelTypeLookUp] = useState([] as any);
    const [transmissionLookUp, setTransmissionLookUp] = useState([] as any);
    const [colorLookUp, setColorLookUp] = useState([] as any);
    const [showErrorMsg, setShowErrorMsg] = useState(false as any);
    const { t } = useLocales();

    const NewModelSchema = Yup.object().shape({
        varientType: Yup.string().required(t('VARIENTTYPE.FORM.VARIENTTYPENAME_REQUIRED') as any),
        description: Yup.string(),
        transmission: Yup.array(),
        fuelType: Yup.array(),
        color: Yup.array(),
        status: Yup.bool(),
    });

    const defaultValues = useMemo(() => ({
        varientType: currentModel?.varientType || "",
        description: currentModel?.description || "",
        transmission: currentModel?.transmission ||  [],
        fuelType: currentModel?.fuelType || [],
        color: currentModel?.color ||  [],
        status: !(currentModel?.status),
        id: currentModel?.id|| 0
    }), [currentModel]);


    useEffect(() => {
        getLookUp("TRANSMISSION").then((x: any) => {
            if (x) {
                setTransmissionLookUp([{ value: "1", label: "Automatic" }, { value: "2", label: "Manual" }]);
            }
        });
        getLookUp("FUELTYPE").then((x: any) => {
            if (x) {
                setFuelTypeLookUp([{ value: "1", label: "Petrol" }, { value: "2", label: "Desiel" }]);
            }
        });
        getLookUp("COLOR").then((x: any) => {
            if (x) {
                setColorLookUp([{ value: "1", label: "Red" }, { value: "2", label: "Blue" }]);
            }
        });
    }, []);

    const methods = useForm<FormValuesProps>({
        resolver: yupResolver(NewModelSchema),
        defaultValues
    });

    const {
        reset,
        handleSubmit, setValue,
        formState: { isSubmitting },
    } = methods;

    const onSubmit = useCallback(
        async (data: FormValuesProps) => {
            try {
                await new Promise((resolve) => setTimeout(resolve, 500));
                reset();
                onClose();
                enqueueSnackbar(t('VARIENTTYPE.FORM.UPDATE_SUCCESS') as any);
                console.info('DATA', data);
            } catch (error) {
                console.error(error);
            }
        }, [enqueueSnackbar, onClose, reset, t]);

    return (
        <Dialog scroll="body"
            fullWidth
            maxWidth={false}
            open={open}
            onClose={() => null}
            PaperProps={{
                sx: { maxWidth: 720 },
            }}
        >
            <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
                <DialogTitle>{title}</DialogTitle>
                <DialogContent>
                {showErrorMsg &&  <Alert variant="outlined" severity="error" sx={{ mb: 3 }}>
                        {t('VARIENTTYPE.FORM.ALREADY_EXISTS_ERROR')}
                    </Alert>}
                    <Box columnGap={2} rowGap={3} display="grid" gridTemplateColumns={{ xs: 'repeat(1, 1fr)', md: 'repeat(1, 1fr)' }}>
                        <RHFTextField size={GLOBALCLASSINPUT} name="varientType" label={t("VARIENTTYPE.FORM.VARIENTTYPENAME")} />
                        <RHFTextField size={GLOBALCLASSINPUT} name="description" label={t("VARIENTTYPE.FORM.DESCRIPTION")} />
                    </Box>
                    {currentModel?.id !== 0 && <RHFSwitch name="status" value={currentModel?.status} label={t("VARIENTTYPE.FORM.STATUS")} />}
                </DialogContent>
                <DialogActions>
                    <Button variant="outlined" onClick={onClose}>
                        {t("CANCEL")}
                    </Button>
                    <LoadingButton type="submit" variant="contained" loading={isSubmitting}>
                        {submitButtonText}
                    </LoadingButton>
                </DialogActions>
            </FormProvider>
        </Dialog>
    )

}