import * as Yup from 'yup';
import { useCallback, useEffect, useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import Box from '@mui/material/Box';
import {
    RHFTextField,
    RHFSelect,
} from 'src/components/hook-form';
import { Accordion, AccordionDetails, AccordionSummary, Button, Divider, Grid, IconButton, MenuItem, Table, TableBody, TableCell, TableHead, TableRow, Tooltip, Typography } from '@mui/material';
import { GLOBALCLASSINPUT } from 'src/utils/contants/config-common';
import { getLookUp } from 'src/redux/slices/lookup';
import { useLocales } from 'src/locales';
import { DatePicker, DateTimePicker } from '@mui/x-date-pickers';
import { ITestDrive } from 'src/types/common';
import { Controller, useForm } from 'react-hook-form';
import { TABLE_HEAD_ENQUIRYTESTDRIVE } from 'src/utils/contants/enquiry-management/config-enquiry-type';
import Iconify from 'src/components/iconify';

// ----------------------------------------------------------------------


export interface FormValuesProps extends ITestDrive {

}

type Props = {
    onChange: (address: ITestDrive) => void;
    gridTemplateColumns: any;
};

export default function TestDriveForm({ onChange, gridTemplateColumns = 3 }: Props) {
    const [tableTestDriveData, setTableTestDriveData] = useState<any[]>([]);
    const [vehicleModelLookUp, setVehicleModelLookUp] = useState([] as any);
    const { t } = useLocales();
    const NewInsuranceDetailSchema = Yup.object().shape({
        testDriveVehicleModelId: Yup.string(),
        testDriveLocation: Yup.string(),
        testDriveDate: Yup.string(),
        testDriveMobileNumber: Yup.string(),
        testDriveStartingKMS: Yup.string(),
        testDriveEndignKMS: Yup.string(),
        testDriveGenerateNumber: Yup.string(),
    });

    const defaultValues = {
        testDriveVehicleModelId: '',
        testDriveMobileNumber: "",
        testDriveLocation: '',
        testDriveDate: '',
        testDriveStartingKMS: '',
        testDriveEndignKMS: '',
        testDriveGenerateNumber: '',
    };

    useEffect(() => {
        getLookUp("VEHICLEMODEL").then((x: any) => {
            if (x && x.data && x.data.length > 0) {
                console.log(x.data)
                setVehicleModelLookUp(x.data);
            }
        });
    }, [])

    const methods = useForm<FormValuesProps>({
        resolver: yupResolver(NewInsuranceDetailSchema),
        defaultValues,
    });

    const {
        handleSubmit, control,
        // formState: { isSubmitting },
    } = methods;

    const onInputChange = useCallback(
        async (data: FormValuesProps) => {
            try {
                onChange({
                    testDriveVehicleModelId: data.testDriveVehicleModelId,
                    testDriveLocation: data.testDriveLocation,
                    testDriveDate: data.testDriveDate,
                    testDriveStartingKMS: data.testDriveStartingKMS,
                    testDriveEndignKMS: data.testDriveEndignKMS,
                    testDriveGenerateNumber: data.testDriveGenerateNumber,
                    testDriveMobileNumber: data.testDriveMobileNumber
                });
            } catch (error) {
                console.error(error);
            }
        },
        [onChange]
    );

    return (
        <>
            <Box rowGap={3} columnGap={2} display="grid" gridTemplateColumns={{ xs: 'repeat(1, 1fr)', sm: `repeat(${gridTemplateColumns}, 1fr)` }}>
                <RHFSelect size={GLOBALCLASSINPUT} name="testDriveVehicleModelId" label={t('COMMON.FORM.TESTDRIVEVEHICLEMODEL') as any} >
                    <MenuItem value="">None</MenuItem>
                    <Divider sx={{ borderStyle: 'dashed' }} />
                    {vehicleModelLookUp && vehicleModelLookUp.map((item: any) => (
                        <MenuItem key={item.value + item.label} value={item.value}> {item.label} </MenuItem>
                    ))}
                </RHFSelect>
                <RHFTextField size={GLOBALCLASSINPUT} name="testDriveLocation" label={t('COMMON.FORM.TESTDRIVELOCATION') as any} />
                <Controller
                    name="testDriveDate"
                    control={control}
                    render={({ field, fieldState: { error } }) => (
                        <DateTimePicker
                            label={t('COMMON.FORM.TESTDRIVEDATE') as any}
                            value={field.value}
                            onChange={(newValue) => {
                                field.onChange(newValue);
                            }}
                            format="dd-MMM-yyyy"
                            slotProps={{
                                textField: {
                                    size: 'small',
                                    error: !!error,
                                    helperText: error?.message,
                                },
                            }}
                        />
                    )}
                />
                <RHFTextField size={GLOBALCLASSINPUT} name="testDriveMobileNumber" label={t('COMMON.FORM.TESTDRIVEMOBILENUMBER') as any} />
                <RHFTextField size={GLOBALCLASSINPUT} name="testDriveStartingKMS" label={t('COMMON.FORM.TESTDRIVESTARTINGKMS') as any} />
                <RHFTextField size={GLOBALCLASSINPUT} name="testDriveEndignKMS" label={t('COMMON.FORM.TESTDRIVEENDINGKMS') as any} />
                <RHFTextField size={GLOBALCLASSINPUT} name="testDriveGenerateNumber" label={t('COMMON.FORM.TESTDRIVEGENERATENUMBER') as any} />
                <Box rowGap={3} columnGap={2} display="grid" gridTemplateColumns={{ xs: 'repeat(1, 1fr)', sm: 'repeat(2, 1fr)' }}>
                    <Button variant="contained" >
                        Save
                    </Button>
                    <Button >
                        Clear
                    </Button>
                </Box>
            </Box>
            <br />
            <Table sx={{ minWidth: "100%" }}>
                <TableHead>
                    <TableRow>
                        {TABLE_HEAD_ENQUIRYTESTDRIVE && TABLE_HEAD_ENQUIRYTESTDRIVE.map((item: any) => (
                            <TableCell key={item.label} style={{ padding: "4px", fontSize: '10px', textAlign: 'center' }}>
                                <Typography variant="caption" sx={{ mb: 0.5 }}>  {t(item.label)} </Typography>
                            </TableCell>
                        ))}
                    </TableRow>
                </TableHead>
                <TableBody>
                    <TableRow>
                        <TableCell style={{ padding: "4px", fontSize: '10px', width: "118px", textAlign: 'center' }}>
                            <Typography variant="caption" sx={{ mb: 0.5 }}>  Maruti </Typography>
                        </TableCell>
                        <TableCell style={{ padding: "4px", fontSize: '10px', width: "118px", textAlign: 'center' }}>
                            <Typography variant="caption" sx={{ mb: 0.5 }}>  Hyderabad </Typography>
                        </TableCell>
                        <TableCell style={{ padding: "4px", width: "118px", textAlign: 'center' }}>
                            <Typography variant="caption" sx={{ mb: 0.5 }}>  20/12/2023 </Typography>
                        </TableCell>
                        <TableCell style={{ padding: "4px", width: "118px", textAlign: 'center' }}>
                            <Typography variant="caption" sx={{ mb: 0.5 }}>  9876542310 </Typography>
                        </TableCell>
                        <TableCell style={{ padding: "4px", width: "118px", textAlign: 'center' }}>
                            <Typography variant="caption" sx={{ mb: 0.5 }}>  12345 </Typography>
                        </TableCell>
                        <TableCell style={{ padding: "4px", width: "118px", textAlign: 'center' }}>
                            <Typography variant="caption" sx={{ mb: 0.5 }}>  12369 </Typography>
                        </TableCell>
                        <TableCell style={{ padding: "4px", width: "118px", textAlign: 'center' }}>
                            <Typography variant="caption" sx={{ mb: 0.5 }}>  326542 </Typography>
                        </TableCell>
                        <TableCell align="right" sx={{ px: 1, whiteSpace: 'nowrap' }}>
                            <Tooltip title="Quick Edit" placement="top" arrow>
                                <IconButton onClick={() => {
                                }}  >
                                    <Iconify icon="solar:pen-bold" />
                                </IconButton>
                            </Tooltip>
                            <Tooltip title="Quick Delete" sx={{ color: 'error.main' }} placement="top" arrow>
                                <IconButton onClick={() => {
                                }}>
                                    <Iconify icon="solar:trash-bin-trash-bold" />
                                </IconButton>
                            </Tooltip>
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell style={{ padding: "4px", fontSize: '10px', width: "118px", textAlign: 'center' }}>
                            <Typography variant="caption" sx={{ mb: 0.5 }}>  Maruti </Typography>
                        </TableCell>
                        <TableCell style={{ padding: "4px", fontSize: '10px', width: "118px", textAlign: 'center' }}>
                            <Typography variant="caption" sx={{ mb: 0.5 }}>  Hyderabad </Typography>
                        </TableCell>
                        <TableCell style={{ padding: "4px", width: "118px", textAlign: 'center' }}>
                            <Typography variant="caption" sx={{ mb: 0.5 }}>  20/12/2023 </Typography>
                        </TableCell>
                        <TableCell style={{ padding: "4px", width: "118px", textAlign: 'center' }}>
                            <Typography variant="caption" sx={{ mb: 0.5 }}>  9876542310 </Typography>
                        </TableCell>
                        <TableCell style={{ padding: "4px", width: "118px", textAlign: 'center' }}>
                            <Typography variant="caption" sx={{ mb: 0.5 }}>  12345 </Typography>
                        </TableCell>
                        <TableCell style={{ padding: "4px", width: "118px", textAlign: 'center' }}>
                            <Typography variant="caption" sx={{ mb: 0.5 }}>  12369 </Typography>
                        </TableCell>
                        <TableCell style={{ padding: "4px", width: "118px", textAlign: 'center' }}>
                            <Typography variant="caption" sx={{ mb: 0.5 }}>  326542 </Typography>
                        </TableCell>
                        <TableCell align="right" sx={{ px: 1, whiteSpace: 'nowrap' }}>
                            <Tooltip title="Quick Edit" placement="top" arrow>
                                <IconButton onClick={() => {
                                }}  >
                                    <Iconify icon="solar:pen-bold" />
                                </IconButton>
                            </Tooltip>
                            <Tooltip title="Quick Delete" sx={{ color: 'error.main' }} placement="top" arrow>
                                <IconButton onClick={() => {
                                }}>
                                    <Iconify icon="solar:trash-bin-trash-bold" />
                                </IconButton>
                            </Tooltip>
                        </TableCell>
                    </TableRow>
                </TableBody>
            </Table>
        </>
    );
}
