export const BOOKING_COLUMNS = ["customerName","bookingNumber","date","model","status"];

export const TABLE_HEAD_BOOKING = [
    { id: 'customerName', label: "BOOKING.VIEW.GRID.CUSTOMERNAME" },
    { id: 'bookingNumber', label: "BOOKING.VIEW.GRID.NUMBER" },
    { id: 'date', label: "BOOKING.VIEW.GRID.DATE" },
    { id: 'model', label: "BOOKING.VIEW.GRID.MODEL" },
    { id: 'status', label: "BOOKING.VIEW.GRID.STATUS" },
    { id: 'id', width: 88 },
  ];

  export const TABLE_HEAD_ENQUIRYTESTDRIVE = [
    { id: 'leadRefNumber', label: "COMMON.FORM.GRID.TESTDRIVEVEHICLEMODEL" },
    { id: 'status', label: "COMMON.FORM.GRID.TESTDRIVELOCATION" },
    { id: 'status', label: "COMMON.FORM.GRID.TESTDRIVEDATE" },
    { id: 'status', label: "COMMON.FORM.GRID.TESTDRIVEMOBILENUMBER" },
    { id: 'status', label: "COMMON.FORM.GRID.TESTDRIVESTARTINGKMS" },
    { id: 'status', label: "COMMON.FORM.GRID.TESTDRIVEENDINGKMS" },
    { id: 'status', label: "COMMON.FORM.GRID.TESTDRIVEGENERATENUMBER" },
    { id: 'id', width: 88 },
  ];
  export const TABLE_HEAD_ENQUIRYFOLLOWUP = [
    { id: 'leadRefNumber', label: "BOOKING.FORM.GRID.FOLLOWUPENQUIRYSTATUS" },
    { id: 'status', label: "BOOKING.FORM.GRID.FOLLOWUPVISIT" },
    { id: 'status', label: "BOOKING.FORM.GRID.FOLLOWUPDATE" },
    { id: 'id', width: 88 },
  ];

export const defaultFilters = {
    name: '',
    status: [],
  };
  