import { IErrorType } from "./error";

export type INewVehicleModelTableFilters = {
    name: string;
    status: string[];
  };
  
export type INewVehicleModelTableFilterValue = string | string[];

export type INewVehicleModel = {
    newVehicleModelId:number;
    newVehicleModelName:string;
    class:string;
    year:string;
    manufacturer:string;
    image:any;
    description:string;
    images:any[];
    varientDetails:any[];
    status:boolean;
}
  
export type INewVehicleModelState = {
  newVehicleModels: INewVehicleModel[];
  newVehicleModel: INewVehicleModel | null;
  newVehicleModelsStatus: {
    loading: boolean;
    empty: boolean;
    error: IErrorType;
  };
  newVehicleModelStatus: {
    loading: boolean;
    error: IErrorType;
  };
  newVehicleModelDelete: {
    loading:boolean;
    empty:any;
    error:IErrorType;
  }
};

export const defaultFilters = {
  name: '',
  status: [],
};