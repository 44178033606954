import { IErrorType } from "../error";

export type IBankNameTableFilters = {
    name: string;
    status: string[];
  };
  
export type IBankNameTableFilterValue = string | string[];

export type IBankName = {
    id:number;
    name:string;
    description:string;
    displayOrder:number;
    status:boolean;
}
 
export type IBankNameState = {
  bankName: IBankName[];
  bankNameStatus: {
    loading: boolean;
    empty: boolean;
    error: IErrorType;
  };
  bankNameDelete: {
    loading:boolean;
    empty:any;
    error:IErrorType;
  }
};

export const defaultFilters = {
  name: '',
  status: [],
};