import { Helmet } from "react-helmet-async";
import { useLocales } from "src/locales";
import BodyTypeListView from "src/sections/masters/settings/body-type/body-type-list-view";
import SegmentDetailListView from "src/sections/masters/settings/segment-detail/segment-detail-list-view";

export default function SegmentDetailListPage() {
  const {t}=useLocales();
    return (
      <>
        <Helmet>
          <title> { t("SEGMENTDETAIL.PAGE.TITLE") }</title>
        </Helmet>
  
         <SegmentDetailListView /> 
      </>
    );
  }