import * as Yup from 'yup';
import { useSnackbar } from "src/components/snackbar";
import { CustomFile } from "src/components/upload";
import { useCallback, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import FormProvider, { RHFSwitch, RHFTextField } from 'src/components/hook-form';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import LoadingButton from '@mui/lab/LoadingButton';
import { Dialog, DialogTitle, Stack } from '@mui/material';
import { IColorPalatte } from 'src/types/color.palatte';
import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import { useLocales } from 'src/locales';
import { GLOBALCLASSINPUT } from 'src/utils/contants/config-common';
import { ColorBox, ColorPicker } from 'material-ui-color';       
import { addUpdateColorPalatte } from 'src/redux/slices/color.palatte';

interface FormValuesProps extends Omit<IColorPalatte, 'avatarUrl'> {
    avatarUrl: CustomFile | string | null;
}

type Props = {
    title:string;
    open: boolean;
    onClose: VoidFunction;
    submitButtonText?:string;
    currentModel?: IColorPalatte;
};

export default function ColorPalatteForm({ currentModel, open, onClose,title,submitButtonText }: Props) {
    const { enqueueSnackbar } = useSnackbar();
    const { t } = useLocales();
    const [colorValue, setColorValue] = useState((currentModel && currentModel?.code?currentModel.code:"#FFFFFF") as any)
    const [showErrorMsg, setShowErrorMsg] = useState(false as any);
    const NewModelSchema = Yup.object().shape({
        name: Yup.string().required(t('COLORPALATTE.FORM.COLORNAME_REQUIRED') as any),
        code: Yup.string(),
        description: Yup.string(),
        status: Yup.bool(),
    });

    const defaultValues = useMemo(() => ({
        name: currentModel?.name || "",
        code: currentModel?.code || "",
        description: currentModel?.description || "",
        status: !(currentModel?.status),
        id: (currentModel?.id)
    }), [currentModel]);


    const methods = useForm<FormValuesProps>({
        resolver: yupResolver(NewModelSchema),
        defaultValues
    });

    const {
        reset,
        handleSubmit,
        formState: { isSubmitting },
    } = methods;

    const onSubmit = useCallback(
        async (data: FormValuesProps) => {
            try {
                addUpdateColorPalatte({ ...data, status: !data.status,code:colorValue}).then((res: any) => {
                    console.log(res);
                    if (res.data.data > 0 && res.data.statusCode === 200) {
                        reset();
                        onClose();
                        enqueueSnackbar(t('COLORPALATTE.FORM.UPDATE_SUCCESS') as any);
                    } else {
                        setShowErrorMsg(true);
                    }
                });
                console.info('DATA', data);
            } catch (error) {
                console.error(error);
            }
        }, [enqueueSnackbar, onClose, reset,t,colorValue]);

    return (
        <Dialog scroll="body"
            fullWidth
            maxWidth={false}
            open={open}
            onClose={() => null}
            PaperProps={{
                sx: { maxWidth: 720 },
            }}
        >
            <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
                <DialogTitle>{title}</DialogTitle>
                <DialogContent>
                {showErrorMsg && <Alert variant="outlined" severity="error" sx={{ mb: 3 }}>
                   { t('COLORPALATTE.FORM.ALREADY_EXISTS_ERROR') }
                    </Alert>}
                    <Stack spacing={3} sx={{ p: 1 }}>
                        <RHFTextField size={GLOBALCLASSINPUT} name="name" label={t("COLORPALATTE.FORM.COLORNAME")} />
                        {/* <span style={{textAlign:"left"}}>Pick a Color</span>
                        <ColorBox value={colorValue}  onChange={(data:any)=> setColorValue(data)} /> */}
                        <span style={{ textAlign: "left" }}>Pick a Color</span>
                        <ColorPicker  disableAlpha   value={colorValue} onChange={(data: any) => {setColorValue(data.css.backgroundColor); }} />
                        <RHFTextField size={GLOBALCLASSINPUT} name="description" label={t("COLORPALATTE.FORM.DESCRIPTION")} />
                       {currentModel?.id!==0 && <RHFSwitch name="status" label={t("COLORPALATTE.FORM.STATUS")} /> }
                    </Stack>
                </DialogContent>
                <DialogActions>
                    <Button variant="outlined" onClick={onClose}>
                        {t("CANCEL")}
                    </Button>
                    <LoadingButton type="submit" variant="contained" loading={isSubmitting}>
                        {submitButtonText}
                    </LoadingButton>
                </DialogActions>
            </FormProvider>
        </Dialog>
          )

}