


export type IInsuranceDetail = {
    id?: string;
    insurenceCompany: string;
    coverNote: string;
    insuranceAmount: string;
    coverNoteDate: string;
    registrationNumber: string;
};
  
export type IFinanceDetail = {
    id?: string;
    financeArranceById: string;
    bankId: string;
    branchName: string;
    fileNumber: string;
    doReceivedId: string;
    doNumber: string;
    doDate: string;
};

export type ISchemesOffers = {
    id?: string;
    schemeTypeId: string;
    schemeCategoryId: string;
    schemeAmount: string;
    validFrom: string;
    validTo: string;
    dealerDiscount:string;
    consumerSchemeId:string;
    oemDiscount:string;
    oemFOCScheme:string;
};

export type ICustomerDetail = {
    id?: string;
    salutationId?: string;
    fullName?: string;
    mobileNumber?: string;
    alternateNumber?: string;
    genderId?: string;
    panNumber: string;
    gsinNumber: string;
    birthDate: string;
    emailAddress: string;
    sourceOfEnquiryId: string;
    buyerTypeId: string;
    enquiryOwnerId: string;
    isSameAsBillingAddress?:any;
    vehicleRegistrationNumber?:any;
    vehicleModelId?:any;
    varientId?:any;
    color?:any;
    isReferral?:any;
    isLoyality?:any;
};


export const CustomerDetail:ICustomerDetail = {
    salutationId: '',
    fullName: '',
    mobileNumber: '',
    alternateNumber: '',
    genderId: '',
    panNumber: '',
    gsinNumber: '',
    birthDate: '',
    emailAddress: '',
    sourceOfEnquiryId: '',
    buyerTypeId: '',
    enquiryOwnerId: '',
    isSameAsBillingAddress:true,
    vehicleRegistrationNumber:"",
    vehicleModelId:"",
    varientId:"",
    color:"",
    isLoyality:false
};

export type IExchange = {
    id?: string;
    exchangeRegistrationNumber: string;
    exchangeMake: string;
    exchangeModel: string;
    exchangeModelGroup: string;
    exchangeVarientId: string;
    exchangeKMS: string;
    exchangeUsage: string;
    exchangeMonthOfRegistration: string;
    exchangeYearOfRegistration: string;
    exchangeVinNumber: string;
    exchangeHypothecatedId: string;
};

export type IReferrals = {
    id?: string;
    referralRegistrationNumber: string;
    referralVINNumber: string;
    referralCustomerCode: string;
    referralCustomerType: string;
    referralCustomerName: string;
    referralMobileNumber: string;
    referralEmailAddress: string;
    referralDateOfBirth: string;
};

export type IBookingDetail = {
    id?: string;
    bookingAmount: string;
    isBookingAmount?: boolean;
    placeOfRegistration: string;
    isPlaceOfRegistration?: boolean;
    specialRequest: string;
    isSpecialRequest?: boolean;
    modePaymentId: string;
    isModePaymentId?: boolean;
    sourceOfEnquiryId: string;
    isSourceOfEnquiryId?: boolean;
    deliveryDate: string;
    isDeliveryDate?: boolean;
    deliveryAt: string;
    isDeliveryAt?: boolean;
    customerExpectedDeliveryDate: string;
    isCustomerExpectedDeliveryDate?: boolean;
    salesExecutiveId: string;
    isSalesExecutiveId?: boolean;
    teamLeaderId: string;
    isTeamLeaderId?: boolean;
    salesManagerId: string;
    isSalesManagerId?: boolean;
    vehicleRegistrationNumber?:any;
    isVehicleRegistrationNumber?:boolean;
    vehicleModelId?:any;
    isVehicleModelId?:boolean;
    varientId?:any;
    isVarientId?:boolean;
    color?:any;
    isColor?:boolean;
    buyerTypeId?:string;
    isBuyerTypeId?:boolean;
    enquiryOwnerId?:string;
    isEnquiryOwnerId?:boolean;
    isAllEnable?:boolean;
    isBoxRequired?:boolean;
};
export const BookingDetail:IBookingDetail = {
    bookingAmount: '',
    placeOfRegistration: '',
    specialRequest: '',
    modePaymentId: '',
    sourceOfEnquiryId: '',
    deliveryDate: '',
    deliveryAt: '',
    customerExpectedDeliveryDate: '',
    salesExecutiveId: '',
    teamLeaderId: '',
    salesManagerId: '',
    vehicleRegistrationNumber:'',
    vehicleModelId:'',
    varientId:'',
    color:'',
};

export type ICustomerBooking = {
    id?: string;
    mobileNumber?: string;
    customerId?: string;
    name?: string;
    emailId?: string;
    genderId?: string;
    age?: string;
    address?: string;
    pincode?: string;
    mandal?: string;
    city?: string;
    stateId?: string;
    alternateNumber?: string;
    pannumber?: string;
    gsin?: string;
    dateOfBirth?: string;
    fatherName?: string;
    salutationId?: string;
    fullName?: string;
    panNumber?: string;
    gsinNumber?: string;
    birthDate?: string;
    emailAddress?: string;
    sourceOfEnquiryId?: string;
    buyerTypeId?: string;
    enquiryOwnerId?: string;
    isSameAsBillingAddress?:any;
    vehicleRegistrationNumber?:any;
    vehicleModelId?:any;
    varientId?:any;
    color?:any;
    isReferral?:any;
    isBillingDetail?:boolean;
    isCustomerDetail?:boolean;
    isOnlyBillingDetail?:boolean;
};

export const CustomerBooking:ICustomerBooking = {
    id: "0",
    mobileNumber: "",
    customerId: "",
    name: "",
    emailId: "",
    genderId: "",
    age: "",
    address: "",
    pincode: "",
    mandal: "",
    city: "",
    stateId: "",
    alternateNumber: "",
    pannumber: "",
    gsin: "",
    dateOfBirth: "",
    fatherName: "",
};

export type IVehicleDetail = {
    id?: string;
    modelFamilyId?: string;
    isModelFamilyId?: boolean;
    modelCodeId?: string;
    isModelCodeId?: boolean;
    varientId?: string;
    isVarientId?: boolean;
    colorId?: string;
    isColorId?: boolean;
    seatingCapacityId?: string;
    isSeatingCapacityId?: boolean;
    inStock?: string;
    isInStock?: boolean;
    bookingNumber?: string;
    isBookingNumber?: boolean;
    oemBookingNumber: string;
    isOEMBookingNumber?: boolean;
    poNumber?: string;
    isPoNumber?: boolean;
    podDate?: string;
    isPodDate?: boolean;
    poStatus?: string;
    isPoStatus?: boolean;
    vinNumber?: string;
    isVinNumber?: boolean;
    engineNumber?:string;
    isEngineNumber?:boolean;
    saleTypeId?:string;
    isSaleTypeId?:boolean;
    priceTypeId?:string;
    isPriceTypeId?:boolean;
    vehicleBasicPrice?:string;
    isVehicleBasicPrice?:boolean;
    vehicleOnRoadPrice?:string;
    isVehicleOnRoadPrice?:boolean;
    taxId?:string;
    isTaxId?:boolean;
    taxRate?:string;
    isTaxRate?:boolean;
    taxAmount?:string;
    isTaxAmount?:boolean;
    serviceName?:string;
    isServiceName?:boolean;
    serviceId?:string;
    isServiceId?:boolean;
    serviceAmount?:string;
    isServiceAmount?:boolean;
    dealerDiscount?:string;
    isDealerDiscount?:boolean;
    isAllEnable?:boolean;
    isBattery?:boolean;
    tyreTypeId?:string;
    isTyreTypeId?:boolean;
    batteryId?:string;
    keyNumber?:string;
    isKeyNumber?:boolean;
};
    export const VehicleDetail:IVehicleDetail = {
    id: "",
    modelFamilyId: "",
    modelCodeId: "",
    varientId: "",
    colorId: "",
    seatingCapacityId: "",
    inStock: "",
    bookingNumber: "",
    oemBookingNumber: "",
    poNumber: "",
    podDate: "",
    poStatus: "",
    vinNumber: "",
    engineNumber:"",
    saleTypeId:"",
    priceTypeId:"",
    vehicleBasicPrice:"",
    vehicleOnRoadPrice:"",
    taxId:"",
    taxRate:"",
    taxAmount:"",
    serviceName:"",
    serviceId:"",
    serviceAmount:"",
    dealerDiscount:"",
};

export type ITaxDetail = {
    id?: any;
    taxId: any;
    taxRate: any;
    taxAmount: any;
    cgstId: any,
    cgstRate: any,
    cgstAmount: any,
    sgstId: any,
    sgstRate: any,
    sgstAmount: any,
};



export type IAddOn = {
    id?: string;
    accesseriesPartName: string;
    accesseriesPartNumber: string;
    accesseriesSellingPrice: string;
    accesseriesDiscountPrice: string;
    accesseriesQuantity: string;
    warrentyName: string;
    warrentyPrice: string;
    warrentyKMS: string;
    warrentyValidFrom: string;
    warrentyValidTo: string;
};

export type ITestDrive = {
    testDriveVehicleModelId: string;
    testDriveLocation: string;
    testDriveDate: string;
    testDriveMobileNumber: string;
    testDriveStartingKMS: string;
    testDriveEndignKMS: string;
    testDriveGenerateNumber: string;
}

export type IDeliveryNoteInvoice = {
    deliveryNoteForId:string;
    invoiceNumber:string;
    invoiceDate:string;
    vinNumber:string;
    engineNumber:string;
    relationShipId:string;
    deliveryDate:string;
    salesExecutiveId:string;
    teamLeadId:string;
    customerName:string;
    fatherName:string;
    customerId:string;
  }

  export const DeliveryNoteInvoice:IDeliveryNoteInvoice = {
    deliveryNoteForId:"",
    invoiceNumber:"",
    invoiceDate:"",
    vinNumber:"",
    engineNumber:"",
    relationShipId:"",
    deliveryDate:"",
    salesExecutiveId:"",
    teamLeadId:"",
    customerName:"",
    fatherName:"",
    customerId:"",
  }

export interface IContact {
    purposeContactId:string;
    mobileNumber:string;
    alternateNumber:string;
    relationship:string;
    gender:string;
    customerName:string;
    emailAddress:string;
}

export interface IFamilyDetail {
    customerTypeId:string;
    customerName:string;
    relationId:string;
    dateOfBirth:string;
    age:string;
    remarks:string;
}

export interface ICustomerAccountDetails {
creditLimit:string;
creditLimitDays:string;
outstandingAmount:string;
partsDiscount:string;
labourDiscount:string;
remark:string;
}

export interface ISupportingDocument {
documentTypeId:string;
fileName:string;
filesize:string;
documentUrl:string;
}