import * as Yup from 'yup';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import Box from '@mui/material/Box';
import {
    RHFTextField,
    RHFSelect,
} from 'src/components/hook-form';
import { Accordion, AccordionDetails, AccordionSummary, Button, Divider, Grid, IconButton, MenuItem, Table, TableBody, TableCell, TableHead, TableRow, Tooltip, Typography } from '@mui/material';
import { GLOBALCLASSINPUT } from 'src/utils/contants/config-common';
import { getLookUp } from 'src/redux/slices/lookup';
import { useLocales } from 'src/locales';
import { DatePicker } from '@mui/x-date-pickers';
import { ITaxDetail, IVehicleDetail, VehicleDetail } from 'src/types/common';
import { Controller, useForm } from 'react-hook-form';
import Iconify from 'src/components/iconify/iconify';
import TaxDetailForm from './tax-detail';

// ----------------------------------------------------------------------

export interface FormValuesProps extends IVehicleDetail {

}

type Props = {
    onChange: (address: IVehicleDetail) => void;
    gridTemplateColumns: any;
    currentModel?: IVehicleDetail;
};

export default function VehicleDetailForm({ onChange, gridTemplateColumns = 1, currentModel = { ...VehicleDetail } }: Props) {
    const [modelFamilyLookUp, setModelFamilyLookUp] = useState([] as any);
    const [modelCodeLookUp, setModelCodeLookUp] = useState([] as any);
    const [varientTypeLookUp, setVarientTypeLookUp] = useState([] as any);
    const [colorLookUp, setColorLookUp] = useState([] as any);
    const [seatingCapacityLookUp, setSeatingCapacityLookUp] = useState([] as any);
    const [inStockLookUp, setInStockLookUp] = useState([] as any);
    const [saleTypeLookUp, setSaleTypeLookUp] = useState([] as any);
    const [priceTypeLookUp, setPriceTypeLookUp] = useState([] as any);

    const [genderLookUp, setGenderLookUp] = useState([] as any);
    const [buyerTypeLookUp, setBuyerTypeLookUp] = useState([] as any);
    const [enquiryOwnerLookUp, setEnquiryOwnerLookUp] = useState([] as any);
    const [sourceOfEnquiryLookUp, setSourceOfEnquiryLookUp] = useState([] as any);

    const { t } = useLocales();
    const NewCustomerDetailSchema = Yup.object().shape({
        modelFamilyId: Yup.string().required(t("COMMON.FORM.REQUIRED.MODELFAMILY") as any),
        modelCodeId: Yup.string().required(t("COMMON.FORM.REQUIRED.MODELCODE") as any),
        varientId: Yup.string().required(t("COMMON.FORM.REQUIRED.VARIENTTYPE") as any),
        colorId: Yup.string().required(t("COMMON.FORM.REQUIRED.COLOR") as any),
        seatingCapacityId: Yup.string().required(t("COMMON.FORM.REQUIRED.SEATINGCAPACITY") as any),
        inStock: Yup.string().required(t("COMMON.FORM.REQUIRED.INSTOCK") as any),
        bookingNumber: Yup.string().required(t("COMMON.FORM.REQUIRED.BOOKINGNUMBER") as any),
        oemBookingNumber: Yup.string().required(t("COMMON.FORM.REQUIRED.OEMBOOKINGNO") as any),
        poNumber: Yup.string().required(t("COMMON.FORM.REQUIRED.PONUMBER") as any),
        podDate: Yup.string().required(t("COMMON.FORM.REQUIRED.PODATE") as any),
        poStatus: Yup.string().required(t("COMMON.FORM.REQUIRED.POSTATUS") as any),
        vinNumber: Yup.string().required(t("COMMON.FORM.REQUIRED.VINNO") as any),
        engineNumber: Yup.string().required(t("COMMON.FORM.REQUIRED.ENGINENO") as any),
        saleTypeId: Yup.string().required(t("COMMON.FORM.REQUIRED.SALETYPE") as any),
        priceTypeId: Yup.string().required(t("COMMON.FORM.REQUIRED.PRICETYPE") as any),
        vehicleBasicPrice: Yup.string().required(t("COMMON.FORM.REQUIRED.VEHICLEBASICPRICE") as any),
        vehicleOnRoadPrice: Yup.string().required(t("COMMON.FORM.REQUIRED.VEHICLEONROADPRICE") as any),
        dealerDiscount: Yup.string().required(t("COMMON.FORM.REQUIRED.DEALERDISCOUNT") as any),
    });

    const defaultValues = useMemo(() => ({
        modelFamilyId: currentModel?.modelFamilyId || "",
        isModelFamilyId: currentModel?.isModelFamilyId || false,
        modelCodeId: currentModel?.modelCodeId || "",
        isModelCodeId: currentModel?.isModelCodeId || false,
        varientId: currentModel?.varientId || "",
        isVarientId: currentModel?.isVarientId || false,
        colorId: currentModel?.colorId || "",
        isColorId: currentModel?.isColorId || false,
        seatingCapacityId: currentModel?.seatingCapacityId || "",
        isSeatingCapacityId: currentModel?.isSeatingCapacityId || false,
        inStock: currentModel?.inStock || "",
        isInStock: currentModel?.isInStock || false,
        bookingNumber: currentModel?.bookingNumber || "",
        isBookingNumber: currentModel?.isBookingNumber || false,
        oemBookingNumber: currentModel?.oemBookingNumber || "",
        isOEMBookingNumber: currentModel?.isOEMBookingNumber || false,
        poNumber: currentModel?.poNumber || "",
        isPoNumber: currentModel?.isPoNumber || false,
        podDate: currentModel?.podDate || "",
        isPodDate: currentModel?.isPodDate || false,
        poStatus: currentModel?.poStatus || "",
        isPoStatus: currentModel?.isPoStatus || false,
        vinNumber: currentModel?.vinNumber || "",
        isVinNumber: currentModel?.isVinNumber || false,
        engineNumber: currentModel?.engineNumber || "",
        isEngineNumber: currentModel?.isEngineNumber || false,
        saleTypeId: currentModel?.saleTypeId || "",
        isSaleTypeId: currentModel?.isSaleTypeId || false,
        priceTypeId: currentModel?.priceTypeId || "",
        isPriceTypeId: currentModel?.isPriceTypeId || false,
        vehicleBasicPrice: currentModel?.vehicleBasicPrice || "",
        isVehicleBasicPrice: currentModel?.isVehicleBasicPrice || false,
        vehicleOnRoadPrice: currentModel?.vehicleOnRoadPrice || "",
        isVehicleOnRoadPrice: currentModel?.isVehicleOnRoadPrice || false,
        dealerDiscount: currentModel?.dealerDiscount || "",
        isDealerDiscount: currentModel?.isDealerDiscount || false,
        isAllEnable: currentModel?.isAllEnable,
        batteryId: currentModel?.batteryId,
        tyreTypeId: currentModel?.tyreTypeId,
        isTyreTypeId: currentModel?.isTyreTypeId,
        isBattery: currentModel?.isBattery,
        keyNumber: currentModel?.keyNumber,
        isKeyNumber: currentModel?.isKeyNumber || false,
    }), [currentModel])

    useEffect(() => {
        getLookUp("MODELFAMILY").then((x: any) => {
            if (x && x.data && x.data.length > 0) {
                console.log(x.data)
                setModelFamilyLookUp(x.data);
            }
        });
        getLookUp("MODELCODE").then((x: any) => {
            if (x && x.data && x.data.length > 0) {
                console.log(x.data)
                setModelCodeLookUp(x.data);
            }
        });
        getLookUp("VARIENTTYPE").then((x: any) => {
            if (x && x.data && x.data.length > 0) {
                console.log(x.data)
                setVarientTypeLookUp(x.data);
            }
        });
        getLookUp("COLOR").then((x: any) => {
            if (x && x.data && x.data.length > 0) {
                console.log(x.data)
                setColorLookUp(x.data);
            }
        });
        getLookUp("SEATINGCAPACITY").then((x: any) => {
            if (x && x.data && x.data.length > 0) {
                console.log(x.data)
                setSeatingCapacityLookUp(x.data);
            }
        });
        getLookUp("INSTOCK").then((x: any) => {
            if (x && x.data && x.data.length > 0) {
                console.log(x.data)
                setInStockLookUp(x.data);
            }
        });
        getLookUp("SALETYPE").then((x: any) => {
            if (x && x.data && x.data.length > 0) {
                console.log(x.data)
                setSaleTypeLookUp(x.data);
            }
        });
        getLookUp("PRICETYPE").then((x: any) => {
            if (x && x.data && x.data.length > 0) {
                console.log(x.data)
                setPriceTypeLookUp(x.data);
            }
        });
        getLookUp("ENQUIRYOWNER").then((x: any) => {
            if (x && x.data && x.data.length > 0) {
                console.log(x.data)
                setEnquiryOwnerLookUp(x.data);
            }
        });
        getLookUp("BUYERTYPE").then((x: any) => {
            if (x && x.data && x.data.length > 0) {
                console.log(x.data)
                setBuyerTypeLookUp(x.data);
            }
        });
        getLookUp("SOURCEOFENQUIRY").then((x: any) => {
            if (x && x.data && x.data.length > 0) {
                console.log(x)
                setSourceOfEnquiryLookUp(x.data);
            }
        });
    }, [])

    const onTaxDetailChange = useCallback(
        (taxDetail: ITaxDetail) => {

        },
        []
    );


    const methods = useForm<FormValuesProps>({
        resolver: yupResolver(NewCustomerDetailSchema),
        defaultValues,
    });

    const {
        handleSubmit, control,
        // formState: { isSubmitting },
    } = methods;

    const onInputChange = useCallback(
        async (data: FormValuesProps) => {
            try {
                onChange({
                    modelFamilyId: data.modelFamilyId,
                    modelCodeId: data.modelCodeId,
                    varientId: data.varientId,
                    colorId: data.colorId,
                    seatingCapacityId: data.seatingCapacityId,
                    inStock: data.inStock,
                    bookingNumber: data.bookingNumber,
                    oemBookingNumber: data.oemBookingNumber,
                    poNumber: data.poNumber,
                    podDate: data.podDate,
                    poStatus: data.poStatus,
                    vinNumber: data.vinNumber,
                    engineNumber: data.engineNumber,
                    saleTypeId: data.saleTypeId,
                    priceTypeId: data.priceTypeId,
                    vehicleBasicPrice: data.vehicleBasicPrice,
                    vehicleOnRoadPrice: data.vehicleOnRoadPrice,
                    dealerDiscount: data.dealerDiscount
                });
            } catch (error) {
                console.error(error);
            }
        },
        [onChange]
    );

    return (
        <>
            <Box rowGap={2} columnGap={2} display="grid" gridTemplateColumns={{ xs: 'repeat(1, 1fr)', sm: `repeat(${gridTemplateColumns}, 1fr)` }}>
                {(defaultValues.isModelFamilyId || defaultValues.isAllEnable) && <RHFSelect name="modelFamilyId" size={GLOBALCLASSINPUT} label={t('COMMON.FORM.MODELFAMILY') as any} >
                    <MenuItem value="">None</MenuItem>
                    <Divider sx={{ borderStyle: 'dashed' }} />
                    {modelFamilyLookUp && modelFamilyLookUp.map((item: any) => (
                        <MenuItem key={item.value + item.label} value={item.value}> {item.label} </MenuItem>
                    ))}
                </RHFSelect>}
                {(defaultValues.isModelCodeId || defaultValues.isAllEnable) && <RHFSelect name="modelCodeId" size={GLOBALCLASSINPUT} label={t('COMMON.FORM.MODELCODE') as any} >
                    <MenuItem value="">None</MenuItem>
                    <Divider sx={{ borderStyle: 'dashed' }} />
                    {modelFamilyLookUp && modelFamilyLookUp.map((item: any) => (
                        <MenuItem key={item.value + item.label} value={item.value}> {item.label} </MenuItem>
                    ))}
                </RHFSelect>}
                {(defaultValues.isKeyNumber) && <RHFTextField size={GLOBALCLASSINPUT} name="keyNumber" label={t('COMMON.FORM.KEYNUMBER') as any} />}
                {(defaultValues.isVarientId || defaultValues.isAllEnable) && <RHFSelect name="varientId" size={GLOBALCLASSINPUT} label={t('COMMON.FORM.VARIENTTYPE') as any} >
                    <MenuItem value="">None</MenuItem>
                    <Divider sx={{ borderStyle: 'dashed' }} />
                    {varientTypeLookUp && varientTypeLookUp.map((item: any) => (
                        <MenuItem key={item.value + item.label} value={item.value}> {item.label} </MenuItem>
                    ))}
                </RHFSelect>}
                {(defaultValues.isColorId || defaultValues.isAllEnable) && <RHFSelect name="colorId" size={GLOBALCLASSINPUT} label={t('COMMON.FORM.COLOR') as any} >
                    <MenuItem value="">None</MenuItem>
                    <Divider sx={{ borderStyle: 'dashed' }} />
                    {colorLookUp && colorLookUp.map((item: any) => (
                        <MenuItem key={item.value + item.label} value={item.value} > <span style={{ backgroundColor: item.data, padding: "3px 10px", borderRadius: "50%", marginRight: "5px", width: "20px", height: "20px" }}>&nbsp;</span> {item.label}    </MenuItem>
                    ))}
                </RHFSelect>}
                {(defaultValues.isSeatingCapacityId || defaultValues.isAllEnable) && <RHFSelect name="seatingCapacityId" size={GLOBALCLASSINPUT} label={t('COMMON.FORM.SEATINGCAPACITY') as any} >
                    <MenuItem value="">None</MenuItem>
                    <Divider sx={{ borderStyle: 'dashed' }} />
                    {seatingCapacityLookUp && seatingCapacityLookUp.map((item: any) => (
                        <MenuItem key={item.value + item.label} value={item.value}> {item.label} </MenuItem>
                    ))}
                </RHFSelect>}
                {(defaultValues.isInStock || defaultValues.isAllEnable) && <RHFSelect name="inStock" size={GLOBALCLASSINPUT} label={t('COMMON.FORM.INSTOCK') as any} >
                    <MenuItem value="">None</MenuItem>
                    <Divider sx={{ borderStyle: 'dashed' }} />
                    {inStockLookUp && inStockLookUp.map((item: any) => (
                        <MenuItem key={item.value + item.label} value={item.value}> {item.label} </MenuItem>
                    ))}
                </RHFSelect>}
                {(defaultValues.isBookingNumber || defaultValues.isAllEnable) && <RHFTextField size={GLOBALCLASSINPUT} name="bookingNumber" label={t('COMMON.FORM.BOOKINGNUMBER') as any} />}
                {(defaultValues.isOEMBookingNumber || defaultValues.isAllEnable) && <RHFTextField size={GLOBALCLASSINPUT} name="oemBookingNumber" label={t('COMMON.FORM.OEMBOOKINGNO') as any} />}
                {(defaultValues.isPoNumber || defaultValues.isAllEnable) && <RHFTextField size={GLOBALCLASSINPUT} name="poNumber" label={t('COMMON.FORM.PONUMBER') as any} />}
                {(defaultValues.isPodDate || defaultValues.isAllEnable) && <Controller
                    name="podDate"
                    control={control}
                    render={({ field, fieldState: { error } }) => (
                        <DatePicker
                            label={t('COMMON.FORM.PODATE') as any}
                            value={field.value}
                            onChange={(newValue) => {
                                field.onChange(newValue);
                            }}
                            format="dd-MMM-yyyy"
                            slotProps={{
                                textField: {
                                    size: 'small',
                                    error: !!error,
                                    helperText: error?.message,
                                },
                            }}
                        />
                    )}
                />}
                {(defaultValues.isPoStatus || defaultValues.isAllEnable) && <RHFTextField size={GLOBALCLASSINPUT} name="poStatus" label={t('COMMON.FORM.POSTATUS') as any} />}
                {(defaultValues.isVinNumber || defaultValues.isAllEnable) && <RHFTextField size={GLOBALCLASSINPUT} name="vinNumber" label={t('COMMON.FORM.VINNO') as any} />}
                {(defaultValues.isEngineNumber || defaultValues.isAllEnable) && <RHFTextField size={GLOBALCLASSINPUT} name="engineNumber" label={t('COMMON.FORM.ENGINENO') as any} />}
                {(defaultValues.isSaleTypeId || defaultValues.isAllEnable) && <RHFSelect name="saleTypeId" size={GLOBALCLASSINPUT} label={t('COMMON.FORM.SALETYPE') as any} >
                    <MenuItem value="">None</MenuItem>
                    <Divider sx={{ borderStyle: 'dashed' }} />
                    {saleTypeLookUp && saleTypeLookUp.map((item: any) => (
                        <MenuItem key={item.value + item.label} value={item.value}> {item.label} </MenuItem>
                    ))}
                </RHFSelect>}
                {(defaultValues.isPriceTypeId || defaultValues.isAllEnable) && <RHFSelect name="priceTypeId" size={GLOBALCLASSINPUT} label={t('COMMON.FORM.PRICETYPE') as any} >
                    <MenuItem value="">None</MenuItem>
                    <Divider sx={{ borderStyle: 'dashed' }} />
                    {priceTypeLookUp && priceTypeLookUp.map((item: any) => (
                        <MenuItem key={item.value + item.label} value={item.value}> {item.label} </MenuItem>
                    ))}
                </RHFSelect>}
                {(defaultValues.isVehicleBasicPrice || defaultValues.isAllEnable) && <RHFTextField size={GLOBALCLASSINPUT} name="vehicleBasicPrice" label={t('COMMON.FORM.VEHICLEBASICPRICE') as any} />}
                {(defaultValues.isVehicleOnRoadPrice || defaultValues.isAllEnable) && <RHFTextField size={GLOBALCLASSINPUT} name="vehicleOnRoadPrice" label={t('COMMON.FORM.VEHICLEONROADPRICE') as any} />}
                {(defaultValues.isDealerDiscount || defaultValues.isAllEnable) && <RHFTextField size={GLOBALCLASSINPUT} name="dealerDiscount" label={t('COMMON.FORM.DEALERDISCOUNT') as any} />}
                {(defaultValues.isBattery) && <RHFSelect name="batteryId" size={GLOBALCLASSINPUT} label={t('COMMON.FORM.BATTERY') as any} >
                    <MenuItem value="">None</MenuItem>
                    <Divider sx={{ borderStyle: 'dashed' }} />
                    {priceTypeLookUp && priceTypeLookUp.map((item: any) => (
                        <MenuItem key={item.value + item.label} value={item.value}> {item.label} </MenuItem>
                    ))}
                </RHFSelect>}
                {(defaultValues.isTyreTypeId) && <RHFSelect name="tyreTypeId" size={GLOBALCLASSINPUT} label={t('COMMON.FORM.TYRETYPE') as any} >
                    <MenuItem value="">None</MenuItem>
                    <Divider sx={{ borderStyle: 'dashed' }} />
                    {priceTypeLookUp && priceTypeLookUp.map((item: any) => (
                        <MenuItem key={item.value + item.label} value={item.value}> {item.label} </MenuItem>
                    ))}
                </RHFSelect>}
            </Box>
            <br />
            <Accordion >
                <AccordionSummary expandIcon={<Iconify icon="eva:arrow-ios-downward-fill" />}>
                    <Typography variant="subtitle1" style={{ width: "100%" }}> Tax Details </Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <TaxDetailForm onChange={onTaxDetailChange} gridTemplateColumns={gridTemplateColumns} />
                </AccordionDetails>
            </Accordion>
            <Accordion >
                <AccordionSummary expandIcon={<Iconify icon="eva:arrow-ios-downward-fill" />}>
                    <Typography variant="subtitle1">  Optional Service </Typography><hr />
                </AccordionSummary>
                <AccordionDetails>
                    <Box gap={3} display="grid" gridTemplateColumns={{ xs: `repeat(${gridTemplateColumns}, 1fr)`, }} >
                        <RHFTextField size={GLOBALCLASSINPUT} fullWidth name="serviceName" label={t('COMMON.FORM.SERVICENAME') as any} />
                        <RHFTextField size={GLOBALCLASSINPUT} fullWidth name="serviceAmount" label={t('COMMON.FORM.SERVICEAMOUNT') as any} />
                        <Box rowGap={3} columnGap={2} display="grid" gridTemplateColumns={{ xs: 'repeat(1, 1fr)', sm: 'repeat(2, 1fr)' }}>
                            <Button variant="contained" >
                                Save
                            </Button>
                            <Button >
                                Clear
                            </Button>
                        </Box>
                    </Box>
                    <br />
                    <Table sx={{ minWidth: "100%" }}>
                        <TableHead>
                            <TableRow>
                                {["COMMON.FORM.SERVICENAME", "COMMON.FORM.SERVICEAMOUNT"].map((item: any) => (
                                    <TableCell key={item} style={{ padding: "4px", fontSize: '10px', textAlign: 'center' }}>
                                        <Typography variant="caption" sx={{ mb: 0.5 }}>  {t(item)} </Typography>
                                    </TableCell>
                                ))}
                                <TableCell style={{ padding: "4px", fontSize: '10px', textAlign: 'center' }}>
                                    <Typography variant="caption" sx={{ mb: 0.5 }}>  &nbsp; </Typography>
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow>
                                <TableCell style={{ padding: "4px", fontSize: '10px', width: "118px", textAlign: 'center' }}>
                                    <Typography variant="caption" sx={{ mb: 0.5 }}>  Service Name 1 </Typography>
                                </TableCell>
                                <TableCell style={{ padding: "4px", fontSize: '10px', width: "118px", textAlign: 'center' }}>
                                    <Typography variant="caption" sx={{ mb: 0.5 }}>  2000 </Typography>
                                </TableCell>
                                <TableCell style={{ padding: "4px", fontSize: '10px', width: "118px", textAlign: 'center' }}>
                                    <Tooltip title="Quick Edit" placement="top" arrow>
                                        <IconButton onClick={() => {
                                        }}  >
                                            <Iconify icon="solar:pen-bold" />
                                        </IconButton>
                                    </Tooltip>
                                    <Tooltip title="Quick Delete" sx={{ color: 'error.main' }} placement="top" arrow>
                                        <IconButton onClick={() => {
                                        }}>
                                            <Iconify icon="solar:trash-bin-trash-bold" />
                                        </IconButton>
                                    </Tooltip>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell style={{ padding: "4px", fontSize: '10px', width: "118px", textAlign: 'center' }}>
                                    <Typography variant="caption" sx={{ mb: 0.5 }}>  Service Name 2 </Typography>
                                </TableCell>
                                <TableCell style={{ padding: "4px", fontSize: '10px', width: "118px", textAlign: 'center' }}>
                                    <Typography variant="caption" sx={{ mb: 0.5 }}>  5000 </Typography>
                                </TableCell>
                                <TableCell style={{ padding: "4px", fontSize: '10px', width: "118px", textAlign: 'center' }}>
                                    <Tooltip title="Quick Edit" placement="top" arrow>
                                        <IconButton onClick={() => {
                                        }}  >
                                            <Iconify icon="solar:pen-bold" />
                                        </IconButton>
                                    </Tooltip>
                                    <Tooltip title="Quick Delete" sx={{ color: 'error.main' }} placement="top" arrow>
                                        <IconButton onClick={() => {
                                        }}>
                                            <Iconify icon="solar:trash-bin-trash-bold" />
                                        </IconButton>
                                    </Tooltip>
                                </TableCell>

                            </TableRow>
                        </TableBody>
                    </Table>
                </AccordionDetails>
            </Accordion>
        </>
    );
}
