import * as Yup from 'yup';
import { useCallback, useEffect, useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import Box from '@mui/material/Box';
import {
    RHFTextField,
    RHFSelect,
} from 'src/components/hook-form';
import { Button, Grid, IconButton, MenuItem, Table, TableBody, TableCell, TableHead, TableRow, Tooltip, Typography } from '@mui/material';
import { GLOBALCLASSINPUT, TAXDETAIL_GRID_COLUMNS } from 'src/utils/contants/config-common';
import { getLookUp } from 'src/redux/slices/lookup';
import { useLocales } from 'src/locales';
import { DatePicker } from '@mui/x-date-pickers';
import { ITaxDetail } from 'src/types/common';
import { Controller, useForm } from 'react-hook-form';
import Iconify from 'src/components/iconify';

// ----------------------------------------------------------------------


export interface FormValuesProps extends ITaxDetail {

}

type Props = {
    onChange: (address: ITaxDetail) => void;
    gridTemplateColumns: any;
};

export default function TaxDetailForm({ onChange, gridTemplateColumns = 3 }: Props) {
    const [taxLookUp, setTaxLookUp] = useState([] as any);
    const { t } = useLocales();
    const NewInsuranceDetailSchema = Yup.object().shape({
        taxId: Yup.number(),
        taxRate: Yup.number(),
        taxAmount: Yup.number(),
        cgstId: Yup.number(),
        cgstRate: Yup.number(),
        cgstAmount: Yup.number(),
        sgstId: Yup.number(),
        sgstRate: Yup.number(),
        sgstAmount: Yup.number(),
    });

    const defaultValues = {
        taxId: "1",
        taxRate: 0,
        taxAmount: 0,
        cgstId: "2",
        cgstRate: 0,
        cgstAmount: 0,
        sgstId: 3,
        sgstRate: 0,
        sgstAmount: 0,
    };

    useEffect(() => {
        getLookUp("TAX").then((x: any) => {
            if (x && x.data && x.data.length > 0) {
                console.log(x.data)
                setTaxLookUp(x.data);
            }
        });
    }, [])

    const methods = useForm<FormValuesProps>({
        resolver: yupResolver(NewInsuranceDetailSchema),
        defaultValues,
    });

    const {
        handleSubmit, control,
        // formState: { isSubmitting },
    } = methods;

    const onInputChange = useCallback(
        async (data: FormValuesProps) => {
            try {
                onChange({
                    taxId: data.taxId,
                    taxRate: data.taxRate,
                    taxAmount: data.taxAmount,
                    cgstId: data.cgstId,
                    cgstRate: data.cgstRate,
                    cgstAmount: data.cgstAmount,
                    sgstId: data.sgstId,
                    sgstRate: data.sgstRate,
                    sgstAmount: data.sgstAmount
                });
            } catch (error) {
                console.error(error);
            }
        },
        [onChange]
    );

    return (
        <>
            <Box gap={3} display="grid" gridTemplateColumns={{ xs: `repeat(${gridTemplateColumns}, 1fr)`, }} >
                <RHFSelect size={GLOBALCLASSINPUT} name="taxId" label={t('COMMON.FORM.TAX') as any} >
                        <MenuItem  value="1"> Tax </MenuItem>
                        <MenuItem  value="2"> CGST </MenuItem>
                        <MenuItem  value="3"> IGST </MenuItem>
                </RHFSelect>
                <RHFTextField size={GLOBALCLASSINPUT} name="taxRate" label={t('COMMON.FORM.TAXRATE') as any} />
                <RHFTextField size={GLOBALCLASSINPUT} name="taxAmount" label={t('COMMON.FORM.TAXAMOUNT') as any} />
                <Box rowGap={3} columnGap={2} display="grid" gridTemplateColumns={{ xs: 'repeat(1, 1fr)', sm: 'repeat(2, 1fr)' }}>
                        <Button variant="contained" >
                            Save
                        </Button>
                        <Button >
                            Clear
                        </Button>
                    </Box>
                </Box>
                <br/>
                <Table sx={{ minWidth: "100%" }}>
                    <TableHead>
                        <TableRow>
                            {TAXDETAIL_GRID_COLUMNS && TAXDETAIL_GRID_COLUMNS.map((item: any) => (
                                <TableCell key={item.label} style={{ padding: "4px", fontSize: '10px', textAlign: 'center' }}>
                                    <Typography variant="caption" sx={{ mb: 0.5 }}>  {t(item)} </Typography>
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow>
                            <TableCell style={{ padding: "4px", fontSize: '10px',  textAlign: 'center' }}>
                                <Typography variant="caption" sx={{ mb: 0.5 }}>  CGST </Typography>
                            </TableCell>
                            <TableCell style={{ padding: "4px", fontSize: '10px',  textAlign: 'center' }}>
                                <Typography variant="caption" sx={{ mb: 0.5 }}>  9 </Typography>
                            </TableCell>
                            <TableCell style={{ padding: "4px",  textAlign: 'center' }}>
                                <Typography variant="caption" sx={{ mb: 0.5 }}>  30 </Typography>
                            </TableCell>
                            <TableCell align="center" sx={{ px: 1, whiteSpace: 'nowrap' }}>
                            <Tooltip title="Quick Edit" placement="top" arrow>
                                <IconButton onClick={() => {
                                }}  >
                                    <Iconify icon="solar:pen-bold" />
                                </IconButton>
                            </Tooltip>
                            <Tooltip title="Quick Delete" sx={{ color: 'error.main' }} placement="top" arrow>
                                <IconButton onClick={() => {
                                }}>
                                    <Iconify icon="solar:trash-bin-trash-bold" />
                                </IconButton>
                            </Tooltip>
                        </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell style={{ padding: "4px", fontSize: '10px',  textAlign: 'center' }}>
                                <Typography variant="caption" sx={{ mb: 0.5 }}>  IGST </Typography>
                            </TableCell>
                            <TableCell style={{ padding: "4px", fontSize: '10px',  textAlign: 'center' }}>
                                <Typography variant="caption" sx={{ mb: 0.5 }}>  9 </Typography>
                            </TableCell>
                            <TableCell style={{ padding: "4px",  textAlign: 'center' }}>
                                <Typography variant="caption" sx={{ mb: 0.5 }}>  20 </Typography>
                            </TableCell> 
                            <TableCell align="center" sx={{ px: 1, whiteSpace: 'nowrap' }}>
                            <Tooltip title="Quick Edit" placement="top" arrow>
                                <IconButton onClick={() => {
                                }}  >
                                    <Iconify icon="solar:pen-bold" />
                                </IconButton>
                            </Tooltip>
                            <Tooltip title="Quick Delete" sx={{ color: 'error.main' }} placement="top" arrow>
                                <IconButton onClick={() => {
                                }}>
                                    <Iconify icon="solar:trash-bin-trash-bold" />
                                </IconButton>
                            </Tooltip>
                        </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
              
        </>
    );
}
