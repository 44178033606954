 
import {  useSelector } from "src/redux/store";

export default function useOwner() {
    const { schemeCategory,schemeCategoryStatus} = useSelector(
        (state) => state.schemeCategory
    );
 
    return {
        schemeCategory,schemeCategoryStatus,
    }
}