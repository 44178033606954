import { Helmet } from "react-helmet-async";
import { useLocales } from "src/locales";
import OwnerListView from "src/sections/masters/vehicle-settings/owner/owner-list-view";


export default function OwnersListPage() {
  const {t}=useLocales();
    return (
      <>
        <Helmet>
          <title> { t("OWNERS.PAGE.TITLE")}</title>
        </Helmet>
  
        <OwnerListView />
      </>
    );
  }