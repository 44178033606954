import { createSlice, Dispatch } from '@reduxjs/toolkit';
import { IPincodeState } from 'src/types/pincode';
// utils
import axios, { API_ENDPOINTS } from 'src/utils/axios';


const initialState: IPincodeState = {
    pincodes:[],
    pincode:null,
    pincodesStatus:{
        loading:false,
        empty:false,
        error:null
    },
    pincodeStatus:{
        loading:false,
        error:null
    },
    pincodeDelete: {
        loading:false,
        empty:false,
        error:null
    }
}

const slice = createSlice({
    name: 'pincode',
    initialState,
    reducers:{
        getPincodesStart(state) {
            state.pincodesStatus.loading=true;
            state.pincodesStatus.empty=false;
            state.pincodesStatus.error = null;
        },
        getPincodesFailure(state, action) {
            state.pincodesStatus.loading=true;
            state.pincodesStatus.empty=false;
            state.pincodesStatus.error = action.payload;
        },
        getPincodesSuccess(state, action) {
            const pincodes = action.payload;
            state.pincodes = pincodes;
            state.pincodesStatus.loading = false;
            state.pincodesStatus.empty = !pincodes.length;
            state.pincodesStatus.error = null;
        }
        ,
        deletePincodeStart(state) {
            state.pincodeDelete.loading=true;
            state.pincodeDelete.empty=false;
            state.pincodeDelete.error = null;
        },
        deletePincodeFailure(state, action) {
            state.pincodeDelete.loading=true;
            state.pincodeDelete.empty=false;
            state.pincodeDelete.error = action.payload;
        },
        deletePincodeSuccess(state, action) {
            const pincodeDelete = action.payload;
            state.pincodeDelete = pincodeDelete;
            state.pincodeDelete.loading = false;
            state.pincodeDelete.empty = action.payload;
            state.pincodeDelete.error = null;
        }
    }
});

export default slice.reducer;

// Actions
export const {
    getPincodesStart,
    getPincodesFailure,
    getPincodesSuccess,
    deletePincodeStart,
    deletePincodeFailure,
    deletePincodeSuccess
} = slice.actions;

export function getPincodes(data:any) {
        return async (dispatch: Dispatch) => {
            dispatch(slice.actions.getPincodesStart());
            try{
               // const response = await axios.post(API_ENDPOINTS.VEHICLECLASS.SEARCH,data);
                dispatch(slice.actions.getPincodesSuccess([
                    {id:1,pincode:'500024',locality:"Chanchalguda",districtName:"Hyderabad -  Central",state:"Telanagana",countryName:"India",cityName:"Hyderabad -  Central",status:true},
                ]
                    ));
            }catch(error){
                dispatch(slice.actions.getPincodesFailure(error));
            }
        }
}

export function deletePincode(id: any) {
    return async (dispatch: Dispatch) => {
        dispatch(slice.actions.deletePincodeStart());
        try{
            const response = await axios.get(API_ENDPOINTS.BODYTYPE.DELETE+id);
            dispatch(slice.actions.deletePincodeSuccess(response.data));
        } catch(error){
            dispatch(slice.actions.deletePincodeFailure(error));
        }
    }
}
