import { useCallback } from "react";
import { deleteDistrict } from "src/redux/slices/geography-settings/district";
import { deleteVehicleClass } from "src/redux/slices/vehicle-class";
import { useDispatch, useSelector } from "src/redux/store";
import { useRouter } from "src/routes/hook";

export default function useDistrict() {
    const dispatch = useDispatch();
    const { district,districtDelete,districtsStatus,districts} = useSelector(
        (state) => state.district
    );

    const onDeleteDistrict = useCallback(
        (id: string) => {
            dispatch(deleteDistrict(id));
        },
        [dispatch]
    );

    return {
        district,districtDelete,districtsStatus,districts,
        onDeleteDistrict
    }
}