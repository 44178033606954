import { createSlice, Dispatch } from '@reduxjs/toolkit';
import { IInsuranceCompanyState } from 'src/types/general-settings/insurance.company';
// utils
import axios, { API_ENDPOINTS } from 'src/utils/axios';

const initialState: IInsuranceCompanyState = {
    insuranceCompany:[],
    insuranceCompanyStatus:{
        loading:false,
        empty:false,
        error:null
    },
    insuranceCompanyDelete: {
        loading:false,
        empty:false,
        error:null
    }
}

const slice = createSlice({
    name: 'insuranceCompany',
    initialState,
    reducers:{
        getInsuranceCompanyStart(state) {
            state.insuranceCompanyStatus.loading=true;
            state.insuranceCompanyStatus.empty=false;
            state.insuranceCompanyStatus.error = null;
        },
        getInsuranceCompanyFailure(state, action) {
            state.insuranceCompanyStatus.loading=true;
            state.insuranceCompanyStatus.empty=false;
            state.insuranceCompanyStatus.error = action.payload;
        },
        getInsuranceCompanySuccess(state, action) {
            const insuranceCompany = action.payload;
            state.insuranceCompany = insuranceCompany;
            state.insuranceCompanyStatus.loading = false;
            state.insuranceCompanyStatus.empty = !insuranceCompany.length;
            state.insuranceCompanyStatus.error = null;
        },
        deleteInsuranceCompanyStart(state) {
            state.insuranceCompanyDelete.loading=true;
            state.insuranceCompanyDelete.empty=false;
            state.insuranceCompanyDelete.error = null;
        },
        deleteInsuranceCompanyFailure(state, action) {
            state.insuranceCompanyDelete.loading=true;
            state.insuranceCompanyDelete.empty=false;
            state.insuranceCompanyDelete.error = action.payload;
        },
        deleteInsuranceCompanySuccess(state, action) {
            const insuranceCompanyDelete = action.payload;
            state.insuranceCompanyDelete = insuranceCompanyDelete;
            state.insuranceCompanyDelete.loading = false;
            state.insuranceCompanyDelete.empty = action.payload;
            state.insuranceCompanyDelete.error = null;
        }
    }
});

export default slice.reducer;

// Actions
export const {
    getInsuranceCompanyStart,
    getInsuranceCompanyFailure,
    getInsuranceCompanySuccess,
    deleteInsuranceCompanyStart,
    deleteInsuranceCompanyFailure,
    deleteInsuranceCompanySuccess
} = slice.actions;
 

export function getInsuranceCompanies(data:any) {
    return async (dispatch: Dispatch) => {
        dispatch(slice.actions.getInsuranceCompanyStart());
        try {
            await axios.get(API_ENDPOINTS.GENERALSETTINGS.INSURANCECOMPANY.LIST).then((res:any)=> {
                if(res.data.statusCode === 200)
                dispatch(slice.actions.getInsuranceCompanySuccess(res.data.data));
            else 
                 dispatch(slice.actions.getInsuranceCompanyFailure([]));
            });
        }catch(error){
            dispatch(slice.actions.getInsuranceCompanyFailure(error));
        }
    }
}

export function deleteInsuranceCompany(id: any) {
return  axios.delete(`${API_ENDPOINTS.GENERALSETTINGS.INSURANCECOMPANY.DELETE}/${id}`);
}

export function addUpdateInsuranceCompany(data: any) {
return axios.post(API_ENDPOINTS.GENERALSETTINGS.INSURANCECOMPANY.ADDUPDATEINSURANCECOMPANY,data);
}