import { createSlice, Dispatch } from '@reduxjs/toolkit';
import { IEnquiryState } from 'src/types/enquiry/enquiry.type';
// utils
import axios, { API_ENDPOINTS } from 'src/utils/axios';

const initialState: IEnquiryState = {
    enquiry:[],
    enquiryStatus:{
        loading:false,
        empty:false,
        error:null
    },
    enquiryDelete: {
        loading:false,
        empty:false,
        error:null
    }
}

const slice = createSlice({
    name: 'enquiry',
    initialState,
    reducers:{
        getEnquiryStart(state) {
            state.enquiryStatus.loading=true;
            state.enquiryStatus.empty=false;
            state.enquiryStatus.error = null;
        },
        getEnquiryFailure(state, action) {
            state.enquiryStatus.loading=true;
            state.enquiryStatus.empty=false;
            state.enquiryStatus.error = action.payload;
        },
        getEnquirySuccess(state, action) {
            const enquiry = action.payload;
            state.enquiry = enquiry;
            state.enquiryStatus.loading = false;
            state.enquiryStatus.empty = !enquiry.length;
            state.enquiryStatus.error = null;
        },
        deleteEnquiryStart(state) {
            state.enquiryDelete.loading=true;
            state.enquiryDelete.empty=false;
            state.enquiryDelete.error = null;
        },
        deleteEnquiryFailure(state, action) {
            state.enquiryDelete.loading=true;
            state.enquiryDelete.empty=false;
            state.enquiryDelete.error = action.payload;
        },
        deleteEnquirySuccess(state, action) {
            const enquiryDelete = action.payload;
            state.enquiryDelete = enquiryDelete;
            state.enquiryDelete.loading = false;
            state.enquiryDelete.empty = action.payload;
            state.enquiryDelete.error = null;
        }
    }
});

export default slice.reducer;

// Actions
export const {
    getEnquiryStart,
    getEnquiryFailure,
    getEnquirySuccess,
    deleteEnquiryStart,
    deleteEnquiryFailure,
    deleteEnquirySuccess
} = slice.actions;

export function getEnquiry(data:any) {
        return async (dispatch: Dispatch) => {
            dispatch(slice.actions.getEnquiryStart());
            try{
            //    const response = await axios.post(API_ENDPOINTS.VEHICLECLASS.SEARCH,data);
                dispatch(slice.actions.getEnquirySuccess([
                    {id:1,leadRefNumber:'671879821372',leadName:"Brandy Moris,Contacted Today 4:30 PM",contact:"987654321",emailAddress:"bindu@samsung.com",img:"https://miro.medium.com/v2/resize:fit:700/0*id3C2KWEdfTCwIYj",
                    leadStatus:'Warm',leadSource:"Offline",leadOwner:"Whitney Harrison",status:true},
                    {id:2,leadRefNumber:'671879821372',leadName:"Bindu Menon,Contacted Today 4:30 PM",contact:"987654321",emailAddress:"bindu@samsung.com",img:"https://miro.medium.com/v2/resize:fit:700/0*id3C2KWEdfTCwIYj",
                    leadStatus:'Cool',leadSource:"Online",leadOwner:"Whitney Harrison",status:false},
                ]
                    ));
            }catch(error){
                dispatch(slice.actions.getEnquiryFailure(error));
            }
        }
}

export function deleteEnquiry(id: any) {
    return async (dispatch: Dispatch) => {
        dispatch(slice.actions.deleteEnquiryStart());
        try{
            const response = await axios.get(API_ENDPOINTS.BODYTYPE.DELETE+id);
            dispatch(slice.actions.deleteEnquirySuccess(response.data));
        } catch(error){
            dispatch(slice.actions.deleteEnquiryFailure(error));
        }
    }
}
