import * as Yup from 'yup';
import { useSnackbar } from "src/components/snackbar";
import { CustomFile } from "src/components/upload";
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import FormProvider, { RHFCode, RHFSelect, RHFSwitch, RHFTextField, RHFUploadAvatar } from 'src/components/hook-form';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import LoadingButton from '@mui/lab/LoadingButton';
import { Accordion, AccordionDetails, AccordionSummary, Box, Card, Container, Dialog, DialogTitle, Divider, Grid, IconButton, MenuItem, Stack, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material';
import { ICustomer } from 'src/types/sales-journey/customer';
import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import { useLocales } from 'src/locales';
import { GLOBALCLASSINPUT, PUBLISH_OPTIONS } from 'src/utils/contants/config-common';
import { addUpdateCustomer } from 'src/redux/slices/sales-journey/customer';
import { DatePicker } from '@mui/x-date-pickers';
import { getLookUp } from 'src/redux/slices/lookup';
import { TABLE_HEAD_ADDRESSDETAILS, TABLE_HEAD_CONTACTDETAILS, TABLE_HEAD_DOCUMENTDETAILS } from 'src/utils/contants/sales-journey/customer';
import { fData } from 'src/utils/format-number';
import { CloseIcon } from 'src/components/lightbox';
import Iconify from 'src/components/iconify/iconify';
import AddressForm from 'src/sections/_common-components/address';
import { IAddressItem } from 'src/types/address';
import ComponentBlock from 'src/sections/_examples/component-block';
import Toolbar from 'src/sections/_common-components/toolbar';
import { paths } from 'src/routes/paths';
import { useSettingsContext } from 'src/components/settings';
import CustomBreadcrumbs from 'src/components/custom-breadcrumbs';
import { useParams, useRouter } from 'src/routes/hook';

interface FormValuesProps extends Omit<ICustomer, 'avatarUrl'> {
    avatarUrl: CustomFile | string | null;
}

type Props = {
    title: string;
    open: boolean;
    onClose: VoidFunction;
    submitButtonText?: string;
    currentModel: ICustomer;
};

// export default function CustomerForm({ currentModel, open, onClose, title, submitButtonText }: Props) {
    export default function CustomerForm() {
        const settings = useSettingsContext();
        const router = useRouter();
        const params = useParams();
        const { id } = params;
    const { enqueueSnackbar } = useSnackbar();
    const [gridTemplateColumns, setGridTemplateColumns] = useState(3);
    const [customerType, setCustomerTypeLookUp] = useState([] as any);
    const [corporateTypeLookUp, setCorporateTypeLookUp] = useState([] as any);
    const [corporateLookUp, setCorporateLookUp] = useState([] as any);
    const [corporateCategoryLookUp, setCorporateCategoryLookUp] = useState([] as any);
    const [genderLookUp, setGenderLookUp] = useState([] as any);
    const [maritalStatusLookUp, setMaritalStatusLookUp] = useState([] as any);
    const [usageCategoryLookUp, setUsageCategoryLookUp] = useState([] as any);
    const [customerCategoryLookUp, setCustomerCategoryLookUp] = useState([] as any);
    const [businessDetailLookUp, setBusinessDetailLookUp] = useState([] as any);
    const [currentModel, setCurrentModel] = useState({} as ICustomer);
    const [selectedRow, setSelectedRow] = useState({} as ICustomer);
    const [submitButtonText, setSubmitButtonText] = useState();
    const [controlled, setControlled] = useState<string | false>("FAMILYDETAIL");
    const { t } = useLocales();
    const [showErrorMsg, setShowErrorMsg] = useState(false as any);
    const NewModelSchema = Yup.object().shape({
        vinNumber: Yup.string(),
        name: Yup.string(),
        mobileNumber: Yup.string(),
        emailAddress: Yup.string(),
        customerTypeId: Yup.string(),
        contactOverWhatApp: Yup.boolean(),
        useMobileNumberAsWhatApp: Yup.boolean(),
        whatAppNumber: Yup.string(),
        corporateTypeId: Yup.string(),
        corporateId: Yup.string(),
        corporateCode: Yup.string(),
        corporateCategoryId: Yup.string(),
        dateOfBirth: Yup.string(),
        genderId: Yup.string(),
        maritalStatusId: Yup.string(),
        weddingAnivarsary: Yup.string(),
        occupation: Yup.string(),
        annualIncome: Yup.string(),
        drivingLicenceNumber: Yup.string(),
        aadharNumber: Yup.string(),
        voterId: Yup.string(),
        vehicleUsed: Yup.string(),
        motherTough: Yup.string(),
        pannumber: Yup.string(),
        gsitNumber: Yup.string(),
        tinNumber: Yup.string(),
        tanNumber: Yup.string(),
        usageCategoryId: Yup.string(),
        usageSubcategoryId: Yup.string(),
        customerCategoryId: Yup.string(),
        businessDetailId: Yup.string(),
        vehicleEmployeeDetails: Yup.string(),
        keyRoleDetails: Yup.string(),
        majorRoleDetail: Yup.string(),
        addressList: Yup.array(),
        address: Yup.object(),
        contactList: Yup.array(),
        contact: Yup.object(),
        familyList: Yup.array(),
        family: Yup.object(),
        accountDetail: Yup.string(),
        supportingDocumentList: Yup.array(),
        supportingDocument: Yup.object()
    });
    useEffect(() => {

    }, [])

    const onAddressChange = useCallback(
        (address: IAddressItem) => {

        },
        []
    );


    const defaultValues = useMemo(() => ({
        name: currentModel?.name || "",
        mobileNumber: currentModel?.mobileNumber || "",
        emailAddress: currentModel?.emailAddress || "",
        customerTypeId: currentModel?.customerTypeId || "",
        contactOverWhatApp: currentModel?.contactOverWhatApp || false,
        useMobileNumberAsWhatApp: currentModel?.useMobileNumberAsWhatApp || false,
        whatAppNumber: currentModel?.whatAppNumber || "",
        corporateTypeId: currentModel?.corporateTypeId || "",
        corporateId: currentModel?.corporateId || "",
        corporateCode: currentModel?.corporateCode || "",
        corporateCategoryId: currentModel?.corporateCategoryId || "",
        dateOfBirth: currentModel?.dateOfBirth || "",
        genderId: currentModel?.genderId || "",
        maritalStatusId: currentModel?.maritalStatusId || "",
        weddingAnivarsary: currentModel?.weddingAnivarsary || "",
        occupation: currentModel?.occupation || "",
        annualIncome: currentModel?.annualIncome || "",
        drivingLicenceNumber: currentModel?.drivingLicenceNumber || "",
        aadharNumber: currentModel?.aadharNumber || "",
        voterId: currentModel?.voterId || "",
        vehicleUsed: currentModel?.vehicleUsed || "",
        motherTough: currentModel?.motherTough || "",
        pannumber: currentModel?.pannumber || "",
        gsitNumber: currentModel?.gsitNumber || "",
        tinNumber: currentModel?.tinNumber || "",
        tanNumber: currentModel?.tanNumber || "",
        usageCategoryId: currentModel?.usageCategoryId || "",
        usageSubcategoryId: currentModel?.usageSubcategoryId || "",
        customerCategoryId: currentModel?.customerCategoryId || "",
        businessDetailId: currentModel?.businessDetailId || "",
        vehicleEmployeeDetails: currentModel?.vehicleEmployeeDetails || "",
        keyRoleDetails: currentModel?.keyRoleDetails || "",
        majorRoleDetail: currentModel?.majorRoleDetail || "",
        addressList: currentModel?.addressList || [],
        contactList: currentModel?.contactList || [],
        familyList: currentModel?.familyList || [],
        accountDetail: currentModel?.accountDetail || {} as any,
        supportingDocumentList: currentModel?.supportingDocumentList || [],
        id: currentModel?.id || 0
    }), [currentModel]);

    const methods = useForm<FormValuesProps>({
        resolver: yupResolver(NewModelSchema),
        defaultValues
    });

    const handleChangeControlled =
        (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
            setControlled(isExpanded ? panel : false);
        };

    const {
        reset,
        handleSubmit, control, watch,
        formState: { isSubmitting },
    } = methods;
    const values = watch();
    const onSubmit = useCallback(
        async (data: FormValuesProps) => {
            try {
                addUpdateCustomer({ ...data }).then((res: any) => {
                    console.log(res);
                    if (res.data.data > 0 && res.data.statusCode === 200) {
                        reset();
                        enqueueSnackbar(t('CUSTOMER.FORM.UPDATE_SUCCESS') as any);
                    } else {
                        setShowErrorMsg(true);
                    }
                });
                console.info('DATA', data);
            } catch (error) {
                console.error(error);
            }
        }, [enqueueSnackbar,  reset, t]);

    return (
        <Container maxWidth={settings.themeStretch ? false : 'lg'}>
        <CustomBreadcrumbs
            heading={t("CUSTOMER.VIEW.VIEW_HEADING") as any}
            links={[
                { name: t("DASHBOARD") as any, href: paths.dashboard.root },
                {
                  name: t("CUSTOMER.VIEW.VIEW_NAME") as any,
                  href: paths.dashboard.salesJouryney.customer.root,
                }
            ]}
            sx={{
                mb: { xs: 3, md: 5 },
            }}
        />
         <Toolbar
            backLink={paths.dashboard.salesJouryney.customer.root}
            editLink={paths.dashboard.salesJouryney.customer.edit(`${selectedRow?.id}`)}
            liveLink={paths.dashboard.salesJouryney.customer.root}
            publish={id ? 'Update' : 'Add'}
            onChangePublish={() => { }}
            publishOptions={PUBLISH_OPTIONS}
        />
            <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
                    {showErrorMsg && <Alert variant="outlined" severity="error" sx={{ mb: 3 }}>
                        {t('CUSTOMER.FORM.ALREADY_EXISTS_ERROR')}
                    </Alert>}
                    <Grid container spacing={3} style={{ padding: "25px" }}>
                        <Grid xs={12} md={3}>
                        <ComponentBlock>
                                <RHFUploadAvatar
                                    name="avatarUrl"
                                    maxSize={3000000}
                                    helperText={
                                        <Typography
                                            variant="caption"
                                            sx={{
                                                mt: 1,
                                                mx: 'auto',
                                                display: 'block',
                                                textAlign: 'center',
                                                color: 'text.disabled',
                                            }}
                                        >
                                            Allowed *.jpeg, *.jpg, *.png, *.gif
                                            <br /> max size of {fData(3000000)}
                                        </Typography>
                                    }
                                />
                                <br />
                                <Table sx={{ maxWidth: 300 }}>
                                    <TableBody>
                                        <TableRow>
                                            <TableCell style={{ padding: "4px", width: "118px" }}>
                                                <Typography variant="caption" sx={{ mb: 0.5 }}>  Customer Name: </Typography>
                                            </TableCell>
                                            <TableCell style={{ padding: "4px", width: "118px" }}>
                                                <Typography variant="caption" sx={{ mb: 0.5 }}>  Raghu Varan </Typography>
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell style={{ padding: "4px", width: "118px" }}>
                                                <Typography variant="caption" sx={{ mb: 0.5 }}> Customer Type: </Typography>
                                            </TableCell>
                                            <TableCell style={{ padding: "4px", width: "118px" }}>
                                                <Typography variant="caption" sx={{ mb: 0.5 }}> Direct </Typography>
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                                <br />
                                <RHFSwitch
                                    name="isVerified"
                                    labelPlacement="start"
                                    label={
                                        <>
                                            <Typography variant="subtitle2" sx={{ mb: 0.5 }}>
                                                Email Verified
                                            </Typography>
                                            <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                                                Disabling this will automatically send the user a verification email
                                            </Typography>
                                        </>
                                    }
                                    sx={{ mx: 0, width: 1, justifyContent: 'space-between' }}
                                />
                            </ComponentBlock>
                        </Grid>
                        <Grid xs={12} md={9} style={{ paddingLeft: "18px" }}>
                        <ComponentBlock>
                            <Box rowGap={2} columnGap={2} display="grid" gridTemplateColumns={{ xs: 'repeat(1, 1fr)', sm: `repeat(${gridTemplateColumns}, 1fr)` }}>
                                <RHFTextField size={GLOBALCLASSINPUT} name="name" label={t("COMMON.FORM.NAME")} />
                                <RHFTextField size={GLOBALCLASSINPUT} name="mobileNumber" label={t("COMMON.FORM.MOBILENUMBER")} />
                                <RHFTextField size={GLOBALCLASSINPUT} name="customerTypeId" label={t("COMMON.FORM.CUSTOMERTYPE")} />
                                <RHFTextField size={GLOBALCLASSINPUT} name="emailAddress" label={t("COMMON.FORM.EMAILADDRESS")} />
                                <RHFSwitch name="contactOverWhatApp" label={t("COMMON.FORM.CONTACTOVERWHATAPP")} />
                                <RHFSwitch name="useMobileNumberAsWhatApp" label={t("COMMON.FORM.USEMOBILENUMBERASWHATAPP")} />
                                <RHFTextField size={GLOBALCLASSINPUT} name="whatAppNumber" label={t("COMMON.FORM.WHATAPPNUMBER")} />
                                <RHFTextField size={GLOBALCLASSINPUT} name="corporateTypeId" label={t("COMMON.FORM.CORPORATETYPE")} />
                                <RHFTextField size={GLOBALCLASSINPUT} name="corporateId" label={t("COMMON.FORM.CORPORATE")} />
                                <RHFTextField size={GLOBALCLASSINPUT} name="corporateCode" label={t("COMMON.FORM.CORPORATECODE")} />
                                <RHFTextField size={GLOBALCLASSINPUT} name="corporateCategoryId" label={t("COMMON.FORM.CORPORATECATEGORY")} />
                                <RHFTextField size={GLOBALCLASSINPUT} name="dateOfBirth" label={t("COMMON.FORM.DATEOFBIRTH")} />
                                <RHFTextField size={GLOBALCLASSINPUT} name="genderId" label={t("COMMON.FORM.GENDER")} />
                                <RHFTextField size={GLOBALCLASSINPUT} name="maritalStatusId" label={t("COMMON.FORM.MARITALSTATUS")} />
                                <RHFTextField size={GLOBALCLASSINPUT} name="weddingAnivarsary" label={t("COMMON.FORM.WEDDINGANIVARSARY")} />
                                <RHFTextField size={GLOBALCLASSINPUT} name="occupation" label={t("COMMON.FORM.OCCUPATION")} />
                                <RHFTextField size={GLOBALCLASSINPUT} name="annualIncome" label={t("COMMON.FORM.ANNUALINCOME")} />
                                <RHFTextField size={GLOBALCLASSINPUT} name="drivingLicenceNumber" label={t("COMMON.FORM.DRIVINGLICENCENUMBER")} />
                                <RHFTextField size={GLOBALCLASSINPUT} name="aadharNumber" label={t("COMMON.FORM.AADHARNUMBER")} />
                                <RHFTextField size={GLOBALCLASSINPUT} name="voterId" label={t("COMMON.FORM.VOTERID")} />
                                <RHFTextField size={GLOBALCLASSINPUT} name="vehicleUsed" label={t("COMMON.FORM.VEHICLEUSED")} />
                                <RHFTextField size={GLOBALCLASSINPUT} name="motherTough" label={t("COMMON.FORM.MOTHERTOUGH")} />
                                <RHFTextField size={GLOBALCLASSINPUT} name="pannumber" label={t("COMMON.FORM.PANNUMBER")} />
                                <RHFTextField size={GLOBALCLASSINPUT} name="gsitNumber" label={t("COMMON.FORM.GSITNUMBER")} />
                                <RHFTextField size={GLOBALCLASSINPUT} name="tinNumber" label={t("COMMON.FORM.TINNUMBER")} />
                                <RHFTextField size={GLOBALCLASSINPUT} name="tanNumber" label={t("COMMON.FORM.TANNUMBER")} />
                                <RHFTextField size={GLOBALCLASSINPUT} name="usageCategoryId" label={t("COMMON.FORM.USAGECATEGORY")} />
                                <RHFTextField size={GLOBALCLASSINPUT} name="usageSubcategoryId" label={t("COMMON.FORM.USAGESUBCATEGORY")} />
                                <RHFTextField size={GLOBALCLASSINPUT} name="customerCategoryId" label={t("COMMON.FORM.CUSTOMERCATEGORY")} />
                                <RHFTextField size={GLOBALCLASSINPUT} name="businessDetailId" label={t("COMMON.FORM.BUSINESSDETAIL")} />
                                <RHFTextField size={GLOBALCLASSINPUT} name="vehicleEmployeeDetails" label={t("COMMON.FORM.VEHICLEEMPLOYEEDETAILS")} />
                                <RHFTextField size={GLOBALCLASSINPUT} name="keyRoleDetails" label={t("COMMON.FORM.KEYROLEDETAILS")} />
                                <RHFTextField size={GLOBALCLASSINPUT} name="majorRoleDetail" label={t("COMMON.FORM.MAJORROLEDETAIL")} />
                            </Box>
                         </ComponentBlock>
                            <br />
                            <Accordion key='FAMILYDETAIL'
                                expanded={controlled === 'FAMILYDETAIL'}
                                onChange={handleChangeControlled('FAMILYDETAIL')}>
                                <AccordionSummary expandIcon={<Iconify icon="eva:arrow-ios-downward-fill" />} >
                                    <Typography variant="subtitle1" style={{ width: "100%" }}>Family Detail  </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Card sx={{ p: 3 }}>
                                        <Box rowGap={3} columnGap={2} display="grid" gridTemplateColumns={{ xs: 'repeat(1, 1fr)', sm: `repeat(${gridTemplateColumns}, 1fr)` }}>
                                            <RHFTextField size={GLOBALCLASSINPUT} name="customerTypeId" label={t("COMMON.FORM.CUSTOMERTYPE")} />
                                            <RHFTextField size={GLOBALCLASSINPUT} name="customerName" label={t("COMMON.FORM.CUSTOMERNAME")} />
                                            <RHFTextField size={GLOBALCLASSINPUT} name="relationshipId" label={t("COMMON.FORM.RELATIONSHIP")} />
                                            <RHFTextField size={GLOBALCLASSINPUT} name="dateOfBirth" label={t("COMMON.FORM.DATEOFBIRTH")} />
                                            <RHFTextField size={GLOBALCLASSINPUT} name="age" label={t("COMMON.FORM.AGE")} />
                                            <RHFTextField size={GLOBALCLASSINPUT} name="remarks" label={t("COMMON.FORM.REMARK")} />
                                        </Box>
                                    </Card>
                                </AccordionDetails>
                            </Accordion>
                            <Accordion key='ACCOUNTDETAIL'
                                expanded={controlled === 'ACCOUNTDETAIL'}
                                onChange={handleChangeControlled('ACCOUNTDETAIL')}>
                                <AccordionSummary expandIcon={<Iconify icon="eva:arrow-ios-downward-fill" />} >
                                    <Typography variant="subtitle1" style={{ width: "100%" }}>Account  </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Card sx={{ p: 3 }}>
                                        <Box rowGap={3} columnGap={2} display="grid" gridTemplateColumns={{ xs: 'repeat(1, 1fr)', sm: `repeat(${gridTemplateColumns}, 1fr)` }}>
                                            <RHFTextField size={GLOBALCLASSINPUT} name="creditLimit" label={t("COMMON.FORM.CREDITLIMIT")} />
                                            <RHFTextField size={GLOBALCLASSINPUT} name="creditLimitDays" label={t("COMMON.FORM.CREDITLIMITDAYS")} />
                                            <RHFTextField size={GLOBALCLASSINPUT} name="outstandingAmount" label={t("COMMON.FORM.OUTSTANDINGAMOUNT")} />
                                            <RHFTextField size={GLOBALCLASSINPUT} name="partsDiscount" label={t("COMMON.FORM.PARTSDISCOUNT")} />
                                            <RHFTextField size={GLOBALCLASSINPUT} name="labourDiscount" label={t("COMMON.FORM.LABOURDISCOUNT")} />
                                            <RHFTextField size={GLOBALCLASSINPUT} name="remarks" label={t("COMMON.FORM.REMARK")} />
                                        </Box>
                                    </Card>
                                </AccordionDetails>
                            </Accordion>
                            <Accordion key='ADDRESSDETAIL'
                                expanded={controlled === 'ADDRESSDETAIL'}
                                onChange={handleChangeControlled('ADDRESSDETAIL')}>
                                <AccordionSummary expandIcon={<Iconify icon="eva:arrow-ios-downward-fill" />} >
                                    <Typography variant="subtitle1" style={{ width: "100%" }}>Address Detail  </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Card sx={{ p: 3 }}>
                                        <Box rowGap={3} columnGap={2} display="grid" gridTemplateColumns={{ xs: 'repeat(1, 1fr)', sm: `repeat(${gridTemplateColumns}, 1fr)` }}>
                                            <AddressForm onChange={onAddressChange} />
                                            <Box rowGap={3} columnGap={2} display="grid" gridTemplateColumns={{ xs: 'repeat(1, 1fr)', sm: 'repeat(2, 1fr)' }}>
                                                <Button variant="contained" >  Add </Button>
                                                <Button >  Clear  </Button>
                                            </Box>
                                        </Box>
                                        <br />
                                        <Table sx={{ minWidth: "100%" }}>
                                            <TableHead>
                                                <TableRow>
                                                    {TABLE_HEAD_ADDRESSDETAILS && TABLE_HEAD_ADDRESSDETAILS.map((item: any) => (
                                                        <TableCell key={item.label} style={{ padding: "4px", fontSize: '10px', textAlign: 'center' }}>
                                                            <Typography variant="caption" sx={{ mb: 0.5 }}>  {t(item.label)} </Typography>
                                                        </TableCell>
                                                    ))}
                                                </TableRow>
                                            </TableHead>
                                        </Table>
                                    </Card>
                                </AccordionDetails>
                            </Accordion>
                            <Accordion key='CONTACTDETAIL'
                                expanded={controlled === 'CONTACTDETAIL'}
                                onChange={handleChangeControlled('CONTACTDETAIL')}>
                                <AccordionSummary expandIcon={<Iconify icon="eva:arrow-ios-downward-fill" />} >
                                    <Typography variant="subtitle1" style={{ width: "100%" }}>Contact Detail  </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Card sx={{ p: 3 }}>
                                        <Box rowGap={3} columnGap={2} display="grid" gridTemplateColumns={{ xs: 'repeat(1, 1fr)', sm: `repeat(${gridTemplateColumns}, 1fr)` }}>
                                            <RHFTextField size={GLOBALCLASSINPUT} name="proposeContactId" label={t("COMMON.FORM.PROPSECONTACT")} />
                                            <RHFTextField size={GLOBALCLASSINPUT} name="mobileNumber" label={t("COMMON.FORM.MOBILENUMBER")} />
                                            <RHFTextField size={GLOBALCLASSINPUT} name="alternateNumber" label={t("COMMON.FORM.ALTERNATENUMBER")} />
                                            <RHFTextField size={GLOBALCLASSINPUT} name="relationshipId" label={t("COMMON.FORM.RELATIONSHIP")} />
                                            <RHFTextField size={GLOBALCLASSINPUT} name="genderIE" label={t("COMMON.FORM.GENDER")} />
                                            <RHFTextField size={GLOBALCLASSINPUT} name="customerName" label={t("COMMON.FORM.CUSTOMERNAME")} />
                                            <RHFTextField size={GLOBALCLASSINPUT} name="emailAddress" label={t("COMMON.FORM.EMAILADDRESS")} />
                                            <Box rowGap={3} columnGap={2} display="grid" gridTemplateColumns={{ xs: 'repeat(1, 1fr)', sm: 'repeat(2, 1fr)' }}>
                                                <Button variant="contained" >  Add </Button>
                                                <Button>  Clear  </Button>
                                            </Box>
                                        </Box>
                                        <br />
                                        <Table sx={{ minWidth: "100%" }}>
                                            <TableHead>
                                                <TableRow>
                                                    {TABLE_HEAD_CONTACTDETAILS && TABLE_HEAD_CONTACTDETAILS.map((item: any) => (
                                                        <TableCell key={item.label} style={{ padding: "4px", fontSize: '10px', textAlign: 'center' }}>
                                                            <Typography variant="caption" sx={{ mb: 0.5 }}>  {t(item.label)} </Typography>
                                                        </TableCell>
                                                    ))}
                                                </TableRow>
                                            </TableHead>
                                        </Table>
                                    </Card>
                                </AccordionDetails>
                            </Accordion>
                            <Accordion key='SUPPORTINGDOCUMENTS'
                                expanded={controlled === 'SUPPORTINGDOCUMENTS'}
                                onChange={handleChangeControlled('SUPPORTINGDOCUMENTS')}>
                                <AccordionSummary expandIcon={<Iconify icon="eva:arrow-ios-downward-fill" />} >
                                    <Typography variant="subtitle1" style={{ width: "100%" }}>Supporting Document  </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Card sx={{ p: 3 }}>

                                    <Box rowGap={3} columnGap={2} display="grid" gridTemplateColumns={{ xs: 'repeat(1, 1fr)', sm: `repeat(${gridTemplateColumns}, 1fr)` }}>
                                            <RHFTextField size={GLOBALCLASSINPUT} name="documentType" label={t("COMMON.FORM.DOCUMENTTYPE")} />
                                            <RHFTextField size={GLOBALCLASSINPUT} name="fileName" label={t("COMMON.FORM.FILENAME")} />
                                            <Box rowGap={3} columnGap={2} display="grid" gridTemplateColumns={{ xs: 'repeat(1, 1fr)', sm: 'repeat(2, 1fr)' }}>
                                                <Button variant="contained" >  Add </Button>
                                                <Button>  Clear  </Button>
                                            </Box>
                                        </Box>
                                        <br />
                                        <Table sx={{ minWidth: "100%" }}>
                                            <TableHead>
                                                <TableRow>
                                                    {TABLE_HEAD_DOCUMENTDETAILS && TABLE_HEAD_DOCUMENTDETAILS.map((item: any) => (
                                                        <TableCell key={item.label} style={{ padding: "4px", fontSize: '10px', textAlign: 'center' }}>
                                                            <Typography variant="caption" sx={{ mb: 0.5 }}>  {t(item.label)} </Typography>
                                                        </TableCell>
                                                    ))}
                                                </TableRow>
                                            </TableHead>
                                        </Table>
                                    </Card>
                                </AccordionDetails>
                            </Accordion>
                        </Grid>
                    </Grid>
           
                    <Button variant="outlined">
                        {t("CANCEL")}
                    </Button>
                    <LoadingButton type="submit" variant="contained" loading={isSubmitting}>
                        {submitButtonText}
                    </LoadingButton>
            </FormProvider>
            </Container>
    )

}