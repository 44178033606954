import { createSlice, Dispatch } from '@reduxjs/toolkit';
import { IHypothecatedState } from 'src/types/general-settings/hypothecated';
// utils
import axios, { API_ENDPOINTS } from 'src/utils/axios';

const initialState: IHypothecatedState = {
    hypothecated:[],
    hypothecatedStatus:{
        loading:false,
        empty:false,
        error:null
    },
    hypothecatedDelete: {
        loading:false,
        empty:false,
        error:null
    }
}

const slice = createSlice({
    name: 'hypothecated',
    initialState,
    reducers:{
        getHypothecatedStart(state) {
            state.hypothecatedStatus.loading=true;
            state.hypothecatedStatus.empty=false;
            state.hypothecatedStatus.error = null;
        },
        getHypothecatedFailure(state, action) {
            state.hypothecatedStatus.loading=true;
            state.hypothecatedStatus.empty=false;
            state.hypothecatedStatus.error = action.payload;
        },
        getHypothecatedSuccess(state, action) {
            const hypothecated = action.payload;
            state.hypothecated = hypothecated;
            state.hypothecatedStatus.loading = false;
            state.hypothecatedStatus.empty = !hypothecated.length;
            state.hypothecatedStatus.error = null;
        },
        deleteHypothecatedStart(state) {
            state.hypothecatedDelete.loading=true;
            state.hypothecatedDelete.empty=false;
            state.hypothecatedDelete.error = null;
        },
        deleteHypothecatedFailure(state, action) {
            state.hypothecatedDelete.loading=true;
            state.hypothecatedDelete.empty=false;
            state.hypothecatedDelete.error = action.payload;
        },
        deleteHypothecatedSuccess(state, action) {
            const hypothecatedDelete = action.payload;
            state.hypothecatedDelete = hypothecatedDelete;
            state.hypothecatedDelete.loading = false;
            state.hypothecatedDelete.empty = action.payload;
            state.hypothecatedDelete.error = null;
        }
    }
});

export default slice.reducer;

// Actions
export const {
    getHypothecatedStart,
    getHypothecatedFailure,
    getHypothecatedSuccess,
    deleteHypothecatedStart,
    deleteHypothecatedFailure,
    deleteHypothecatedSuccess
} = slice.actions;
 

export function getHypothecateds(data:any) {
    return async (dispatch: Dispatch) => {
        dispatch(slice.actions.getHypothecatedStart());
        try {
            await axios.get(API_ENDPOINTS.GENERALSETTINGS.HYPOTHECATED.LIST).then((res:any)=> {
                if(res.data.statusCode === 200)
                dispatch(slice.actions.getHypothecatedSuccess(res.data.data));
            else 
                 dispatch(slice.actions.getHypothecatedFailure([]));
            });
        }catch(error){
            dispatch(slice.actions.getHypothecatedFailure(error));
        }
    }
}

export function deleteHypothecated(id: any) {
return  axios.delete(`${API_ENDPOINTS.GENERALSETTINGS.HYPOTHECATED.DELETE}/${id}`);
}

export function addUpdateHypothecated(data: any) {
return axios.post(API_ENDPOINTS.GENERALSETTINGS.HYPOTHECATED.ADDUPDATEHYPOTHECATED,data);
}