import * as Yup from 'yup';
import { useCallback } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import Box from '@mui/material/Box';
// types
import { IAddressItem } from 'src/types/address';
// assets
import { countries } from 'src/assets/data';
// components
import Iconify from 'src/components/iconify';
import {
    RHFTextField,
    RHFAutocomplete,
    RHFSelect,
} from 'src/components/hook-form';
import { Divider, Grid, MenuItem } from '@mui/material';
import { GLOBALCLASSINPUT } from 'src/utils/contants/config-common';
import { useLocales } from 'src/locales';

// ----------------------------------------------------------------------

interface FormValuesProps extends IAddressItem {
    address: string;
    city: string;
    state: string;
    country: string;
    zipCode: string;
}

type Props = {
    onChange: (address: IAddressItem) => void;
};

export default function AddressForm({ onChange }: Props) {
    const { t } = useLocales();

    const NewAddressSchema = Yup.object().shape({
        address: Yup.string().required(t("COMMON.FORM.REQUIRED.ADDRESS") as any),
        addressLine1: Yup.string().required(t("COMMON.FORM.REQUIRED.ADDRESSLINE1") as any),
        addressLine2: Yup.string().required(t("COMMON.FORM.REQUIRED.ADDRESSLINE2") as any),
        addressLine3: Yup.string().required(t("COMMON.FORM.REQUIRED.ADDRESSLINE3") as any),
        city: Yup.string().required(t("COMMON.FORM.REQUIRED.CITY") as any),
        mandal: Yup.string().required(t("COMMON.FORM.REQUIRED.MANDAL") as any),
        state: Yup.string().required(t("COMMON.FORM.REQUIRED.STATE") as any),
        country: Yup.string().required(t("COMMON.FORM.REQUIRED.COUNTRY") as any),
        pincode: Yup.string().required(t("COMMON.FORM.REQUIRED.PINCODE") as any),
    });

    const defaultValues = {
        fullAddress: '',
        address: '',
        addressLine1: '',
        addressLine2: '',
        addressLine3: '',
        city: '',
        state: '',
        country: '',
        mandal: '',
        pincode: '',
        primary: true,
    };

    const methods = useForm<FormValuesProps>({
        resolver: yupResolver(NewAddressSchema),
        defaultValues,
    });

    const {
        handleSubmit,
        // formState: { isSubmitting },
    } = methods;

    const onInputChange = useCallback(
        async (data: FormValuesProps) => {
            try {
                onChange({
                    fullAddress: `${data.address}, ${data.city}, ${data.state}, ${data.country}, ${data.pincode}`,
                    address: data.address,
                    addressLine1: data.addressLine1,
                    addressLine2: data.addressLine2,
                    addressLine3: data.addressLine3,
                    city: data.city,
                    state: data.state,
                    country: data.country,
                    mandal: data.mandal,
                    pincode: data.pincode,
                    primary: data.primary,
                    name: ""
                });
            } catch (error) {
                console.error(error);
            }
        },
        [onChange]
    );

    return (
        <>
            <RHFTextField size={GLOBALCLASSINPUT} name="address" label={t("COMMON.FORM.ADDRESSLINE1")} onChange={() => onInputChange} />
            <RHFTextField size={GLOBALCLASSINPUT} name="addressLine1" label={t("COMMON.FORM.ADDRESSLINE2")} onChange={() => onInputChange} />
            <RHFTextField size={GLOBALCLASSINPUT} name="addressLine2" label={t("COMMON.FORM.LANDMARK")} onChange={() => onInputChange} />
            <RHFTextField size={GLOBALCLASSINPUT} name="city" label="Town / City" onChange={() => onInputChange} />
            <RHFTextField size={GLOBALCLASSINPUT} name="mandal" label="Mandal" onChange={() => onInputChange} />
            <RHFTextField size={GLOBALCLASSINPUT} name="district" label="District" onChange={() => onInputChange} />

            <RHFTextField size={GLOBALCLASSINPUT} name="state" label="State" onChange={() => onInputChange} />
            <RHFTextField size={GLOBALCLASSINPUT} name="zipCode" label="Zipcode" onChange={() => onInputChange} />
            <RHFSelect name="countryId" size={GLOBALCLASSINPUT} label={t('COMMON.FORM.COUNTRY') as any} >
            <MenuItem value="">None</MenuItem>
                <Divider sx={{ borderStyle: 'dashed' }} />
                {countries && countries.map((item: any) => (
                    <MenuItem key={item.value + item.label} value={item.value}>
                        <Iconify
                            key={item.label}
                            icon={`circle-flags:${item.code.toLowerCase()}`}
                            width={28}
                            sx={{ mr: 1 }}
                        />
                        {item.label} ({item.code}) +{item.phone} </MenuItem>
                ))}
            </RHFSelect>
        </>
    );
}
