export const GLOBALCLASSINPUT="small";

export const TAXDETAIL_GRID_COLUMNS=[ "Tax Description","Tax Rate","Tax Amount","Action"]
export const ACCESSORIES_GRID_COLUMNS=[ "Part Name","Part Number","Selling Price","Discount","Quantity","Action"]
export const SCHEMEANDOFFERS_GRID_COLUMNS=[ "Scheme Type","Scheme Category","Amount","Valid From","Valid To","Action"]



export const PUBLISH_OPTIONS = [
    {
      value: 'published',
      label: 'Published',
    }
  ];