import {  useSelector } from "src/redux/store";

export default function useBuyerType() {
    const { buyerType,buyerTypeDelete,buyerTypeStatus} = useSelector(
        (state) => state.buyerType
    );

    return {
        buyerType,buyerTypeDelete,buyerTypeStatus,
    }
}