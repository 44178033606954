import { Helmet } from "react-helmet-async";
import { useLocales } from "src/locales";
import LeadCategoryListView from "src/sections/masters/lead-settings/lead-category/lead-category-list-view";

export default function LeadCategoryListPage() {
  const {t}=useLocales();
    return (
      <>
        <Helmet>
          <title> { t("LEADCATEGORY.PAGE.TITLE")}</title>
        </Helmet>
  
        <LeadCategoryListView />
      </>
    );
  }