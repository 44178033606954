import { createSlice, Dispatch } from '@reduxjs/toolkit';
import { ISalutationState } from 'src/types/general-settings/salutation';
// utils
import axios, { API_ENDPOINTS } from 'src/utils/axios';

const initialState: ISalutationState = {
    salutation:[],
    salutationStatus:{
        loading:false,
        empty:false,
        error:null
    },
    salutationDelete: {
        loading:false,
        empty:false,
        error:null
    }
}

const slice = createSlice({
    name: 'salutation',
    initialState,
    reducers:{
        getSalutationStart(state) {
            state.salutationStatus.loading=true;
            state.salutationStatus.empty=false;
            state.salutationStatus.error = null;
        },
        getSalutationFailure(state, action) {
            state.salutationStatus.loading=true;
            state.salutationStatus.empty=false;
            state.salutationStatus.error = action.payload;
        },
        getSalutationSuccess(state, action) {
            const salutation = action.payload;
            state.salutation = salutation;
            state.salutationStatus.loading = false;
            state.salutationStatus.empty = !salutation.length;
            state.salutationStatus.error = null;
        },
        deleteSalutationStart(state) {
            state.salutationDelete.loading=true;
            state.salutationDelete.empty=false;
            state.salutationDelete.error = null;
        },
        deleteSalutationFailure(state, action) {
            state.salutationDelete.loading=true;
            state.salutationDelete.empty=false;
            state.salutationDelete.error = action.payload;
        },
        deleteSalutationSuccess(state, action) {
            const salutationDelete = action.payload;
            state.salutationDelete = salutationDelete;
            state.salutationDelete.loading = false;
            state.salutationDelete.empty = action.payload;
            state.salutationDelete.error = null;
        }
    }
});

export default slice.reducer;

// Actions
export const {
    getSalutationStart,
    getSalutationFailure,
    getSalutationSuccess,
    deleteSalutationStart,
    deleteSalutationFailure,
    deleteSalutationSuccess
} = slice.actions;
 

export function getSalutations(data:any) {
    return async (dispatch: Dispatch) => {
        dispatch(slice.actions.getSalutationStart());
        try {
            await axios.get(API_ENDPOINTS.GENERALSETTINGS.SALUTATION.LIST).then((res:any)=> {
                if(res.data.statusCode === 200)
                dispatch(slice.actions.getSalutationSuccess(res.data.data));
            else 
                 dispatch(slice.actions.getSalutationFailure([]));
            });
        }catch(error){
            dispatch(slice.actions.getSalutationFailure(error));
        }
    }
}

export function deleteSalutation(id: any) {
return  axios.delete(`${API_ENDPOINTS.GENERALSETTINGS.SALUTATION.DELETE}/${id}`);
}

export function addUpdateSalutation(data: any) {
return axios.post(API_ENDPOINTS.GENERALSETTINGS.SALUTATION.ADDUPDATESALUTATION,data);
}