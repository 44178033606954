import { Helmet } from "react-helmet-async";
import { useLocales } from "src/locales";
import FuelTypeListView from "src/sections/masters/settings/fuel-type/fuel-type-list-view";

export default function FuelTypeListPage() {
  const {t}=useLocales();
    return (
      <>
        <Helmet>
          <title> { t("FUELTYPE.PAGE.TITLE")}</title>
        </Helmet>
  
        <FuelTypeListView/>
      </>
    );
  }