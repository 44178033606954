import { Helmet } from "react-helmet-async";
import { useLocales } from "src/locales";
import RelationShipListView from "src/sections/masters/general-settings/relation-ship/relation-ship-list-view";


export default function RelationShipListPage() {
  const {t}=useLocales();
    return (
      <>
        <Helmet>
          <title> { t("RELATIONSHIP.PAGE.TITLE")}</title>
        </Helmet>
        <RelationShipListView/>
      </>
    );
  }