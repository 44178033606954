 
import {  useSelector } from "src/redux/store";

export default function useRelationShip() {
    const { relationShip,relationShipStatus} = useSelector(
        (state) => state.relationShip
    );
 
    return {
        relationShip,relationShipStatus,
    }
}